import { Form } from "react-bootstrap";
import { createInitialManagerForTenantSchema, formikDefault } from "utils/forms-schema";
import LargeCard from "components/Structure/LargeCard";
import { StringInput } from "../../../../components/Reports/Fields";
import { useFormik } from "formik";
import { firstLetterToUppercase } from "../../../../utils/strings";

export const AddTenantAdminForm = ({ tenant, updateTenant }) => {

  // Copy contact person to manager
  if (tenant.loginFirstName === null) {
    for (const key of ["firstName", "lastName", "email"]) {
      tenant[`login${firstLetterToUppercase(key)}`] = tenant[key];
    }
  }

  // Define the form
  const formik = useFormik({
    ...formikDefault,
    initialValues: tenant,
    validationSchema: createInitialManagerForTenantSchema,
    onSubmit: tenant => updateTenant({ ...tenant, submit: true })
  });

  return (
    <div className="col-xl-6 offset-xl-3">
      <LargeCard title="Create a new tenant">
        <Form noValidate onSubmit={formik.handleSubmit}>

          <h4 className="form-header">Initial tenant manager</h4>
          <div className="row">
            <div className="col-md-6"><StringInput placeholder="First Name" name="loginFirstName" formik={formik} /></div>
            <div className="col-md-6"><StringInput placeholder="Last Name" name="loginLastName" formik={formik} /></div>
          </div>
          <StringInput placeholder="Email" name="loginEmail" formik={formik} />

          <h4 className="form-header">Login credentials</h4>
          <StringInput placeholder="Password" name="loginPassword" type="password" formik={formik} />
          <StringInput placeholder="Confirm password" name="loginPasswordRepeat" type="password" formik={formik} />

          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={() => updateTenant({ ...formik.values, step: 1 })}>Back</button>
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </Form>
      </LargeCard>
    </div>
  );
};
