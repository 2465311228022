import Select from "react-select"
import { Button, Modal, Form } from "react-bootstrap"
import { Formik } from "formik"
import { ErrorMessage } from "components/UserManagement/ErrorMessage"
import { editTenantSchema } from "utils/forms-schema"
import countries from "../../../../config/countries"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { useEditTenant } from "../queries"

const EditTenant = ({ tenant, show, exitModal }) => {
  const audit = useCameraManagementAPI()
  const {
    system_id,
    name,
    contact: {
      country,
      address,
      first_name,
      last_name,
      email_address,
      phone: { country_code, number }
    }
  } = tenant

  //Define initial value for select inputs
  const initialValueCountry = { value: `${country ? country : "Malta"}`, label: `${country ? country : "Malta"}` }
  const initialValueDialCode = { value: `${country_code ? country_code : "+356"}`, label: `${country_code ? country_code : "+356 (MT)"}` }

  const editTenant = useEditTenant(system_id)
  const onSubmit = (values) => {
    const { name, email, address, country, dial_code, personName, phone } = values
    const fullName = personName.split(" ")
    const data = {
      name,
      contact: {
        first_name: fullName[0],
        last_name: fullName.length > 1 ? fullName[1] : "",
        email_address: email,
        address,
        country: country.value,
        phone: {
          country_code: dial_code.value,
          number: phone
        }
      }
    }

    editTenant.mutate(data)
    audit.log("# Tenant edit", { tenant, newData: data })
    exitModal()
  }

  return (
    <Modal centered show={show} size="md" onHide={exitModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit tenant : {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name,
            address: address,
            country: initialValueCountry,
            personName: `${first_name} ${last_name}`,
            email: email_address,
            dial_code: initialValueDialCode,
            phone: number
          }}
          validationSchema={editTenantSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <h5 className="mb-3">Tenant details </h5>
              <Form.Group className="mb-3">
                <Form.Control placeholder="Tenant name" type="text" name="name" value={values.name} onChange={handleChange} />
                <ErrorMessage>{errors.name}</ErrorMessage>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control placeholder="Address" type="text" name="address" value={values.address} onChange={handleChange} />
                <ErrorMessage>{errors.address}</ErrorMessage>
              </Form.Group>
              <Form.Group className="mb-3">
                <Select
                  name="country"
                  defaultValue={initialValueCountry}
                  onChange={(value) => handleChange({ target: { name: "country", value } })}
                  options={countries.map((country) => ({ value: country.name, label: country.name }))}
                />
                <ErrorMessage>{errors.country}</ErrorMessage>
              </Form.Group>
              <h5 className="mt-7 mb-3">Person to contact </h5>
              <Form.Group className="mb-3">
                <Form.Control type="text" name="personName" placeholder="Name" value={values.personName} onChange={handleChange} />
                <ErrorMessage>{errors.personName}</ErrorMessage>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="email" name="email" placeholder="Email" value={values.email} onChange={handleChange} />
                <ErrorMessage>{errors.email}</ErrorMessage>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="d-flex">
                  <Select
                    name="dial_code"
                    defaultValue={initialValueDialCode}
                    styles={{ container: (options) => ({ ...options, width: "15rem" }) }}
                    onChange={(value) => handleChange({ target: { name: "dial_code", value } })}
                    options={countries.map((country) => ({ value: country.dial_code, label: `${country.dial_code} (${country.code})` }))}
                  />

                  <Form.Control type="number" className="ml-5" name="phone" placeholder="Phone number" value={values.phone} onChange={handleChange} />
                </div>
                <ErrorMessage>{errors.phone}</ErrorMessage>
              </Form.Group>

              <div className="float-right">
                <Button variant="primary" type="submit" className="mt-9 mr-3">
                  Confirm
                </Button>
                <Button variant="secondary" className="mt-9" onClick={exitModal}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default EditTenant
