import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { emptyCamera } from "./Form/camera-template"
import Step1 from "./Form/Step1"
import Step2 from "./Form/Step2"
import Step3 from "./Form/Step3"
import Step4 from "./Form/Step4"
import Step5 from "./Form/Step5"
import Step6 from "./Form/Step6"
import Step7 from "./Form/Step7"
import { useLoading } from "../../../hooks/useLoading"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import { covertCameraSettingsToFormData } from "./utils"

const CameraManagementForm = () => {
  const api = useCameraManagementAPI()
  const history = useHistory()
  const { cameraUUID } = useParams()

  // Current selections
  const [camera, setCamera] = useState({ ...emptyCamera })
  const title = cameraUUID?.length > 0 ? "Edit Camera" : "New Camera"

  // Load the camera from the API
  const [cameraSettings: CameraDTO] = useLoading(() => api.camera(cameraUUID))
  useEffect(() => cameraSettings && setCamera(covertCameraSettingsToFormData(cameraSettings)), [cameraSettings])

  // Step properties
  const props = {
    title,
    camera,
    setValues: values => setCamera({ ...camera, ...values, step: nextStep(true, camera, values) }),
    setLines: lines => setCamera({ ...camera, ...lines }),
    prevStep: () => setCamera({ ...camera, step: nextStep(false, camera) })
  }
  // console.log(camera)
  switch (camera.step) {
    case 1:
    default:
      return <Step1 {...props} />

    case 2:
      return <Step2 {...props} />

    case 3:
      return <Step3 {...props} />

    case 4:
      return <Step4 {...props} />

    case 5:
      return <Step5 {...props} />

    case 6:
      return <Step6 {...props} />

    case 7:
      return <Step7 {...props} onComplete={() => history.push("/admin/cm")} />
  }
}

/** @var int The total number of steps. */
export const stepCount = 7

/**
 * Check if a step is available, based on the current state of the form.
 *
 * @param {CMCamera} camera The current state of the camera.
 * @param {*} values The current form values.
 *
 * @return {boolean} True if step is available at the current state, false otherwise.
 */
export const stepAvailable = (camera: CMCamera, values: * = null) => {
  switch (camera.step) {

    // Image must be defined
    case 4:
      return camera.image || camera.newImage || values?.newImage

    // Image and lines must be defined
    case 5:
    case 6:
      return (camera.image || camera.newImage || values?.newImage) && (camera.lines.length + (values?.lines.length ?? 0)) > 0

    // Other steps are allowed
    default:
      return true
  }
}

/**
 * Get the next step to show.
 *
 * @param {number} next True to go to the next step, false for previous one.
 * @param {CMCamera} camera The current state of the camera.
 * @param {*} values The current form values.
 *
 * @return {number} The next step to go to.
 */
export const nextStep = (next: boolean, camera: CMCamera, values: * = null) => {
  do {
    camera.step += (next ? 1 : -1)
  } while (!stepAvailable(camera, values))

  // Fallback to the first step if steps out of range
  return camera.step > 0 && camera.step <= stepCount
    ? camera.step
    : 1
}

/**
 * Get the full title for the step.
 *
 * @param {string} prefix The prefix to add to the step.
 * @param {CMCamera} camera The current camera.
 * @param {string} title The title of the step.
 *
 * @return {string} The full title, with the current step prefix.
 */
export const stepTitle = (prefix, camera, title) =>
  `${prefix} ${camera.step}/${stepCount}: ${title}`

export default CameraManagementForm
