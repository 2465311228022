import { Button, Modal } from "react-bootstrap"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { useDeleteTenant } from "../queries"

const DeleteTenant = ({ tenant, show, exitModal }) => {
  const { system_id, name } = tenant
  const deleteTenant = useDeleteTenant(system_id)
  const audit = useCameraManagementAPI()

  const execute = (data) => {
    deleteTenant.mutate(data)
    audit.log("# Tenant delete", tenant)
  }

  return (
    <Modal centered show={show} size="lg" onHide={exitModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete tenant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-weight-light">
          Are you sure you want to permanently delete the tenant: <span className="font-weight-bold">{name}</span>?
        </h5>
        <div className="mt-5 float-right">
          <Button variant="secondary" onClick={exitModal}>
            Cancel
          </Button>
          <Button variant="danger" className="ml-3" onClick={execute}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteTenant
