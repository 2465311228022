import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Table } from "react-bootstrap"
import { AddLocation } from "@material-ui/icons"
import { allowEmpty } from "../../../components/LoadingWrapper/LoadingWrapper"
import LargeCard from "../../../components/Structure/LargeCard"
import { LoadingWrapper } from "../../../components"
import { useLoading, useQueryLoading } from "../../../hooks/useLoading"
import useAPI from "../../../services/ApiService"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import { slugOf } from "../../../utils/strings"
import { useContext, useEffect } from "react"
import { EventsContext } from "../Events/EventsLayout"
import { BorderColor } from "@material-ui/icons"
import { Tooltip } from "@mui/material"


const EventsDashboard = () => {
    const { user } = useSelector((state: ReduxStore) => state.auth)
    const { setSelectedCameras, setEventTitle, setZones } = useContext(EventsContext)
    // Load the existing cameras
    const dataAPI = useAPI()
    const confAPI = useCameraManagementAPI()
    const [cameras: CMCameraResponse[], camerasLoadingState] = useQueryLoading("CameraManagement:List", () => confAPI.cameras())

    // Get the list of all systems used, as a unique array
    const [systems: CMSystemResponse[], systemsLoadingState] = useQueryLoading("Systems", () => confAPI.systems())
    const filteredCameras = (cameras ?? []).filter(camera => user.userManagingTenantsIds.includes(slugOf(camera.system)))

    useEffect(() => {
        setZones([])
    }, [])

    return (
        <LargeCard title="Event Management">
            <LoadingWrapper state={[camerasLoadingState, systemsLoadingState]} onEmpty={"There are no cameras to show"}>
                {filteredCameras.length > 0 ? (
                    <Table bordered hover={false} className="v-align">
                        <thead>
                            <tr>
                                {systems?.length > 1 && <th width="200">System</th>}
                                <th width="*">Camera name</th>
                                <th width="200">Location</th>
                                <th width="100" />
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCameras.map((camera) => (
                                <tr key={camera.uuid}>
                                    {systems?.length > 1 && <td>{camera.system}</td>}
                                    <td>{camera.name}{camera.is_virtual ? " (virtual)" : ""}</td>
                                    <td>{camera.locality}</td>
                                    <td className="text-center">
                                        <Link className="link" onClick={() => {
                                            setSelectedCameras(JSON.stringify(camera))
                                            setEventTitle(camera.name)
                                        }} to={`/event/dashboard/${camera.uuid}`}>
                                            <Tooltip title="Add" placement="top">
                                                <BorderColor color="black" />
                                            </Tooltip>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <h1 className="text-center my-10">No camera added yet</h1>
                )}
            </LoadingWrapper>
        </LargeCard>
    )
}

export default EventsDashboard