import React, { createContext, useState } from 'react'
import MCReport from './MCReport'
import MCLayout from '../MCLayout'
import { MCReportsForm } from './MCReportForm'

export const McResContext = createContext()
const MCResLayout = () => {
    const [step, setStep] = useState(1)
    const [report, setReport] = useState()
    // Required selected values
    const [selectedReportType, selectReportType] = useState("")

    const [selectedEntries, setSelectedEntries] = useState([])
    const [entryName, setEntryName] = useState('')
    const [selectedExits, setSelectedExits] = useState([])
    const [exitName, setExitName] = useState('')
    const [reportFilters, setReportFilters] = useState([])
    const [camera, setCamera] = useState(null)

    const cxtValues = {
        setStep, setReport, selectReportType,
        selectedReportType, selectedEntries, setSelectedEntries,
        selectedExits, setSelectedExits, report,
        reportFilters, setReportFilters,
        entryName, setEntryName, exitName, setExitName,
        camera, setCamera
    }
    return (
        <MCLayout>
            <McResContext.Provider value={cxtValues}>
                {step === 1 ?
                    <MCReportsForm
                        setStep={setStep}
                    /> :
                    <MCReport
                    />
                }
            </McResContext.Provider>
        </MCLayout>
    )
}

export default MCResLayout