import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { Form, Table } from "react-bootstrap"
import cameraConfig from "../../../../components/CameraImage/CameraConfig"
import { BooleanInput, FormText, StringInput } from "../../../../components/Reports/Fields"
import LargeCard from "../../../../components/Structure/LargeCard"
import { get } from "../../../../utils/data"
import { cameraManagementStep6, formikDefault } from "../../../../utils/forms-schema"
import { toaster } from "../../../../utils/toaster"
import CameraCanvas from "../Form/CameraCanvas/CameraCanvas"
import { stepTitle } from "../form"
import { useCameraImage } from "../../../../hooks/useCameraImage"

/**
 * Set the connection between the traffic points.
 *
 * @param camera
 * @param title
 * @param updatePairs
 * @param prevStep
 * @return {JSX.Element}
 * @constructor
 */
const Step6 = ({ camera, title, setValues, prevStep }) => {
  const image = useCameraImage(camera)
  const [pairs, setPairs] = useState([])

  // Extract entries and exits
  const entries = (camera.lines ?? []).filter(line => line.type === "entry")
  const exits = (camera.lines ?? []).filter(line => line.type === "exit")

  // Define form
  const formik = useFormik({
    ...formikDefault,
    initialValues: { pairs: [] },
    validationSchema: cameraManagementStep6,
    onSubmit: values => {

      // Make sure at least one distance is set
      if (!values.pairs.find(pair => pair.active)) {
        return toaster.error("You must select at least one entry-exit pair")
      }

      setValues(values)
    }
  })

  // Initialize pair values
  useEffect(() => {
    const initialPairs = []
    for (const entry of entries) {
      for (const exit of exits) {

        // Add all pairs with a unique id
        const id = `${entry.id}:${exit.id}`
        initialPairs.push({

          // Initialize the empty one
          id,
          entry: entry.number,
          exit: exit.number,
          distance: "",
          active: false,

          // Append existing values
          ...(camera.pairs.find(existing => existing.id === id) ?? {})
        })
      }
    }

    // Store in state and update the form
    setPairs(initialPairs)
    formik.setValues({ pairs: initialPairs })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LargeCard title={stepTitle(title, camera, "Distances between lines")}>
      <Form noValidate onSubmit={formik.handleSubmit}>

        {/* Instructions */}
        <FormText>
          <p>
            Select all entry-exit pairs of lines that are a valid direction of travel.<br />
            For each pair, the trajectory of a vehicle entering and exiting should make sense.
          </p>
          <p>
            The travel distance between each pair is required, use an estimate if exact distance is unknown.<br />
            This distance is used to estimate the speed of the vehicle.
          </p>
          <p>
            Trajectory of the vehicle between lines should be considered for the travel distance (i.e: junctions with bends will have more travel distance than straight lines).
          </p>
        </FormText>

        <div className="row">

          {/* Select what is connected with what and their distances */}
          <div className="col-md-5">

            <Table bordered hover={false} className="v-align">
              <thead>
                <tr>
                  <th width="40" />
                  <th width="50" className="text-center">Entry</th>
                  <th width="50" className="text-center">Exit</th>
                  <th width="*">Travel distance between entry and exit, in meters</th>
                </tr>
              </thead>

              <tbody>
                {pairs.map((pair, index) => {
                  const active = get(formik.values, `pairs.${index}.active`)
                  return (
                    <tr key={pair.id} className={active ? null : "empty"}>

                      {/* Which entry and which exit, connected or not */}
                      <td className="text-center"><BooleanInput name={`pairs.${index}.active`} formik={formik} /></td>
                      <td className="text-center"><h3 style={{ color: active ? cameraConfig.point.type.entry.active.fill : "inherit" }}>{pair.entry}</h3></td>
                      <td className="text-center"><h3 style={{ color: active ? cameraConfig.point.type.exit.active.fill : "inherit" }}>{pair.exit}</h3></td>

                      {/* What is the distance, if entry and exit are connected */}
                      <td>
                        {
                          active
                            ? <StringInput name={`pairs.${index}.distance`} placeholder="Travel distance, in meters" formik={formik} />
                            : <em className="empty">not connected</em>
                        }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>

          {/* The image that shows the locations of lines */}
          <div className="col-md-7">
            <Table bordered hover={false} className="v-align">
              <thead>
                <tr>
                  <th>Entry and exit lines preview</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <CameraCanvas image={image} lines={camera.lines} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

        </div>

        <div className="form-buttons">
          <button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>
          <button className="btn btn-primary" type="submit">Next</button>
        </div>
      </Form>
    </LargeCard>
  )
}

export default Step6
