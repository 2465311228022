import React from "react";
import { Rect, Text } from "react-konva";
import config from "./CameraConfig";

/**
 * Draw the legend for the camera image in the top left corner.
 */
export const Legend = () => {
  return (
    <>
      <Rect fill="rgba(255, 255, 255, 0.5)" x={3} y={3} width={120} height={60} />
      <Rect fill={config.point.type.entry.inactive.fill} x={10} y={10} width={60} height={20} />
      <Text text="Entries" x={75} y={15} fill="black" />
      <Rect fill={config.point.type.exit.inactive.fill} x={10} y={35} width={60} height={20} />
      <Text text="Exits" x={75} y={40} fill="black" />
    </>
  );
};
