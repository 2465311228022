/**
 * Trigger an action once and ignore the same action for the next number of seconds.
 *
 * @param {string} key The key used to identify the action.
 * @param {int} ttl The number of seconds to ignore sequential calls.
 * @param {function} action The action to execute.
 */
export const triggerFirst = (key: string, ttl: number, action: function) => {
  const now = new Date().getTime()

  // Global stats
  window.debounceTriggerFirst = window.debounceTriggerFirst || {}

  // Check the last trigger time and set the new one
  const lastTriggerTime = window.debounceTriggerFirst[key] || -1
  window.debounceTriggerFirst[key] = now

  // Trigger only if more than supplied TTL seconds have passed
  if (lastTriggerTime + ttl * 1000 < now) {
    action()
  }
}
