import { Section } from "./Section"
import { Table } from "react-bootstrap"
import { humanNumber, humanPercent, humanSpeed } from "../../../../../../utils/functions"
import { initPath } from "../../../../../../utils/data"
import { PointType } from "../../../../../../utils/enums"
import { VisibilityOutlined } from "@material-ui/icons"

/**
 * Show the flow for the current camera, grouped by carriageways.
 *
 * @param {?TransitFlowPointDTO} selectedPoint The currently selected node.
 * @param {?TransitSummaryDTO} transitSummary The complete transit summary of the camera.
 * @param {function(string)} setSelectedCarriagewayUUID Set the UUID of the carriageway to highlight.
 *
 * @return {JSX.Element}
 * @constructor
 */
const FlowGlobal = ({ selectedPoint, transitSummary, selectedCarriagewayState }) => {

  // Guard: Do not show if there is a selected point
  if (selectedPoint) {
    return <></>
  }

  // Convert transit points to carriageways
  let total = 0
  const tmpCarriageways = {}
  for (const point of transitSummary?.transit_points) {
    const entryPoint = point.transit_point.type === PointType.ENTRY

    // Make sure the carriageway with the current UUID is initialized
    const carriageway = initPath(tmpCarriageways, point.transit_point.carriageway.uuid, {
      uuid: point.transit_point.carriageway.uuid,
      name: point.transit_point.carriageway.name,
      speed: 0,
      count: 0,
      entries: 0,
      exits: 0,
      length: 0
    })
    // Update the values
    total += point.count
    carriageway.length += point.count > 0 ? 1 : 0
    carriageway.count += point.count
    carriageway[entryPoint ? "entries" : "exits"] += point.count
    carriageway.speed += point.avg_speed * point.count

  }

  // Calculate the average speeds, the percentages and half the total count as we used both the entry and exit points
  const carriageways = Object.values(tmpCarriageways)?.map(carriageway => ({
    uuid: carriageway.uuid,
    name: carriageway.name,
    count: carriageway.count / 2,
    entries: carriageway.entries,
    exits: carriageway.exits,
    percent: 100 * carriageway.count / total,
    avg_speed: carriageway.length > 0 ? carriageway.speed / carriageway.count : null
  })
  )

  return (
    <Section title="Traffic flow">
      <Table bordered hover>
        <thead>
          <tr>
            <th width="*">Carriageway</th>
            <th width="15%" className="text-right">Entries</th>
            <th width="15%" className="text-right">Exits</th>
            <th width="15%" className="text-right">Percent</th>
            <th width="15%" className="text-right">Avg Speed</th>
          </tr>
        </thead>
        <tbody>
          {
            carriageways?.map(carriageway => (
              <tr key={carriageway.uuid}>
                <td>
                  {
                    selectedCarriagewayState[0] === carriageway.uuid
                      ? <span className="btn-icon active" title={`Clear highlighted carriageway`} onClick={() => selectedCarriagewayState[1](null)}><VisibilityOutlined /></span>
                      : <span className="btn-icon" title={`Highlight ${carriageway.name} on camera`} onClick={() => selectedCarriagewayState[1](carriageway.uuid)}><VisibilityOutlined /></span>
                  }
                  {carriageway.name}
                </td>
                <td className="text-right">{humanNumber(carriageway.entries)}</td>
                <td className="text-right">{humanNumber(carriageway.exits)}</td>
                <td className="text-right">{humanPercent(carriageway.percent)}</td>
                <td className="text-right">{humanSpeed(carriageway.avg_speed)}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Section>
  )
}

export default FlowGlobal
