import toast from "react-hot-toast";

/**
 * App-wide toast messages.
 */
export const toaster = {

  /**
   * Show a toast that shows appropriate message based on the status of the promise (loading, success (on resolve), error (on reject).
   *
   * @param {Promise<*>} promise The promise that determines which message is shown.
   * @param {string} success The message to show when promise is resolved.
   * @param {string} loading The message to show while promise is resolving.
   * @param {string} error The message to show when promise is rejected,
   */
  promise: (promise: Promise<*>, success: string, loading: string = "Please wait", error: string = "An error has occurred") =>
    toast.promise(promise, { loading, success, error }),

  /**
   * Show a success toast.
   *
   * @param {string} message The message to show.
   */
  success: toast.success,

  /**
   * Show an error toast.
   *
   * @param {string} message The message to show.
   */
  error: toast.error

};
