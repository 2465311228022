import { Pagination } from "react-bootstrap"
import { useHistory } from "react-router-dom"

/**
 * @param {CMPaginationInfo} pagination
 * @param {function} setPage The method that is called when a page is changed.
 * @return {JSX.Element}
 * @constructor
 */
const PaginationToolbar = ({ pagination, setPage }) => {
  const history = useHistory()

  // The number of pages to show before and after the page
  const COUNT = 6

  // Guard: Not ready
  if (!pagination) {
    return <></>
  }

  // Define the header
  const header = <center style={{ marginTop: "2em" }}>Page {pagination.page} / {pagination.pages}</center>

  // Guard: Do not show if only one page
  if (pagination.pages === 1) {
    return header
  }

  // Get the base URL
  const url = "/audit"
  const go = page => {
    history.push(`${url}?page=${page}`)
    setPage(page)
  }

  // Get the start end the end of the list
  const from = Math.max(1, pagination.page - COUNT / 2)
  const to = Math.min(pagination.pages, Math.max(from + COUNT, pagination.page + COUNT / 2))
  const pages = []
  for (let i = Math.max(1, Math.min(from, to - COUNT)); i <= to; i++) {
    pages.push(i)
  }

  return <>

    {header}

    <Pagination>

      {/* first and previous */}
      <Pagination.First disabled={pagination.page === 1} onClick={() => go(1)} />
      <Pagination.Prev disabled={pagination.page === 1} onClick={() => go(pagination.page - 1)} />

      {
        pages.map(page => (
          <Pagination.Item onClick={() => go(page)} key={page} active={page === pagination.page}>{page}</Pagination.Item>
        ))
      }

      {/* next and last */}
      <Pagination.Next disabled={pagination.page === pagination.pages} onClick={() => go(pagination.page + 1)} />
      <Pagination.Last disabled={pagination.page === pagination.pages} onClick={() => go(pagination.pages)} />

    </Pagination>
  </>
}

export default PaginationToolbar
