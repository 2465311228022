/**
 * Group lanes by carriageway
 *
 * @param {[lanes:{array}]} param.
 *
 * @return {[carriageway:{lanes:array}]}
 */
export const groupLanesByCarriageway = (lanes) => {
  let lanesGroupedByCarriageway = {};
  lanes.forEach((lane, index) => {
    lanesGroupedByCarriageway[lane.carriageway] = [];
  });
  for (let key in lanesGroupedByCarriageway) {
    lanesGroupedByCarriageway[key] = [
      ...lanes.filter((lane) => lane.carriageway === key)
    ];
  }
  return lanesGroupedByCarriageway;
};

/**
 * Get the parent carriage way for a lane.
 *
 * @param {CameraDTO} camera The camera this lane is on.
 * @param {string} laneUUID The UUID of the lane.
 *
 * @return {?CarriageWayDTO} The carriageway of this lane UUID, or null if not found.
 */
export const getCarriageWayForLane = (camera: CameraDTO, laneUUID: string) => {

  // Try to find the lane in all the carriageways
  for (const carriageway of camera?.carriageways ?? []) {
    if (carriageway.lanes.find(lane => lane.uuid === laneUUID)) {
      return carriageway;
    }
  }

  return null;
};
