import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { LoadingWrapper } from "components";
import LargeCard from "components/Structure/LargeCard";
import TenantTableRow from "./TenantTableRow";
import { useGetTenants } from "../queries";
import Filter from "components/Filter/Filter";
import { useState } from "react";

const TenantTable = () => {
  //Get all groups
  const { status, data: tenants } = useGetTenants();
  const [filter, setFilter] = useState({})

  const params = [{
    name: 'term',
    label: 'Search name or address',
    type: 'text'
  }]
  // The header options
  const headerOptions = (
    <div className="float-right">
      <NavLink className="btn btn-primary camera-views" to="/tenants/add-tenant">
        <Add /> <span className="font-bold">Add tenant</span>
      </NavLink>
    </div>
  );

  console.log(tenants)

  return (
    <>
      <LargeCard title="Tenants" headerOptions={headerOptions}>
        <LoadingWrapper state={status} onEmpty={"There is no tenant to show."}>
          <Filter params={params} onChange={filter => setFilter(filter)} />
          <Table bordered hover={false}>
            <thead>
              <tr>
                <th width="15%" className="text-left">
                  Name
                </th>
                <th className="text-left">Address</th>
                <th width="8%" className="text-left">
                  Country
                </th>
                <th width="30%" className="text-left">
                  Person to contact
                </th>
                <th className="text-left" width="9%">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{tenants?.length >= 0 && tenants.filter(tenant => !filter.term || tenant.name?.toLowerCase().includes(filter.term) ||
              tenant.contact?.address?.toLowerCase().includes(filter.term) ||
              tenant.contact?.email_address.includes(filter.term))
              ?.map((tenant) => <TenantTableRow tenant={tenant} key={tenant.system_id} />)}</tbody>
          </Table>
        </LoadingWrapper>
      </LargeCard>
    </>
  );
};

export default TenantTable;
