import { EventsContext } from 'app/pages/Events/EventsLayout';
import React, { useContext, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

const PaginationBar = ({ arr, systemID, cameraID }) => {
    const totalPages = Math.ceil(arr?.length / 20);
    const { page, setPage } = useContext(EventsContext)
    const history = useHistory()
    const { sys_id, cameraId } = useParams()

    // The number of pages to show before and after the page
    const COUNT = 6

    // Guard: Not ready
    if (!arr) {
        return <></>
    }

    // Define the header
    const header = <center style={{ marginTop: "2em" }}>{totalPages > 0 && `Page ${page} / ${totalPages}`} </center>

    // Guard: Do not show if only one page
    if (totalPages === 1) {
        return header
    }

    // Get the base URL
    const url = `/events/${systemID ?? sys_id}/${cameraID ?? cameraId}`
    const go = page => {
        history.push(`${url}?page=${page}`)
        setPage(page)
    }

    // Get the start end the end of the list
    const from = Math.max(1, page - COUNT / 2)
    const to = Math.min(totalPages, Math.max(from + COUNT, page + COUNT / 2))
    const pages = []
    for (let i = Math.max(1, Math.min(from, to - COUNT)); i <= to; i++) {
        pages.push(i)
    }
    return (
        <>
            {header}
            <Pagination>
                {/* first and previous */}
                <Pagination.First disabled={page === 1} onClick={() => go(1)} />
                <Pagination.Prev disabled={page === 1} onClick={() => go(page - 1)} />

                {
                    pages.map(item => (
                        <Pagination.Item onClick={() => go(item)} key={item} active={item === page}>{item}</Pagination.Item>
                    ))
                }

                {/* next and last */}
                <Pagination.Next disabled={page === totalPages} onClick={() => go(page + 1)} />
                <Pagination.Last disabled={page === totalPages} onClick={() => go(totalPages)} />
            </Pagination>
        </>
    )
}

export default PaginationBar