import { useValidLine } from "hooks/useValidLine"
import { useEffect, useRef } from "react"
import { Rect, Line, Arrow, Group, Transformer, Text } from "react-konva"
import cameraConfig from "../../../../../components/CameraImage/CameraConfig"
import { deepClone } from "../../../../../utils/data"
import Rectangle from "../../../../../utils/rectangle"

/**
 * @return {JSX.Element}
 * @constructor
 *
 * @typedef {{current: { getAbsoluteTransform: function }}} ShapeRef
 * @typedef {{current: { nodes: function, getLayer: function }}} TransformerRef
 */
const CameraCanvasLine = ({ shape, isSelected, onSelect, updateShape, isEditable, width, height }) => {

  // Make sure
  const shapeClone = deepClone(shape)

  // Reference to the share
  const shapeRef: ShapeRef = useRef()

  // Attach the Transformer
  const transformerRef: TransformerRef = useRef()
  useEffect(() => {
    if (isEditable && isSelected && transformerRef.current) {
      transformerRef.current.nodes([shapeRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isEditable, isSelected])

  // Handle new points so that the line is at the center and on 45 degrees
  if (shapeClone.points === null) {
    const length = 65
    shapeClone.points = [
      .5 - length / width,
      .5 - length / height,
      .5 + length / width,
      .5 + length / height
    ]
    console.log(shapeClone)
  }


  // Make sure the origin points will not be changed
  const origin = [...shapeClone.points]

  // Convert percentages to absolute values in pixes
  const relative = new Rectangle(...origin)
  const absolute = relative.scale(width, height)
  const vectorArrow = absolute.vector()

  const handleShapePositionChange = () => {

    // Guard: Editable
    if (!isEditable) {
      return
    }

    // Track shapes coordinates
    const transform = shapeRef.current.getAbsoluteTransform()

    // Transform the points
    const { x: x1, y: y1 } = transform.point({ x: absolute.x1, y: absolute.y1 })
    const { x: x2, y: y2 } = transform.point({ x: absolute.x2, y: absolute.y2 })

    // Set new points
    shapeClone.points[0] = x1 / width
    shapeClone.points[1] = y1 / height
    shapeClone.points[2] = x2 / width
    shapeClone.points[3] = y2 / height

    return updateShape(shapeClone)
  }

  return (
    <>
      <Group
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={isEditable}
        onDragEnd={handleShapePositionChange}
        onTransformEnd={handleShapePositionChange}>

        {/* Invisible rectangle around the line used to capture events */}
        <Rect
          // x={vectorArrow.x2}
          // y={vectorArrow.y2}
          // width={absolute.diagonal * 0.95 / Math.sqrt(2)}
          // height={absolute.diagonal * 0.95 / Math.sqrt(2)}
          // rotation={225 + Math.round(180 * vectorArrow.angle / Math.PI)}
          x={absolute.left}
          y={absolute.top}
          width={absolute.width}
          height={absolute.height}
          fill="transparent"
        />

        {/* The arrow that points the direction of travel */}
        <Arrow
          points={[vectorArrow.x1, vectorArrow.y1, vectorArrow.x1, vectorArrow.y1, vectorArrow.x2, vectorArrow.y2]}
          stroke="yellow"
          fill="yellow"
          strokeWidth={3}
          pointerWidth={8} />

        {/* The line that detects vehicles */}
        <Line
          points={absolute.toArray()}
          stroke={cameraConfig.point.type[shapeClone.type].line}
          strokeWidth={3} />

        {/* The number of the line */}
        <Text
          x={absolute.center.x - 5}
          y={absolute.center.y - 28}
          text={shapeClone.number}
          fill={cameraConfig.point.type[shapeClone.type].line}
          fontSize={30} />

      </Group>

      {isEditable && isSelected && (
        <Transformer
          ref={transformerRef}
          enabledAnchors={["top-left", "top-center", "top-right", "middle-right", "bottom-right", "bottom-center", "bottom-left", "middle-left"]}

          // Make sure the box cannot get too small
          boundBoxFunc={(oldBox, newBox) => {
            return newBox.width > 60 && newBox.height > 60
              ? newBox
              : oldBox
          }}
        />
      )}
    </>
  )
}

export default CameraCanvasLine
