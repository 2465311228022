import React, { useState } from 'react';
import videojs from 'video.js';
import VideoJS from './Video';

export default function LiveStreamComp({ src }) {
    const [isReady, setisReady] = useState(false)

    const playerRef = React.useRef(null);

    const videoJsOptions = {
        controls: true,
        responsive: true,
        autoplay: true,
        fluid: true,
        sources: [{
            src,
            type: 'application/x-mpegURL'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };

    return (
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
    );
}