import { LaneFlowStructuredReportPage } from "./Types/LaneFlowStructuredReportPage";
import { LaneFlowStructuredReportPageByExit } from "./Types/LaneFlowStructuredReportPageByExit";
import { LanesSummaryReportPage } from "./Types/LanesSummaryReportPage";
import { LanesSummaryWithVehicleTypeBreakdownReportPage } from "./Types/LanesSummaryWithVehicleTypeBreakdownReportPage";
import { VehicleTypeSummaryReportPage } from "./Types/VehicleTypeSummaryReportPage";
import { VehicleTypeHourlyBreakdownReportPage } from "./Types/VehicleTypeHourlyBreakdownReportPage";
import { HourlySummaryReportPage } from "./Types/HourlySummaryReportPage";
import { DailySummaryReportPage } from "./Types/DailySummaryReportPage";
import { DayOfWeekGridReport } from "./Types/DayOfWeekGridReport";
import { buildQuery } from "../../../utils/functions";
import { deserializeDateRange, serializeDateRange } from "../../../utils/time";
import { buildFromPreset, DefaultPreset } from "../../../components/DateRangePicker/Presets";
import { TrajectoriesReportPage } from "./Types/TrajectoriesReportPage";
import { OriginDestinationReport } from "app/pages/Reports/Types/OriginDestinationReport";
import { OriginDestinationPcu } from "app/pages/Reports/Types/OriginDestinationPcu";
import { LiveSessions } from "app/pages/Reports/Types/LiveSessions";
import { HourlyPedestrianReport } from "./Types/HourlyPedestrian";
import { DailyPedestrianReport } from "./Types/DailyPedestrianReport";
import { PedestrianSummaryReport } from "./Types/PedestrianSummaryReport";
import { FDSByLane } from "./Types/FDSbyLane";
import { FDSByCarriageway } from "./Types/FDSByCarriageway";

export const ReportTypes = [
  {
    name: "OD Matrix - Counts",
    path: "transitflow",
    desc: 'Origin-Destination matrix showing the number of  vehicles',
    handler: OriginDestinationReport,
  },
  {
    name: "OD Matrix - PCU",
    path: "pcu",
    desc: 'Origin-Destination matrix showing the vehicle count and the PCU',
    handler: OriginDestinationPcu
  },
  {
    name: "Camera Heartbeat Report",
    path: "live-sessions",
    desc: 'Camara status report showing when the camera was offline and when it was online',
    handler: LiveSessions
  },
  {
    name: "Trajectories",
    path: "trajectories",
    desc: "A visual graphic of each vehicle's route",
    handler: TrajectoriesReportPage,
    filter: {
      vehicle_types: {
        skip: true
      }
    }
  },
  {
    name: "Lane analysis, by entry",
    path: "lane-flow-by-entry",
    desc: 'The flow of vehicles passing through an entry',
    handler: LaneFlowStructuredReportPage
  },
  {
    name: "Lane analysis, by exit",
    path: "lane-flow-by-exit",
    desc: 'The flow of vehicles passing through an exit',
    handler: LaneFlowStructuredReportPageByExit
  },

  {
    name: "Lanes summary report",
    path: "lanes-summary",
    desc: 'A summary showing vehicle entries and exits per lane',
    handler: LanesSummaryReportPage
  },
  {
    name: "Lanes summary with vehicle type breakdown",
    path: "lanes-summary-with-vehicle-type-breakdown",
    desc: 'A summary showing vehicle entries and exits per lane and split by type.',
    handler: LanesSummaryWithVehicleTypeBreakdownReportPage
  },
  {
    name: "Flow-Density-Occupancy Report by lane",
    path: "fdo-by-lane",
    desc: 'Hourly breakdown showing the flow, density and occupancy percentage of lanes',
    handler: FDSByLane
  },
  {
    name: "Flow-Density-Occupancy Report by carriageway",
    path: "fdo-by-carriageway",
    desc: 'Hourly breakdown showing the flow, density and occupancy percentage of carriageway',
    handler: FDSByCarriageway
  },
  {
    name: "Vehicle type summary",
    path: "vehicle-type-summary",
    desc: 'A summary showing the number of vehicles split by type',
    handler: VehicleTypeSummaryReportPage
  },
  {
    name: "Vehicle type breakdown",
    path: "vehicle-type-hourly-breakdown",
    desc: 'An hourly breakdown showing the PCU and number of vehicles split by type',
    handler: VehicleTypeHourlyBreakdownReportPage
  },
  {
    name: "Hourly summary",
    path: "hourly-summary",
    desc: 'An hourly breakdown showing the total number of vehicles',
    handler: HourlySummaryReportPage
  },
  {
    name: "Daily summary",
    path: "daily-summary",
    desc: 'A daily breakdown showing the total number of vehicles',
    handler: DailySummaryReportPage
  },
  {
    name: "Pedestrian summary",
    path: "pedestrian-summary",
    desc: 'A summary showing the total number of pedestrians',
    handler: PedestrianSummaryReport
  },
  {
    name: "Hourly pedestrian summary",
    path: "hourly-pedestrian-summary",
    desc: 'An hourly breakdown showing the total number of pedestrians',
    handler: HourlyPedestrianReport
  },
  {
    name: "Daily pedestrian summary",
    path: "daily-pedestrian-summary",
    desc: 'A daily breakdown showing the total number of pedestrians',
    handler: DailyPedestrianReport
  },
  {
    name: "Day of week grid",
    path: "day-of-week-grid-report",
    desc: 'Vehicle counts per hour for each day of the week.',
    handler: DayOfWeekGridReport
  }
];

/**
 * Get the report type for the supplied path.
 *
 * @param {string} path The unique URL-friendly id of the report/
 *
 * @return {?ReportType} The report type for the supplied component.
 */
export const parseReportPath = (path: string) =>
  ReportTypes.find((type) => type.path === path);

/**
 * Get the report type for the supplied handler./
 *
 * @param {string|JSX.Element} handler The component that will handle and show the report, or the path for this component.
 *
 * @return {?ReportType} The report type for the supplied component.
 */
export const parseReportHandler = (handler: string | JSX.Element) =>
  ReportTypes.find(
    (type) => type[typeof handler === "string" ? "path" : "handler"] === handler
  );

/**
 * Get the link for the report page that will show the report for the supplied parameters.
 *
 * @param {JSX.Element} handler The component that will handle and show the report.
 * @param {?ReportParamsDTO} params The parameters for the report.
 * @param {boolean} form True to open the form, not the reports.
 *
 * @return {?string} The relative URL to the requested report page.
 */
export const buildReportLink = (handler: JSX.Element, params: ReportParamsDTO = null, form: boolean = false) => {

  // Guard: Report type must be found.
  const type = parseReportHandler(handler);
  if (!type) {
    return form ? "/reports" : null;
  }

  // Guard: All of the required parameters must be present, unless intentionally empty
  if (params !== null) {
    const required = ["systemID", "cameraID", "range"];
    for (const param of required) {
      if (params[param] == null) {
        return null;
      }
    }
  }

  // Handle: Date and time range
  if (typeof params?.range === "object") {
    params.range = params.range.name
      ? params.range.name
      : serializeDateRange(params.range);
  }
  if (typeof params?.range === "string") {
    params.range = params.range.replace(/ /g, "-");
  }

  // Handle: Arrays
  const arrays = ["carriageways", "lanes", "vehicles"];
  arrays.forEach((key) => {
    if (params && params[key] && Array.isArray(params[key])) {
      params[key] = params[key].join(",");
    }
  });

  return `/reports/${!form ? type.path : ""}${buildQuery(params)}`;
};

/**
 * Read the data from the GET query.
 *
 * @return {ReportParamsDTO} The parameters for the report.
 */
export const readReportParamsFromQuery = () => {

  // Read the GET parameters from the URL
  const query = new URLSearchParams(window.location.search);

  // Define the parameters
  return {
    systemID: query.get("systemID"),
    cameraID: query.get("cameraID"),
    range: query.get("range")
      ? deserializeDateRange(query.get("range"))
      : buildFromPreset(DefaultPreset),
    carriageways: query.get("carriageways")?.split(",") ?? [],
    lanes: query.get("lanes")?.split(",") ?? [],
    vehicles: query.get("vehicles")?.split(",") ?? [],
    reportType: query.get("reportType") ?? ""
  };
};
