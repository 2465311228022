import { Kpi } from "../../../../../../components"
import { humanNumber } from "../../../../../../utils/functions"

/**
 * Draw the most important information about the camera i a separate boxes.
 *
 * @param {KPIItemDTO[]} indicators All the indicators to show.
 * @param {?string} selected The key of the currently selected indicator.
 * @param {function} setSelected The setter for the selected.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Indicators = ({ indicators, selected, setSelected }) => {
  return <div className="row">
    {
      indicators?.map((indicator, index) => (
        <div key={indicator.key} className={index === 0 && indicators.length % 2 ? "col-12" : "col-lg-6 col-xxl-6"}>
          <Kpi
            title={indicator.name}
            active={indicator.key === selected}
            onClick={() => setSelected(indicator.key !== selected ? indicator.key : null)}
            value={humanNumber(Math.round(parseFloat(indicator.value))) + " " + (indicator.unit === "vehicles" ? "" : indicator.unit)}
          />
        </div>
      ))
    }
  </div>
}


export default Indicators
