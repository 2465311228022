export const mediaWidth = {
  mobile: 760
};

/** @return boolean True if the current device has the supplied max witdh. */
export const matchesMaxWidth = width =>
  window.matchMedia(`only screen and (max-width: ${width}px)`).matches;

/** @return boolean True if the current device is small as a mobile phone, false otherwise. */
export const isMobile = () =>
  matchesMaxWidth(mediaWidth.mobile);
