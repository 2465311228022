import React, { useState, useRef, useEffect } from "react";
import { Stage, Layer, Image as KonvaImage } from "react-konva";
import { Point } from "./Point";
import config from "./CameraConfig";
import { Legend } from "./Legend";
import useAPI from "../../services/ApiService";
import { Tooltip } from "../Tooltip/Tooltip";
import Loader from "../Loader/Loader";
import { useOnResize } from "hooks/useOnResize";

function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio, ratio };
}


/**
 * Show an interactive still image from the camera with transit points drawn on it.
 *
 * @param {?string} image The URL to the image.
 * @param {int} width The width of the image.
 * @param {int} height The height of the image.
 * @param {TransitFlowPointDTO[]} points All of the points to show.
 * @param {?TransitFlowPointDTO} selectedPoint The currently selected point, if any.
 * @param {function(?TransitFlowPointDTO)} setSelectedPoint The method set the new selected point.
 * @param {LaneFlowDTO[]} relatedPoints All of the points related to the currently selected one.
 * @param {string[]} highlightedPoints The UUID of the transit points to highlight.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const MultiCameraImage = ({
    image,
    width,
    height,
    points,
    selectedPoint,
    setSelectedPoint,
    relatedPoints,
    highlightedPoints
}) => {
    const mainContainer = useRef();
    const myCanvas = useRef();
    const api = useAPI();
    const onResize = useOnResize();
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);
    const [ratio, setRatio] = useState(1);

    // The image of the camera
    const [imageObject, setImageObject] = useState(null);

    useEffect(() => {
        api.getImage(image).then(src => {
            const image = new Image();
            image.src = src;

            setTimeout(() => setImageObject(image), 450);
        });
    }, [api, image]);

    // The tooltip that that shows the name of the lane on hover
    const [tooltip: TooltipDTO, setTooltip] = useState();
    const hideTooltip = () => setTooltip();
    const showTooltip = (point: TransitFlowPointDTO) => {
        setTooltip({
            text: point.lane_name,
            width: config.tooltip.width,
            position: {
                x: point.handle.x * width * ratio,
                y: point.handle.y * height * ratio
            }
        });
    };

    /** Check if a point is an active one. */
    const isPointActive = (point: TransitFlowPointDTO) => point && selectedPoint && selectedPoint.uuid === point.uuid;

    useEffect(() => {
        const imgSize = calculateAspectRatioFit(
            width,
            height,
            (mainContainer.current.offsetWidth - 15),
            700,
        );
        setCanvasWidth(imgSize.width);
        setCanvasHeight(imgSize.height);
        setRatio(imgSize.ratio);

        return () => {
            setCanvasHeight(0);
        };
    }, [width, height, onResize]);
    console.log({ canvasHeight, canvasWidth, width, height, ratio })
    return (
        <div ref={mainContainer}>
            {
                !imageObject ? <Loader /> :
                    <Stage width={canvasWidth} height={canvasWidth*0.75}>
                        <Layer ref={myCanvas}>

                            {/* The camera still image */}
                            <KonvaImage
                                scale={{ x: ratio, y: ratio }}
                                width={width}
                                height={width*0.75}
                                image={imageObject}
                            />

                            {/* The lane entry and exit points. */}
                            {points?.map((point) => {
                                return (
                                    <Point
                                        key={point.uuid}
                                        point={point}
                                        ratio={ratio}
                                        width={width}
                                        height={width*0.75}
                                        active={isPointActive(point)}
                                        dimmed={highlightedPoints?.length > 0 && !highlightedPoints.includes(point.uuid)}
                                        relatedPoints={relatedPoints}
                                        onHover={point => point != null ? showTooltip(point) : hideTooltip()}
                                        onClick={point => setSelectedPoint(!isPointActive(point) ? point : null)}
                                    />
                                );
                            })}

                            {/* The legend in the top left of the camera image */}
                            <Legend />

                        </Layer>
                    </Stage>
            }

            {/* The tooltip that shows the line name when hovering over entry and exit points */}
            {tooltip && <Tooltip tooltip={tooltip} />}

        </div>
    );
};
