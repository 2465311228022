import { useLoading } from "../../../../../hooks/useLoading";
import useAPI from "../../../../../services/ApiService";
import Presentation from "../../../../../components/Trajectories/Presentation";
import { LoadingWrapper } from "../../../../../components";
import { useDispatch, useSelector } from "react-redux";
import useCameraManagementAPI from "../../../../../services/CameraManagementService";
import HeatmapDemo from "components/Trajectories/OldPresentation";
import "./Dashboard.scss";
import { useEffect, useState } from "react";
import { loadLast24Hrs, sameDate } from "utils/functions";
import toast from "react-hot-toast";
import { updateDateRange } from "redux/dateRangeSlice";
import HeatmapComp from "components/Trajectories/Heatmap";
import HeatmapLayout from "components/Trajectories/HeatmapLayout";
import { useQueryLoading } from "../../../../../hooks/useLoading";

/**
 * Show the trajectories on the camera details page.
 *
 * @param {CameraDTO} camera
 *
 * @return {JSX.Element}
 * @constructor
 */
const HeatMapTrajectory = ({ camera }) => {
  const [systemID, cameraID] = [camera?.sys_id, camera?.camera_id]

  // Handle date changes
  const dateChange = useSelector((state: ReduxStore) => state.dateRange)
  const [isLast24, setisLast24] = useState(true)
  const [localRange, setLocalRange] = useState(loadLast24Hrs(dateChange, isLast24))
  const dispatch = useDispatch()
  // const dateRange = useStoreDateRange()
  const api = useAPI()

  // console.log({ localRange, isLast24, dateChange })
  // Audit log
  useCameraManagementAPI().log("> Camera trajectories", {
    url: window.location.pathname,
    params: { subpage: "trajectories", systemID, cameraID }
  })

  // Load the data
  // const [vehicles: VehicleTrajectoryDTO, vehiclesLoadingState] = useLoading(
  //   () => api.getVehicleTrajectory(systemID, cameraID, localRange),
  //   [systemID, cameraID, localRange],
  //   ["entries"]
  // )
  // const [pedestrians: PedestrianTrajectoryDTO, pedestriansLoadingState] = useLoading(
  //   () => api.getPedestrianTrajectory(systemID, cameraID, localRange),
  //   [systemID, cameraID, localRange],
  //   ["entries"]
  // )

  const [vehicleTrajectoryHeatMap, vehicleTrajectoryHeatMapLoading] = useQueryLoading(
    ["vehicleTrajectoryHeatMap", systemID, cameraID, localRange],
    () => api.getVehicleTrajectoryHeatMaps(systemID, cameraID, localRange),
    [systemID, cameraID, localRange]
  );

  const [pedestrianTrajectoryHeatMap, pedestrianTrajectoryHeatMapLoading] = useQueryLoading(
    ["pedestrianTrajectoryHeatMap", systemID, cameraID, localRange],
    () => api.getPedestrianTrajectoryHeatMaps(systemID, cameraID, localRange),
    [systemID, cameraID, localRange]
  );

  // console.log(vehicleTrajectoryHeatMap, pedestrianTrajectoryHeatMap);

  const combinedData = replacePedestrianData(vehicleTrajectoryHeatMap, pedestrianTrajectoryHeatMap)

  // console.log(newData)

  // console.log(localRange, isLast24)
  // console.log({test})
  const state = [vehicleTrajectoryHeatMapLoading, pedestrianTrajectoryHeatMapLoading];

  useEffect(() => {
    const check = sameDate(localRange.globalRange, dateChange)

    if (check) {
      setLocalRange(loadLast24Hrs(dateChange, isLast24))
    }
    else {
      setisLast24(true)
      setLocalRange(loadLast24Hrs(dateChange, true))
    }
    return () => {
      toast.dismiss()
    }
  }, [dateChange, isLast24])

  return (
    <LoadingWrapper state={state}>
      <HeatmapLayout
        camera={camera}
        isLast24={isLast24}
        setisLast24={setisLast24}
        // vehicles={vehicles}
        vehicleTrajectoryHeatMap={combinedData}
        // pedestrians={pedestrians}
        localRange={localRange}
        dateRange={dateChange}
      />
    </LoadingWrapper>
  );
};

export default HeatMapTrajectory;

function replacePedestrianData(dataList, newData) {
  if(!dataList || !newData) return;
  for (let item of dataList) {
    if (item.vehicle_type.type === "pedestrian") {
      item.trajectory_count = newData.trajectory_count;
      item.heatmap = newData.heatmap;
      break;
    }
  }
  return dataList;
}
