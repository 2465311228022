import { useEffect, useRef } from "react";
import * as d3 from "d3";


const CountDiagram2 = ({ data, positions }) => {
  const svgContainerRef = useRef(null);
  useEffect(() => {
    if (!data || !Array.isArray(data.entries) || !positions) return;
    d3.select(svgContainerRef.current)
      .selectAll("*")
      .remove();

    // Set up SVG dimensions
    const svgWidth = 1200;
    const svgHeight = 1200;

    const config = rearrangeData(positions);

    // Extract lanes from config
    const lanesByPosition = extractLanes(config);

    // console.log(lanesByPosition);

    // User-defined sides (positions)
    const selectedSides = filterKeysWithNonEmptyArrays(lanesByPosition);
    // console.log(selectedSides);

    // Define all possible sides with their properties
    const allSides = [
      { name: "North", position: "N", color: "red" },
      { name: "East", position: "E", color: "orange" },
      { name: "South", position: "S", color: "blue" },
      { name: "West", position: "W", color: "green" }
    ];

    // Filter sides based on selectedSides
    const sides = allSides.filter((side) => selectedSides.includes(side.position));

    // Define segment configurations per side
    const segmentDefinitions = {
      N: [
        { segment: 1, type: "ENTRY" },
        { segment: 2, type: "EXIT" }
      ],
      W: [
        { segment: 1, type: "EXIT" },
        { segment: 2, type: "EXIT" },
        { segment: 3, type: "ENTRY" }
      ],
      E: [
        { segment: 1, type: "ENTRY" },
        { segment: 2, type: "ENTRY" },
        { segment: 3, type: "EXIT" }
      ],
      S: [
        { segment: 1, type: "ENTRY" },
        { segment: 2, type: "ENTRY" },
        { segment: 3, type: "EXIT" },
        { segment: 4, type: "ENTRY" }
      ]
    };

    // Function to determine segment color based on type
    function getSegmentColorByType(type) {
      const colorMap = {
        ENTRY: "green",
        EXIT: "red",
        EMPTY: "gray"
      };
      return colorMap[type] || "gray";
    }

    // Number of segments per side
    const segmentsPerSide = 3;
    const subSegmentsPerSegment = 3; // Each segment divided into 3 sub-segments

    // Spacing variables
    const segmentSpacing = 3; // Space between segments
    const subSegmentSpacing = 0.5; // Space between sub-segments

    // Calculate total length considering spacing
    const segmentLength = 300; // Adjusted for better spacing
    // const totalSegmentSpacing = (segmentsPerSide - 1) * segmentSpacing;
    const totalSubSegmentSpacing = (subSegmentsPerSegment - 1) * subSegmentSpacing;

    const segmentSize = (segmentLength - totalSubSegmentSpacing) / segmentsPerSide;
    const subSegmentSize = (segmentSize - totalSubSegmentSpacing) / subSegmentsPerSegment;
    const margin = 300;

    // Generate segment data based on segmentDefinitions and sub-segments
    const segmentData2 = sides.flatMap((side) => {
      const segments = segmentDefinitions[side.position];
      return segments.flatMap((segmentInfo) => {
        return Array.from({ length: subSegmentsPerSegment }, (_, subIdx) => ({
          side: side.name,
          position: side.position,
          segment: segmentInfo.segment,
          subSegment: subIdx + 1,
          type: segmentInfo.type,
          color: getSegmentColorByType(segmentInfo.type)
        }));
      });
    });

    // Process traffic data
    const { result: trafficData, laneMap } = processTrafficData(data, lanesByPosition, segmentDefinitions, indexMapping);

    // console.log(laneMap);

    // Create SVG container
    const svg = d3
      .select("#chart")
      .append("svg")
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .style("background", "white")
      // .style("border", "1px solid black");

    // Create scales for line widths
    const maxLineThickness = subSegmentSize;
    const trafficValues = trafficData.map((d) => d.value);
    const lineWidthScale = d3
      .scaleLog()
      .domain([1, d3.max(trafficValues)])
      .range([1, maxLineThickness]);

    // Helper function to get segment coordinates
    function getSegmentCoords2(position, segment, subSegment) {
      const segmentIdx = segment - 1;
      const subSegmentIdx = subSegment - 1;

      const totalLength = segmentsPerSide * segmentSize + (segmentsPerSide - 1) * segmentSpacing;

      const segmentOffset = segmentIdx * (segmentSize + segmentSpacing) + subSegmentIdx * (subSegmentSize + subSegmentSpacing);

      switch (position) {
        case "N":
          return {
            x: (svgWidth - totalLength) / 2 + segmentOffset - margin / 5.2,
            y: margin,
            width: subSegmentSize,
            height: 12
          };
        case "S":
          return {
            x: (svgWidth - totalLength) / 2 + segmentOffset - margin / 5.2,
            y: svgHeight - margin - 10,
            width: subSegmentSize,
            height: 12
          };
        case "W":
          return {
            x: margin - 50,
            y: (svgHeight - totalLength) / 2 + segmentOffset,
            width: 12,
            height: subSegmentSize
          };
        case "E":
          return {
            x: svgWidth - margin + 30,
            y: (svgHeight - totalLength) / 2 + segmentOffset,
            width: 12,
            height: subSegmentSize
          };
        default:
          return {};
      }
    }

    // Helper function to get color by position
    function getColorByPosition(position) {
      const side = sides.find((s) => s.position === position);
      return side ? side.color : "black";
    }

    // Draw connections
    trafficData.forEach((connection) => {
      const sourceCoords = getSegmentCoords2(connection.source.position, connection.source.segment, connection.source.subSegment);
      const targetCoords = getSegmentCoords2(connection.target.position, connection.target.segment, connection.target.subSegment);

      const sourceX = sourceCoords.x + sourceCoords.width / 2;
      const sourceY = sourceCoords.y + sourceCoords.height / 2;

      const targetX = targetCoords.x + targetCoords.width / 2;
      const targetY = targetCoords.y + targetCoords.height / 2;

      const lineWidth = lineWidthScale(connection.value);

      const lineColor = getColorByPosition(connection.source.position);

      // Determine if the line should be straight or curved
      const isStraightLine =
        (connection.source.position === "N" && connection.target.position === "S") ||
        (connection.source.position === "S" && connection.target.position === "N") ||
        (connection.source.position === "E" && connection.target.position === "W") ||
        (connection.source.position === "W" && connection.target.position === "E") ||
        connection.source.position === connection.target.position;

      if (isSpecialConnection(connection)) {
        // Draw special curved connections
        const dx = targetX - sourceX;
        const dy = targetY - sourceY;
        const dr = Math.sqrt(dx * dx + dy * dy) * 0.9; // Adjust curvature

        svg
          .append("path")
          .attr("d", `M${sourceX},${sourceY} A${dr},${dr} 1 0,0 ${targetX},${targetY}`)
          .attr("stroke", lineColor)
          .attr("stroke-width", lineWidth)
          .attr("fill", "none")
          .attr("stroke-opacity", 0.4);
      } else if (isStraightLine) {
        // Draw straight lines for direct connections
        svg
          .append("line")
          .attr("x1", sourceX)
          .attr("y1", sourceY)
          .attr("x2", targetX)
          .attr("y2", targetY)
          .attr("stroke", lineColor)
          .attr("stroke-width", lineWidth)
          .attr("stroke-opacity", 0.4);
      } else {
        // Draw curved lines for better visualization
        const dx = targetX - sourceX;
        const dy = targetY - sourceY;
        const dr = Math.sqrt(dx * dx + dy * dy) ;
        svg
          .append("path")
          .attr("d", `M${sourceX},${sourceY} A${dr},${dr} 0 0,1 ${targetX},${targetY}`)
          .attr("stroke", lineColor)
          .attr("stroke-width", lineWidth)
          .attr("fill", "none")
          .attr("stroke-opacity", 0.4);
      }
    });

    // console.log("segmentData", segmentData2);

    // Draw segments as rectangles
    svg
      .selectAll(".segment")
      .data(segmentData2)
      .enter()
      .append("rect")
      .attr("class", "segment")
      .attr("x", (d) => getSegmentCoords2(d.position, d.segment, d.subSegment).x)
      .attr("y", (d) => getSegmentCoords2(d.position, d.segment, d.subSegment).y)
      .attr("width", (d) => getSegmentCoords2(d.position, d.segment, d.subSegment).width)
      .attr("height", (d) => getSegmentCoords2(d.position, d.segment, d.subSegment).height)
      .attr("fill", (d) => d.color);

    // Calculate total traffic per sub-segment
    const trafficPerSubSegment = {};

    trafficData.forEach((d) => {
      const sourceKey = `${d.source.position}-${d.source.segment}-${d.source.subSegment}`;
      const targetKey = `${d.target.position}-${d.target.segment}-${d.target.subSegment}`;

      if (!trafficPerSubSegment[sourceKey]) {
        trafficPerSubSegment[sourceKey] = { in: 0, out: 0 };
      }
      trafficPerSubSegment[sourceKey].out += d.value;

      if (!trafficPerSubSegment[targetKey]) {
        trafficPerSubSegment[targetKey] = { in: 0, out: 0 };
      }
      trafficPerSubSegment[targetKey].in += d.value;
    });

    // console.log(laneMap);

    // Add Traffic Count Labels to Each Sub-Segment
    svg
      .selectAll(".segment-label")
      .data(segmentData2)
      .enter()
      .append("text")
      .attr("class", "segment-label")
      .attr("text-anchor", (d) => {
        return d.position === "E" ? "start" : d.position === "W" || d.position === "N" || d.position === "S" ? "end" : "middle";
      })
      .attr("dominant-baseline", "middle")
      .style("font-size", "12px")
      .style("fill", "black")
      .text((d) => {
        const key = `${d.position}-${d.segment}-${d.subSegment}`;
        const total = (trafficPerSubSegment[key]?.in || 0) + (trafficPerSubSegment[key]?.out || 0);
        return total > 0 ? total : "";
      })
      .each(function(d) {
        const coords = getSegmentCoords2(d.position, d.segment, d.subSegment);
        const x = coords.x + coords.width / 2 + (d.position === "E" ? 15 : d.position === "W" ? -15 : 0);
        const y = coords.y + coords.height / 2 + (d.position === "N" ? -15 : d.position === "S" ? 15 : 0);

        d3.select(this)
          .attr("x", x)
          .attr("y", y)
          .attr("transform", d.position === "N" ? `rotate(90, ${x}, ${y})` : d.position === "S" ? `rotate(-90, ${x}, ${y})` : null);
      });

    // Add lane labels
    allSides.forEach((side) => {
      const lanes = lanesByPosition[side.position] || [];
      lanes.forEach((lane) => {
        const test = laneMap[`${lane.uuid}_${lane.type}`];
        // Get the coordinates of the segment and sub-segment
        const coords = getSegmentCoords2(test.position, test.segment, test.subSegment);

        // console.log(coords);

        // Calculate label position
        let labelX = coords.x + coords.width / 2;
        let labelY = coords.y;
        let anchor = "middle";
        let rotation = 0;

        switch (side.position) {
          case "N":
            labelY = coords.y - coords.height - 40; // Above the segment
            rotation = 90;
            anchor = "end";
            break;
          case "S":
            labelY = coords.y + coords.height + 50; // Below the segment
            rotation = 90;
            anchor = "start";
            break;
          case "W":
            labelY = coords.y + coords.height / 2;
            labelX = coords.x - 50; // Left of the segment
            anchor = "end";
            break;
          case "E":
            labelY = coords.y + coords.height / 2;
            labelX = coords.x + coords.width + 40; // Right of the segment
            anchor = "start";
            break;
          default:
            break;
        }

        svg
          .append("text")
          .attr("class", "labels")
          .attr("x", labelX)
          .attr("y", labelY)
          .attr("text-anchor", anchor)
          .attr("dominant-baseline", "middle")
          .attr("transform", `rotate(${rotation}, ${labelX}, ${labelY})`)
          .style("font-size", "14px")
          .style("font-weight", "bold")
          .style("fill", "black")
          .selectAll("tspan")
          .data(splitText(lane.name)) // Split the text into two lines
          .enter()
          .append("tspan")
          .attr("x", labelX) // Keep the same x position for all lines
          .attr("dy", (d, i) => (i === 0 ? "0em" : "1.2em")) // Adjust vertical spacing
          .text((d) => d);
      });
    });
  }, [data, positions]);

  if (!data || !Array.isArray(data.entries) || !positions) {
    return <div style={{ textAlign: "center" }}>No data to show</div>;
  }

  return (
    <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
      <div id="chart" ref={svgContainerRef}></div>
    </div>
  );
};

export default CountDiagram2;

// Updated processTrafficData function with index mapping
function processTrafficData(trafficData, lanePositionData, segmentDefinitions, indexMapping) {
  const laneMap = {};

  // Map lanes to segments and sub-segments based on position and type
  for (const [position, segments] of Object.entries(segmentDefinitions)) {
    // Get lanes for the current position
    const lanes = lanePositionData[position] || [];

    // Group segments and lanes by type
    const types = ["ENTRY", "EXIT"];
    types.forEach((type) => {
      const segmentsOfType = segments.filter((segmentInfo) => segmentInfo.type === type);
      const lanesOfType = lanes.filter((lane) => lane.type === type);

      // Assign lanes to segments one-to-one
      for (let i = 0; i < Math.min(segmentsOfType.length, lanesOfType.length); i++) {
        const segmentInfo = segmentsOfType[i];
        const lane = lanesOfType[i];

        const key = `${lane.uuid}_${lane.type}`;
        const mappingKey = `${position}${segmentInfo.segment}`;
        const subSegmentIndex = indexMapping[mappingKey]?.subSegment || 2;

        laneMap[key] = {
          position,
          segment: segmentInfo.segment,
          subSegment: subSegmentIndex
        };
      }
    });
  }

  // console.log(laneMap);

  const result = [];

  for (const entry of trafficData.entries) {
    const sourceKey = `${entry.transit_point.lane.uuid}_${entry.transit_point.type}`;
    const sourceLaneInfo = laneMap[sourceKey];

    if (entry.transit_point.type !== "ENTRY" || !sourceLaneInfo) continue;

    for (const flow of entry.flows) {
      const targetKey = `${flow.transit_point.lane.uuid}_${flow.transit_point.type}`;
      const targetLaneInfo = laneMap[targetKey];

      if (flow.transit_point.type !== "EXIT" || !targetLaneInfo) continue;

      // Use index mapping for connections
      const mappingKey = `${sourceLaneInfo.position}${sourceLaneInfo.segment}-${targetLaneInfo.position}${targetLaneInfo.segment}`;

      // console.log(mappingKey);

      const indices = indexMapping[mappingKey] || {
        sourceSubSegment: 1,
        targetSubSegment: 1
      };

      // console.log("indices", indexMapping[mappingKey]);

      result.push({
        source: {
          position: sourceLaneInfo.position,
          segment: sourceLaneInfo.segment,
          subSegment: indices.sourceSubSegment
        },
        target: {
          position: targetLaneInfo.position,
          segment: targetLaneInfo.segment,
          subSegment: indices.targetSubSegment
        },
        value: flow.count
      });
    }
  }

  return { result, laneMap };
}

// Function to filter keys with non-empty arrays
function filterKeysWithNonEmptyArrays(data) {
  return Object.keys(data).filter((key) => Array.isArray(data[key]) && data[key].length > 0);
}

// Direction to Position Mapping
const directionMap = {
  UP: "N",
  RIGHT: "E",
  DOWN: "S",
  LEFT: "W"
};

// Function to extract lanes from config
function extractLanes(config) {
  const lanesByPosition = {
    N: [],
    E: [],
    S: [],
    W: []
  };

  const allTransitPoints = [...config.entries, ...config.exits];

  allTransitPoints.forEach((tp) => {
    const position = directionMap[tp.position];
    if (position) {
      const existingLane = lanesByPosition[position].find((lane) => lane.uuid === tp.lane.uuid);

      if (!existingLane) {
        lanesByPosition[position].push({
          name: tp.lane.name,
          uuid: tp.lane.uuid,
          type: tp.type
        });
      }
    }
  });

  return lanesByPosition;
}

// Index mapping with sub-segments
const indexMapping = {
  // Mapping for source and target positions with sub-segment indices
  "N1-S1": { sourceSubSegment: 2, targetSubSegment: 2 },
  "N1-S3": { sourceSubSegment: 2, targetSubSegment: 2 },
  "S1-N1": { sourceSubSegment: 2, targetSubSegment: 2 },
  "W3-E3": { sourceSubSegment: 2, targetSubSegment: 2 },
  "E2-W2": { sourceSubSegment: 2, targetSubSegment: 2 },
  "E1-W1": { sourceSubSegment: 2, targetSubSegment: 2 },
  "N1-W1": { sourceSubSegment: 1, targetSubSegment: 1 },
  "W3-N1": { sourceSubSegment: 1, targetSubSegment: 1 },
  "N1-E3": { sourceSubSegment: 3, targetSubSegment: 1 },
  "E1-N1": { sourceSubSegment: 1, targetSubSegment: 3 },
  "S2-W1": { sourceSubSegment: 1, targetSubSegment: 3 },
  "W3-S2": { sourceSubSegment: 3, targetSubSegment: 1 },
  "S2-E3": { sourceSubSegment: 3, targetSubSegment: 3 },
  "E1-S2": { sourceSubSegment: 3, targetSubSegment: 3 },
  "E2-E3": { sourceSubSegment: 3, targetSubSegment: 1 },
  "W3-S3": { sourceSubSegment: 3, targetSubSegment: 1 },
  "E1-N2": { sourceSubSegment: 1, targetSubSegment: 3 },
  "S4-W2": { sourceSubSegment: 1, targetSubSegment: 3 },
  "S4-E3": { sourceSubSegment: 3, targetSubSegment: 3 },
  "S4-W1": { sourceSubSegment: 1, targetSubSegment: 3 },
  "E2-S3": { sourceSubSegment: 3, targetSubSegment: 3 },
  "S1-W1": { sourceSubSegment: 1, targetSubSegment: 3 },
  "S1-E3": { sourceSubSegment: 3, targetSubSegment: 3 },
  "S2-W2": { sourceSubSegment: 1, targetSubSegment: 3 },
  "S1-W2": { sourceSubSegment: 1, targetSubSegment: 3 },
  "S4-N2": { sourceSubSegment: 2, targetSubSegment: 2 }
};

// Function to check if a connection is among the specified ones
function isSpecialConnection(connection) {
  const specialConnections = [
    {
      source: { position: "S", segment: 2, subSegment: 1 },
      target: { position: "W", segment: 1, subSegment: 3 }
    },
    {
      source: { position: "N", segment: 1, subSegment: 3 },
      target: { position: "E", segment: 2, subSegment: 1 }
    },
    {
      source: { position: "W", segment: 2, subSegment: 1 },
      target: { position: "N", segment: 2, subSegment: 1 }
    },
    {
      source: { position: "N", segment: 1, subSegment: 2 },
      target: { position: "W", segment: 1, subSegment: 2 }
    },
    {
      source: { position: "E", segment: 2, subSegment: 3 },
      target: { position: "E", segment: 3, subSegment: 1 }
    },
    {
      source: { position: "S", segment: 4, subSegment: 1 },
      target: { position: "W", segment: 1, subSegment: 3 }
    },
    {
      source: { position: "S", segment: 4, subSegment: 1 },
      target: { position: "W", segment: 2, subSegment: 3 }
    },
    {
      source: { position: "E", segment: 2, subSegment: 3 },
      target: { position: "S", segment: 3, subSegment: 3 }
    },
    {
      source: { position: "S", segment: 1, subSegment: 1 },
      target: { position: "W", segment: 1, subSegment: 3 }
    },
    {
      source: { position: "S", segment: 2, subSegment: 1 },
      target: { position: "W", segment: 2, subSegment: 3 }
    },
    {
      source: { position: "S", segment: 1, subSegment: 1 },
      target: { position: "W", segment: 2, subSegment: 3 }
    }
  ];

  return specialConnections.some(
    (special) =>
      special.source.position === connection.source.position &&
      special.source.segment === connection.source.segment &&
      special.source.subSegment === connection.source.subSegment &&
      special.target.position === connection.target.position &&
      special.target.segment === connection.target.segment &&
      special.target.subSegment === connection.target.subSegment
  );
}

function rearrangeData(data) {
  const positionOrder = ["RIGHT", "DOWN", "LEFT", "UP"];

  data.entries.sort((a, b) => {
    const posDiff = positionOrder.indexOf(a.position) - positionOrder.indexOf(b.position);
    if (posDiff !== 0) return posDiff;

    const carDiff = a.carriageway.name.localeCompare(b.carriageway.name);
    if (carDiff !== 0) return carDiff;

    if (a.carriageway.name === "Hofbouwlaan") {
      return b.lane.name.localeCompare(a.lane.name); // Descending order for Hofbouwlaan entries
    }

    return a.lane.name.localeCompare(b.lane.name); // Ascending order for others
  });

  data.exits.sort((a, b) => {
    const posDiff = positionOrder.indexOf(a.position) - positionOrder.indexOf(b.position);
    if (posDiff !== 0) return posDiff;

    const carDiff = a.carriageway.name.localeCompare(b.carriageway.name);
    if (carDiff !== 0) return carDiff;

    if (a.carriageway.name === "Citadellan - West") {
      return a.lane.name.localeCompare(b.lane.name); // Ascending order for Citadellan - West exits
    }

    return a.lane.name.localeCompare(b.lane.name); // Ascending order for others
  });

  return data;
}
function splitText(text) {
  const words = text.split(" ");
  const midpoint = Math.ceil(words.length / 2);
  const line1 = words.slice(0, midpoint).join(" ");
  const line2 = words.slice(midpoint).join(" ");
  return [line1, line2];
}
