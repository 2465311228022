import { isMobile } from "../../utils/responsive"

export const cameraConfig = {
  point: {
    radius: isMobile() ? 80 : 40,
    type: {
      entry: {
        line: "#00ff00",
        inactive: {
          fill: "rgba(132, 195, 65, 0.6)",
          border: "rgba(132, 195, 65, 0.8)"
        },
        active: {
          fill: "rgba(132, 195, 65, 0.9)",
          border: "rgba(132, 195, 65, 0.8)"
        },
        dimmed: {
          fill: "rgba(132, 195, 65, 0.1)",
          border: "rgba(132, 195, 65, 0.1)"
        }
      },
      exit: {
        line: "#ff0000",
        inactive: {
          fill: "rgba(237, 41, 57, 0.6)",
          border: "rgba(237, 41, 57, 0.8)"
        },
        active: {
          fill: "rgba(237, 41, 57, 0.9)",
          border: "rgba(237, 41, 57, 0.8)"
        },
        dimmed: {
          fill: "rgba(237, 41, 57, 0.1)",
          border: "rgba(237, 41, 57, 0.1)"
        }
      }
    }
  },

  // The tooltip that shows the lane name on hover
  tooltip: {
    width: 300
  }
}

export default cameraConfig
