export const menuItems = [
  { name: "Locations", to: "/locations", svgIcon: "/media/svg/icons/Map/Marker1.svg" },
  { name: "Cameras", to: "/cameras", svgIcon: "/media/svg/icons/Devices/Video-camera.svg" },
  { name: "Reports", to: "/reports", svgIcon: "/media/svg/icons/Files/File.svg" },
];

// Multicamera report
export const multiCameraReportRoute = { name: "Multi-Camera", to: "/multi_camera_report", svgIcon: "/media/svg/icons/Devices/Camera.svg" };

// Multiscene report
export const Multiscene = { name: "Multi-Scene", to: "/multiscene", svgIcon: "/media/svg/icons/Code/Commit.svg" };

// Tenant management
export const tenantsRoute = { name: "Tenants", to: "/tenants", svgIcon: "/media/svg/icons/Communication/Group.svg" };

// User management
export const userManagementRoute = { name: "Users", to: "/user-management", svgIcon: "/media/svg/icons/General/User.svg" };

// Events management
export const eventsManagementRoute = { name: "Events", to: "/events", svgIcon: "/media/svg/icons/Code/Compiling.svg" };

// Live camera view
export const LiveCameraRoute = { name: "Live camera", to: "/camera/video", svgIcon: "/media/svg/icons/Communication/RSS.svg" };

// Events Dashboard
export const EventsDashboard = { name: "Event Management", to: "/event/dashboard", svgIcon: "/media/svg/icons/Devices/Cardboard-vr.svg" };

// O-D  matrix
export const ODMatrixRoute = { name: "matrix", to: "/matrix", svgIcon: "/media/svg/icons/Map/Compass.svg" };

// Camera management
export const cameraManagementRoute = { name: "Camera Management", to: "/admin/cm", svgIcon: "/media/svg/icons/Code/Settings4.svg" };

// Camera files
export const cameraFilesRoute = { name: "Camera Files", to: "/camera-files", svgIcon: "/media/svg/icons/Files/Upload-folder.svg" };

// Audit trails
export const auditRoute = { name: "Audit Trail", to: "/audit", svgIcon: "/media/svg/icons/General/Binocular.svg" };

// Audit trails
export const documentationRoute = { name: "User Manual", to: "/documentation", svgIcon: "/media/svg/icons/Design/Image.svg" };

// Settings
export const settingsRoute = { name: "Settings", to: "/settings", svgIcon: "/media/svg/icons/Code/Settings4.svg" };

// Billing dashboard
export const billingRoute = { name: "Billing", to: "/billing", svgIcon: "/media/svg/icons/Shopping/Calculator.svg" };