import React from "react"
import { Tooltip, OverlayTrigger } from "react-bootstrap"

const TableRowAction = ({ actions }) => (
  <>
    {(actions ?? []).map(({ icon, title, setState, show = true }, index) => (
      <React.Fragment key={index}>
        {show && <OverlayTrigger key={index} placement="top" delay={{ show: 100, hide: 200 }} overlay={<Tooltip id="button-tooltip">{title}</Tooltip>}>
          <span className="cursor-pointer mx-2" onClick={() => setState(true)}>
            {icon}
          </span>
        </OverlayTrigger>}
      </React.Fragment>
    ))}
  </>
)
export default TableRowAction
