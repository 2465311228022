import { useState, useEffect } from "react"
import { State } from "../../../../../hooks/useLoading"
import { LoadingWrapper } from "components"
import useAPI from "../../../../../services/ApiService"
import CameraCanvasLines from "./CameraCanvasLines"

/** The configuration for the canvas. */
export const config = {
  aspectRatio: 16 / 9
}

const CameraCanvas = ({ lines = [], image, updateLines, prevStep }) => {
  const api = useAPI()

  // Make sure nothing is rendered until the image is completely downloaded
  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(false)
  // Use the API service to load the image in order to use proper authentication headers
  const [imageBlobURL, setImageBlobURL] = useState()
  useEffect(() => {
    api.getImage(image).then(response => setImageBlobURL(response))
  }, [api, image])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }, [lines])

  // Build the image element
  const imageElement = document.createElement("img")
  imageElement.onload = () => setReady(true)
  if (imageBlobURL) {
    imageElement.src = imageBlobURL
  }

  // Handle loading
  const state = ready && !loading
    ? State.READY
    : State.LOADING

  // To prevent unnecessary re-renders we need to do deep copy of lines, so we do not update original array because each line is an object
  return (
    <LoadingWrapper state={state} aspectRatio={config.aspectRatio}>
      <CameraCanvasLines lines={lines} updateLines={updateLines} prevStep={prevStep} image={imageElement} />
    </LoadingWrapper>
  )
}

export default CameraCanvas
