import { AddCircleOutline } from "@material-ui/icons"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Form } from "react-bootstrap"
import { useQuery } from "react-query"
import { useFormik } from "formik"
import useCameraManagementAPI from "services/CameraManagementService"
import { LoadingWrapper } from "components"
import LargeCard from "components/Structure/LargeCard"
import { formikDefault, uploadFilesStep1 } from "utils/forms-schema"
import { ConfirmModal, useConfirmModal } from "../../../../../components/Modal/ConfirmModal"
import { FormText, SelectInput, StringInput, TimeInput } from "../../../../../components/Reports/Fields"
import { uniqueValues } from "../../../../../utils/data"
import { applyTimeZone, applyTimezoneOffset, getTimezoneOffset, humanTime, NOW } from "../../../../../utils/time"
import moment from "moment"

/**
 * @param {CMBatch} batch
 * @param {function([])} updateValues
 * @return {JSX.Element}
 * @constructor
 */
const Step1 = ({ batch, updateValues }) => {
  const history = useHistory()
  const api = useCameraManagementAPI()
  const { data: cameras, status } = useQuery("CameraManagement", () => api.cameras())

  // Prefix every camera with the system, if the user has access to multiple ones
  const systems = uniqueValues((cameras ?? []).map((camera) => camera.system))
  const listOfCameras = (cameras ?? [])?.map(camera => ({
    value: camera.uuid,
    label: (systems.length > 1 ? `(${camera.system}) ` : "") + camera.name
  }))

  // Add a new camera from the form
  const addNewCamera = useConfirmModal(
    "Add a new camera",
    <><p>You will be redirected to the Camera Management to add a new camera.</p><p>Once the new camera is set up please return here and choose the new camera you have set up.</p></>,
    () => history.push("/admin/cm/add"),
    "Continue"
  )

  // Define form
  const formik = useFormik({
    ...formikDefault,
    initialValues: batch,
    validationSchema: uploadFilesStep1,
    // onSubmit: values => updateValues({ ...values, start: applyTimezoneOffset(values.start, getTimezoneOffset()), step: 2 })
    // onSubmit: values => updateValues({ ...values, start: values.start, step: 2 })
    onSubmit: values => updateValues({ ...values, start: batch?.edit ? applyTimeZone(values.start, values.timezone) : values.start, step: 2 })
  })

  // Update form
  useEffect(() => {
    // convert to local time
    batch.timezone.length > 0 && formik.setValues({ ...batch, start: moment.utc(batch.start).local().format("YYYY-MM-DD HH:mm") })
    // formik.setValues(batch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch])

  return (
    <LargeCard title={batch?.edit ? "Video Files Batch Edit" : "Video Files Batch 1/3: Basic info"}>
      <LoadingWrapper state={status} onEmpty={"There are no cameras to add files to"}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="row">

            <FormText>
              <p>Files are uploaded in batches, where each batch of files represents a continuous video that can be processed.</p>
              <p>Please define batch details here, the files can be uploaded in the next step.</p>
            </FormText>

            {/* Name and start time */}
            <div className="col-md-6">
              <StringInput label="Batch name" name="name" formik={formik} />
              <TimeInput label="Start time of the batch" name="start" max={NOW} formik={formik} />
            </div>

            {/* Camera */}
            <div className="col-md-6">
              <SelectInput label="Camera" name="camera_uuid" options={listOfCameras} formik={formik} />
              <button type="button" className="btn btn-secondary" onClick={() => addNewCamera.show()}>
                <AddCircleOutline /> Add a new camera
              </button>
            </div>
          </div>

          <div className="form-buttons">
            <button className="btn btn-default" type="button" onClick={() => history.goBack()}>Cancel</button>
            <button className="btn btn-primary" type="submit">{batch?.edit ? "Save" : "Next"}</button>
          </div>

        </Form>
      </LoadingWrapper>

      <ConfirmModal hook={addNewCamera} />
    </LargeCard>
  )
}

export default Step1
