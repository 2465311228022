import { MapContainer, Marker, TileLayer, useMap, useMapEvent } from "react-leaflet"
import { greenMarker } from "../../../../../components"

const MapEventHandler = ({ setLocation }) => {
  useMapEvent("click", e => setLocation(e.latlng))
  return null
}

function UpdateMapCentre({ center }) {
  const map = useMap()
  center && map.panTo(center)
  return null
}

const Map = ({ locations, onChange, height = 400, satellite = false, scrollWheelZoom = false }) => (
  <MapContainer
    className="map-container"
    center={locations ? locations[0] : [35.881856, 14.443718]}
    zoom={13}
    scrollWheelZoom={scrollWheelZoom}
    style={{ height, cursor: onChange ? "pointer" : "inherit" }}
  >

    {!satellite && (
      <TileLayer
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    )}

    {/*{satellite && (*/}
    {/*  <TileLayer*/}
    {/*    url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"*/}
    {/*    maxZoom={20}*/}
    {/*    subdomains={["mt1", "mt2", "mt3"]} />*/}
    {/*)}*/}

    {(locations ?? []).map((location, index) => (
      <Marker key={index} position={location} icon={greenMarker} />
    ))}

    <UpdateMapCentre center={locations ? locations[0] : [35.881856, 14.443718]} />
    <MapEventHandler setLocation={(event) => onChange && onChange(event)} />
  </MapContainer>
)

export default Map
