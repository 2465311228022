import { toArray } from "../../utils/data"
import JSONPainterCollapsable from "./JSONPainterCollapsable"
import JSONPainterNode from "./JSONPainterNode"

const JSONPainterNodeObject = ({ node, collapsed = false }) => (
  <JSONPainterCollapsable collapsed={collapsed} before="{ " after=" }">
    {"{"}
    <ul>
      {
        toArray(node).map((item, index) => (
          <li key={index} className="jsonpainter-item">
            <em className="jsonpainter-key">{`${item.key}: `}</em>
            <JSONPainterNode node={item.value} />
          </li>
        ))
      }
    </ul>
    {"}"}
  </JSONPainterCollapsable>
)

export default JSONPainterNodeObject
