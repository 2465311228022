const groupStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
  marginBottom: "0.5rem",
  fontSize: "0.8rem"
};
const groupBadgeStyles = {
  backgroundColor: "#ebecf0",
  borderRadius: "2em",
  color: "#172b4d",
  display: "inline-block",
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center"
};
// Customize Dropdown styles
const dropDownStyles = {
  option: (provided, state) => {
    return {
      padding: "5px 10px",
      fontSize: "0.85rem",
      cursor: "default",
      label: "option",
      "&:hover": {
        backgroundColor: "#deebff"
      },
      input: {
        marginTop: 8,
        marginRight: 5
      }
    };
  }
};
export { dropDownStyles, groupBadgeStyles, groupStyles };
