import { useQuery, useMutation, useQueryClient } from "react-query"
import toast from "react-hot-toast"
import useCameraManagementAPI from "../../../services/CameraManagementService"

/**
 * Mutate files dashboard data.
 *
 * @param {function} fn function to execute ,it depends on operations create/edit/delete file.
 * @param {string} successMessage Success message to render in Toast.
 *
 * @return {function} react-query mutation function.
 */

export const useMutateFile = (fn, successMessage) => {
  const queryClient = useQueryClient()

  return useMutation(fn, {
    onSuccess: () => {
      if (successMessage) {
        toast.success(successMessage)
      }
      queryClient.invalidateQueries("files")
    },

    onError: error => toast.error(error?.message ?? "An unknown error has occurred")
  })
}
/**
 * Create an empty Batch.
 * @return {{}} mutationFn.
 */
export const useCreateBatchFiles = () => {
  const api = useCameraManagementAPI()
  return useMutation((data) => api.createBatchFiles(data))
}

/**
 * Get all Batch Files.
 * @return {{}} queryFn.
 */
export const useGetAllBatches = () => {
  const api = useCameraManagementAPI()
  return useQuery("files", () => api.getAllFiles())
}

/**
 * Get Files of a single Batch.
 * @param {string} batchUUID The batch uuid.
 * @return {{}} queryFn.
 */
export const useGetFiles = (batchUUID) => {
  const api = useCameraManagementAPI()
  return useQuery("files", () => api.getAllFiles(), { select: (data) => data?.find((batchFile) => batchFile?.uuid === batchUUID) })
}

/**
 * Upload a single file.
 * @param {function} fn The axios fetcher to mutate a Batch.
 * @return {{}} mutationFn.
 */
export const useUploadFile = (fn) => {
  return useMutateFile(fn)
}

/**
 * Update files of a Batch.
 * @param {string} batchUUID The batch uuid.
 * @return {{}} mutationFn.
 */
export const useUpdateFiles = (batchUUID) => {
  const api = useCameraManagementAPI()
  return useMutateFile((data) => api.updateFiles(batchUUID, data), "Files are updated")
}

/**
 * Process a Batch.
 * @param {string} batchUUID The batch uuid.
 * @return {{}} mutationFn.
 */
export const useProcessFiles = (batchUUID) => {
  const api = useCameraManagementAPI()
  return useMutateFile(() => api.processFiles(batchUUID), "File batch is sent for processing")
}

/**
 * Cancel the processing of a Batch.
 * @param {string} batchUUID The batch uuid.
 * @return {{}} mutationFn.

 */
export const useCancelProcessing = (batchUUID) => {
  const api = useCameraManagementAPI()
  return useMutateFile(() => api.cancelProcessing(batchUUID), "Batch processing has been canceled")
}

/**
 * Delete a Batch.
 * @param {string} batchUUID The batch uuid.
 * @return {{}} mutationFn.
 */
export const useDeleteBatch = (batchUUID) => {
  const api = useCameraManagementAPI()
  return useMutateFile(() => api.deleteBatch(batchUUID), "File batch is deleted")
}
