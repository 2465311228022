import { useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { useDeleteBatch } from "../queries"

const DeleteBatch = ({ name, batchUUID, state }) => {
  const deleteBatch = useDeleteBatch(batchUUID)
  const audit = useCameraManagementAPI()

  useEffect(() => {
    if (deleteBatch.isSuccess) {
      state[1](false)

      // Audit log
      audit.log("# File batch delete", { batchUUID })
    }
  }, [deleteBatch.isSuccess, state, audit, batchUUID])

  return (
    <Modal centered show={state[0]} size="lg" onHide={() => state[1](false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete File Batch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-weight-light">
          Are you sure you want to permanently delete the batch: <span className="font-weight-bold">{name}</span>?
        </h5>
        <div className="mt-5 float-right">
          <Button variant="secondary" onClick={() => state[1](false)}>
            Cancel
          </Button>
          <Button variant="danger" className="ml-3" onClick={deleteBatch.mutate}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteBatch
