import { useState, Fragment, useEffect, useContext } from "react"
import { Table } from "react-bootstrap"
import { NavLink, useParams } from "react-router-dom"
import { useLoading } from "hooks/useLoading"
import { ExportToCsv, LoadingWrapper } from "components"
import { buildReportLink } from "../Reports"
import LargeCard from "components/Structure/LargeCard"
import { set } from "utils/data"
import { readReportParamsFromQuery } from "../Reports"
import ReportFilters from "components/Reports/ReportFilters"
import { ArrowBack } from "@material-ui/icons"
import useAPI from "services/ApiService"
import useStoreDateRange from "hooks/useStoreDateRange"
import useCameraManagementAPI from "services/CameraManagementService"
import { humanDate, humanDayTime } from "utils/time"
import { getSessionsWithStatus } from "utils/functions"
import PaginationBar from "components/Pagination/PaginationBar"
import { Lens } from "@material-ui/icons"
import { EventsContext } from "app/pages/Events/EventsLayout"
import PaginationBarReport from "components/Pagination/PaginationBarReport"
import format from "date-fns/format"

export const LiveSessions = () => {
    const dateRange = useStoreDateRange()
    const api = useAPI()
    const cameraApi = useCameraManagementAPI()
    const { page, setPage } = useContext(EventsContext)

    // lower and upper boundary for pagination
    let lower = (page - 1) * 20
    let upper = page * 20

    // Read Report type
    const reportType = window.location.pathname.split("/")[2]
    // Read report parameters from the URL
    const [params, updateParams] = useState(readReportParamsFromQuery())
    const [filteredData, setFilteredData] = useState(null)
    const [mappedData, setMappedData] = useState(null)
    const [csv, setCsv] = useState([])

    // Load the data
    const [camera] = useLoading(() => api.camera(params))
    const [report, reportLoadingState] = useLoading(() =>
        api.getLiveSessions(params.systemID, params.cameraID, dateRange),
        [params]
    )
    console.log(params)
    useEffect(() => {
        if (report) {
            setFilteredData(report?.sessions)
            setMappedData(getSessionsWithStatus(report?.sessions))
        }
        setPage(1)
    }, [report])

    // Repack the data into a structured pack
    const structured = {}
    for (const item of report?.rows ?? []) {
        set(
            structured,
            [
                item.entry_carriageway,
                item.entry_lane,
                item.exit_carriageway,
                item.exit_lane
            ],
            item
        )
    }

    // CSV table
    useEffect(() => {
        let arr = ['Entry'];

    }, [filteredData, mappedData, params?.vehicles])

    const startDate = format(dateRange?.start, "dd-MM-yyyy")
    const endDate = format(dateRange?.end, "dd-MM-yyyy")

    // The header options
    const headerOptions = (
        <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
            <NavLink
                className="mr-5"
                to={buildReportLink(reportType, {
                    systemID: params.systemID,
                    cameraID: params.cameraID,
                    carriageways: params.carriageways,
                    lanes: params.lanes,
                    vehicles: params.vehicles,
                    range: params.range
                }, true)}
            >
                <ArrowBack /> Back to reports generator
            </NavLink>

            <ExportToCsv data={csv} filename={`${camera?.name} - Camera Heartbeat Report - ${startDate} - ${endDate}`} />
        </div>
    )

    return (
        <LargeCard title="Camera Heartbeat Report" headerOptions={headerOptions}>
            {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

            <LoadingWrapper
                state={reportLoadingState}
                onEmpty={"There is no data to show for the supplied filter"}
            >
                {mappedData && mappedData?.length > 0 ? (
                    <>
                        <Table bordered hover={false} className="v-align">
                            <thead>
                                <tr>
                                    <th>
                                        Session Start
                                    </th>
                                    <th>
                                        Session End
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Duration
                                    </th>
                                </tr>

                            </thead>
                            <tbody>

                                {mappedData?.slice(lower, upper)?.map((item, i) => <tr>
                                    <td>
                                        {humanDate(item?.start)}{' '}
                                        {humanDayTime(item?.start)}
                                    </td>
                                    <td>
                                        {humanDate(item?.end)}{' '}
                                        {humanDayTime(item?.end)}
                                    </td>
                                    <td>
                                        <span className="mr-4">{item?.status}</span>
                                        <Lens className={item?.status === "ON" ? 'green' : 'red'} />
                                    </td>
                                    <td>
                                        {item?.duration}
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </>

                ) : (
                    <h1 className="text-center my-10">No events captured yet</h1>
                )}
                {filteredData && <PaginationBarReport
                    type={reportType}
                    params={params}
                    arr={mappedData} />}
            </LoadingWrapper>
        </LargeCard>
    )
}
