import { useEffect, useState } from "react"
import { isEmpty, isNotEmpty } from "../utils/functions"
import { get } from "../utils/data"
import { normalize } from "../components/LoadingWrapper/LoadingWrapper"
import { useQuery, useQueryClient } from "react-query"

/** The list of all possible states loading can be in. */
export const State = {
  LOADING: "LOADING",
  REFRESH: "REFRESH",
  ERROR: "ERROR",
  EMPTY: "EMPTY",
  READY: "READY"
}

/**
 * Check if the response contains an error and return in.
 *
 * @param {*} response The response from the API (or somewhere else, I guess)
 * @return {string|null} If error was found it is returned, if not returns null.
 */
export const getError = (response) => {

  // String is always treated as an error
  if (typeof response === "string") {
    return response
  }

  // If there is an explicit error in the response, with optional status and an additional message
  if (response != null && response.error != null) {
    const code = response.status != null ? `${response.status} - ` : ""
    const message = response.message != null ? ` "${response.message}"` : ""
    return `${code}${response.error}${message}`
  }

  // No errors found
  return null
}

/**
 * The hook for loading stuff with the current state.
 *
 * @param {Promise<*>} loadingPromise The promise that will load the data.
 * @param {string[]} deps The list of dependencies, same as with useEffect() hook.
 * @param {string|string[]} emptyTest The path to check for empty content.
 *
 * @return {[string, *]}
 *    0 - The loaded data, or null during loading and error.
 *    1 - The current state of the loading.
 */
export const useLoading = (loadingPromise, deps = [], emptyTest = null) => {
  const [state, setState] = useState(State.LOADING)
  const [data, setData] = useState(null)
  /** Easier way to update both states. */
  const update = (state, data = null) => {
    // Set data only on READY state
    setData(state === State.READY ? data : null)

    // Always set data before setting the state
    setState(state ?? State.EMPTY)
  }

  useEffect(() => {
    const controller = new AbortController();
    // Start in the loading state
    update(State.LOADING)

    // Load the data
    loadingPromise()
      .catch(() => update(State.ERROR))
      .then((result) => {
        // Guard: Check for errors
        const error = getError(result)
        if (error != null) {
          console.error(`Loading promise returned an error: ${error}`)
          update(State.ERROR)
          return
        }

        // If reached the data is ready, but make sure that the payload is not empty
        const test = emptyTest ? get(result, emptyTest) : result
        update(isNotEmpty(test) ? State.READY : State.EMPTY, result)

      })
    return () => {
      controller.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return [data, state]
}


/**
 * The hook for loading stuff with the current state, with a key to allow for invalidation.
 *
 * @param {string}  key
 * @param {string[]} deps The list of dependencies, same as with useEffect() hook.
 * @param {Promise<*>} loadingPromise The promise that will load the data. * @param emptyTest
 * @param {string|string[]} emptyTest The path to check for empty content.
 *
 * @return {[string, *]}
 *    0 - The loaded data, or null during loading and error.
 *    1 - The current state of the loading. */
export const useQueryLoading = (key, loadingPromise, deps = [], emptyTest = null) => {
  const queryClient = useQueryClient()
  const query = useQuery(key, loadingPromise)

  // Invalidate on any dependency change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {

    const controller = new AbortController();
    queryClient.invalidateQueries(key)

    return () => {
      controller.abort()
    }
  }, deps)

  // Normalize the status and check for empty
  let status = normalize(query.status)
  if (status === State.READY && isEmpty(emptyTest ? get(query.data, emptyTest) : query.data)) {
    status = State.EMPTY
  }

  return [query.data, status]
}
