import { useFormik } from "formik"
import { Form } from "react-bootstrap"
import LargeCard from "../../../../components/Structure/LargeCard"
import { FileInput, FormHeader, FormText, StringInput } from "../../../../components/Reports/Fields"
import { cameraManagementStep3, formikDefault } from "../../../../utils/forms-schema"
import { LoadingImage } from "../../../../components"
import { stepTitle } from "../form"
import { config } from "./CameraCanvas/CameraCanvas"

/**
 * Set the basic information about the camera.
 *
 * @param camera
 * @param title
 * @param setValues
 * @param prevStep
 * @return {JSX.Element}
 * @constructor
 */
const Step3 = ({ camera, title, setValues, prevStep }) => {

  // Define form
  const formik = useFormik({
    ...formikDefault,
    initialValues: camera,
    onSubmit: setValues
  })

  // Show the proper image
  const image = formik.values.newImage || camera.newImage || camera.image?.url
  const preview = image ? (
    <LoadingImage className="img-fluid mb-2" src={image} alt="Camera image" aspectRatio={config.aspectRatio} />
  ) : (
    <div className="well info mt-0 mb-3" style={{ display: "none" }}>
      <h5>Auto-generated camera screenshot image</h5>
      If a video stream / video file was provided the system will generate a camera screenshot image in the next few minutes
    </div>
  )

  return (
    <LargeCard title={stepTitle(title, camera, "Image")}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="">
          {/* Camera image */}

          <FormHeader>Camera image</FormHeader>
          <FormText>
            <p>
              A still image of the camera that you are setting up is required. Results of camera analysis are plotted on this image.<br />
              You can either manually upload an image yourself or else the system can automatically generate an image.
            </p>
            <ul>
              <li><b>Manual upload:</b> upload the image that will be used by clicking on the link below</li>
              <li><b>Auto-generated:</b> do not upload image, the system will generate a camera screenshot image from the video feed or file in the next few minutes</li>
            </ul>
          </FormText>


          {/* Upload image */}
          <FileInput
            name="newImage"
            button={image ? "Click here to change the image" : "Click here to manually upload an image"}
            preview={preview}
            accept="image/jpeg"
            formik={formik} />
        </div>

        <div className="form-buttons">
          <button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>
          <button className="btn btn-primary" type="submit">Next</button>
        </div>

      </Form>
    </LargeCard>
  )
}

export default Step3
