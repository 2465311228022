import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Table } from "react-bootstrap"
import { AddLocation } from "@material-ui/icons"
import { allowEmpty } from "../../../components/LoadingWrapper/LoadingWrapper"
import LargeCard from "../../../components/Structure/LargeCard"
import { LoadingWrapper } from "../../../components"
import { useLoading, useQueryLoading } from "../../../hooks/useLoading"
import useAPI from "../../../services/ApiService"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import { slugOf } from "../../../utils/strings"
import TableRow from "./TableRow"

const CameraManagementDashboard = () => {
  const { user } = useSelector((state: ReduxStore) => state.auth)

  // Load the existing cameras
  const dataAPI = useAPI()
  const confAPI = useCameraManagementAPI()
  const [cameras: CMCameraResponse[], camerasLoadingState] = useQueryLoading("CameraManagement:List", () => confAPI.cameras())
  const [camerasData: CameraDTO[], camerasDataLoadingState] = useLoading(() => dataAPI.cameras())

  // Get the list of all systems used, as a unique array
  const [systems: CMSystemResponse[], systemsLoadingState] = useQueryLoading("Systems", () => confAPI.systems())

  // Add new camera
  const headerOptions = (
    <div className="float-right">
      <Link to="/admin/cm/add" className="btn btn-primary">
        <AddLocation /> Add a new camera
      </Link>
    </div>
  )

  const filteredCameras = (cameras ?? []).filter(camera => user.userManagingTenantsIds.includes(slugOf(camera.system)))
  // console.log(filteredCameras)
  return (
    <LargeCard title="Camera Management" headerOptions={headerOptions}>
      <LoadingWrapper state={[camerasLoadingState, allowEmpty(camerasDataLoadingState), systemsLoadingState]} onEmpty={"There are no cameras to show"}>
        {filteredCameras.length > 0 ? (
          <Table bordered hover={false} className="v-align">
            <thead>
              <tr>
                {systems?.length > 1 && <th width="200">System</th>}
                <th width="*">Camera name</th>
                <th width="200">Location</th>
                <th width="250">Status</th>
                <th width="120" className="text-center">Created at</th>
                <th width="100" />
              </tr>
            </thead>
            <tbody>
              {filteredCameras.map((camera: CMCameraResponse) => (
                <TableRow camera={camera} cameraData={camerasData?.find(cam => cam.uuid === camera.uuid)} key={camera.uuid} showSystemColumn={systems?.length > 1} />
              ))}
            </tbody>
          </Table>
        ) : (
          <h1 className="text-center my-10">No camera added yet</h1>
        )}
      </LoadingWrapper>
    </LargeCard>
  )
}

export default CameraManagementDashboard
