import { noImage } from "./static";
import { url } from "../services/CameraManagementService";

/**
 * Get the name of the carriageway.
 *
 * @param {CameraDTO} camera The camera containing the carriageway.
 * @param {string} carriagewayUUID The UUID of the carriageway.
 *
 * @return {string|null} The name of the requested carriageway, or null if the carriageway cannot be found.
 */
export const getCarriagewayName = (camera: CameraDTO, carriagewayUUID: string) =>
  camera?.carriageways?.find(carriageway => carriageway.uuid === carriagewayUUID)?.name;

/**
 * Get the name of the lane.
 *
 * @param {CameraDTO} camera The camera containing the lane.
 * @param {string} laneUUID The UUID of the lane.
 *
 * @return {string|null} The name of the requested lane, or null if the lane cannot be found.
 */
export const getLaneName = (camera: CameraDTO, laneUUID: string) => {
  for (const carriageway of camera?.carriageways) {
    const lane = carriageway.lanes.find(lane => lane.uuid === laneUUID);
    if (lane) {
      return lane.name;
    }
  }

  return null;
};

/**
 * Get the URL to the image.
 *
 * @param {UUID} cameraUUID The UUID of the camera to get the image for.

 * @return {string} The absolute URL to the image.
 */
export const getCameraImageURL = (cameraUUID: string): string =>
  url(`storage/camera-image/${cameraUUID}`);

/**
 * The fallback image to show when the camera does not have its own.
 *
 * @type {{width: number, url: string, height: number}}
 */
export const defaultCameraImage = {
  url: noImage,
  width: 1920,
  height: 1080
};
