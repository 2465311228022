import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { extend } from "../../utils/data";
import { humanNumber } from "../../utils/functions";
import Gradient from "javascript-color-gradient";
import { isMobile } from "../../utils/responsive";

// Define the color gradient to use
const colorGradient = new Gradient();
colorGradient.setGradient("#b0dbbc", "#256d54");

// Define the locked
const roundingBoundaries = [8, 6, 5, 4, 2];

/**
 * Generate the color scale to use for the data.
 *
 * @param {HeatmapChartRowDTO[]} data The supplied data.
 *
 * @return {{ranges: []}} The data go supply to the heatmap chart.
 */
const generateColorScale = (data) => {

  // The number of different steps to use
  const steps = 5;
  colorGradient.setMidpoint(steps + 1);

  // Get the maximum value from the whole
  const max = data?.reduce((max, row) => {
    const rowMax = row.data.reduce((rowMax, cell) => cell.y > rowMax ? cell.y : rowMax, 0);
    return rowMax > max ? rowMax : max;
  }, 0) ?? 0;

  // Get the leading number and the magnitude of te max
  const leadingNumber = 1 * (max + "").charAt(0);
  const magnitude = Math.floor(Math.log10(max));

  // Get the rounded maximum to use
  let roundedMax = roundingBoundaries.find(boundary => boundary <= leadingNumber) ?? 1;

  // Update the rounded maximum with the actual magnitude
  roundedMax *= Math.pow(10, magnitude);

  // Prepare the range
  const ranges: [] = [
    { from: -1, to: -1, color: "#e4e4e4", name: "no data" }
  ];

  // Build the additional ranges
  for (let step = 0; step <= steps; step++) {
    ranges.push({
      color: colorGradient.getColor(step + 1),

      // Start from 0 on the first step, not for 1
      from: step > 0
        ? step * roundedMax / steps + 1
        : 0,

      // No limit on the last step
      to: step < steps
        ? (step + 1) * roundedMax / steps
        : Number.MAX_SAFE_INTEGER,

      // Show that there is no limit on the last step
      name: step < steps
        ? humanNumber(step * roundedMax / steps + 1) + " - " + humanNumber((step + 1) * roundedMax / steps)
        : humanNumber(step * roundedMax / steps + 1) + " +"
    });
  }
  ranges.push({ from: max, to: max, color: "#2365A7", name: "Max" })
  return { ranges };
}
  ;

/**
 * Show the two-dimensional heatmap.
 *
 * @param {?string} title The optional title for the chart.
 * @param {HeatmapChartRowDTO[]} data The data to show in the chart.
 * @param {int} height The height of the chart.
 * @param {{}} options The additional options for ApexCharts.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const HeatmapChart = ({ title, data, height = 400, options = {} }) => {

  // Create a reference to the DOM element that shows the chart
  const chartRef = useRef();

  // Show Monday(top) to Sunday(bottom), not other way around
  data = data.reverse();

  useEffect(() => {

    // Make sure the DOM element has been initialized
    if (!chartRef.current) {
      return;
    }

    // Define the options for the chart
    const useOptions = extend(options, {
      chart: {
        type: "heatmap",
        toolbar: {
          show: false
        },
        height: height,
        animations: {
          enabled: false
        }
      },
      series: data,
      plotOptions: {
        heatmap: {
          colorScale: generateColorScale(data),
          enableShades: false
        }
      },
      dataLabels: {
        formatter: (val, opts) => val !== -1 ? humanNumber(val) : "-"
      },
      states: {
        hover: {
          filter: {
            type: "lighten",
            value: 0.05
          }
        }
      },
      xaxis: {
        labels: {
          formatter: (val, val2, info) =>
            isMobile() // Show every fourth label on the chart
              ? info.i % 4 === 0 ? val : " "
              : val
        }
      },
      yaxis: {
        labels: {
          formatter: val => (val + "").substr(0, 3)
        }
      },
      tooltip: {
        followCursor: true,
        y: {
          formatter: val => val !== -1 ? humanNumber(val) : "-",
          title: {
            formatter: (seriesName, data) => {
              const start = (data.dataPointIndex + "").padStart(2, "0");
              const end = (data.dataPointIndex + 1 + "").padStart(2, "0");
              return `${seriesName}, ${start}h - ${end}h:`;
            }
          }
        }
      }

    });

    // Draw the chart
    const chart = new ApexCharts(chartRef.current, useOptions);
    chart.render();

    // Make sure chart is properly disposed of
    return () => chart.destroy();

  }, [data, height, options]);

  return (
    <div className="chart heatmap-chart">
      {title && <h5>{title}</h5>}
      <div ref={chartRef} />
    </div>
  );
};
