import React from "react";
import L from "leaflet";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const accident = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/svg/accident.png"),
  iconSize: [30, 26],
  iconAnchor: [12, 22],
  popupAnchor: [1, -34]
});

const roadworks = new L.Icon({
  iconUrl: toAbsoluteUrl("/media/svg/roadworks.png"),
  iconSize: [30, 26],
  iconAnchor: [12, 22],
  popupAnchor: [1, -34]
});

/**
 * Build a marker based on its color.
 *
 * @typedef {"blue"|"gold"|"red"|"green"|"orange"|"yellow"|"violet"|"grey"|"black"} MarkerColor
 * @param {MarkerColor} color The color of the marker to use.
 *
 * @return {L.Icon} The icon to use as the marker.
 */
const colorMarker = (color) => new L.Icon({
  iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${color}.png`,
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
const blueMarker = colorMarker("blue");
const goldMarker = colorMarker("gold");
const redMarker = colorMarker("red");
const greenMarker = colorMarker("green");
const orangeMarker = colorMarker("orange");
const yellowMarker = colorMarker("yellow");
const violetMarker = colorMarker("violet");
const greyMarker = colorMarker("grey");
const blackMarker = colorMarker("black");

const MarkerImg = ({ label, color }) => {
  return (
    <>
      <span
        style={{
          display: "inline-block",
          padding: "4px",
          marginRight: "5px",
          marginLeft: "4px"
        }}
      >
        <img
          style={{ height: "20px" }}
          alt={label}
          src={`https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${color}.png`}
        />{" "}
        {label}
      </span>
    </>
  );
};

export {
  blueMarker,
  goldMarker,
  redMarker,
  greenMarker,
  orangeMarker,
  yellowMarker,
  violetMarker,
  greyMarker,
  blackMarker,
  accident,
  roadworks,
  MarkerImg
};
