import { State, useLoading } from 'hooks/useLoading'
import React, { createContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useAPI from 'services/ApiService'
import useCameraManagementAPI from 'services/CameraManagementService'

export const EventsContext = createContext('')

const EventsLayout = ({ children }) => {
    // EVENTS STATES
    const { systemID, cameraID } = useParams();
    const dateRange = useSelector(state => state.dateRange)
    const [dateRangeModal: boolean, setDateRangeModal] = useState(false)
    const [selectedCameras, setSelectedCameras] = useState()
    const [selectedVehicles, setSelectedVehicles] = useState([])
    const [selectedAPI, setSelectedAPI] = useState('vehicles-stopped-count')
    const [minDuration, setMinDuration] = useState(0)
    const [page, setPage] = useState(1)
    const [filter, setFilter] = useState('')
    const [validZone, setValidZone] = useState(false)
    const [zoneTypes, setZoneTypes] = useState('')
    const [zoneIds, setZoneIds] = useState([])

    // EVENT MANAGEMENT STATES
    const [Steps, setSteps] = useState(1)
    const [eventTitle, setEventTitle] = useState('')
    const [zones, setZones] = useState([])
    const [SelectedZone, setSelectedZone] = useState(null)
    const [camera, setCamera] = useState('')

    let sys_id = selectedCameras ? JSON.parse(selectedCameras)?.sys_id : systemID;
    let camera_id = selectedCameras ? JSON.parse(selectedCameras)?.camera_id : cameraID;

    const api = useAPI();
    const cameraAPI = useCameraManagementAPI()

    const [vehicleData, eventLoadingState] =
        useLoading(() =>
            api.getVehicleStoppedCount(sys_id, camera_id, dateRange,
                selectedAPI === 'vehicletypes-stopped-granular-15' ? 'vehicletypes-stopped-count-granular' : selectedAPI,
                minDuration, selectedAPI === 'vehicletypes-stopped-granular-15' && 15, selectedVehicles, zoneIds),
            [dateRange, sys_id, selectedAPI, camera_id, minDuration, selectedVehicles, zoneIds]);

    const [types] = useLoading(() =>
        cameraAPI.getParkingZones('zones/type'),
        []
    )

    let isLoading = !sys_id || !camera_id ? State.LOADING : eventLoadingState
    return (
        <EventsContext.Provider value={{
            dateRangeModal, setDateRangeModal,
            selectedAPI, setSelectedAPI, selectedCameras,
            setSelectedCameras, selectedVehicles,
            setSelectedVehicles, minDuration, setMinDuration,
            dateRange, vehicleData, eventLoadingState, isLoading,
            filter, setFilter, page, setPage, camera, setCamera, systemID: sys_id,
            Steps, setSteps, zones, setZones, SelectedZone, setSelectedZone,
            eventTitle, setEventTitle, types, setZoneTypes, validZone, setValidZone,
            zoneIds, setZoneIds
        }}>
            {children}
        </EventsContext.Provider>
    )
}

export default EventsLayout