import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { reduxBatch } from "@manaflair/redux-batch";
import { persistStore } from "redux-persist";
import { saveDateRangeState } from "../utils/localStorage";
import AuthService from "../services/AuthService";
import { combineReducers } from "redux";
import { dateRangeSlice } from "./dateRangeSlice";
import { cameraSlice } from "./cameraSlice";
import { reducer } from "./authSlice";

/**
 * @typedef ReduxAction
 *    @property {string} type
 *    @property {{}} payload
 */

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  sagaMiddleware
];

const store = configureStore({
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch],
  preloadedState: {
    auth: AuthService.readFromStorage()
  },
  reducer: combineReducers({
    auth: reducer,
    dateRange: dateRangeSlice.reducer,
    camera: cameraSlice.reducer
  })
});

store.subscribe(() => {
  saveDateRangeState(store.getState().dateRange);
  AuthService.writeToStorage(store.getState().auth);
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;
