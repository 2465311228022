import './document.scss'
import React, { useEffect, useRef } from 'react';

const StyledIframe = ({ src, title, styles }) => {
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        // Inject the CSS styles into the iframe
        const styleElement = iframeDoc.createElement('style');
        styleElement.type = 'text/css';
        styleElement.appendChild(document.createTextNode(styles));
        iframeDoc.head.appendChild(styleElement);
    }, [styles]);

    return <iframe ref={iframeRef} src={src} title={title} style={{ width: '100%', height: '100%' }} />;
};

export { StyledIframe };

const Documentation = () => {
    const iframeStyles = `
    .flex.z-20 {
        display: none;
    }
  `;
    return (
        <div className='documentation' style={{ height: '90vh' }}>
            <StyledIframe styles={iframeStyles} title='greenroads' src="https://scribehow.com/page-embed/Greenroads_dashboard_walkthrough__dxNjZ_MURPiHy6b0ueVDvA" />
        </div>
    )
}

export default Documentation