import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CMContext } from './CameraLayout';
import { useHistory } from 'react-router-dom';

export default function CameraSearch({ label }) {
    const { searchFilter, setSearchFilter, cameras, filteredCameras } = React.useContext(CMContext)
    const history = useHistory()

    const handleSearchCamera = (e) => {
        if (e) {
            setSearchFilter(e.target.value)
        }

    }

    const handleSelect = (e) => {
        if (e) {
            let cam = e.target.textContent
            setSearchFilter(cam)
            console.log(cam)
            const { sys_id, camera_id, name } = cameras.find(camera => camera.name === cam)
            history.push(`/cameras/dashboard/${sys_id}/${camera_id ?? name}`)
        }
    }

    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            placeholder='Search Cameras'
            inputValue={searchFilter}
            onInputChange={handleSearchCamera}
            options={filteredCameras}
            getOptionLabel={option => option.name}
            onChange={handleSelect}
            sx={{ width: 300, height: 40, marginBottom: 5, outlineColor: '#f0f0f0' }}
            renderInput={(params) => <TextField value={searchFilter} onChange={handleSearchCamera} {...params} label={'Search Cameras'} />}
        />
    );
}