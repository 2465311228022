import { ArrowDownward, ArrowUpward } from "@material-ui/icons"
import { useContext } from "react"
import TitleHover from "../TitleHover/TitleHover"
import { TableHeaderContext } from "./TableHeader"

/**
 * Draw a table column header.
 *
 * @param {string} title The title of the column.
 * @param {?string} name The name that will be used to signal in which order to retrieve the values, or null to prevent ordering for ths column.
 * @param {"+"|"-"} direction Default direction for ordering: "+" for ascending, "-" for descending.
 * @param {"left"|"right"|"center"} align The alignment of the text.
 * @param {int|string} width The width of the column.
 *
 * @return {JSX.Element}
 * @constructor
 */
const TableColumn = ({ title, name, direction = "+", align = "left", width = "*" }) => {
  const context = useContext(TableHeaderContext)

  // Define what happens on click
  const onClick = () => name != null && context.updateOrdering(name, direction)

  // Define the CSS class names
  const classNames = []
  align && classNames.push(`text-${align}`)
  name && classNames.push("clickable")

  // Show the arrow that represents the direction of the ordering
  let arrow = <></>
  if (context?.order && context.order.name === name) {
    arrow = context.order.direction === "-"
      ? <ArrowDownward />
      : <ArrowUpward />
  }

  // The content of the column header
  let content = <>{arrow}{title}</>

  // Wrap into tooltip
  if (name) {
    content = <TitleHover text={`Click to order by ${title.toLowerCase()}`}>{content}</TitleHover>
  }

  return <th width={width} className={classNames.join(" ")} onClick={onClick}>{content} </th>
}

export default TableColumn
