import { Card } from "../../../_metronic/_partials/controls";

/**
 * Show an error to the user.
 *
 * @param {string} title The title of the page.
 * @param {string} message The additional message to show to the user.
 *
 * @return {JSX.Element}
 * @constructor
 */
const ErrorTemplate = ({ title, message }) => (
  <Card>
    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
      <div className="pt-lg-10 mb-10">
        <h1 className="fw-bolder fs-2qx text-gray-800 mb-10">{title}</h1>
        <div className="fw-bold fs-3 mt-20 mb-20">
          {message}
        </div>
        <div className="text-center">
          <a href="/" className="btn btn-lg btn-primary fw-bolder">Go to the dashboard</a>
        </div>
      </div>
    </div>
  </Card>
);

export default ErrorTemplate;
