/**
 * @typedef {[number, number, number, number]} RectangleArray The rectangle represented by an array of 4 numbers: x1, y1, x2 and y2 (where 0,0 is top left).
 */

/**
 * @typedef Rectangle An object with all coordinates along with all calculated values (where 0,0 is top left).
 *   @property {number} x1
 *   @property {number} y1
 *   @property {number} x2
 *   @property {number} y2
 *   @property {number} left
 *   @property {number} right
 *   @property {number} top
 *   @property {number} bottom
 *   @property {number} width
 *   @property {number} height
 *   @property {number} diagonal
 *   @property {{x: number, y:number}} center
 *   @property {number} angle
 */
class Rectangle {

  constructor(x1, y1, x2, y2, isZone) {
    this.x1 = x1
    this.y1 = y1
    this.x2 = x2
    this.y2 = y2
    this.left = Math.min(x1, x2)
    this.right = Math.max(x1, x2)
    this.top = Math.min(y1, y2)
    this.bottom = Math.max(y1, y2)
    this.width = Math.abs(x1 - x2)
    this.height = Math.abs(y1 - y2)
    this.diagonal = Math.sqrt(this.width * this.width + this.height * this.height)
    this.text = Math.sqrt(this.width) + Math.sqrt(this.height)
    this.center = isZone ? { x: this.left, y: this.top } : { x: (this.left + this.right) / 2, y: (this.top + this.bottom) / 2 }
    this.angle = this.getAngle()
  }

  /**
   * Scale a rectangle by a ratio.
   *
   * @param {number} scaleX The amount to scale across X axis.
   * @param {?number} scaleY The amount to scale across Y axis, or null to scale the same as on X axis.
   *
   * @return {Rectangle} The resulting rectangle.
   */
  scale(scaleX: number, scaleY: ?number = null) {
    return new Rectangle(
      this.x1 * scaleX,
      this.y1 * scaleY ?? scaleX,
      this.x2 * scaleX,
      this.y2 * scaleY ?? scaleX
    )
  }

  /**
   * Convert a Rectangle to an array of values.
   *
   * @return {RectangleArray} The resulting values. /
   */
  toArray(): RectangleArray {
    return [
      this.x1,
      this.y1,
      this.x2,
      this.y2
    ]
  }

  /** @return {number} The angle that the rectangle creates with the Y axis, in radians. */
  getAngle(): number {

    // Use the Pythagorean theorem to get the length of the line (we need hypotenuse to calculate the angle, see below)
    const distance = Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2))

    // Get the angle the line creates with the X base of the coordinate system
    let arrowAngle = Math.acos((this.y2 - this.y1) / distance) || 0

    // Function Math.acos() returns the same value for angles that are mirrors of each other, over the X axis
    // Thus we need to compare the Y coordinates of the start and the end points to determine if the arrow angle is above or below the X axis
    if (this.y1 > this.y2) {
      arrowAngle = Math.PI - arrowAngle
    }

    return arrowAngle
  }

  /**
   * Get the vector that starts at the center of the
   *
   * @param {number} length The length of the vector to create.
   *
   * @return {Rectangle} The requested vector.
   */
  vector(length: ?number = null) {

    // Use the Pythagorean theorem to get the length of the line (we need hypotenuse to calculate the angle, see below)
    const distance = Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2))

    // Get the angle the line creates with the X base of the coordinate system
    let arrowAngle = Math.acos((this.y2 - this.y1) / distance) || 0

    // The arrow is a 90 degrees away from the line
    arrowAngle -= Math.PI / 2

    // Function Math.acos() returns the same value for angles that are mirrors of each other, over the X axis
    // Thus we need to compare the Y coordinates of the start and the end points to determine if the arrow angle is above or below the X axis
    if (this.x1 > this.x2) {
      arrowAngle = Math.PI - arrowAngle
    }

    // If length is not supplied, set it to be a little less than half so that the arrow can fit as well
    length = length ?? distance / 2.175

    // Describe the vector with a Rectangle
    return new Rectangle(
      this.center.x,
      this.center.y,
      this.center.x + length * Math.sin(arrowAngle),
      this.center.y + length * Math.cos(arrowAngle)
    )
  }

}

export default Rectangle
