/** Contains utility methods for working with forms. */
const FormHelper = {

  /**
   * Set a single value in Formik.
   *
   * @param {{}} formik The Formik handle that is being used for this form.
   * @param {string} name The name of the field to set.
   * @param {any} value The value to set.
   */
  setValue: (formik: {}, name: string, value: any) => {
    const values = formik?.values ?? {}
    values[name] = value
    formik.setValues(values)
  },

  /** Contains utility methods for working with modals that are related to a field in a form. */
  Modal: {

    /** {string} A suffix to add to the name of the field that will store the current state of the modal. */
    suffix: "ModalActive",

    /**
     * A handle to be used be a modal.
     *
     * @param {{}} formik The Formik handle that is being used for this form.
     * @param {string} name The name of the field to set.
     *
     * @return {[boolean, function(boolean)]} The handle to use.
     */
    handle: (formik: {}, name: string) => [
      FormHelper.Modal.isActive(formik, name),
      show => FormHelper.Modal.showModal(formik, name, show)
    ],

    /**
     * Check if the modal that is related to a field is active or not.
     *
     * @param {{}} formik The Formik handle that is being used for this form.
     * @param {string} name The name of the field to set.
     *
     * @return {boolean} True if the modal is visible, false otherwise.
     */
    isActive: (formik: {}, name: string) =>
      formik?.values[name + "ModalActive"] ?? false,

    /**
     * Show or hide a modal that is related to a field in a form.
     *
     * @param {{}} formik The Formik handle that is being used for this form.
     * @param {string} name The name of the field to set.
     * @param {boolean} show True to show the modal, false to hide it.
     */
    showModal: (formik: {}, name: string, show: boolean = true) =>
      FormHelper.setValue(formik, name + "ModalActive", show),

    /**
     * Hide a modal that is related to a field in a form.
     *
     * @param {{}} formik The Formik handle that is being used for this form.
     * @param {string} name The name of the field to set.
     */
    hideModal: (formik: {}, name: string) =>
      FormHelper.Modal.showModal(formik, name, false)

  }

}

export default FormHelper
