import { CustomInput } from 'components/Events/Fields'
import React, { useEffect, useState } from 'react'

const SettingsInput = ({ item, handlePcuValueChange, value, index }) => {
    const [pcu, setPcu] = useState(value)

    const handleChange = (e) => {
        setPcu(e.target.value)
        handlePcuValueChange({ item, value: e.target.value, index })
    }
    return (
        <td>
            <CustomInput value={pcu} handleChange={handleChange} />
        </td>
    )
}

export default SettingsInput