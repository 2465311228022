import { CustomInput } from 'components/Events/Fields'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Redirect, useParams } from 'react-router-dom'
import useAPI from 'services/ApiService'
import useCameraManagementAPI from 'services/CameraManagementService'
import { formikDefault } from 'utils/forms-schema'
import { EventsContext } from '../Events/EventsLayout'
import ZoneWrapper from './ZoneCanvas/ZoneWrapper'
import toast from 'react-hot-toast'

const ConfirmZone = () => {
    const { setSteps, Zone, zones, camera: image, types } = useContext(EventsContext)
    const api = useCameraManagementAPI()
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const { cameraId } = useParams()

    const res = zones.slice()
    zones.map((item, i) => res[i] = { ...item, type: types?.find(data => data?.name === res[i]?.type)?.id })
    const data = {
        camera_uuid: cameraId,
        zones: res
    }
    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            const res = await api.postNewZone(`cameras/${cameraId}/zones`, data)
            setLoading(false)
            setSuccess(res?.success)
            setSteps(1)
            toast.success('Successfully updated zone')
        } catch (error) {
            toast.error('An error occurred when updating zone')
        }

    }
    if (success) {
        return <Redirect to="/event/dashboard" />
    }
    return (
        <div>
            <h1>Confirm Zone </h1>

            <div className="row">

                {/* Confirm zone details */}
                <div className="col-md-5">

                    <Table bordered hover={false} className="v-align">
                        <thead>
                            <tr>
                                <th width="50" className="text-center">Zone</th>
                                <th width="*">Name</th>
                                <th width="*">Type</th>
                            </tr>
                        </thead>

                        <tbody>
                            {(zones ?? []).map((zone, index) => (
                                <tr key={index}>

                                    {/* zone number */}
                                    <td className="text-center">
                                        <h3>{index + 1}</h3>
                                    </td>

                                    {/* Name */}
                                    <td>
                                        <CustomInput
                                            readonly={true}
                                            name={`zones.${index}.name`}
                                            value={zone.name} />
                                    </td>

                                    {/* zone */}
                                    <td>
                                        <CustomInput
                                            readonly={true}
                                            name={`zones.${index}.name`}
                                            value={zone.type} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                {/* The image that shows the locations of zones */}
                <div className="col-md-7">
                    <Table bordered hover={false} className="v-align">
                        <thead>
                            <tr>
                                <th>Zones preview</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ position: "sticky", top: 0 }}>
                                    <ZoneWrapper image={image} lines={zones} isEditable={false} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="form-buttons">
                <button className="btn btn-default" type="button" onClick={() => setSteps(prev => prev - 1)}>Back</button>
                <button onClick={handleSubmit} className="btn btn-primary confirm" type="submit">Finish
                    {loading && <span className="ml-1  spinner spinner-white" />}
                </button>
            </div>
        </div>
    )
}

export default ConfirmZone