import { useQuery, useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import AuthService from "services/AuthService";

/**
 * Mutate tenant data.
 *
 * @param {function} fn function to execute ,it depends on operation create/edit/delete group.
 * @param {string} successMessage Success message to render in Toast.
 *
 * @return {function} react-query mutation function.
 */
export const useMutationTenant = (fn, successMessage = "Success") => {
  const queryClient = useQueryClient();

  return useMutation(fn, {
    onSuccess: () => {
      toast.success(successMessage);
      queryClient.invalidateQueries();
    },
    onError: (error) => {
      toast.error(error.message);
    }
  });
};
/**
 * Get logged in user profile.
 *
 * @return {{}} result.
 */
export const useGetProfile = () =>
  useQuery("user", () => AuthService.getMyProfile());

/**
 * List of tenants.
 *
 * @param {string} tenantsIds Tenants ids.
 *
 * @return {{}} result.
 */
export const useGetTenants = (options= {}) =>
  useQuery("tenants", () => AuthService.getTenants(), options);

/**
 * Edit tenant data.
 *
 * @param {string} sysId Tenant id.
 *
 * @return {function} react-query mutation function.
 */
export const useEditTenant = (sysId) =>
  useMutationTenant((data) => AuthService.editTenant(data, sysId), "Tenant has been edited successfully");

/**
 * Create a new tenant.
 *
 * @return {function} react-query mutation function.
 */
export const useCreateTenant = () =>
  useMutationTenant((data) => AuthService.createTenant(data), "Tenant has been created successfully");

/**
 * Delete tenant.
 *
 * @param {string} sysId Tenant id.
 *
 * @return {function} react-query mutation function.
 */
export const useDeleteTenant = (sysId) =>
  useMutationTenant(() => AuthService.deleteTenant(sysId), "Tenant has been deleted successfully");

