import { CameraPreview } from 'components'
import React, { useContext } from 'react'
import { CMContext } from './CameraLayout'

const AllCameras = () => {
    const { searchFilter, cameras } = useContext(CMContext)
    const filteredCameras = [...cameras].filter((item) => searchFilter.length > 0 ? item.name?.includes(searchFilter) : item)
    return (
        <div className="row">
            {filteredCameras?.map((camera: CameraDTO) => (
                <CameraPreview key={camera.uuid} camera={camera} />
            ))}
        </div>
    )
}

export default AllCameras