import BarChart from "../../../../../../components/Charts/BarChart";
import React, { useEffect, useState } from "react";
import { getVehicleTypes, sortByVehicleType } from "../../../../../../config/VehicleTypes";

/**
 * Draw a bar chart showing the count per vehicle type.
 *
 * @param {?TransitSummaryDTO} transitSummary The complete transit summary of the camera.
 * @param {?TransitFlowPointDTO} selectedPoint Currently selected point, or null if nothing is selected.
 *
 * @return {JSX.Element}
 * @constructor
 */
const VehicleTypeChart = ({ transitSummary, selectedPoint }) => {
  /** The data for the chart. */
  const [chartData: KeyValue[], setChartData] = useState();

  /** Extract the data for the chart. */
  useEffect(() => {

    // Make sure we have the data loaded
    if (transitSummary) {
      let points = transitSummary.transit_points;

      // If there is an active point get the list for it, otherwise for the whole carriageway
      if (selectedPoint) {
        points = points.filter(tp => selectedPoint.transit_point.uuid === tp.transit_point.uuid);
      }

      // Initialize the data for every known vehicle type
      const vehicleCountData = getVehicleTypes().reduce((acc, curr_val) => {
        acc[curr_val.type] = { vehicle_type: curr_val.type, vehicle_name: curr_val.name, count: 0 };
        return acc;
      }, {});

      // Extract the data for the bar
      for (const row of points) {
        for (const vehicle of row.vehicles) {
          // Increase the total counter
          if (vehicleCountData.hasOwnProperty(vehicle.vehicle_type)) {
            vehicleCountData[vehicle.vehicle_type].count += vehicle.count;
          }
        }
      }

      // If there was no active point we were counting on both entry and exit points
      if (!selectedPoint) {
        for (const vehicle in vehicleCountData) {
          if (vehicleCountData.hasOwnProperty(vehicle)) {
            vehicleCountData[vehicle].count = Math.floor(vehicleCountData[vehicle].count / 2);
          }
        }
      }

      // Sort by the vehicle type and show the chart
      const sorted = sortByVehicleType(Object.values(vehicleCountData));
      setChartData(sorted.map(item => ({ key: item.vehicle_name, value: item.count })));
    }
  }, [transitSummary, selectedPoint]);

  // Guard: There must be data for the chart
  if (!chartData) {
    return <></>;
  }

  return (
    <div className="card mt-4 mt-md-0">
      <div className="card-body">
        <BarChart title="Per vehicle type" data={chartData} percentage />
      </div>
    </div>
  );

};

export default VehicleTypeChart;
