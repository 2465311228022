import React, { useState } from "react";
import { Section, headerToggle } from "./Section";
import { Table } from "react-bootstrap";
import { humanNumber, humanPercent, humanSpeed } from "../../../../../../utils/functions";
import { PointType } from "../../../../../../utils/enums";
import { Commute } from "@material-ui/icons";
import { sortByVehicleType } from "../../../../../../config/VehicleTypes";

/**
 * Show the flow from the selected point.
 *
 * @param {?TransitFlowPointDTO} selectedPoint The currently selected node.
 * @param {LaneFlowDTO[]} relatedPoints All the points related to the currently selected one.
 *
 * @return {JSX.Element}
 * @constructor
 */
const FlowSelectedPoint = ({ selectedPoint, relatedPoints }) => {

  // Show the vehicle breakdown or not
  const [showVehicleTypeBreakDown, setShowVehicleTypeBreakDown] = useState(true);

  // Guard: Make sure there is a selected point
  if (!selectedPoint) {
    return <></>;
  }

  // Header options: Toggle the vehicle type breakdown
  const options = [
    headerToggle(<Commute />, showVehicleTypeBreakDown, () => setShowVehicleTypeBreakDown(!showVehicleTypeBreakDown))
  ];

  return (
    <Section title="Traffic flow" options={options}>
      <Table bordered hover>
        <thead>
          <tr>
            <th width="*">{`${selectedPoint.transit_point.type === PointType.EXIT ? "Entry" : "Exit"} lane`}</th>
            <th width="30%" className="text-right">Count</th>
            <th width="15%" className="text-right">Percent</th>
            <th width="15%" className="text-right">Avg Speed</th>
          </tr>
        </thead>
        <tbody>
          {
            selectedPoint && relatedPoints?.map(point => (
              <React.Fragment key={point.transit_point.uuid}>
                <tr>
                  <td>{point.transit_point.lane.name}</td>
                  <td className="text-right">{humanNumber(point.count)}</td>
                  <td className="text-right">{humanPercent(point.percent)}</td>
                  <td className="text-right">{humanSpeed(point.avg_speed)}</td>
                </tr>
                {
                  showVehicleTypeBreakDown && (
                    <tr>
                      <td />
                      <td colSpan="3">
                        <table className="inner-table">
                          <tbody>
                            {
                              sortByVehicleType(point.vehicles, { count: 0 }).map(vehicle => (
                                <tr key={vehicle.vehicle_type}>
                                  <td width="50%">
                                    <span className="float-right">{humanNumber(vehicle.count, "0")}</span>
                                    {vehicle.vehicle_name}
                                  </td>
                                  <td width="25%" className="text-right">{humanPercent(vehicle.percentage, "-")}</td>
                                  <td width="25%" className="text-right">{humanSpeed(vehicle.avg_speed, "-")}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )
                }
              </React.Fragment>
            ))
          }
        </tbody>
      </Table>
    </Section>
  );
};

export default FlowSelectedPoint;
