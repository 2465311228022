import { Select, SelectDropdown } from "components/Events/Fields";
import { ReadOnly } from "components/Reports/Fields";
import React, { useContext, useEffect, useState } from "react";
import { McResContext } from "./MCResLayout";


const MCReportFilter = () => {
    const { report, selectedEntries, setSelectedEntries,
        selectedExits, setSelectedExits, reportFilters, setEntryName, setExitName } = useContext(McResContext)

    let entry = reportFilters?.entries?.find(entry => entry.uuid === selectedEntries),
        exit = reportFilters?.exits?.find(entry => entry.uuid === selectedExits);

    useEffect(() => {
        if (exit && entry) {
            setEntryName(entry?.lane?.name)
            setExitName(exit?.lane?.name)
        }
    }, [exit, entry])

    return <div className="row">
        {/* Select: Entry camera */}
        <div className={`${"col-xl-4 col-md-6"}`}>
            {/* <SelectDropdown
                state={[selectedEntries, setSelectedEntries]}
                empty="Select entry camera"
                name="Entry Camera"
                options={entries?.map(camera => [camera?.uuid, camera?.lane?.name, camera])}
            /> */}
            <ReadOnly label='Entry' value={entry?.lane?.name} />
        </div>
        {/* Select: Exit camera */}
        {
            <div className={`${"col-xl-4 col-md-6"}`}>
                {/* <SelectDropdown
                    state={[selectedExits, setSelectedExits]}
                    empty="Select exit camera"
                    name="Exit Camera"
                    options={exits?.map(camera => [camera?.uuid, camera?.lane?.name, camera])}
                /> */}
                <ReadOnly label='Exit' value={exit?.lane?.name} />
            </div>
        }
    </div>;
};

export default MCReportFilter;

