import React, { useEffect, useRef } from "react"
import ApexCharts from "apexcharts"
import "./LineChart.scss"
import { humanTime } from "../../utils/time"
import { humanNumber } from "../../utils/functions"
import { extend } from "../../utils/data"

export const LineChart = ({
  data,
  title,
  chartXLabel,
  chartYSuffix = "",
  height = 500,
  options = {},
  isSeries = false,
  inv,
  fileName
}) => {
  // Create a reference to the DOM element that shows the chart
  const chartRef = useRef()
  useEffect(() => {

    // Make sure the DOM element has been initialized
    if (!chartRef.current) {
      return
    }

    // Define the options for the chart
    const useOptions = extend(options, {
      chart: {
        type: "line",
        height: height,
        animations: {
          enabled: false
        },
        toolbar: {
          export: {
            csv: {
              filename: fileName,
            },
            svg: {
              filename: fileName,
            },
            png: {
              filename: fileName,
            }
          }
        }
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false
        }
      },
      toolbar: {
        show: true
      },
      yaxis: {
        show: true,
        title: {
          text: chartYSuffix,
          style: {
            fontSize: "13px"
          }
        },
        labels: {
          formatter: (val) => {
            const isFloat = val % 1 == 0;
            return isFloat ? val : Math.round(val)
          }
        }
      },
      tooltip: {
        x: {
          formatter: val => humanTime(val / 1000)
        },
        y: {

          formatter: val => {
            const isFloat = val % 1 !== 0;
            return humanNumber(val, isFloat ? 3 : 0) + chartYSuffix
          }
        }
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
        }
      },
      colors: ["#84c341", '#0052cc', '#00E396', '#FEB019'],
      stroke: {
        curve: "smooth"
      },
      grid: {
        position: "front"
      },
      series: isSeries ? data : [
        {
          name: chartXLabel,
          data
        }
      ]
    })

    // Draw the chart
    const chart = new ApexCharts(chartRef.current, useOptions)
    chart.render()

    // Make sure chart is properly disposed of
    return () => chart.destroy()
  }, [data, title, height, chartXLabel, chartYSuffix, options])

  return (
    <div className="chart line-chart">
      {title && <h5 style={{ textAlign: "center", textTransform: 'capitalize' }}>{title}</h5>}
      <div ref={chartRef} />
    </div>
  )
}

export default LineChart
