import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { LoadingWrapper } from "../../../../components"
import { State, useLoading } from "../../../../hooks/useLoading"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import Step1 from "./forms/Step1"
import Step2 from "./forms/Step2"
import "./styles.scss"
import Step3 from "./forms/Step3"

const UploadFilesForm = () => {
  const api = useCameraManagementAPI()
  const { batchUUID } = useParams()

  // Batch update
  const [batch: CMBatch, setBatch] = useState({ step: 1, name: "", camera_uuid: "", start: "", timezone: "", files: [] })

  // Format the time
  const formatTime = value =>
    value.replace("T", " ").replace(/\..+$/, "")

  // Load the camera from the API
  const [batchSettings] = useLoading(() => api.videoBatch(batchUUID))
  useEffect(() => {
    batchUUID && batchSettings &&
      setBatch({
        ...batch, edit: true, uuid: batchSettings.uuid,
        name: batchSettings.name, start: formatTime(batchSettings.start),
        camera_uuid: batchSettings?.camera.uuid,
        timezone: batchSettings?.camera?.time_zone
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchUUID, batchSettings])

  // Return loading
  if (batchUUID && !batchSettings) {
    return <LoadingWrapper state={State.LOADING} />
  }

  // Update existing batch
  const updateValues = update =>
    setBatch(batch => ({ ...batch, ...update }))

  switch (batch.step) {
    case 1:
    default:
      return <Step1 batch={batch} updateValues={updateValues} />

    case 2:
      return <Step2 batch={batch} updateValues={updateValues} />

    case 3:
      return <Step3 batch={batch} updateValues={updateValues} />
  }
}

export default UploadFilesForm
