import { useHistory } from "react-router-dom";
import { AssignmentOutlined } from "@material-ui/icons";
import useAPI from "services/ApiService";
import { useLoading } from "hooks/useLoading";
import useStoreDateRange from "hooks/useStoreDateRange";
import { HeatmapChart } from "components/Charts/HeatmapChart";
import { LoadingWrapper } from "components";
import { buildReportLink } from "app/pages/Reports/Reports";
import { Section, headerButton } from "./Section";

/**
 * Show the traffic volume heatmap per weekday / hour.
 *
 * @param {string} systemID The unique id of the system.
 * @param {string} cameraID The unique id of the camera.
 * @param {?TransitFlowPointDTO} selectedPoint The currently selected node.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Heatmap = ({ systemID, cameraID, selectedPoint }) => {
  const dateRange = useStoreDateRange();
  const history = useHistory();
  const api = useAPI();

  // Fetch: Heatmap data
  const [heatmap: HeatmapRowDTO[], state] = useLoading(
    () =>
      api.getHeatmap(
        systemID,
        cameraID,
        dateRange,
        [],
        selectedPoint?.transit_point?.lane?.uuid
      ),
    [systemID, cameraID, selectedPoint]
  );

  // Convert data for the chart
  const data: HeatmapChartRowDTO[] = heatmap?.map((row) => ({
    name: row.row_title,
    data: row.columns.map((cell) => ({
      x: cell.col_title.replace(":00", "h"),
      y: Math.round(cell.value)
    }))
  }));

  // Link to the full report
  const reportURL = buildReportLink("day-of-week-grid-report", {
    systemID,
    cameraID,
    range: dateRange,
    lanes: selectedPoint?.transit_point?.lane?.uuid,
    carriageways: selectedPoint?.transit_point?.carriageway?.uuid
  });
  // Header options
  const options = !reportURL
    ? []
    : [
      headerButton(<AssignmentOutlined />, "show full report", false, () =>
        history.push(reportURL)
      )
    ];

  return (
    <Section title="Heatmap" options={options}>
      <LoadingWrapper state={state} height={300}>
        {heatmap && <HeatmapChart data={data} />}
      </LoadingWrapper>
    </Section>
  );
};

export default Heatmap;
