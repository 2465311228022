import { useFormik } from "formik"
import { isNotEmpty } from "../../utils/functions"
import { systemTime } from "../../utils/time"
import { MultipleSelectInput, StringInput, TimeIntervalInput } from "../Reports/Fields"

/** Filter type "text": Arbitrary string input. */
const Text = (formik, field: FilterParam) => {
  return <div key={field.name} className="col-xl-3 col-md-6">
    <StringInput formik={formik} name={field.name} label={field.label} />
  </div>
}

/** Filter type "time": Time interval picker. */
const Time = (formik, field: FilterParam) => {
  return <div key={field.name} className="col-xl-3 col-md-6">
    <TimeIntervalInput
      name={field.name}
      label={field.label}
      formik={formik}
    />
  </div>
}

/** Filter type "multi": Multiple choice from a dropdown. */
const Multi = (formik, field: FilterParam) => {
  return <div key={field.name} className="col-xl-3 col-md-6">
    <MultipleSelectInput
      name={field.name}
      label={field.label}
      empty={field.params.empty}
      formik={formik}
      options={field.params.options}
    />
  </div>
}

/**
 * Build the filter field.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {FilterParam} field A single filter parameter.
 * *
 * @return {JSX.Element}
 */
const build = (formik, field: FilterParam) => {
  switch (field.type) {
    case "text":
      return Text(formik, field)
    case "multi":
      return Multi(formik, field)
    case "time":
      return Time(formik, field)
    default:
      return <></>
  }
}

/**
 * Capture the change in the form and get the parameters from the filter.
 *
 * @param {any[]}values The complete formik values.
 *
 * @return {{string: string}} The query for the filter.
 */
const handleChange = values => {
  const query = {}

  // Iterate over every value to create a final list of parameters
  for (const key in values) {
    if (values.hasOwnProperty(key) && isNotEmpty(values[key])) {
      const value = values[key]

      // Guard: Skip functions and empty values
      if (typeof value === "function") {
        continue
      }

      // Handle arrays
      if (Array.isArray(values[key])) {
        query[key] = value.map(val => val.value ?? val).join(";;")
        continue
      }

      // Handle time as an interval or a single value
      if (key === "time") {
        query.time = value.start && value.end
          ? systemTime(value.start) + "-" + systemTime(value.end)
          : systemTime(value)
        continue
      }

      // Everything else
      query[key] = value
    }
  }

  // Set the page to 1 on any change
  query.page = 1

  return query
}

/**
 * Build a filter for a standardized table.
 *
 * @param {FilterParam[]} params The list of filter parameters.
 * @param {function({string: string})} onChange The callback that will receive the filter to apply.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Filter = ({ params, onChange }) => {

  // All params defaults to an empty string
  const values = {}
  for (const param of params) {
    values[param.name] = ""
  }

  // Use Formik in order to reuse existing form components (that rely on Formik)
  const formik = useFormik({
    initialValues: { ...values, onChange: values => onChange(handleChange(values)) }
  })

  return (
    <div className="row">
      {params.map(field => build(formik, field))}
    </div>
  )
}

/**
 * Update the ordering via the filter.
 *
 * @param {[{}, function({})]} filter The state used by the filter.
 *
 * @return {function(*)} The function to use in for TableHeader.onClick() method.
 */
export const updateOrdering = filter =>
  order => filter && order !== filter[0].order && filter[1]({ ...filter[0], page: 1, order })

export default Filter
