import React, { useState, useEffect } from "react"
import { useHistory, useParams, NavLink } from "react-router-dom"
import { Button } from "react-bootstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { Warning, DateRange, Folder, Camera, Info } from "@material-ui/icons"
import { format } from "date-fns"
import LargeCard from "components/Structure/LargeCard"
import { LoadingWrapper } from "components"
import { Loader } from "components/Loader/Loader"
import { FormText } from "../../../../components/Reports/Fields"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import BatchHeader from "../components/BatchHeader"
import { useGetFiles, useUpdateFiles, useProcessFiles } from "../queries"
import "./styles.scss"

export default function Process() {
  const history = useHistory()
  const audit = useCameraManagementAPI()
  const { batchUUID } = useParams()
  const { data: batchFiles, status } = useGetFiles(batchUUID)

  const [files, setFiles] = useState([])
  const [isStartProcessing, setStartProcessing] = useState(false)

  const updateFiles = useUpdateFiles(batchUUID)
  const processFiles = useProcessFiles(batchUUID)

  const isCameraReady = batchFiles?.camera?.ready

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const newItems = [...files]
    const [removed] = newItems.splice(result.source.index, 1)
    newItems.splice(result.destination.index, 0, removed)
    setFiles(newItems)
  }

  const onDragUpdate = (result) => {
    if (!result.destination) {
      return
    }
    const dragged = files[result.source.index]
    const prevPosition = dragged.fileIndex
    dragged.fileIndex = result.destination.index
    const draggDiffIndex = dragged.fileIndex - prevPosition
    const updatedFilesPositions = files.map((file, index) => {
      const pos = file
      if (pos.fileIndex === result.destination.index && index !== result.source.index) {
        pos.fileIndex -= draggDiffIndex
      }
      return pos
    })
    setFiles(updatedFilesPositions)
  }

  const saveFilesOrdering = () => {
    const ordering = []
    files.forEach((file) => {
      ordering.push(file.uuid)
    })
    const data = { camera_uuid: batchFiles?.camera?.uuid, start: batchFiles?.start, name: batchFiles?.name, ordering, files: [], remove: [] }
    updateFiles.mutate(data)

    // Audit log
    audit.log("# File batch ordering", { batchUUID, ...data })
  }

  const handleStartProcessing = () => {
    setStartProcessing(true)
    saveFilesOrdering()
  }

  useEffect(() => {
    if (batchFiles?.files) {
      const files = [...batchFiles.files].map((file, index) => ({ ...file, fileIndex: index }))
      setFiles(files)
    }
  }, [batchFiles?.files])

  useEffect(() => {
    if (updateFiles.isSuccess) {
      if (isStartProcessing) {
        processFiles.mutate()

        // Audit log
        audit.log("# File batch process", { batchUUID })
      }
      history.push("/camera-files")
    }
  }, [history, updateFiles.isSuccess, isStartProcessing, processFiles, audit, batchUUID])

  return (
    <LargeCard title="Batch File Processing">
      <LoadingWrapper state={status} onEmpty={"Loading ..."}>
        {batchFiles ? (
          <>
            <BatchHeader batch={batchFiles} />

            {isCameraReady ? (
              <FormText>
                Video files will be processed sequentially so make sure to drag the files in order.
                <br />
                <br />
                The top file will be processed first.
              </FormText>
            ) : (
              <FormText>
                <div style={{ display: "flex", justifyItems: "center" }}>
                  <Info fontSize="large" style={{ marginRight: ".25em" }} />

                  <div>
                    This Camera is not yet ready for processing.
                    <br />
                    <br />
                    Batch files can still be dragged around to order the sequence of processing.
                    <br />
                    You can finish the camera configuration later on and then manually start the processing.
                  </div>
                </div>
              </FormText>
            )}
            <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
              <Droppable droppableId="files">
                {(provided, snapshot) => (
                  <div className="w-100 h-auto p-0 shadow-sm bg-light rounded my-15 mx-auto" {...provided.droppableProps} ref={provided.innerRef}>
                    {files?.length > 0 &&
                      files?.map((file, index) => (
                        <Draggable key={file.uuid} draggableId={file.uuid} index={index}>
                          {(provided) => (
                            <div className="box" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <div className="box-number">{file.fileIndex + 1}</div>
                              <div className="draggable">
                                {/* <DragHandle /> */}
                                <span>{file.name}</span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="float-right">
              <Button variant="secondary" className="mt-9  mr-3" onClick={saveFilesOrdering}>
                Save & Process Later
              </Button>
              <Button disabled={!batchFiles?.camera?.ready} variant="primary" type="submit" className="mt-9" onClick={handleStartProcessing}>
                Save & Process immediately
              </Button>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </LoadingWrapper>
    </LargeCard>
  )
}
