import videoIcon from "../images/video-icon.png"
import { useState, useEffect } from "react"
import { useUploadFile } from "../queries"
import useCameraManagementAPI from "../../../../services/CameraManagementService"

const FileUploadProgress = ({ file, batchUUID, state }) => {
  const api = useCameraManagementAPI()
  const { camera_uuid, start, fileName, fileSize, name, payload } = file
  const data = { name: name, start, camera_uuid, files: [{ name: fileName, payload }], remove: [], ordering: [] }
  const [uploadProgress, setUploadProgress] = useState()
  const uploadFile = useUploadFile(() => api.updateFiles(batchUUID, data, onUploadProgress, onSuccess))

  // Update the progress
  const maxProgress = 1
  const onUploadProgress = upload => setUploadProgress(Math.max(maxProgress, upload.loaded / upload.total))
  const onSuccess = () => {
    setUploadProgress(maxProgress)
    state[1](c => c + 1)
  }
 
  useEffect(() => {
    if (file && batchUUID) {
      uploadFile.mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="bar-content">
      <img src={videoIcon} alt="files-icon" width="40px" />
      <div className="bar-flex">
        <p>
          {fileName} <span className="text-dark-50 ml-3 text-sm">{Math.round(fileSize * 100) / 100} MB</span>
        </p>

        {uploadProgress != null && (
          <>
            <div className="progress" style={{ height: 4 }}>
              <div className="progress-bar" style={{ padding: 0, width: `${Math.round(uploadProgress * 100)}%` }} role="progressbar" />
            </div>

            <p className="text-dark-50 text-sm">{`${Math.round(uploadProgress * 100)}%`}</p>
          </>
        )}
      </div>
    </div>
  )
}

export default FileUploadProgress
