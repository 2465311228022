import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import AuthService from "../../../../services/AuthService"
import { login } from "../../../../redux/authSlice"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import ForgotPassword from "./ForgotPassword"

/**
 * Build an input for the login form.
 *
 * @param formik The formik controller for the input field.
 * @param {string} name The name of the field.
 * @param {string} type The type of the field.
 * @param {string}  placeholder The human-friendly placeholder.
 *
 * @return {JSX.Element}
 * @constructor
 */
const LoginInput = ({ formik, name, type, placeholder }) => {
  return (
    <div className="form-group fv-plugins-icon-container">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        className={`form-control form-control-solid h-auto py-5 px-6 ${
          formik.touched[name] ? (formik.errors[name] ? "is-invalid" : "is-valid") : null
        }`}
        {...formik.getFieldProps(name)}
      />

      {formik.touched[name] && formik.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors[name]}</div>
        </div>
      )}
    </div>
  )
}

/**
 * Show the login form.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Login = () => {
  const audit = useCameraManagementAPI()
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false)

  // Show loading on submit
  const [loading, setLoading] = useState(false)

  // Define the complete form behavior
  const formik = useFormik({
    initialValues: { email: "", password: "" },

    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Wrong email format")
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 symbols")
        .required("This field is required"),

      password: Yup.string()
        .min(3, "Minimum 3 characters")
        .max(50, "Maximum 50 symbols")
        .required("This field is required")
    }),

    onSubmit: (values, { setStatus }) => {
      setLoading(true)

      // Try to log in with the received JWT
      const tryLogin = (response: KeyCloakResponse) => {
        if (!response?.access_token) {
          throw new Error("Access denied")
        }

        audit.log("> Login granted", values, response.access_token)
        dispatch(login(response))
      }
      // Load the data
      AuthService.login(values.email, values.password)
        .finally(() => setLoading(false))
        .then(response => tryLogin(response))
        .catch(err => {
          setStatus("The supplied credentials are incorrect")
        })
    }
  })

  return (
    <>
      <div className="login-form">
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">Login</h3>
          <p className="text-muted font-weight-bold">Enter your username and password</p>
        </div>

        <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
          {/* The status of the form */}
          {!loading && formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/* Inputs */}
          <LoginInput formik={formik} type="email" name="email" placeholder="Email" />
          <LoginInput formik={formik} type="password" name="password" placeholder="Password" />
          {/* Password recovery */}
          <p onClick={() => setIsOpenModal(true)} className="m-0 cursor-pointer text-primary text-hover-secondary font-weight-bold">
            Forgot Password ?
          </p>
          <div className="mt-2 self-end">
            {/* Submit */}
            <button type="submit" disabled={loading} className="btn btn-primary w-full font-weight-bold px-9 py-4 my-3">
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white" />}
            </button>
          </div>
        </form>
      </div>
      <ForgotPassword isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  )
}

export default Login
