import React, { useEffect } from 'react';
import useCameraManagementAPI from 'services/CameraManagementService';
import LiveStreamComp from './LiveStreamComp';
import { Loader } from 'components';
import { useLoading } from 'hooks/useLoading';
import LiveStreamTest from './LiveStreamTest';
import { FormText } from 'components/Reports/Fields';
import LiveMediaStream from './LiveMediaStream';

export default function LiveStream({ hls_url, uuid, activeStream }) {
    const cameraAPI = useCameraManagementAPI()
    // const [data, dataLoadingState] = useLoading(() => cameraAPI.getRtspUrlStartVideo({ uri: hls_url, alias: uuid }), [])

    const getCacheBustedUrl = (url) => {
        const cacheBuster = `cb=${new Date().getTime()}`;
        return `${url}${url.includes('?') ? '&' : '?'}${cacheBuster}`;
    };

    useEffect(() => {
        const clearCache = () => {
            console.log(window)
            if ('caches' in window) {
                caches.keys().then((names) => {
                    console.log(names)
                    names.forEach(name => {
                        caches.delete(name);
                    });
                });
            }
        };
        clearCache()
    }, [])

    return (
        <>
            <FormText>
                If accessing the live feed is taking longer than expected, toggle the button to refresh the stream.
            </FormText>
            {!hls_url ? <Loader /> :
                <>
                    {activeStream === 'videojs' ?
                        <LiveStreamComp src={getCacheBustedUrl(hls_url)} />
                        : activeStream === 'media-chrome' ?
                            <LiveMediaStream src={getCacheBustedUrl(hls_url)} />
                            : <LiveStreamTest src={getCacheBustedUrl(hls_url)} />}
                </>}
        </>
    );
}