import { useState } from "react"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { AddTenantForm } from "./AddTenantForm"
import { AddTenantAdminForm } from "./AddTenantAdminForm"
import { useCreateTenant } from "../queries"
import { useCreateUser } from "../../UserManagement/queries"
import { slugOf } from "../../../../utils/strings"
import { useHistory } from "react-router-dom"

export const AddTenant = () => {
  const audit = useCameraManagementAPI()
  const history = useHistory()

  // Define methods for mutating state on the server
  const apiCreateTenant = useCreateTenant()
  const apiCreateUser = useCreateUser()

  // Set the initial tenant values
  const [tenant, setTenant] = useState({
    step: 1,
    submit: false,

    // Step 1
    name: "",
    address: "",
    country: { value: "Malta", label: "Malta" },
    firstName: "",
    lastName: "",
    email: "",
    dial_code: { value: "+356", label: "+356 (MT)" },
    phone: "",

    // Step 2
    loginFirstName: null,
    loginLastName: null,
    loginEmail: null,
    loginPassword: "",
    loginPasswordRepeat: ""
  })

  // Update the tenant
  const updateTenant = tenant => {
    setTenant(tenant)

    // Submit the data
    if (tenant.submit) {

      // Create a tenant and add an admin to it
      const execute = new Promise(async (resolve) => {
        const system = slugOf(tenant.name)

        // Create a tenant
        const tenantData =
          {
            system_id: system,
            name: tenant.name,
            contact: {
              first_name: tenant.firstName,
              last_name: tenant.lastName,
              email_address: tenant.email,
              country: tenant.country,
              address: tenant.address,
              phone: {
                country_code: tenant.dial_code,
                number: tenant.phone
              }
            }
          }
        await apiCreateTenant.mutateAsync(tenantData)

        // Create an admin for the created tenant
        const userData = {
          first_name: tenant.loginFirstName,
          last_name: tenant.loginLastName,
          email_address: tenant.loginEmail,
          password: tenant.loginPassword,
          tenant_manager: true
        }
        await apiCreateUser.mutateAsync([userData, system])

        resolve()

        // Audit log
        audit.log("# Tenant create", { tenant: tenantData, user: userData })
      })

      // Go back to the list of tenants
      execute.then(() => history.push("/tenants"))
    }
  }

  switch (tenant.step) {
    case 1:
    default:
      return <AddTenantForm
        tenant={tenant}
        updateTenant={data => updateTenant({ ...tenant, ...data })} />

    case 2:
      return <AddTenantAdminForm
        tenant={tenant}
        updateTenant={data => updateTenant({ ...tenant, ...data })} />
  }
}
