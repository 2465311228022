import { useDispatch } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import { useMutationUser } from "utils/user-management";
import { changeAccountPassword } from "utils/forms-schema";
import { ErrorMessage } from "components/UserManagement/ErrorMessage";
import AuthService from "services/AuthService";
import { logout } from "redux/authSlice";

const ChangePassword = ({ user, state, dispatch }) => {
  const reduxDispatch = useDispatch();
  const { username } = user;

  const editPasswordSuccessMessage = "Password has been edited successfully !";
  const errorMessage = "Old password is wrong !";
  const mutation = useMutationUser((data) => AuthService.changeMyPassword(data), editPasswordSuccessMessage, errorMessage);
  const onSubmit = (values) => {
    mutation.mutate({
      username,
      old_password: values.oldPassword,
      new_password: values.newPassword
    });
  };
  const closeForm = () => {
    dispatch({ type: "settings" });
  };

  //If user changes his password => logout
  if (mutation.isSuccess) {
    dispatch({ type: "settings" });
    reduxDispatch(logout());
  }

  return (
    <Modal centered show={state.password} size="md" onHide={closeForm}>
      <Modal.Header closeButton>
        <Modal.Title className="h4">Change password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
          }}
          validationSchema={changeAccountPassword}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-7">
                <Form.Label className="h6">Old password</Form.Label>
                <Form.Control type="password" name="oldPassword" value={values.oldPassword} onChange={handleChange} />
              </Form.Group>
              <ErrorMessage>{errors.oldPassword}</ErrorMessage>
              <Form.Group className="mb-7">
                <Form.Label className="h6">New password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  name="newPassword"
                  value={values.newPassword}
                  onChange={handleChange}
                  className="mb-3"
                />
                <ErrorMessage>{errors.newPassword}</ErrorMessage>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                />
                <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
              </Form.Group>
              <div className="mt-9 float-right">
                <Button variant="secondary" onClick={closeForm}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="ml-3">
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default ChangePassword;
