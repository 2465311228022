import React, { useEffect } from "react"
import { NavLink } from "react-router-dom"
import { Button, Table } from "react-bootstrap"
import { useLoading } from "../../hooks/useLoading"
import useCameraManagementAPI from "../../services/CameraManagementService"
import { CameraOperationState } from "../CameraOperationState/CameraOperationState"
import { LoadingWrapper } from "../LoadingWrapper/LoadingWrapper"
import { LoadingImage } from "../LoadingImage/LoadingImage"
import { averageValue, humanNumber, humanSpeed, totalValue } from "../../utils/functions"
import { addDays } from "../../utils/time"
import useAPI from "../../services/ApiService"
import { getCameraImageURL } from "../../utils/camera"
import { AppModal } from "./AppModal"

export const MapModal = ({ systemID, cameraID, onHide }) => {
  const api = useAPI()

  // Audit log
  useCameraManagementAPI().log("> Location modal", { systemID, cameraID })

  // Define the date range for the overview
  const days = 30
  // const dateRange: DateRangeDTO = {
  //   start: addDays(-days),
  //   end: addDays(0)
  // }

  // Load everything
  const [camera, cameraState] = useLoading(() => api.camera({ systemID, cameraID }), [systemID, cameraID])
  const [summary, summaryState] = useLoading(() => api.kpi({ systemID, cameraID }), [systemID, cameraID])
  // const [summary, summaryState] = useLoading(() => api.getDataHourlySummary(systemID, cameraID, dateRange), [systemID, cameraID])

  const convertedObject = summary?.reduce((result, currentObject) => {
    const { key, unit, value, name } = currentObject
    result[key] = [value, unit, name];
    return result;
  }, {});

  // Set the title
  const title =
    camera !== null ?
      <>{camera.name} <br /><span style={{ fontSize: "15px", fontWeight: "normal", marginTop: "6px" }}>{camera.location}</span></>
      : "Loading"

  return (
    <AppModal title={title} onHide={onHide}>
      <LoadingWrapper state={[cameraState, summaryState]}>
        {camera && (
          <div className="row">
            <div className="col-4" style={{ position: "relative", paddingRight: 0 }}>
              <NavLink to={`/cameras/dashboard/${systemID}/${cameraID}`}>
                <LoadingImage className="img-fluid" src={getCameraImageURL(camera.uuid)} alt={camera.name} />
                <CameraOperationState camera={camera} position="top-right" />
              </NavLink>
            </div>
            <div className="col-8">
              <LoadingWrapper state={summaryState} onEmpty={`No data has been collected in the previous ${days} days`}>
                {summary && convertedObject && (
                  <Table striped bordered>
                    <tbody>
                      <tr>
                        <td>Congestion</td>
                        <td className="text-right">{"N/A"}</td>
                      </tr>
                      <tr>
                        <td>Total vehicles (last {days} days)</td>
                        {/* <td className="text-right">{humanNumber(totalValue(summary, "count"))}</td> */}
                        <td className="text-right">{convertedObject['count'][0]} {convertedObject['count'][1]}</td>
                      </tr>
                      <tr>
                        <td>Average speed (last {days} days)</td>
                        {/* <td className="text-right">{humanSpeed(averageValue(summary, "avg_speed"))}</td> */}
                        <td className="text-right">{parseFloat(convertedObject['avg_speed'][0]).toFixed(2)} {convertedObject['avg_speed'][1]}</td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </LoadingWrapper>
            </div>
            <div className="col-12">
              <p className="text-center">
                <br />
                <NavLink to={`/cameras/dashboard/${systemID}/${cameraID}`}>
                  <Button size="sm">Show more details</Button>
                </NavLink>
              </p>
            </div>
          </div>
        )}
      </LoadingWrapper>
    </AppModal>
  )

}
