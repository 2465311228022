import { Table, Button, Modal } from "react-bootstrap";

const ViewTenants = ({ show, exitModal, user }) => {
  const { first_name, last_name, groups: tenants, admin_groups: managingTenants } = user;
  return (
    <Modal centered show={show} size="md" onHide={exitModal}>
      <Modal.Header closeButton>
        <Modal.Title>Tenants</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-5">
          List of{" "}
          <strong>
            {first_name} {last_name}
          </strong>{" "}
          tenants and roles :{" "}
        </p>
        <Table bordered hover={false} className="w-75 mx-auto">
          <thead>
            <tr>
              <th>Tenant</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {tenants?.map((tenant) => (
              <tr key={tenant.id}>
                <td style={{ verticalAlign: "middle" }}>{tenant?.name}</td>
                <td>{managingTenants?.some((tn) => tn.id === tenant.id) ? "Admin" : "Viewer"}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Button onClick={exitModal} variant="secondary" className="mt-7 float-right">
          close
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default ViewTenants;
