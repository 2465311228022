/**
 * Pad a string on the left until it has a minimum desired value.
 *
 * @param {boolean} fromStart True to pad on the start of the string, false for the end.
 * @param {string|int} input The original input.
 * @param {string} padding The padding to use.
 * @param {int} length The minimum required length of the resulting string.
 * @return {string} The padded string.
 */
export const pad = (fromStart, input, padding, length) => {
  let output = input.toString();
  while (output.length < length) {
    output = fromStart
      ? padding + output
      : output + padding;
  }

  return output;
};

/**
 * Pad a string from the start until it has a minimum desired value.
 *
 * @param {string|int} input The original input.
 * @param {string} padding The padding to use.
 * @param {int} length The minimum required length of the resulting string.
 *
 * @return {string} The padded string.
 */
export const padStart = (input, padding, length) =>
  pad(true, input, padding, length);

/**
 * Pad a string at the end until it has a minimum desired value.
 *
 * @param {string|int} input The original input.
 * @param {string} padding The padding to use.
 * @param {int} length The total length of the resulting string.
 *
 * @return {string} The padded string.
 */
export const padEnd = (input, padding, length) =>
  pad(true, input, padding, length);

/**
 * Make sure the number has leading zeros.
 *
 * @param {string|int} input The original input.
 * @param {int} length The total length of the resulting string.
 *
 * @return {string} The number with leading zeros.
 */
export const leadingZeros = (input, length = 2) =>
  padStart(input, "0", length);

/**
 * Make the first letter in the string an uppercase one.
 *
 * @param {string} input The original input.
 *
 * @return {string} The modified input.
 */
export const firstLetterToUppercase = (input) =>
  input !== null && input.length > 0
    ? `${input[0].toUpperCase()}${input.slice(1)}`
    : "";

/**
 * Create a slug from a string.
 *
 * @param {string} input The original input.
 *
 * @return {string} The slugged input.
 */
export const slugOf = input => input
  .trim()
  .toLowerCase()
  .replace(/[^\w\s-]/g, "")
  .replace(/[\s_-]+/g, "-")
  .replace(/^-+|-+$/g, "");
