import { createSlice } from "@reduxjs/toolkit";
import { loadDateRangeState } from "../utils/localStorage";

export const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState: loadDateRangeState(),
  reducers: {
    updateDateRange: (state, action) => ({
      ...action.payload,
      // Make sure the hash is loaded as well in order to force the refresh of all dependent components
      hash: action.payload.start.getTime() + "-" + action.payload.end.getTime()
    }),
    resetPrevRange: (state) => ({
      ...state,
      prevRange: null
    })
  }
});

export const updateDateRange = (dateRange) =>
  dispatch => dispatch(dateRangeSlice.actions.updateDateRange(dateRange));

export const resetPrevRange = () =>
  dispatch => dispatch(dateRangeSlice.actions.resetPrevRange());
