import LargeCard from 'components/Structure/LargeCard'
import React, { useContext, useState } from 'react'
import { Table } from 'react-bootstrap'
import useCameraManagementAPI from 'services/CameraManagementService'
import { MCLayoutContext } from '../MCLayout'
import Alert from 'components/Alert/Alert'
import Button from 'components/Alert/Button'
import { useHistory, useParams } from 'react-router-dom'

const Step3 = () => {
    const { step, lines, cameras, setStep, cameraName } = useContext(MCLayoutContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const cameraApi = useCameraManagementAPI()
    const { push } = useHistory()
    const { uuid } = useParams()

    const newLines = lines.map(item => {
        if (item.entry && item.exit) {
            let parsedEntry = item.entry,
                parsedExit = item.exit;
            return {
                entry: { id: parsedEntry?.id ?? parsedEntry?.uuid, name: parsedEntry?.label ?? `${parsedEntry.lane_name} (${parsedEntry?.camera})` },
                exit: { id: parsedExit?.id ?? parsedExit?.uuid, name: parsedExit?.label ?? `${parsedExit.lane_name} (${parsedExit?.camera})` }
            }
        }
        return item
    })

    const onSubmit = async () => {
        setLoading(true)
        try {
            const cameras_to_combine = cameras.map(camera => camera.value),
                lines_pair = newLines.map(line => {
                    return { entry: line?.entry?.id, exit: line?.exit?.id }
                })
            const data = {
                cameras_to_combine,
                lines_to_combine: lines_pair,
                name: cameraName,
                uuid: uuid ?? null
            }
            const res = await cameraApi.getSavedCameras(data)
            // const res = uuid ? await cameraApi.getSavedCamera(uuid, data, 'POST') : await cameraApi.getSavedCameras(data)
            console.log(res)
            push('/multi_camera_report')
        } catch (error) {
            console.log(error)
            setError(error?.error ?? error?.code === 404 ? 'An error occurred' : error)
            setTimeout(() => {
                setError('')
            }, 2000);
        }
        setLoading(false)
    }

    return (
        <LargeCard title={`Multi-Camera ${step}/3: Overview and Confirmation`}>
            <Alert title={error} type='danger' />
            <div className='row justify-content-between'>
                <div className='col-md-4'>
                    <Table bordered hover={false} className="v-align">
                        <thead>
                            <tr>
                                <th width="*">Cameras</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cameras?.map((item, i) =>
                                <tr key={i}>
                                    <td>{item.label}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <div className='col-md-8'>
                    <Table bordered hover={false} className="v-align">
                        <thead>
                            <tr>
                                <th>Lines to combine</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {newLines?.map((item, i) =>
                                <tr key={i}>
                                    <td width={'50%'}>{item?.entry?.name}</td>
                                    <td>{item?.exit?.name}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

            <div className='row full-width mx-3 justify-content-between'>
                <div>
                    <button onClick={() => setStep(prev => prev - 1)} className="btn btn-primary" type="submit">Back</button>
                </div>
                <div className="form-buttons">
                    <Button text='Save' onClick={onSubmit} loading={loading} />
                    {/* <Button text='Report' onClick={onSubmit} loading={loading} /> */}
                </div>
            </div>


        </LargeCard>
    )
}

export default Step3