import { AxiosError } from "axios"
import { AxiosResponse } from "axios"
import axios from "axios"
import { useState } from "react"
import { apiTime, getTimezoneName, getTimezoneOffset, now } from "utils/time"
import { useDispatch, useSelector } from "react-redux"
import { clearBase64, clearSensitiveData, getQuery } from "../utils/data"
import { triggerFirst } from "../utils/debounce"
import { isEmpty, isNotEmpty } from "../utils/functions"
import AuthService from "./AuthService"
import { logout, refresh } from "../redux/authSlice"
import { useHistory } from "react-router-dom"
import store from "redux/store"

/** The base URL for the configuration URL. */
export const baseConfigurationURL = "https://config-dev.greenroadsmalta.com"

/** @var {int} The maximum size of uploads, in megabytes. */
export const MAX_UPLOAD_SIZE = 2048 * 1024 * 1024

/**
 * Get the full URL from the relative path.
 *
 * @param {string} path The relative path to use.
 * @param {{string: string}} query The GET query to use.
 *
 * @return {string} The full URL.
 */
export const url = (path: string, query: { string: string } = {}) => {

  // Do we need the protocol, or is it already included in the path
  const base = path[0] === ':' ? `${baseConfigurationURL}` : !path.match(/^https?:\/\//) ? `${baseConfigurationURL}/` : ""
  const valid_url = base + path + getQuery(query)
  return valid_url
}

export class CameraManagementService {

  /** Prepare the service. */
  constructor(refresh: TokenDTO, auth: TokenDTO, history, dispatch) {
    this.baseURL = baseConfigurationURL
    this.refresh = refresh
    this.auth = auth
    this.history = history
    this.dispatch = dispatch
  }

  /**
   * Get the token to use.
   *
   * @return {Promise<string>} The token to use in the authorization header.
   *
   * @private
   */
  _getToken(): string {
    return new Promise((resolve, reject) => {

      // Token must be more than 10 minutes away from expiring
      if (this.auth && this.auth.token && this.auth.expires > now(600)) {
        resolve(this.auth.token)
        return
      }

      // Use the refresh token
      if (this.refresh && this.refresh.token) {

        // Load the data
        AuthService.refresh(this.refresh.token)
          .then((response) => {
            if (!response?.access_token) {
              throw new Error("AUTH: No token in refresh response")
            }

            this.dispatch(refresh(response))
            resolve(response?.access_token)
          })
          .catch(() => {
            console.error("AUTH: Unable utilize refresh token")
            this.history.push("/logout")
            reject()
          })
      } else {
        console.error("AUTH: No suitable token found")
        this.history.push("/logout")
        reject()
      }
    })
  }

  /** @return {Promise<{}>} The list of headers to send in each request.*/
  async _getHeaders(authorization = null): string[] {
    return {
      "Content-Type": "application/json",
      "Authorization": authorization ?? "Bearer " + (await this._getToken()),
      "Client-Timezone": getTimezoneName(),
      "Client-Timezone-Offset": getTimezoneOffset()
    }
  }

  /**
 * Parse the parameters.
 *
 * @param {ReportParamsDTO} params The complete list of parameters.
 * @param {string} type Type of query to parse. (pcu, multicamera, etc...)
 * @return {{cameraInfo: SystemAndCamera, query: {carriageways: string[], lanes: string[], vehicles: string[], from: string, to: string, entry: string, exit: string}}}
 * @private
 */
  _parseParams(params, type) {
    let data = {};
    if (type === 'pcu') {
      data = {
        cameraInfo: { systemID: params.systemID, cameraID: params.cameraID },
        query: {
          from: apiTime(params.range.start),
          to: apiTime(params.range.end),
          vehicle_types: params.vehicles,

        }
      }
    }
    else if (type === 'mc') {
      data = {
        query: {
          from_ts: apiTime(params.range.start),
          to_ts: apiTime(params.range.end),
          entries: params.entry,
          exits: params.exit
        }
      }
    }
    else {
      data = {
        cameraInfo: { systemID: params.systemID, cameraID: params.cameraID },
        query: {
          from_ts: apiTime(params.range.start),
          to_ts: apiTime(params.range.end),
          // carriageway_uuids: isEmpty(params.lanes) ? params.carriageways : [],
          // lane_uuids: params.lanes,
          // vehicle_types: params.vehicles,
        }
      }
    }

    return data
  }

  /**
   * Execute an arbitrary API call on the predefined base URL.
   *
   * @param {string} path The path to execute on the predefined base URL.
   * @param {?{}|string} data The data to send.
   * @param {{}} params The additional parameters for the native fetch() function().
   *
   * @return {Promise<{}>} The raw response.
   * @private
   */
  async _execute(path: string, data: ?{} = null, params = {}) {
    return new Promise(async (resolve, reject) => {

      const controller = new AbortController();
      const signal = controller.signal;

      // Load the headers
      const headers = await this._getHeaders(params.authorization)

      // Handle "query" as the GET parameters
      const query = data?.query
      data && delete data.query

      // Send the request
      axios
        .request({
          method: params.method ?? (isNotEmpty(data) ? "POST" : "GET"),
          url: url(path, query),
          headers,
          data,
          ...params,
          signal
        })

        // On success
        .then((response: AxiosResponse) => {
          resolve(response)
          params.onSuccess && params.onSuccess(response.data)
        })

        // On error
        .catch((error: AxiosError) => {
          console.log(error?.response)
          if (error.response) {

            // Handle 401 - log the user out
            if (error.response.status === 401) {
              this.dispatch(logout())
            }

            // Reject the promise
            return reject({
              code: error.response.status,
              error: error.response.data.message ?? error.response.data.detail ?? error.response.data.error
            })
          }

          // this.dispatch(logout())
          return reject('An unknown error occurred')
        })
    })
  }

  /**
   * Execute an arbitrary API call on the predefined base URL.
   *
   * @param {string} path The path to execute on the predefined base URL.
   * @param {?{}} data The data to send as JSON.
   * @param {{}} params The additional parameters for the native fetch() function().
   *
   * @return {Promise<{}>} The response as a JSON.
   * @private
   */
  async _executeJSON(path: string, data: {}, params: {}) {
    return await (await this._execute(path, data, params)).data
  }

  async getToken(): string {
    return new Promise((resolve, reject) => {

      // Token must be more than 10 minutes away from expiring
      if (this.auth && this.auth.token && this.auth.expires > now(600)) {
        resolve(this.auth.token)
        return
      }

      // Use the refresh token
      if (this.refresh && this.refresh.token) {

        // Load the data
        AuthService.refresh(this.refresh.token)
          .then((response) => {
            if (!response?.access_token) {
              throw new Error("AUTH: No token in refresh response")
            }

            this.dispatch(refresh(response))
            resolve(response?.access_token)
          })
          .catch(() => {
            console.error("AUTH: Unable utilize refresh token")
            this.history.push("/logout")
            reject()
          })
      } else {
        console.error("AUTH: No suitable token found")
        this.history.push("/logout")
        reject()
      }
    })
  }
  /**
   * Get the list of all available systems for the current user.
   */
  async systems(): CMSystemResponse[] {
    return this._executeJSON("systems")
  }


  /**
   * Get the list of all available cameras for the tenant.
   */
  async cameras(): CMCameraResponse[] {
    return this._executeJSON("cameras")
  }

  /**
   * Get details about a single camera.
   *
   * @param {UUID} uuid The UUID of the camera to get.
   */
  async camera(uuid: UUID): CMCameraResponse {
    return uuid ? this._executeJSON(`cameras/${uuid}/settings`) : null
  }
  /**
    * Get details about a single camera.
    *
    * @param {UUID} uuid The UUID of the camera to get.
    */
  async cameraLocation(uuid) {
    return uuid ? this._executeJSON(`cameras/${uuid}/location`) : null
  }

  /**
   * Get the list of all available countries.
   */
  async countries(): CMCountryResponse[] {
    return this._executeJSON("countries")
  }

  /**
   * Get the list of all available localities.
   */
  async localities(): CMLocalityResponse[] {
    return this._executeJSON("localities")
  }

  /**
   * Get the list of all available streets.
   */
  async streets(): CMStreetResponse[] {
    return this._executeJSON("streets")
  }

  /**
   * Post camera data.
   */
  async postCamera(camera: CMCameraPost) {
    return this._execute("cameras", camera)
  }

  /**
   * Delete a camera.
   */
  async deleteCamera(cameraUUID: UUID) {
    return this._execute(`cameras/${cameraUUID}`, null, { method: "DELETE" })
  }

  /**
   * Disable a camera
   */
  async disableCamera(cameraUUID) {
    return this._execute(`cameras/${cameraUUID}/toggle-disabled`)
  }

  /**
   * Get the path to the image.
   */
  imagePath(cameraUUID: UUID): string {
    return this.url(`storage/camera-image/${cameraUUID}`)
  }

  /**
   * Create empty batch for files.
   *
   * @param {?{}} data The batch data to send as JSON.
   */
  async createBatchFiles(data) {
    return this._executeJSON(`storage/video-batches${data.uuid ? "/" + data.uuid : ""}`, data)
  }

  /**
   * Get all files from all batches.
   */
  async getAllFiles() {
    return this._executeJSON("storage/video-batches")
  }

  /**
   * Get a specific batch.
   *
   * @param {?string} batchUUID The UUID of the batch to get, or null to skip.
   */
  async videoBatch(batchUUID: ?string) {
    return batchUUID
      ? this._executeJSON(`storage/video-batches/${batchUUID}`)
      : null
  }

  /**
   * Update files.
   *
   * @param {string} batchUUID BatchUUID.
   * @param {?{}} data The data to send as JSON.
   * @param {?function} onUploadProgress Optional function to invoke on progress update.
   * @param {?function} onSuccess Optional action to execute on success.
   */
  async updateFiles(batchUUID, data, onUploadProgress = null, onSuccess = null) {
    return this._executeJSON(`storage/video-batches/${batchUUID}`, data, { onSuccess, onUploadProgress })
  }

  /**
   * Process files.
   *
   * @param {string} batchUUID BatchUUID.
   */
  async processFiles(batchUUID) {
    return this._executeJSON(`storage/video-batches/${batchUUID}/process`)
  }

  /**
   * Video processing duration.
   * @return The total time the videos of a camera has been processed.
   */
  async processDuration() {
    return this._executeJSON(`storage/video-batches/duration`)
  }
  /**
   * Delete batch.
   * @param {string} batchUUID BatchUUID.
   */
  async deleteBatch(batchUUID) {
    return this._executeJSON(`storage/video-batches/${batchUUID}`, null, { method: "DELETE" })
  }


  /**
   * Cancel batch processing.
   *
   * @param {string} batchUUID BatchUUID.
   */
  async cancelProcessing(batchUUID) {
    return this._executeJSON(`storage/video-batches/${batchUUID}/cancel`)
  }

  /**
   * Get the event log from the camera management database.
   *
   * @param {{string: string}} query The additional GET query parameters.
   */
  eventLog(query): CMPagination<EventLogEntry> {
    return this._executeJSON("events", { query })
  }

  /**
   * Submit a log entry to the audit log.
   *
   * @param {string} action The action to log.
   * @param {?{}} payload The additional details to submit for the action.
   * @param {?string} token The forced token to for user authentication.
   */
  log(action, payload = null, token = null) {

    // Skip if token is not available
    const bearer = token ?? this.auth?.token
    if (!window["DISABLE_AUDIT"] && bearer) {

      // Do not send any sensitive data
      payload = clearBase64(clearSensitiveData(payload))

      // Make sure we do not spam the same call
      triggerFirst(`${action}::${JSON.stringify(payload)}`, 5, () => {
        // console.log("AUDIT", action, payload)
        return this._executeJSON("events", { action, payload }, { method: "PUT", authorization: `Bearer ${bearer}` })
      })
    }
  }

  /**
   * Get the list of available filters for the audit log.
   */
  eventFilterParams(): EventLogFilterParams {
    return this._executeJSON("events/filter-params")
  }

  /**
  * Get traffic details according to the weight of the vehicles.
  *
  * @param {DateRangeDTO} range The range for the report.
  * @param {string|string[]} vehicles The list of vehicle types to filter on.
  *
  * @return {LanesSummaryReportDTO} The flow details between the lanes.
  */
  async getPcuReport(range, vehicles) {
    const { query } = this._parseParams({ range, vehicles }, 'pcu')
    return await this._executeJSON("pcu", { query }, {
      method: 'GET'
    })
  }

  async getPcu() {
    return await this._executeJSON("pcu")
  }

  async getBillingData() {
    return await this._executeJSON("systems/balance")
  }
  async getBalanceType() {
    return await this._executeJSON(`systems/balance/type`)
  }
  async getTransactionHistory(type) {
    return await this._executeJSON(`systems/video-balance-history?balanceType=${type}`)
  }

  async getRtspUrl(cameraID) {
    return await this._executeJSON(`https://config-dev.greenroadsmalta.com/cameras/${cameraID}/connection`)
  }
  async getRtspUrlStartVideo(values) {
    return await this._executeJSON(`https://config-dev.greenroadsmalta.com:8084/start`, values)
  }
  async startRtspStream(uuid) {
    return await this._executeJSON(`https://config-dev.greenroadsmalta.com/cameras/${uuid}/start-stream`, null, {
      cache: 'no-store'
    })
  }

  /**
   * @param {}
   */
  async updatePcu(system_code, data) {
    return await this._executeJSON(`pcu`, data, {
      method: 'PUT'
    })
  }

  /**
 * Get the Parking zone available
* @param {string} api the Api endpoint to call
* @returns 
 */
  async getParkingZones(api) {
    return await this._executeJSON(`${api}`)
  }

  /**
   * @param {string} api The API endpoint to call
   * @param {object} data the data to send to the API
   * @returns 
   */
  async postNewZone(api, data) {
    return await this._executeJSON(`${api}`, data)
  }

  /**
   * @param {object} data The data to be sent to the server
   * @param {string} url The endpoint url to query
   * @param {Date} dateRange The date range to capture
   * @returns
   */
  async getMultiCameraReport(data, url, dateRange, tenant_id, entry, exit, type) {
    const token = store.getState().auth?.auth?.token
    const { query } = this._parseParams({ range: dateRange, entry, exit }, type)
    return await this._executeJSON(`:8083/${tenant_id ?? 'greenroads'}/${url}`, { data, query }, {
      bearer: `${token}`,
      method: "POST"
    })
  }

  /**
   * 
   * @param {Object} data If present, Creates a new camera, Else gets all the available joined cameras.
   * @returns Returns all the Joined Cameras
   */
  async getSavedCameras(data) {
    return await this._executeJSON('multi_camera_contexts', data)
  }

  /**
  * 
  * @param {string} uuid The Camera UUID
  * @returns Data containing the details of the Joined cameras.
  */
  async getSavedCamera(uuid, data = null, method = 'GET') {
    if (uuid) {
      return await this._executeJSON(`multi_camera_contexts/${uuid}`, data, {
        method
      })
    }
  }

  /**
   * 
   * @returns The Entry-Exit filter 
   */
  async getSavedCameraFilter(uuid) {
    return await this._executeJSON(`multi_camera_contexts/${uuid}/entries_exits`)
  }

  /**
   * If value is empty, get the existing multiscenes else, create a multiscene with existing camera uuid and new camera uuid
   * @param {*} values existingCameraUid , newCameraUuid
   * @returns The newly created multi-scene 
   */
  async createMultiScene(values) {
    return await this._executeJSON('multi_scene', values)
  }
  async deleteMultiScene(uuid) {
    return await this._executeJSON(`multi_scene/cameras/${uuid}`, null, { method: "DELETE" })
  }

  async getCameraUrl(uuid) {
    return await this._executeJSON(`cameras/${uuid}/connection`)
  }

  async uploadFileChunks(data, params) {

    // Set up the headers for the request
    const headers = {
      'Content-Type': 'application/octet-stream',
      "Authorization": "Bearer " + (await this._getToken()),
      "Client-Timezone": getTimezoneName(),
      "Client-Timezone-Offset": getTimezoneOffset()
    };
    const url = `${process.env.REACT_APP_API_CONF_URL}/storage/video-files/upload-chunk?` + params.toString()
    try {
      // Make the POST request to the server
      const res = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: data
      })
      const response = res.json()
      return response
    }
    catch (error) {
      throw new Error(`Unable to upload chunks: ${error}`)
    }
  }

  /** Get Camera lines, directions for the OD Matrix
 * 
 * @param {string} uuid The camera id
 */
  async getCameraLines(uuid) {
    return await this._executeJSON(`cameras/${uuid}/lines`)
  }

  async getLinePositions() {
    return await this._executeJSON('lines/positions')
  }

  async getCameraTypes(){
    return await this._executeJSON(`cameras/types`)
  }

}

/**
 * Get the reference to the API service.
 */
const useCameraManagementAPI = (): CameraManagementService => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { refresh, auth } = useSelector((state: ReduxStore) => state.auth)

  const [api] = useState(new CameraManagementService(refresh, auth, history, dispatch))
  return api
}

export default useCameraManagementAPI
