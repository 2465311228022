import { useState } from "react";
import { Delete } from "@material-ui/icons";
import TableRowAction from "components/Table/TableRowAction";
import DeleteFile from "../forms/DeleteFile";

export default function TableRow({ cameraUUID, batchName, batchUUID, fileUUID, name, size, start, status }) {
  const [showDeleteFile, setShowDeleteFile] = useState(false);

  return (
    <>
      <tr>
        <td className="py-5">{name}</td>
        <td>{Math.round(size * 0.000001)} MB</td>
        <td>{status}</td>
        <td className="text-center">
          <TableRowAction actions={[{ icon: <Delete />, title: "Delete file", setState: () => setShowDeleteFile(true) }]} />
        </td>
      </tr>
      {/* Delete Batch Modal */}
      <DeleteFile
        batchName={batchName}
        name={name}
        cameraUUID={cameraUUID}
        fileUUID={fileUUID}
        batchUUID={batchUUID}
        start={start}
        state={[showDeleteFile, setShowDeleteFile]}
      />
    </>
  );
}
