import { dropDownStyles, groupBadgeStyles, groupStyles } from "components/Reports/multi-select-styles"
import React, { useState } from "react"
import { Form } from "react-bootstrap"
import ReactSelect, { components } from "react-select"
import Creatable from "react-select/creatable"
import { groupLanesByCarriageway } from "../../utils/carriageways"
import { get } from "../../utils/data"
import FormHelper from "../../utils/form"
import { isNotEmpty } from "../../utils/functions"
import { formatDateRange } from "../../utils/time"
import { DateRangePickerModal } from "../DateRangePicker/DateRangePickerModal"
import { ErrorMessage } from "../UserManagement/ErrorMessage"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CiCircleMore } from 'react-icons/ci'
import { Button } from "@mui/material"

/**
 * @typedef MultiSelectOption
 *    @property {string} id
 *    @property {string} label
 *    @property {string} value
 */

/**
 * Show a header in the form.
 *
 * @param {JSX.Element} children The elements to show in the form.
 *
 * @return {JSX.Element}
 */
export const FormHeader = ({ children }) =>
    <h4 className="form-header">{children}</h4>

/**
 * Show a paragraph in the form.
 *
 * @param {{}} props All the properties of the component.
 *
 * @return {JSX.Element}
 */
export const FormText = (props) =>
    <div {...props} className={`form-text well info${props.className ? " " + props.className : ""}`} />
/**
 * The wrapper for the field in the form.
 *
 * @param {string} name The human-friendly name to show.
 * @param {JSX.Element[]} children The content of the wrapper.
 * @param {?string} error The error to show, usually from formik.errors.
 * @param {?string} className THe class name for the main wrapper.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const FieldWrapper = ({ name, children, error = null, className = null }) => (
    <Form.Group className={className}>
        {name && <Form.Label>{name}</Form.Label>}

        {children}

        {error && <ErrorMessage type="invalid">{error}</ErrorMessage>}
    </Form.Group>
)

/**
 * Create a handler for onChange method, for both Formik and the global event.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik. *
 * @param {any} value The value to set.
 *
 * @return {(function(*): void)|*} The method that will handle the change.
 */
const onChange = (formik, name: string, value: any = null) => event => {

    // Handle change the standard way
    if (event) {
        event && formik.handleChange(event)
    }

    // Update the value
    const values = formik.values
    values[name] = value ?? event?.target?.value

    // Global onChange event
    if (formik.initialValues.onChange) {
        formik.initialValues.onChange(values)
    }
}

/**
 * Show a standard text input, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} type The type of the input.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {boolean} readOnly True to make this input a read-only one.
 * @param {{}} inputParams Additional params for the input.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const InputInput = ({ formik, type, name, label, readOnly = false, inputParams = {} }) => (
    <FieldWrapper name={label} error={get(formik.errors, name)}>
        <Form.Control type={type} name={name} value={get(formik.values, name, type === "checkbox" ? false : "")} onChange={onChange(formik, name)} readOnly={readOnly} {...inputParams} />
    </FieldWrapper>
)

/**
 * Show a standard text input, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {string} placeholder The placeholder to show when the input is empty.
 * @param {boolean} readOnly True to make this input a read-only one.
 * @param {string} type The input type to use.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const CustomInput = ({ name, label, value, error, placeholder, handleChange, readOnly = false, type = "text", inputParams = {} }) => (
    <FieldWrapper name={label} error={error}>
        <Form.Control type={type} name={name} value={value} onChange={handleChange} readOnly={readOnly} {...inputParams} />
    </FieldWrapper>
)

/**
 * Show a standard text input, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {boolean} readOnly True to make this input a read-only one.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const BooleanInput = ({ formik, name, label, readOnly = false }) => (
    <InputInput type="checkbox" {...{ formik, name, label, readOnly }} inputParams={{ checked: isNotEmpty(get(formik.values, name)) }} />
)

/**
 * Show a standard text input, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {string} placeholder The placeholder to show when the input is empty.
 * @param {boolean} readOnly True to make this input a read-only one.
 * @param {string} type The input type to use.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const StringInput = ({ formik, name, label, placeholder, handleChange, readOnly = false, type = "text" }) => (
    <InputInput type={type} {...{ formik, name, label, readOnly, handleChange, }} inputParams={{ placeholder }} />
)

/**
 * Show a standard text input, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {boolean} readOnly True to make this input read-only.
 * @param {int} rows The number of rows to show.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const TextInput = ({ formik, name, label, rows = 3, readOnly = false }) => (
    <InputInput type="textarea" {...{ formik, name, label, readOnly }} inputParams={{ rows, as: "textarea" }} />
)

/**
 * Show a standard file input, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {string} accept Limit the allowed mime types.
 * @param {string} button The text to show, instead of a standard button.
 * @param {?JSX.Element} preview The URL to show as an image.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const FileInput = ({ formik, name, label, button, preview, accept = "*" }) => (
    <FieldWrapper name={label} error={get(formik.errors, name)}>
        <label className="file-upload">
            {preview}

            <em>{button}</em>

            <Form.Control
                type="file"
                name={name}
                accept={accept}
                onChange={e => {

                    // Set the formik value
                    const fileReader = new FileReader()
                    fileReader.onload = () => {
                        if (fileReader.readyState === 2) {

                            // Accept JPEG only
                            if (fileReader.result.substring(0, 16) !== "data:image/jpeg;") {
                                alert("Unfortunately only JPEG images are supported.")
                                return
                            }

                            formik.setFieldValue(name, fileReader.result)
                        }
                    }
                    fileReader.readAsDataURL(e.target.files[0])
                }}
            />
        </label>
    </FieldWrapper>
)

/**
 * Show a datetime interval picker.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const TimeIntervalInput = ({ formik, name, label }) => (
    <ReadOnly
        name={name}
        label={label}
        className="cursor-pointer w-full"
        value={formatDateRange(formik?.values[name]?.start, formik?.values[name]?.end)}
        onClick={() => FormHelper.Modal.showModal(formik, name)}
    >

        <DateRangePickerModal
            show={FormHelper.Modal.handle(formik, name)}
            value={formik?.values[name] ?? false}
            update={dateRange => onChange(formik, name, dateRange)()} />
    </ReadOnly>
)

/**
 * Show a select that allows a new item, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {{ label: string, value: string }[]} options An array options.
 * @param {?string} empty The value to show for empty, or null not to allow empty.
 * @param {boolean} readOnly True to make this input a read-only one.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ExtendableSelectInput = ({ formik, name, label, options = [], empty = null, readOnly = false }) => {

    // Build the list and optionally include the empty value
    options = [
        ...(empty ? { label: "", value: empty } : []),
        ...options
    ]

    return (
        <FieldWrapper name={label} error={get(formik.errors, name)}>
            <Creatable
                name={name}
                formatCreateLabel={input => `Press "Enter" to create and use "${input}"`}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                value={options.find(option => option.label === formik.values[name])}
                options={options}
                placeholder="Select or insert your own"
                onChange={selected => formik.setFieldValue(name, selected.value)}
                readOnly={readOnly} />
        </FieldWrapper>
    )
}

/**
 * Show a standard text input, in a single line.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {{ label: string, value: string }[]} options An array options.
 * @param {?string} empty The value to show for empty, or null not to allow empty.
 * @param {boolean} readOnly True to make this input a read-only one.
 * @param {?string} className THe class name for the main wrapper.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const SelectInput = ({ formik, name, label, options = [], empty = null, readOnly = false, className = null }) => {

    // Build the list and optionally include the empty value
    options = [
        ...(empty ? { label: "", value: empty } : []),
        ...options
    ]

    return (
        <FieldWrapper name={label} error={get(formik.errors, name)} className={className}>
            <ReactSelect
                name={name}
                value={options.find(option => option.value === formik.values[name])}
                options={options}
                onChange={selected => formik.setFieldValue(name, selected.value)}
                readOnly={readOnly} />
        </FieldWrapper>
    )
}

/**
 * Show a date-time input.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {boolean} readOnly True to make this input a read-only one.
 * @param {?string} min The earliest possible time to select.
 * @param {?string} max The latest possible time to select.

 * @return {JSX.Element}
 * @constructor
 */
export const TimeInput = ({ formik, name, label, readOnly = false, min = null, max = null }) => (
    <InputInput type="datetime-local" {...{ formik, name, label, readOnly }} inputParams={{ max, step: 1, min: min ?? "2000-01-01 00:00:00", pattern: "[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}" }} />
)

/**
 * Show a drop-down select input.
 *
 * @param {string} name The human-friendly name to show.
 * @param {[any, function(val: any)]} state The state to use to show and set the value.
 * @param {[string, string][]} options The list of options to show.
 * @param {boolean} required True to request this field to be required.
 * @param {?string} empty A string to show for an empty value, or null not to show an empty value.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const Select = ({ name, state, options, handleChange, required, empty = null }) => (
    <FieldWrapper name={name === 'zone' ? '' : name}>
        <Form.Control
            required={required}
            as="select"
            value={state[0]}
            onChange={handleChange ? handleChange : (event) => state[1](event.target.value)}
            className={`${!state[0] ? "border-danger" : ""}`}
        >
            {/* Show the empty option or not */}
            {empty != null && <option value=""> - {empty} - </option>}

            {options?.map((option) => (
                <option key={option[1]} value={option[0]}>
                    {name?.toLowerCase() === 'report' ? option[1] : option[0]}
                </option>
            ))}
        </Form.Control>
    </FieldWrapper>
)

/**
 * Show a drop-down select input.
 *
 * @param {string} name The human-friendly name to show.
 * @param {[any, function(val: any)]} state The state to use to show and set the value.
 * @param {[string, string][]} options The list of options to show.
 * @param {boolean} required True to request this field to be required.
 * @param {?string} empty A string to show for an empty value, or null not to show an empty value.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const SelectDropdown = ({ name, state, options, handleChange, required, empty = null }) => (
    <FieldWrapper name={name} >
        <Form.Control
            required={required}
            as="select"
            value={state[0]}
            onChange={handleChange ? handleChange : (event) => state[1](event.target.value)}
            className={`${!state[0] ? "border-danger" : ""}`}
        >
            {/* Show the empty option or not */}
            {empty != null && <option value=""> - {empty} - </option>}
            {options?.map((option) => (
                <option key={option[0]} value={JSON.stringify(option[2])}>
                    {option[1]}
                </option>
            ))}
        </Form.Control>
    </FieldWrapper >
)

/**
 * Show a read only field, with an optional action on click.
 *
 * @param {string} label The human-friendly name to show.
 * @param {string} value The initial value of the input.
 * @param {string} className The additional class names to add to the input.
 * @param {function} onClick The action to perform when user click on the field.
 * @param {JSX.Element[]} children The additional children to add.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ReadOnly = ({ label, value, onClick, className, children }) => (
    <FieldWrapper name={label}>
        <Form.Control readOnly value={value} onClick={onClick} className={className} />

        {children}
    </FieldWrapper>
)

export const MultiSelect = ({ options, name, label, state, empty = "", groupBy = false }) => {

    // Group lanes by carriageways
    const formatGroupedOptions = []

    if (groupBy && options) {
        let lanesGroupedByCarriageway = groupLanesByCarriageway(options)
        for (let key in lanesGroupedByCarriageway) {
            const item = {}
            item.label = key
            item.options = [...lanesGroupedByCarriageway[key]]
            formatGroupedOptions.push(item)
        }
    }
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    )

    // Customize dropdown options: options with checkbox
    const Option = (props) => {
        return (
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />
                <label>{props.label}</label>
            </components.Option>
        )
    }

    const handleChange = state[1]
    return (
        <div className="form-group">
            <Form.Label>{label ?? name}</Form.Label>
            <ReactSelect
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={groupBy ? formatGroupedOptions : options}
                placeholder={empty}
                styles={dropDownStyles}
                allowSelectAll={true}
                onChange={handleChange}
                value={state[0]}
                components={{ Option }}
                formatGroupLabel={groupBy ? formatGroupLabel : ""}
            />
        </div>
    )
}

/**
 * Show a multiple select input.
 *
 * @param {{}} formik The formik data to use, from useFormik() method.
 * @param {string} name The name of the field, same as in formik.
 * @param {string} label The optional label to show above the field.
 * @param {{ label: string, value: string }[]} options An array options.
 * @param {?string} empty The value to show for empty, or null not to allow empty.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const MultipleSelectInput = ({ formik, name, label, options, empty = "" }) => {

    // Customize dropdown options: options with checkbox
    const Option = (props) => {
        return (
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />
                <label>{props.label}</label>
            </components.Option>
        )
    }

    return (
        <FieldWrapper name={label} error={get(formik.errors, name)}>
            <ReactSelect
                isMulti
                allowSelectAll={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={dropDownStyles}
                placeholder={empty}
                components={{ Option }}
                options={options}
                value={get(formik.values, name, [])}
                onChange={values => onChange(formik, name, values)()} />
        </FieldWrapper>
    )
}

/**
 * Shows menu items for popover
 * @param {string[]} Array Array of menu items
 * @return {JSX.Element}
 * @constructor
 */
export const MoreFilter = ({ items, filter, setFilter }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (item) => {
        setAnchorEl(null);
        setFilter(item)
    };
    return <>
        <Button
            sx={{ color: 'black' }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <CiCircleMore className="more" />
        </Button>

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <h5 className="text-center">Filter by</h5>
            {items.map((item, i) => <MenuItem onClick={() => handleClose(item)}>{item.type}</MenuItem>)}
        </Menu>
    </>
}
