import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { slugOf } from "../../utils/strings";
import format from "date-fns/format";

export const ExportToCsv = ({ data, filename = "export" }) => {
  const [timestamp, setTimestamp] = useState("");

  const handleClick = (event, done) => {
    const currentTimestamp = format(new Date(), "dd-MM-yyyy-HH-mm-ss");
    setTimestamp(currentTimestamp);
    done();
  };

  return (
    <CSVLink className="btn btn-light" data={data ?? []} filename={`${slugOf(filename)}-report-${timestamp}.csv`} asyncOnClick={true} onClick={handleClick}> 
      Export to CSV
    </CSVLink>
  );
};
