import React, { useState } from 'react'
import { createContext } from 'react'

export const MCLayoutContext = createContext('')

const MCLayout = ({ children }) => {
    const [step, setStep] = useState(1)
    const [cameras, setCameras] = useState([])
    const [savedMultiCamera, setSavedMultiCamera] = useState([])
    const [cameraName, setCameraName] = useState('')
    const [selectedEntryLines, setSelectedEntryLines] = useState({})
    const [selectedExitLines, setSelectedExitLines] = useState({})
    const [combinedCameras, setCombinedCameras] = useState(null)
    const [lines, setLines] = useState([])
    const [report, setReport] = useState(null)

    return (
        <MCLayoutContext.Provider value={{
            step, setStep,
            cameras, setCameras,
            selectedEntryLines, setSelectedEntryLines,
            selectedExitLines, setSelectedExitLines,
            combinedCameras, setCombinedCameras,
            lines, setLines, report, setReport,
            cameraName, setCameraName,
            savedMultiCamera, setSavedMultiCamera
        }}>
            {children}
        </MCLayoutContext.Provider>
    )
}

export default MCLayout