import React, { useState, useRef, useCallback, useEffect } from 'react';
import Hls from 'hls.js';

const LiveStreamTest = ({ src }) => {
    const [showModal, setShowModal] = useState(false);
    const videoRef = useRef(null);

    const toggle = useCallback(() => {
        setShowModal(true);

        // We use setTimeout to ensure the video element is rendered
        // before we try to attach the HLS stream
        setTimeout(() => {
            const videoSrc = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
            if (Hls.isSupported() && videoRef.current) {
                const hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(videoRef.current);
            }
        }, 0);
    }, []);

    useEffect(() => {
        setShowModal(true);

        // We use setTimeout to ensure the video element is rendered
        // before we try to attach the HLS stream
        setTimeout(() => {
            const videoSrc = src;
            if (Hls.isSupported() && videoRef.current) {
                const hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(videoRef.current);
            }
        }, 0);
    }, [])

    return (
        <>
            {/* <button onClick={toggle}>Toggle</button> */}
            <br />
            {showModal && (
                <video
                    autoPlay
                    controls
                    ref={videoRef}
                    style={{ height: '80%', width: '100%' }}
                ></video>
            )}
        </>
    );
};

export default LiveStreamTest;