import ErrorTemplate from "./ErrorTemplate";

/**
 * Show an unknown error to the user.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Error500 = () => <ErrorTemplate
  title="System Error"
  message="An unexpected error has occurred" />;

export default Error500;
