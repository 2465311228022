import { LoadingWrapper } from "components";
import { allowEmpty } from "components/LoadingWrapper/LoadingWrapper";
import LargeCard from "components/Structure/LargeCard";
import { Table } from "react-bootstrap"
import React, { useContext, useState } from "react";
import { formatDateRange } from "utils/time";
import { DateRange } from "@material-ui/icons";
import { DateRangePickerModal } from "components";
import { CiCircleMore } from 'react-icons/ci'
import moment from "moment";
import { getReadableTime, gridMatrixFn, transformTransitData } from "utils/functions";
import { MoreFilter } from "components/Events/Fields";
import { useEffect } from "react";
import MatrixFilter from "./MatrixFilter";
import { MatrixContext } from "./MatrixLayout";

const MatrixPage = () => {
    const { setDateRangeModal, dateRange, dateRangeModal,
        matrixData, eventLoadingState, selectedAPI,
        setSelectedAPI, isLoading, filter, setFilter } = useContext(MatrixContext)
    const [filteredData, setFilteredData] = useState(null)
    const [mappedData, setMappedData] = useState(null)

    console.log(mappedData)
    console.log(filteredData)

    // Date Range filter in reports pages
    const DateRangeFilterHeader =
        <>
            <div
                className="row date-range justify-content-between w-1/5 border rounded align-self-center mr-5 cursor-pointer px-5 py-2"
                onClick={() => setDateRangeModal(true)}
                title="Current date range"
            >
                <div className="mr-3 font-size-lg">
                    <span>{formatDateRange(dateRange.start, dateRange.end)}</span>
                </div>
                <DateRange title="date range" />
            </div>

            <DateRangePickerModal show={[dateRangeModal, setDateRangeModal]} />
        </>

    useEffect(() => {
        if (matrixData) {
            setFilteredData(matrixData?.entries)
            const data = transformTransitData(matrixData?.entries)
            setMappedData(data)
        }

    }, [matrixData])


    return (
        <LargeCard title="Origin-Destination" headerOptions={DateRangeFilterHeader}>
            <MatrixFilter
            />
            <LoadingWrapper state={allowEmpty(isLoading)} onEmpty={"Data currently unavailable"}>
                {filteredData && filteredData?.length > 0 ? (
                    <>
                        {selectedAPI === 'transitflow' ? <Table bordered hover={false} className="v-align">
                            <thead>
                                <tr>
                                    <th>Entry</th>
                                    {mappedData?.map((item, i) => <th key={i}>{item?.name} Exit</th>)}
                                    {/* <th /> */}
                                </tr>
                            </thead>
                            <tbody>

                                {filteredData?.map((item, i) => <tr key={i}>
                                    <td>{item?.transit_point?.lane?.name}</td>
                                    <td>{gridMatrixFn(item?.flows, mappedData[0])}</td>
                                    <td>{gridMatrixFn(item?.flows, mappedData[1])}</td>
                                    <td>{gridMatrixFn(item?.flows, mappedData[2])}</td>
                                    <td>{gridMatrixFn(item?.flows, mappedData[3])}</td>
                                    {/* <td>  <span className="cursor-pointer" onClick={() => null}><CiCircleMore className="more" /></span></td> */}
                                </tr>)}
                            </tbody>
                        </Table> : <></>}
                    </>

                ) : (
                    <h1 className="text-center my-10">No events captured yet</h1>
                )}
            </LoadingWrapper>
        </LargeCard >
    );
};

export default MatrixPage;
