import React from "react";
import { Table, Button, Modal } from "react-bootstrap";
const ViewBatch = ({ name, files, state }) => {
  return (
    <Modal centered show={state[0]} size="md" onHide={() => state[1](false)}>
      <Modal.Header closeButton>
        <Modal.Title>View File Batch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered hover={false} className="w-100 mx-auto">
          <thead>
            <tr>
              <th width="35%">File name</th>
              <th>Size</th>
              <th width="45%">Status</th>
            </tr>
          </thead>
          <tbody>
            {files?.map((file) => (
              <tr key={file.uuid}>
                <td>{file.name}</td>
                <td>{Math.round(file.size * 0.000001)} MB</td>
                <td>{file.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Button onClick={() => state[1](false)} variant="secondary" className="mt-7 float-right">
          close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ViewBatch;
