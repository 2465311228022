import React, { useEffect, useState } from 'react'
import HeaderText from './HeaderText'
import ScatterChartSeries from 'components/Charts/ScatterChartSeries'
import { aggregateStatsData, createLineSeries, humanNumber, humanSpeed, jointDataByCategory } from 'utils/functions'
import LineChart from 'components/Charts/LineChart'
import { Select } from 'components/Events/Fields'
import MultiLineChart from 'components/Charts/MultiLineChart'
import useStoreDateRange from 'hooks/useStoreDateRange'
import format from 'date-fns/format'

const AllCharts = React.memo(({ fdoCarriage, fdoLane, data, height, laneSection, carriagewaySection, totalSection, cameraName }) => {
  const dateRange = useStoreDateRange()
    const [category, setCategory] = useState(fdoLane?.data[0]?.lane_name)
    const [carriagewayCategory, setCarriagewayCategory] = useState(fdoCarriage?.data[0]?.carriageway_name)

    useEffect(() => {
        setCategory(fdoLane?.data[0]?.lane_name)
        setCarriagewayCategory(fdoCarriage?.data[0]?.carriageway_name)
    }, [fdoLane, fdoCarriage])


    const startDate = format(dateRange?.start, "dd-MM-yyyy")
    const endDate = format(dateRange?.end, "dd-MM-yyyy")

    return (
        <div className="">

            {/* By Lane */}
            <div id="lane" ref={laneSection}>
                <HeaderText message={'By Lane'} extraComp={<div>
                    <Select
                        name=""
                        empty="Sort by"
                        state={[category, setCategory]}
                        options={fdoLane?.data.map(item => [item.lane_name, item.lane_name])}
                    />
                </div>} />
                {/* The next three charts contains speed, flow_rate and density plotted against each other */}

                {/* Superimposed lane chart */}
                {fdoLane && category &&
                    (
                        <div className="col-md-12 mb-5">
                            <MultiLineChart
                                title={category}
                                chartXLabel="Lane overall statistics"
                                chartYSuffix=""
                                height={height}
                                isSeries={true}
                                yaxis={jointDataByCategory(fdoLane.charts, category)?.yAxis}
                                data={jointDataByCategory(fdoLane.charts, category)?.res}
                                options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                                fileName={`${cameraName} - By Lane - Lane overall statistics - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {fdoLane &&
                    (
                        <div className="col-md-12 mb-5">
                            <ScatterChartSeries
                                title="Flow rate - density"
                                chartXLabel="Vehicles per km"
                                chartYSuffix="Vehicles per hour"
                                label={{ x: 'density', xUnit: 'veh/km', y: 'flow rate', yUnit: 'veh/hr', type: 'rate' }}
                                data={createLineSeries(fdoLane.chartPlots, 'rate')}
                                options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                                fileName={`${cameraName} - By Lane - Flow rate - density - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* speed vs density */}
                {fdoLane &&
                    (
                        <div className="col-md-12 mb-5">
                            <ScatterChartSeries
                                title="Average speed - density"
                                chartXLabel="Vehicles per km"
                                chartYSuffix="Average Speed"
                                label={{ x: 'density', xUnit: 'veh/km', y: 'speed', yUnit: 'km/hr', type: 'speed' }}
                                data={createLineSeries(fdoLane.chartPlots, 'speed')}
                                options={{ yaxis: { labels: { formatter: val => humanSpeed(val) } } }}
                                fileName={`${cameraName} - By Lane - Average speed - density - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* speed vs flow rate */}
                {fdoLane &&
                    (
                        <div className="col-md-12 mb-5">
                            <ScatterChartSeries
                                title="Average speed - flow rate"
                                chartXLabel="Vehicles per hour"
                                chartYSuffix="Average Speed"
                                label={{ x: 'low rate', xUnit: 'veh/hr', y: 'speed', yUnit: 'km/hr', type: 'speed' }}
                                data={createLineSeries(fdoLane.chartPlots, 'flow')}
                                options={{ yaxis: { labels: { formatter: val => humanSpeed(val) } } }}
                                fileName={`${cameraName} - By Lane - Average speed - flow rate - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }

                {/* Flow rate vs Time */}
                {fdoLane &&
                    (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                title="Flow rate"
                                chartXLabel="Flow rate"
                                chartYSuffix=" Vehicles per hour"
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoLane.charts, 'ratetime')}
                                options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                                fileName={`${cameraName} - By Lane - Flow rate - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* Density vs Time */}
                {fdoLane &&
                    LineChart && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                title="Density"
                                chartXLabel="Density"
                                chartYSuffix=" Vehicles per km"
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoLane.charts, 'densitytime')}
                            // options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                            fileName={`${cameraName} - By Lane - Density - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* Occupancy vs Time */}
                {
                    fdoLane && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                type="line"
                                title="Occupancy %"
                                chartXLabel="Occupancy %"
                                chartYSuffix=""
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoLane.charts, 'occupancy', fdoLane.occupancy_percent)}
                            // options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                            fileName={`${cameraName} - By Lane - Occupancy % - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }

                {/* Congestion vs Time */}
                {
                    fdoLane && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                type="line"
                                title="Congestion %"
                                chartXLabel="Congestion %"
                                chartYSuffix=""
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoLane.charts, 'congestion')}
                            // options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                            fileName={`${cameraName} - By Lane - Congestion % - ${category} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
            </div>

            {/* By carriageway */}
            <div id="carriageway" ref={carriagewaySection}>
                <HeaderText message={'By Carriageway'} extraComp={<div>
                    <Select
                        name=""
                        empty="Sort by"
                        state={[carriagewayCategory, setCarriagewayCategory]}
                        options={fdoCarriage?.data.map(item => [item.carriageway_name, item.carriageway_name])}
                    />
                </div>} />
                {/* Superimposed carriageway chart */}
                {fdoCarriage && carriagewayCategory &&
                    (
                        <div className="col-md-12 mb-5">
                            <MultiLineChart
                                title={carriagewayCategory}
                                chartXLabel="Carriageway overall statistics"
                                chartYSuffix=""
                                height={height}
                                isSeries={true}
                                yaxis={jointDataByCategory(fdoCarriage.charts, carriagewayCategory)?.yAxis}
                                data={jointDataByCategory(fdoCarriage.charts, carriagewayCategory)?.res}
                                options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                                fileName={`${cameraName} - By Carriageway - Carriageway overall statistics - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* The next three charts contains speed, flow_rate and density plotted against each other */}
                {fdoCarriage &&
                    (
                        <div className="col-md-12 mb-5">
                            <ScatterChartSeries
                                title="Flow rate - density"
                                chartXLabel="Vehicles per km"
                                chartYSuffix="Vehicles per hour"
                                label={{ x: 'density', xUnit: 'veh/km', y: 'flow rate', yUnit: 'veh/hr', type: 'rate' }}
                                data={createLineSeries(fdoCarriage.chartPlots, 'rate')}
                                options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                                fileName={`${cameraName} - By Carriageway - Flow rate - density - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* speed vs density */}
                {fdoCarriage &&
                    (
                        <div className="col-md-12 mb-5">
                            <ScatterChartSeries
                                title="Average speed - density"
                                chartXLabel="Vehicles per km"
                                chartYSuffix="Average Speed"
                                label={{ x: 'density', xUnit: 'veh/km', y: 'speed', yUnit: 'km/hr', type: 'speed' }}
                                data={createLineSeries(fdoCarriage.chartPlots, 'speed')}
                                options={{ yaxis: { labels: { formatter: val => humanSpeed(val) } } }}
                                fileName={`${cameraName} - By Carriageway - Average speed - density - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* speed vs flow rate */}
                {fdoCarriage &&
                    (
                        <div className="col-md-12 mb-5">
                            <ScatterChartSeries
                                title="Average speed - flow rate"
                                chartXLabel="Vehicles per hour"
                                chartYSuffix="Average Speed"
                                label={{ x: 'low rate', xUnit: 'veh/hr', y: 'speed', yUnit: 'km/hr', type: 'speed' }}
                                data={createLineSeries(fdoCarriage.chartPlots, 'flow')}
                                options={{ yaxis: { labels: { formatter: val => humanSpeed(val) } } }}
                                fileName={`${cameraName} - By Carriageway - Average speed - flow rate - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }

                {/* Flow rate vs Time */}
                {fdoCarriage &&
                    (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                title="Flow rate"
                                chartXLabel="Flow rate"
                                chartYSuffix="Vehicles per hour"
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoCarriage.charts, 'ratetime')}
                                options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                                fileName={`${cameraName} - By Carriageway - Flow rate - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* Density vs Time */}
                {fdoCarriage &&
                    LineChart && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                title="Density"
                                chartXLabel="Density"
                                chartYSuffix="Vehicles per km"
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoCarriage.charts, 'densitytime')}
                            // options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                            fileName={`${cameraName} - By Carriageway - Density - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {/* Occupancy vs Time */}
                {
                    fdoCarriage && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                type="line"
                                title="Occupancy %"
                                chartXLabel="Occupancy %"
                                chartYSuffix=""
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoCarriage.charts, 'occupancy', fdoCarriage.occupancy_percent)}
                            // options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                            fileName={`${cameraName} - By Carriageway - Occupancy % - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }

                {/* Congestion vs Time */}
                {
                    fdoCarriage && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                type="line"
                                title="Congestion %"
                                chartXLabel="Congestion %"
                                chartYSuffix=""
                                height={height}
                                isSeries={true}
                                data={createLineSeries(fdoCarriage.charts, 'congestion')}
                            // options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                            fileName={`${cameraName} - By Carriageway - Congestion % - ${carriagewayCategory} - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
            </div>

            {/* Total stats */}
            <div id="total" ref={totalSection}>
                {/* next 3 metrics are granular metrics */}
                <HeaderText message={'Totals'} />
                {
                    data?.charts && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                type="line"
                                title="Totals summary"
                                chartXLabel="Totals summary"
                                chartYSuffix=""
                                height={height}
                                isSeries={true}
                                data={aggregateStatsData(data.charts)}
                                fileName={`${cameraName} - Totals - Totals summary - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
                {
                    data?.charts.count && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                title="Vehicle count"
                                chartXLabel="Total vehicles"
                                chartYSuffix=" Vehicles"
                                height={height}
                                data={data.charts.count}
                                options={{ yaxis: { labels: { formatter: val => humanNumber(val) } } }}
                                fileName={`${cameraName} - Totals - Vehicle count - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }

                {
                    data?.charts.avgSpeed && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                title="Average vehicle speed"
                                chartXLabel="Average speed"
                                chartYSuffix=" km/h"
                                height={height}
                                data={data.charts.avgSpeed}
                                options={{ yaxis: { labels: { formatter: val => humanSpeed(val) } } }}
                                fileName={`${cameraName} - Totals - Average vehicle speed - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }

                {
                    data?.charts.p85Speed && (
                        <div className="col-md-12 mb-5">
                            <LineChart
                                title="The 85th percentile speed"
                                chartXLabel="The 85th percentile speed"
                                chartYSuffix=" km/h"
                                height={height}
                                data={data.charts.p85Speed}
                                options={{ yaxis: { labels: { formatter: val => humanSpeed(val) } } }}
                                fileName={`${cameraName} - Totals - The 85th percentile speed - ${startDate} - ${endDate}`}
                            />
                        </div>
                    )
                }
            </div>

            {/*{*/}
            {/*  data?.charts.percentiles && (*/}
            {/*    <div className="col-md-6 mb-5">*/}
            {/*      <BoxPlotChart*/}
            {/*        title="The speed"*/}
            {/*        chartXLabel="The 85th percentile speed"*/}
            {/*        chartYSuffix=" km/h"*/}
            {/*        height={height}*/}
            {/*        data={data.charts.percentiles}*/}
            {/*        options={{ yaxis: { labels: { formatter: val => humanSpeed(val) } } }}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  )*/}
            {/*}*/}
        </div>
    )
})

export default AllCharts
