import { useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropDown } from "hooks/useDropDown";
import { logout } from "redux/authSlice";
import { Settings, ExitToApp } from "@material-ui/icons";
import ProfileSettings from "./ProfileSettings";
import ChangePassword from "./ChangePassword";

// Profile reducer
const initialState = { settings: false, password: false, account: false };

function reducer(state, action) {
  switch (action.type) {
    case "settings":
      return { ...state, password: false, account: false, settings: true };
    case "password":
      return { ...state, settings: false, account: false, password: true };
    case "account":
      return { ...state, settings: false, password: false, account: true };
    case "close":
      return initialState;
    default:
      throw new Error();
  }
}

const UserProfile = () => {
  const dispatchRedux = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // Profile reducer state
  const [state, dispatch] = useReducer(reducer, initialState);
  // Menu toggle state
  const { dropDownRef, toggleDropdownn, setToggleDropdown } = useDropDown();

  return (
    <div className="dropdown d-flex flex-column align-items-center text-center align-self-center mr-5" ref={dropDownRef}>
      <div className="topbar-item cursor-pointer" onClick={() => setToggleDropdown(true)}>
        <span className="symbol symbol-40 symbol-circle symbol-light-success bg-hover-light">
          <span className="symbol-label font-size-h5 font-weight-bold">
            {user?.firstname[0].toUpperCase()}{user?.lastname[0].toUpperCase()}
          </span>
        </span>
      </div>

      <div className={`dropdown-menu dropdown-menu-custom w-full mt-5 ${toggleDropdownn ? "show" : ""}`} aria-labelledby="dropdownMenuButton">
        <div className="ml-3 my-3 d-flex align-items-center w-full">
          <span className="symbol symbol-35 symbol-circle symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">{user?.firstname[0]}</span>
          </span>
          <div className="mx-4 d-flex align-items-center">
            <span>{user?.firstname} {user?.lastname}</span>
          </div>
        </div>

        <button className="dropdown-item" onClick={() => dispatch({ type: "settings" })}>
          <Settings /> <span className="ml-3">Settings</span>
        </button>

        <button className="dropdown-item" onClick={() => dispatchRedux(logout())}>
          <ExitToApp />
          <span className="ml-3">Logout</span>
        </button>
      </div>
      <ProfileSettings state={state} dispatch={dispatch} user={user} />
      <ChangePassword state={state} dispatch={dispatch} user={user} />

      {/* Hide delete account option for now */}
      {/* <DeleteAccount state={state} dispatch={dispatch} /> */}
    </div>
  );
};

export default UserProfile;
