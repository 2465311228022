import React, { useContext, useEffect, useState } from 'react'
import { CustomInput, FormText, Select } from 'components/Events/Fields'
import { Form, Table } from 'react-bootstrap'
import { useFormik } from 'formik'
import { formikDefault } from 'utils/forms-schema'
import { CiCircleMore } from 'react-icons/ci'
import { RiDeleteBinLine } from 'react-icons/ri'

const ZoneTable = ({ zones, types, setZones, setZoneType }) => {
    const handleSubmit = (val) => {
        console.log(val)
    }

    // Define form
    const formik = useFormik({
        ...formikDefault,
        initialValues: { zones },
        validationSchema: {},
        onSubmit: handleSubmit
    })

    const handleZoneDetails = (e, i, item, type) => {
        const res = zones.slice();
        res[i] = { ...item, [type]: e.target.value };
        setZones(res)
    }

    const handleDeleteZone = (item, ind) => {
        const res = zones.filter(zone => item.uuid !== zone.uuid)
        setZones(res)
    }
   
    return (
        <Form noValidate onSubmit={formik.handleSubmit}>
            <FormText>Please insert the name for the Zones across the rectangles you created.</FormText>
            <div className="row">

                {/* Set zone names for all rectangles */}
                <div className="col-md-6">

                    <Table bordered hover={false} className="v-align">
                        <thead>
                            <tr>
                                <th width="50" className="text-center">Zone</th>
                                <th width="*">Name</th>
                                <th width="*">Type</th>
                                <th><CiCircleMore className="more" /></th>
                            </tr>
                        </thead>

                        <tbody>
                            {(zones ?? []).map((zone, index) => (
                                <tr key={index}>

                                    {/* zone number */}
                                    <td className="text-center">
                                        <h3>{index + 1}</h3>
                                    </td>

                                    {/* Name */}
                                    <td>
                                        <CustomInput
                                            handleChange={(e) => handleZoneDetails(e, index, zone, 'name')}
                                            name={`zones.${index}.name`}
                                            value={zone?.name} />
                                    </td>

                                    {/* zone */}
                                    <td>
                                        <Select
                                            state={[zone?.type, setZoneType]}
                                            handleChange={(e) => handleZoneDetails(e, index, zone, 'type')}
                                            name="zone"
                                            empty='zone type'
                                            options={types?.map(type => [type.name, type.id, type])}
                                        />
                                    </td>
                                    <td>
                                        {zone.id &&
                                            <div className='table-cont'
                                                onClick={() => handleDeleteZone(zone, index)}>
                                                <RiDeleteBinLine className='table-icon' />
                                            </div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </Form>
    )
}

export default ZoneTable