import { getVehicleTypes } from 'config/VehicleTypes'
import { useLoading } from 'hooks/useLoading'
import moment from 'moment'
import React, { useContext } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import useAPI from 'services/ApiService'
import { getMCReportCount, getMCReportPcu, getMCReportTotal } from 'utils/functions'
import { McResContext } from './Report/MCResLayout'

const GranularTable = ({ pcu }) => {
    const { report } = useContext(McResContext)
    const vehicles = getVehicleTypes()


    return (
        <Table bordered hover={false} className="v-align">
            <thead>
                <tr>
                    <th width="">Date</th>
                    <th width="">Time</th>
                    {vehicles?.map((item, i) => <th key={i}>{item?.name}</th>)}
                    <th width="">Total</th>
                    <th width="">PCU</th>
                </tr>
            </thead>
            <tbody>

                {report?.data?.map((item, ind) => {
                    return <tr className={`${item?.is_hourly ? 'total' : ''}`}>
                        <td>
                            {moment(item?.start).format('DD/MM/YYYY')}</td>
                        <td>{moment(item?.start).format('H:mm:ss')} - {moment(item?.end).format('H:mm:ss')}</td>
                        {vehicles?.map((vehicle, i) =>
                            <td key={i}>{getMCReportCount(item?.entries, vehicle)}
                            </td>)}
                        <td>{getMCReportTotal(item?.entries)}</td>
                        <td>{getMCReportPcu(item?.entries, pcu)}</td>
                    </tr>
                })
                }
            </tbody>
        </Table>
    )
}

export default GranularTable