import { createContext } from "react"

/** The context for all column headers to communicate with the API. */
export const TableHeaderContext = createContext(null)

/**
 * Draw a table column header.
 *
 * @param {function} onClick
 * @param {string|{name: string, direction: string}} order The default order to use
 * @param {JSX.Element[]} children The list of columns to include in the header.
 *
 * @return {JSX.Element}
 * @constructor
 */
const TableHeader = ({ onClick, order, children }) => {

  // Use the default order
  if (order) {
    order = {
      name: order.replace(/^[+-]/, ""),
      direction: ["+", "-"].includes(order.substring(0, 1)) ? order.substring(0, 1) : "+"
    }
  }

  // Update the ordering
  const updateOrdering = (name, direction) => {

    // Revert if existing, or create a new ordering
    if (order?.name === name) {
      order.direction = order.direction === "-" ? "+" : "-"
    } else {
      order = { name, direction }
    }

    // Set the new ordering
    onClick(order.direction + order.name)
  }

  return <thead>
    <tr className="">
      <TableHeaderContext.Provider value={{ updateOrdering, order }}>
        {children}
      </TableHeaderContext.Provider>
    </tr>
  </thead>
}

export default TableHeader
