import { State, useLoading } from 'hooks/useLoading'
import React, { createContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useAPI from 'services/ApiService'

export const MatrixContext = createContext('')

const MatrixLayout = ({ children }) => {
    const { systemID, cameraID } = useParams();
    const dateRange = useSelector(state => state.dateRange)
    const [dateRangeModal: boolean, setDateRangeModal] = useState(false)
    const [selectedCameras, setSelectedCameras] = useState()
    const [selectedVehicles, setSelectedVehicles] = useState('')
    const [selectedAPI, setSelectedAPI] = useState('transitflow')
    const [minDuration, setMinDuration] = useState(0)
    const [filter, setFilter] = useState('')

    let sys_id = selectedCameras ? JSON.parse(selectedCameras)?.sys_id : systemID;
    let camera_id = selectedCameras ? JSON.parse(selectedCameras)?.camera_id : cameraID;

    const api = useAPI();
    const [matrixData, eventLoadingState] =
        useLoading(() =>
            api.getOriginDestinationMatrix(sys_id, camera_id, dateRange, selectedAPI, minDuration),
            [dateRange, sys_id, selectedAPI, camera_id, minDuration]);

    let isLoading = !sys_id || !camera_id ? State.LOADING : eventLoadingState
    return (
        <MatrixContext.Provider value={{
            dateRangeModal, setDateRangeModal,
            selectedAPI, setSelectedAPI, selectedCameras,
            setSelectedCameras, selectedVehicles,
            setSelectedVehicles, minDuration, setMinDuration,
            dateRange, matrixData, eventLoadingState, isLoading,
            filter, setFilter
        }}>
            {children}
        </MatrixContext.Provider>
    )
}

export default MatrixLayout