import { v4 as UUIDv4 } from "uuid"
import { deepClone } from "../../../../utils/data"
import { toaster } from "../../../../utils/toaster"

/**
 * Submit a camera to the API to be saved on the server.
 *
 * @param {CameraManagementService} api The API hook to use.
 * @param {CMCamera} camera The camera from the form.
 */
const submit = async (api, camera: CMCamera) => {
  const post = { ...camera, lines: camera.lines.map(deepClone), pairs: camera.pairs.map(deepClone) }

  // Make sure there is a UUID for the camera
  // post.uuid = post.uuid ?? UUIDv4()

  // Create camera
  const promise = api.postCamera(post)
  await toaster.promise(promise, "Camera saved successfully")

  // Audit log
  api.log(camera.uuid ? "# Camera edit" : "# Camera create", post)

  return promise
}

export default submit
