import JSONPainterNodeArray from "./JSONPainterNodeArray"
import JSONPainterNodeBoolean from "./JSONPainterNodeBoolean"
import JSONPainterNodeNull from "./JSONPainterNodeNull"
import JSONPainterNodeObject from "./JSONPainterNodeObject"
import JSONPainterNodePrimitive from "./JSONPainterNodePrimitive"
import JSONPainterNodeURL from "./JSONPainterNodeURL"

const JSONPainterObject = ({ node, collapsed = false }) => {

  // Route based on the type of the supplied node
  const type = getType(node)
  switch (type) {
    case VarType.OBJECT:
      return <JSONPainterNodeObject node={node} collapsed={collapsed} />

    case VarType.ARRAY:
      return <JSONPainterNodeArray node={node} collapsed={collapsed} />

    case VarType.BOOLEAN:
      return <JSONPainterNodeBoolean node={node} />

    case VarType.URL:
      return <JSONPainterNodeURL node={node} />

    case VarType.NULL:
      return <JSONPainterNodeNull />

    default:
      return <JSONPainterNodePrimitive node={node} type={type} />
  }
}

/** @const The list of possible variable types. */
export const VarType = {
  NULL: "null",
  OBJECT: "object",
  ARRAY: "array",
  BOOLEAN: "boolean",
  NUMBER: "number",
  STRING: "string",
  FUNCTION: "function",
  SYMBOL: "symbol",
  BIGINT: "bigint",
  URL: "url"
}

/**
 * Get the type of the variable.
 *
 * @param {*} variable The variable to check.
 *
 * @typedef {"null"|"object"|"array"|"boolean"|"number"|"string"|"function"|"symbol"|"bigint"|"url"} VarType
 *
 * @return {VarType}  If null or undefined return "null", special cases for string or just the result of "typeof variable" otherwise.
 */
export const getType = (variable: *): string => {
  const type = typeof variable

  // Null as a special type
  if (variable == null) {
    return VarType.NULL
  }

  // Array
  if (Array.isArray(variable)) {
    return VarType.ARRAY
  }

  // String special cases
  if (type === VarType.STRING) {
    switch (true) {
      case variable.match(/^(s?ftp|https?):\/\/.+/):
        return VarType.URL

      default:
        return VarType.STRING
    }
  }

  return type
}

export default JSONPainterObject
