import { CameraOperationState } from 'components/CameraOperationState/CameraOperationState'
import { LoadingImage } from 'components/LoadingImage/LoadingImage'
import React from 'react'
import { getCameraImageURL } from 'utils/camera'
import { BsCheckCircleFill } from 'react-icons/bs'

const CameraCard = ({ camera, isActive }) => {
    return (
        <div className="camera-wrapper">
            <div className="camera-wrapper__image ratio-16-9">
                <LoadingImage className="img-fluid cropped ratio-16-9" src={getCameraImageURL(camera.uuid)} alt={camera.location} />
                <CameraOperationState camera={camera} position="top-right" />
            </div>
            <div className="text-secondary wordBreak">
                <h1>{camera.name}</h1>
                <h2>{camera.group}</h2>
                <h3>{camera.location}</h3>
            </div>
            {isActive && <div>
                <BsCheckCircleFill className='cam-check' />
            </div>}
        </div>
    )
}

export default CameraCard