import React, { useEffect, useRef, useState } from "react"
import { v4 as UUIDv4 } from "uuid"
import { blobToData, isSupported, noDefault } from "utils/functions"
import { Loader } from "../../../../components"
import { MessageModal, useMessageModal } from "../../../../components/Modal/MessageModal"
import useCameraManagementAPI, { MAX_UPLOAD_SIZE } from "../../../../services/CameraManagementService"
import uploadIcon from "../images/upload.png"
import toast, { ToastBar } from "react-hot-toast"
import ProgressBar from "./ProgressBar"

const supportedMimeTypes = [
  "video/mp4",
  "video/avi",
  "video/x-matroska",
  "video/quicktime",
  "video/x-ms-wmv",
  "video/vnd.dlna.mpeg-tts",
  "video/mpeg"
]
const supportedExtensions = ".mp4,.avi,.mkv,.mov,.wmv,.m2ts,.mpeg"

const DropZone = ({ setFiles, files: savedFiles, batch, uploading, tempFilename, setTempFileName, setUploading }) => {
  const messageModal = useMessageModal()
  const { camera_uuid, name, start } = batch
  const api = useCameraManagementAPI()
  /** @var {{current: {}}} The reference to the file input DOM element. */
  const uploadRef = useRef()

  // State variables for the file and the current chunk index
  const [file, setFile] = useState(null);
  const [currentFileIndex, setCurrentFileIndex] = useState(0)
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  const [uploads, setUploads] = useState([])
  const [filesToUpload, setFilesToUpload] = useState([])
  const [filesLen, setFilesLen] = useState(0)
  const [fileLimit, setFileLimit] = useState(false)

  const MAX_COUNT = 5;
  // Constant for the chunk size (20MB)
  const chunkSize = 1024 * 1024 * 2
  const uploadProgress = Math.round(currentChunkIndex / Math.ceil(file?.size ?? 0 / chunkSize))

  // Function to handle the start of the upload
  async function handleUpload(files) {
    setUploads([])
    // Guard: There is at least one files
    if (files.length === 0) {
      return
    }
    if (files.length > 5) {
      toast.error('Cannot upload more than 5 files at a time', {
        duration: 5000
      })
      return
    }
    // Guard: Do nothing while upload is in progress
    if (uploading) {
      return
    }

    // Guard: Make sure all files are allowed
    for (const file of files) {
      if (!supportedMimeTypes.includes(file.type) && !isSupported(file.name, supportedExtensions)) {
        const supported = supportedExtensions.replace(/\./g, " ").trim()
        messageModal.show("Unsupported file format", `File type "${file.type}" is not supported.\n\nSupported video formats are: ${supported}`)
        return
      }
    }

    const chosenFiles = Array.prototype.slice.call(files)
    let batchFiles = [...savedFiles, ...chosenFiles]

    // Guard: Enforce maximum upload size
    const size = batchFiles.reduce((size, file) => size + file.size, 0)
    console.log({ size, chosenFiles, batchFiles, MAX_UPLOAD_SIZE })
    if (size > MAX_UPLOAD_SIZE) {

      // Different messages for a single and multiple files
      const message = files.length > 1
        ? `Maximum upload size is limited to ${MAX_UPLOAD_SIZE / (1024 * 1024 * 1024)}GB.\n\nPlease try uploading fewer files as a time.`
        : `File cannot be larger than ${MAX_UPLOAD_SIZE / (1024 * 1024 * 1024)}GB.`
      messageModal.show("Max upload size reached", message)
      batchFiles = []

    }
    else {
      setFilesLen(files.length)
      handleFilesToUpload(chosenFiles)

      // initiate the file to upload & the chunk index to 0
      setCurrentChunkIndex(0)
      setFile(files[0])
      setFiles(uploads)
    }
  }

  const handleFilesToUpload = files => {
    const uploaded = [];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    })
    if (!limitExceeded) setFilesToUpload(uploaded)

  }

  // useEffect hook to handle the file upload
  useEffect(() => {
    // Function to upload a chunk of the file
    async function uploadChunk(readerEvent, retries = 3) {
      if (!file) return;
      const data = readerEvent.target.result;
      const fileExtension = file.name.split('.').pop();
      // Set up the parameters for the request
      const params = new URLSearchParams();
      params.set('name', `${tempFilename}.${fileExtension}`);
      params.set('currentChunkIndex', currentChunkIndex);
      params.set('totalChunks', Math.ceil(file.size / chunkSize));

      try {
        const response = await api.uploadFileChunks(data, params)

        // Prepare files for the upload
        const upload = []
        // check if there's a final filename
        if (response.final_filename) {
          upload.push({
            name,
            fileName: file.name,
            fileSize: Math.round(file.size * 0.000001),
            payload: `${tempFilename}.${fileExtension}`,
            id: UUIDv4(),
            camera_uuid,
            start: new Date(start).toISOString()
          })
          setUploads(upload)
          setFile(null)
        }
        // Check if this is the last chunk
        const isLastChunk = currentChunkIndex === Math.ceil(file.size / chunkSize) - 1;
        // If it is, set the final filename and reset the current chunk index
        if (isLastChunk) {
          file.finalFilename = response.final_filename;
          setTempFileName(UUIDv4())
          if (savedFiles.length + 1 < filesLen) {
            setCurrentChunkIndex(0)
            setFile(filesToUpload[savedFiles.length + 1])
            setCurrentFileIndex(savedFiles.length + 1)
          }
          else if (filesLen === 1 && savedFiles.length === 0) {

          } else {
            setCurrentChunkIndex(null);
            toast.success('All uploads completed', {
              duration: 10000
            })
            return
          }

        } else {
          // If it's not, increment the current chunk index
          setCurrentChunkIndex(currentChunkIndex + 1);
        }
        return response
      } catch (error) {
        if (retries > 0) {
          await uploadChunk(readerEvent, retries - 1);
        } else {
          toast.error('Failed to upload chunk: ', error)
          console.error('Failed to upload chunk: ', error);
          throw new Error('Failed to upload chunk: ', error)
        }
      }
    }

    // Function to read and upload the current chunk
    function readAndUploadCurrentChunk() {
      if (!file) return;
      // Calculate the start and end of the current chunk
      const from = currentChunkIndex * chunkSize;
      const to = (currentChunkIndex + 1) * chunkSize >= file.size ? file.size : from + chunkSize;

      // Slice the file to get the current chunk
      const blob = file.slice(from, to);

      const reader = new FileReader();
      // Set the onload function to upload the chunk when it's read
      reader.onload = e => uploadChunk(e);
      // Read the blob as a data URL
      reader.readAsDataURL(blob);
    }

    // If a chunk index is set, read and upload the current chunk
    if (currentChunkIndex != null) readAndUploadCurrentChunk();
  }, [chunkSize, currentChunkIndex, file])

  // Execute and disable until files are uploaded
  useEffect(() => {
    setFiles(uploads)
  }, [uploads])

  // check if the files to upload is more than the uploaded files or the currently uploading files
  useEffect(() => {
    if (filesLen > savedFiles.length || filesLen > filesToUpload.length) {
      setUploading(true)
    }
    else {
      setUploading(false)
    }

  }, [savedFiles, filesLen, uploads])

  console.log({ file, savedFiles, currentChunkIndex, uploads, filesLen, filesToUpload, uploading })

  return (
    <>
      {file && !file.finalFilename && <>
        <h3>File In progress</h3>
        <ProgressBar file={file} currentChunkIndex={currentChunkIndex} chunkSize={chunkSize} />
      </>}
      {filesToUpload.slice(currentFileIndex + 1).filter(item => item.finalFilename == null).map(file =>
        <ProgressBar waiting={true} file={file} />)}

      <div
        onClick={() => uploadRef.current.click()}
        onDrop={event => noDefault(event) && handleUpload(event.dataTransfer.files)}
        onDragEnd={noDefault}
        onDragOver={noDefault}
        onDragEnter={noDefault}
        className="upload-drop-zone col"
      >

        <div className="upload">
          <img src={uploadIcon} alt="upload" width="150px" className="mb-7" />
          <Loader visible={uploading} type="beat" />
          <h3 style={{ display: uploading ? "none" : "block" }}>
            <input type="file" onChange={event => noDefault(event) && handleUpload(event.target.files)} accept={supportedExtensions} multiple ref={uploadRef} disabled={uploading} />
            Drag and drop files here, or <label className="custom-file-upload">browse</label> for files
          </h3>
        </div>

      </div>

      <MessageModal hook={messageModal} />
    </>
  )
}

export default DropZone
