import { useHistory } from "react-router-dom"
import { Table, Button } from "react-bootstrap"
import { useGetAllBatches } from "./queries"
import { CloudUploadOutlined } from "@material-ui/icons"
import LargeCard from "components/Structure/LargeCard"
import { LoadingWrapper } from "components"
import TableRow from "./components/TableRow"
import useCameraManagementAPI from "services/CameraManagementService"
import { useLoading } from "hooks/useLoading"
import './files.scss'
import { useState } from "react"
import Filter from "components/Filter/Filter"

const UploadFilesDashboard = () => {
  const { data: batchFiles, status } = useGetAllBatches()
  const api = useCameraManagementAPI()
  // Load the data
  const [duration, loadingState] = useLoading(
    () => api.processDuration(),
    [],
  )
  const history = useHistory()
  const [filter, setFilter] = useState({})
  if (!duration) {
    return null
  }

  const headerOptions = (
    <div className="float-right">
      <div className="batch-btn">
        <Button variant="primary" className="btn btn-primary" onClick={() => history.push("/camera-files/new")}>
          <CloudUploadOutlined /> <span className="ml-2">New file batch</span>
        </Button>
      </div>

    </div>
  )
  const params = [{
    name: 'term',
    label: 'Search batch name or camera',
    type: 'text'
  }]

  // console.log({ filter, batchFiles })
  return (
    <LargeCard title="Camera Files" headerOptions={headerOptions}>
      <LoadingWrapper state={[status, loadingState]} onEmpty={"No file uploaded yet"}>
        <div className="process-cont">
          <div className="filter">
            <Filter params={params} onChange={filter => setFilter(filter)} />
          </div>

          <div className="process">
            <div className="tile">
              <div className="title">{500 - parseInt(duration['completed']?.hours)}
              </div>
              <div>hours left</div>
            </div>
          </div>
        </div>

        {batchFiles?.length > 0 ? (
          <Table bordered hover={false} className="v-align">
            <thead>
              <tr>
                <th width="200" className="text-left">Batch Name</th>
                <th width="100" className="text-left">Files</th>
                <th width="120" className="text-left">Total Size</th>
                <th width="250" className="text-left">Start Time</th>
                <th width="200" className="text-left">Linked Camera</th>
                <th width="160" className="text-left">Status</th>
                <th width="150" className="text-left">Duration</th>
                <th width="200" className="text-center" />
              </tr>
            </thead>
            <tbody>
              {batchFiles?.filter(item => !filter.term || item?.name.includes(filter.term) || item?.camera?.name.includes(filter.term))
                ?.map(batch => <TableRow key={batch.uuid} batch={batch} />)}
            </tbody>
          </Table>
        ) : (
          <h1 className="text-center my-10">No Batch File added yet</h1>
        )}
      </LoadingWrapper>
    </LargeCard>
  )
}

export default UploadFilesDashboard
