import AllCameras from "."

/**
 * Display all the available cameras in a grid view.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const CamerasPage = () => {

  return (
    <AllCameras />
    // <CameraLayout>
    //   <AllCameras />
    // </CameraLayout>
  )
}
