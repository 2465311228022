import { useQueries, useMutation, useQueryClient, useQuery } from "react-query";
import toast from "react-hot-toast";
import { uniqueArrOfObj } from "utils/functions";
import AuthService from "services/AuthService";

/**
 * List of users in which the logged in user is admin.
 *
 * @param {string} tenantsIds Tenants ids.
 *
 * @return {{}} result.
 */
export const useUsersQueries = (tenantsIds) => {
  const result = useQueries(
    tenantsIds?.map((tenant) => {
      return {
        queryKey: ["users", tenant],
        queryFn: () => AuthService.getTenantUsers(tenant),
        enabled: !!tenant
      };
    }) ?? []
  );
  const users = result
    .map((res) => res.data)
    .filter((data) => data !== undefined)
    .flat(1);
  const status = result.map((res) => res.status).at(-1);
  return { users: uniqueArrOfObj(users).flat(1), status };
};

/**
 * List of users in which the logged in user is admin.
 *
 * @param {string} tenantsIds Tenants ids.
 *
 * @return {{}} result.
 */
export const useUsersQuery = (tenantsIds) => {
  const { data, status } = useQuery(
    ["users", tenantsIds],
    async () => {
      const usersPromises = tenantsIds.map(tenant => AuthService.getTenantUsers(tenant));
      const usersArrays = await Promise.all(usersPromises);
      const flattenedUsers = usersArrays.flat(1);
      return uniqueArrOfObj(flattenedUsers);
    },
    {
      enabled: tenantsIds?.length > 0,
      staleTime: 5 * 60 * 1000, // 5 minutes
    }
  );
  return { users: data, status }
};

/**
 * Mutate user dashboard data.
 *
 * @param {function} fn function to execute, it depends on operations create/edit/delete user.
 * @param {string} successMessage The message to show in toast after the successful operation.
 * @param {string} errorMessage The message to show in toast if an error occurs.
 *
 * @return {function} react-query mutation function.
 */

export const useMutationUser = (fn, successMessage = "Success", errorMessage = "An error occurred") => {
  const queryClient = useQueryClient();

  return useMutation(fn, {
    onSuccess: () => {
      toast.success(successMessage);
      queryClient.invalidateQueries("users");
    },
    onError: error =>
      toast.error(error?.message ?? errorMessage)
  });
};

/**
 * Update user data.
 *
 * @param {string} userId User id.
 *
 * @return {function} react-query mutation function.
 */

export const useUpdateUser = (userId) =>
  useMutationUser(
    ({ tenantID, tenant_manager, details }) => AuthService.updateUser({ tenant_manager, details }, tenantID, userId),
    "User has been edited successfully"
  );
/**
 * Add user to tenant.
 *
 * @return {function} react-query mutation function.
 */
export const useAddUserToTenant = () =>
  useMutationUser(
    ({ email_address, tenant_manager, system_id }) => AuthService.addUserToTenant({ email_address, tenant_manager }, system_id),
    "User has been added to tenant"
  );
/**
 * Remove user from tenant.
 *
 * @param {string} userId User id.
 *
 * @return {function} react-query mutation function.
 */
export const useRemoveUserFromTenant = (userId) =>
  useMutationUser((sysId) => AuthService.removeUserFromTenant(sysId, userId), "User has been removed from tenant");

/**
 * Create a new user.
 *
 * @return {function} react-query mutation function.
 */
export const useCreateUser = () =>
  useMutationUser((data) => AuthService.createUser(data[0], data[1]), "User has been created successfully");
