import { addDays, endOfDay, startOfDay, subHours } from "date-fns";

// export const DefaultPresetRanges = window.location.pathname.includes('cameras/trajectories') ? [
//   ["Last 1 hour"],
//   ["Today"],
//   ["Yesterday", 1],
//   ["Last 7 days", 7],
// ] : [
//   ["Last 1 hour"],
//   ["Today"],
//   ["Yesterday", 1],
//   ["Last 7 days", 7],
//   ["Last 30 days", 30],
//   ["Last 90 days", 90],
//   ["Last 365 days", 365]
// ];
export const DefaultPresetRanges = (change) => {
  let data = [
    ["Last 1 hour"],
    ["Today"],
    ["Yesterday", 1],
    ["Last 7 days", 7],
    ["Last 30 days", 30],
    ["Last 90 days", 90],
    ["Last 365 days", 365]]

  if (change) {
    data = [["Last 1 hour"],
    ["Today"],
    ["Yesterday", 1],
    ["Last 7 days", 7],
    ]
  }
  return data
}

/** @return {string} The name of the default preset.*/
export const DefaultPreset = DefaultPresetRanges()[0];

/**
 * Build a standardized date range from a preset.
 *
 * @param {[string, int, int]} preset The preset to build the date range from.
 *
 * @return {DateRangeDTO} The resulting standardized date range.
 */
export const buildFromPreset = (preset) => ({
  name: preset[0],
  start: preset[0] === "Last 1 hour" ? subHours(new Date(), 1)
    : preset[0] === 'Today' ? startOfDay(new Date()) : preset[0] === 'Yesterday' ? addDays(startOfDay(new Date()), -1)
      : addDays(new Date(), -(preset[1] ?? 0)),
  end: preset[0] === 'Today' ? endOfDay(new Date()) : preset[0] === 'Yesterday' ? addDays(endOfDay(new Date()), -1) : new Date()
});
