import { isEmpty, isNotEmpty } from "./functions"

/**
 * Make sure that a path in an object is initialized.
 *
 * @param {JSON} data The object in which to initialize the path.
 * @param {string|string[]} path The path in the data to initialize, as array or a dot-separated string.
 * @param {any} value The value to initialize the path to.
 */
export function set(data, path, value = []) {

  // Support both array and dot-separated paths
  const split = typeof path === "string"
    ? path.split(".")
    : path

  // Initialize the data itself
  if (data == null) {
    data = {}
  }

  // Initialize the pointer
  let pointer = data

  // Save the last step for later and iterate over all others
  const last = split.pop()
  for (const step of split) {

    // Make sure that each step in a path is initialized
    if (!pointer.hasOwnProperty(step)) {
      pointer[step] = {}
    }

    // Update the pointer
    pointer = pointer[step]
  }

  // Set the terminal value
  pointer[last] = value
}

/**
 * Read a value from an object by its path.
 *
 * @param {JSON} data The object from which to read the value.
 * @param {?string|string[]} path The path to get the data from.
 * @param {any} defaultValue The value to return if the path does not exist.
 *
 * @return {any} The found value or the supplied defaultValue if the path does not exist.
 */
export function get(data, path, defaultValue = null) {

  // No path
  if (path === null) {
    return data ?? defaultValue
  }

  // Support both array and dot-separated paths
  const split = typeof path === "string"
    ? path.split(".")
    : path

  // Guard: Data must be valid
  if (data == null || typeof data !== "object") {
    return defaultValue
  }

  // Initialize the pointer
  let pointer = data

  // Save the last step for later and iterate over all others
  for (const step of split ?? []) {

    // Not found
    if (pointer == null || !pointer.hasOwnProperty(step)) {
      return defaultValue
    }

    // Update the pointer
    pointer = pointer[step]
  }

  return pointer
}

/**
 * Get only unique values from an array.
 *
 * @param {any[]} arr The original array.
 *
 * @return {any[]} The array containing only unique names.
 */
export const uniqueValues = (arr) =>
  Array.from(new Set(arr))

/**
 * Make sure that a path exists in a data.
 *
 * @param {JSON} data The data to check.
 * @param {string|string[]} path The path to check.
 * @param {any} defaultValue The value to set if the path does not exist.
 *
 * @return {any} The pointer to the specified path.
 */
export const initPath = (data, path, defaultValue = {}) => {
  const flag = "mWe94Wm2qY2NLMBg5AGK7SMFVz1srSADBhVqqJQYFxaT0qhi1vTMmMsU11GGDXnu"

  // Check if a path exists
  if (get(data, path, flag) === flag) {
    set(data, path, defaultValue)
  }

  return get(data, path)
}

/**
 * Convert a JSON object to an array.
 *
 * @param {JSON} json The JSON data to convert.
 * @param {int} depth The depth in which to convert the data, after which the JSON objects will be left as is.
 * @param {int} iteration Internally used by the method, always leave this value as default.
 *
 * @return {KeyValue[]} The supplied data, but as an array.
 */
export function toArray(json, depth = 1, iteration = 0) {
  const arr = []

  // Only handle objects up until the defined depth
  if (typeof json !== "object" || iteration === depth) {
    return json
  }

  // Convert the key-value pair to an object with a "key" and a "value"
  for (const key in json) {
    if (json.hasOwnProperty(key)) {
      const value = toArray(json[key], depth, iteration + 1)
      arr.push({ key, value })
    }
  }

  return arr
}

/**
 * Rotate a two-dimensional matrix by 90 degrees by swapping the first and the second indices between each other.
 *
 * @param {[][]} originalMatrix The two-dimensional matrix to rotate.
 *
 * @return {[][]} The rotated matrix.
 */
export function rotateMatrix(originalMatrix) {
  const rotatedMatrix = []

  // Go over the first dimension
  for (const key1 in originalMatrix) {
    if (originalMatrix.hasOwnProperty(key1)) {

      // Go over the second dimension
      for (const key2 in originalMatrix[key1]) {
        if (originalMatrix[key1].hasOwnProperty(key2)) {

          // Create a copy with the swapped indices
          rotatedMatrix[key2][key1] = rotatedMatrix[key1][key2]
        }
      }
    }
  }

  return rotatedMatrix
}

/**
 * Convert a linear data into a matrix.
 *
 * @param {[]} list The initial list.
 * @param {function(any)} key1 Generate the first level key from the supplied item.
 * @param {function(any)} key2 Generate the second level key from the supplied item.
 * @param {function(any)} defaultCell The default value for a cell from the supplied item.
 * @param {function(any, any)} updateCell Update the existing cell in the matrix with the data from the item.
 *
 * @return {{}} The generated matrix.
 */
export const convertToMatrix = (list, key1, key2, defaultCell, updateCell) => {
  const matrix = {}

  // Make sure we have anything to work with
  if (list) {
    for (const item of list) {

      // Make sure that the cell is initialized
      const [x, y] = [key1(item), key2(item)]
      initPath(matrix, [x, y], defaultCell(item))

      // Update with the value from this item
      matrix[x][y] = updateCell(item, matrix[x][y])
    }
  }

  return matrix
}

/**
 * Cycle the array for a number of steps.
 *
 * @param {[]} array The array to cycle.
 * @param {int} steps The number of iterations to execute, positive for 0->1, negative for 1->0.
 *
 * @return {*} The cycled array.
 */
export const cycleArray = (array, steps) => {

  // Works in both directions
  const inc = steps > 0
  steps = Math.abs(steps) % array.length

  // Iterate
  const cycledArray = [...array]
  for (let i = 0; i < steps; i++) {
    if (inc) {
      cycledArray.unshift(cycledArray.pop())
    } else {
      cycledArray.push(cycledArray.shift())
    }
  }

  return cycledArray
}

/**
 * Extend some original or default values with values found in the custom data.
 *
 * @param {{}} custom The data to write over the original values.
 * @param {{}} original The original data to extend.
 *
 * @return {{}} The original data extended with some data overridden.
 */
export const extend = (custom, original) => {
  const result = original

  // Go over the custom dataset to make sure we have all the values
  for (const key in custom) {
    if (custom.hasOwnProperty(key)) {
      const value = custom[key]

      // Skip if there is no data in the custom dataset
      result[key] = typeof value === "object" && original.hasOwnProperty(key)
        ? extend(custom[key], result[key])
        : value
    }
  }

  return result
}

/**
 * Sort an object with values, much like the arrays can be sorted.
 *
 * @param {{}} data The JSON data to sort.
 * @param {?string} path The path where the values to check are located.
 *
 * @return {{}} The sorted object.
 */
export const sortDataObject = (data, path = null) => {
  const array = toArray(data).sort((a, b) => get(b, path) - get(a, path))
  return array.reduce((acc, row) => {
    acc[row.key] = row.value
    return acc
  }, {})
}

/**
 * Map an array to an object with custom used keys and values.
 *
 * @param {{}[]} array The original data from which to read the data.
 * @param {string} key The path to the value that will be used as a key for the new object.
 * @param {string} value The path to the value that will be used as a new value.
 *
 * @return {{}} The resulting object.
 */
export const mapToObject = (array, key, value) => {
  const json = {}

  // Traverse over every item in the array
  for (const item of array ?? []) {

    // Make sure there is something on the key path
    const myKey = get(item, key)
    if (myKey != null) {
      json[myKey] = get(item, value)
    }
  }

  return json
}

/**
 * Decode a Base64URL encoded string.
 *
 * @param {string} input The encoded input.
 * @return {string} The decoded result.
 */
export const decodeBase64URL = (input: string) => {

  // Guard: No input
  if (input == null) {
    return null
  }

  // Replace back to original base64 chars
  input = input
    .replace(/-/g, "+")
    .replace(/_/g, "/")

  // Pad out with standard base64 required padding characters
  let pad = input.length % 4
  while (pad--) {
    input += "="
  }

  return (window.Buffer || require("buffer").Buffer).from(input, "base64").toString()
}

/**
 * Change the key names inside a JSON object.
 *
 * @param {{}} data The original data.
 * @param {{string:string}} map The map of original keys to the new ones.
 *
 * @return {{}} An object with remapped keys.
 */
export const remap = (data: {}, map: {}) => {
  const mappedData = {}

  // Iterate over each key in the data
  for (const key in data) {

    // Assign the value to another key, if the key is found in the supplied map
    mappedData[map.hasOwnProperty(key) ? map[key] : key] = data[key]
  }

  return mappedData
}

/**
 * Very similar to the map, but works with JSON objects.
 *
 * @param {{}} source The original JSON object to transpose.
 * @param {function(key:string,value:*)} mapper The mapper function that returns both key and value as [ key, value ].
 *
 * @return {{}} The transposed JSON object.
 */
export const transpose = (source, mapper) => {
  const result = {}

  // Iterate only over true properties
  for (const originalKey in source) {
    if (source.hasOwnProperty(originalKey)) {
      const [key, value] = mapper(originalKey, source[originalKey])
      result[key] = value
    }
  }

  return result
}

/**
 * Create an object with only one key and value, or an empty object if value is empty.
 *
 * @param {string}key The key to set the value to.
 * @param {?*} value The value to check if empty, and set as value if not.
 * @param {function(?*)} modify The optional modification to use on the value when setting.
 *
 * @return {{}} Empty object if supplied value is treated as empty, or a { key: value }.
 */
export const pairIfNotEmpty = (key: string, value: ?*, modify = null) => {
  const obj = {}

  // Set the key
  if (isNotEmpty(value)) {
    obj[key] = modify
      ? modify(value)
      : value
  }

  return obj
}

/** @return {{}} The data which has all of its sensitive data removed. */
export const clearSensitiveData = (data) => {
  const sensitiveKeys = ["pass", "password", "secret", "api_secret", "key", "api_key", "token", "api_token", "access_token", "refresh_token"]

  // Handle arrays
  if (Array.isArray(data)) {
    return data.map(clearSensitiveData)
  }

  // Handle objects
  if (typeof data === "object") {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {

        // Override sensitive keys or handle normal ones
        data[key] = !sensitiveKeys.includes(key)
          ? clearSensitiveData(data[key])
          : "*****"
      }
    }
  }

  return data
}

/**
 * Simple function to append items in state setters:
 * ```js
 * setItems(append(newItem))
 * ```
 *
 * @param {*} item The item to append.
 *
 * @return {function([]): []}
 */
export const append = (item) =>
  array => [...array, deepClone(item)]

/**
 * Simple function to append items in state setters:
 * ```js
 * setItems(updateAt(index, item))
 * ```
 *
 * @param {number} index The index to update.
 * @param {*} item The item to append.
 *
 * @return {function([]): []}
 */
export const updateAt = (index, item) =>
  values => {
    const update = deepClone(values)
    update[index] = deepClone(item)
    return update
  }

/**
 * Build GET query from the JSON.
 *
 * @param {{string: string}} query The query to build the parameters from.
 *
 * @return {string} The GET query with included '?' at the start (if needed).
 */
export const getQuery = (query: { string: string }) => {

  // Guard: Not empty
  if (isEmpty(query)) {
    return ""
  }

  // Build
  const list = []
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      list.push(encodeURIComponent(key) + "=" + encodeURIComponent(query[key]))
    }
  }

  return "?" + list.join("&")
}

/**
 * Clear any and all items that contains base64 encoded data.
 *
 * @param {*} data The data to handle.
 *
 * @return * The cleared data.
 */
export const clearBase64 = (data) => {

  // Handle arrays
  if (Array.isArray(data)) {
    return data.map(clearBase64)
  }

  // Handle objects
  if (typeof data === "object") {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = clearBase64(data[key])
      }
    }
  }

  // Finally
  if (typeof data === "string" && data.match(/^data:[^;]+\/[^;]+;base64,/)) {
    const [type] = data.split(";", 2)
    return `[base64-${type}]`
  }

  return data
}

/**
 * Deep clone an object.
 *
 * @param {{}} obj The object to clone.
 * @return {*} The cloned object.
 */
export const deepClone = obj => {

  // Handle nulls
  if (obj == null) {
    return null
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return [...obj].map(deepClone)
  }

  // Handle objects
  if (typeof obj === "object") {

    // Type: Date
    if (obj.getTime && obj.valueOf) {
      return new Date(obj.valueOf())
    }

    // Handle all children as well
    obj = { ...obj }
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = deepClone(obj[key])
      }
    }
  }

  return obj
}

