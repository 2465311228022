import { useState, useEffect, useRef } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Button } from "react-bootstrap"
import { LoadingWrapper } from "components"
import DropZone from "../components/DropZone"
import LargeCard from "components/Structure/LargeCard"
import FilesToUpload from "../components/FilesToUpload"
import { useGetFiles } from "../queries"
import { v4 as UUIDv4 } from "uuid"
import FileUploadProgress from "../components/FileUploadProgress"

const AddFilesToBatch = () => {
  const { batchUUID } = useParams()
  const countFilesRef = useRef(0)
  const history = useHistory()

  const { data: batchFiles, status } = useGetFiles(batchUUID)
  const [progressBars, setProgressBars] = useState([])
  const [files, setFiles] = useState([])
  const [minBatchesToProcess, setMinBatchesToProcess] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [tempFileName, setTempFileName] = useState(UUIDv4())
  const [newlyUploadedFiles, setNewlyUploadedNewlyUploadedFiles] = useState([])

  const isProcessEnabled = minBatchesToProcess !== 0 && minBatchesToProcess === countFilesRef.current

  const batch = { camera_uuid: batchFiles?.camera?.uuid, name: batchFiles?.name, start: batchFiles?.start }

  // useEffect(() => {
  //   if (batchUUID && files.length > 0) {
  //     setProgressBars((prevProgressBars) => [
  //       ...prevProgressBars,
  //       <FilesToUpload state={[minBatchesToProcess, setMinBatchesToProcess]} key={files[0].id} files={files} batchUUID={batchUUID} />
  //     ])
  //     countFilesRef.current = countFilesRef.current + files.length
  //     // setFiles([])
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [files, batchUUID])

  useEffect(() => {
    if (batchUUID && newlyUploadedFiles.length > 0) {

      // Update files without payload
      let newFiles = [...files, ...newlyUploadedFiles]

      const uniqueArray = newFiles.filter((obj, index, self) => {
        const firstIndex = self.findIndex(el =>
          Object.keys(el).every(key => el[key] === obj[key])
        );
        return firstIndex === index;
      });
      setFiles(uniqueArray)

      // The total number of files
      countFilesRef.current = countFilesRef.current + newlyUploadedFiles.length

      // Clear the list of new files
      setNewlyUploadedNewlyUploadedFiles([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyUploadedFiles, batchUUID])

  console.log(uploading)

  return (
    <LargeCard title="Add Video Files">
      <LoadingWrapper state={status} onEmpty={"No file uploaded yet"}>
        <div className="">
          {files.length > 0 && <h3>Files uploaded</h3>}

          {(files ?? []).map(file => (
            <FileUploadProgress key={file.id} state={[minBatchesToProcess, setMinBatchesToProcess]} file={file} batchUUID={batchUUID} />
          ))}
          {batch?.camera_uuid && <DropZone
            files={files}
            setFiles={setNewlyUploadedNewlyUploadedFiles}
            batch={batch}
            tempFilename={tempFileName}
            setTempFileName={setTempFileName}
            setUploading={setUploading}
            uploading={uploading} />}


        </div>
        <div className="float-right">
          <Button variant="secondary" className="mt-9  mr-3" onClick={() => history.push(`/camera-files/edit-files/${batchUUID}`)}>
            Back
          </Button>

          <Button
            disabled={uploading}
            variant="primary"
            type="submit"
            className="mt-9"
            onClick={() => history.push(`/camera-files/edit-files/${batchUUID}`)}
          >
            Save
          </Button>
        </div>
      </LoadingWrapper>
    </LargeCard>
  )
}

export default AddFilesToBatch
