import { useState } from "react"
import { apiTime, momentIgnoreTimezone, now, prepareDateRangeApiService as prepareDateRange } from "utils/time"
import { buildQuery, isEmpty } from "../utils/functions"
import { useDispatch, useSelector } from "react-redux"
import AuthService from "./AuthService"
import { logout, refresh } from "../redux/authSlice"
import { useHistory } from "react-router-dom"

class ApiService {

  /** Prepare the service. */
  constructor(refresh: TokenDTO, auth: TokenDTO, history, dispatch) {
    this.baseURL = process.env.REACT_APP_API_DATA_URL ?? "https://flow.greenroads.ai:9443"
    this.refresh = refresh
    this.auth = auth
    this.history = history
    this.dispatch = dispatch
  }

  /**
   * Get the token to use.
   *
   * @return {Promise<string>} The token to use in the authorization header.
   *
   * @private
   */
  _getToken(): string {
    return new Promise((resolve, reject) => {

      // Token must be more than 10 minutes away from expiring
      if (this.auth && this.auth.token && this.auth.expires > now(600)) {
        resolve(this.auth.token)
        return
      }

      // Use the refresh token
      if (this.refresh && this.refresh.token) {

        // Load the data
        AuthService.refresh(this.refresh.token)
          .then((response) => {
            if (!response?.access_token) {
              throw new Error("AUTH: No token in refresh response")
            }

            this.dispatch(refresh(response))
            resolve(response?.access_token)
          })
          .catch(() => {
            console.error("AUTH: Unable utilize refresh token")
            // this.history.push("/logout")
            reject()
          })
      } else {
        console.error("AUTH: No suitable token found")
        // this.history.push("/logout")
        reject()
      }
    })
  }

  /** @return {Promise<{}>} The list of headers to send in each request. */
  async _getHeaders(): string[] {
    return {
      Authorization: "Bearer " + await this._getToken()
    }
  }

  /**
   * Parse the parameters.
   *
   * @param {ReportParamsDTO} params The complete list of parameters.
   * @return {{cameraInfo: SystemAndCamera, query: {carriageways: string[], lanes: string[], vehicles: string[], from: string, to: string}}}
   * @private
   */
  _parseParams(params: ReportParamsDTO) {
    return {
      cameraInfo: { systemID: params.systemID, cameraID: params.cameraID },
      query: {
        from: apiTime(params.range.start),
        to: apiTime(params.range.end),
        carriageway_uuids: params.carriageways ?? [],
        lane_uuids: params.lanes,
        vehicle_types: params.vehicles,
        // ...params
      }
    }
  }

  /**
   * Execute an arbitrary API call on the predefined base URL.
   *
   * @param {string} path The path to execute on the predefined base URL.
   * @param {{}} query The query to append as a GET params.
   * @param {{}} params The additional parameters for the native fetch() function().
   *
   * @return {Promise<*>} The raw response.
   * @private
   */
  async _execute(path: string, query: {}, params = {}) {
    // Do we need the base URL
    const baseURL = !path.match(/^https?:\/\//) ? `${this.baseURL}/` : ""

    // cancel the request on unmount component
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      // Execute the request
      const response = await fetch(`${baseURL}${path}${buildQuery(query)}`, {
        headers: await this._getHeaders(),
        ...params,
        signal
      })
      if (!signal.aborted) {
        // Handle 401 - log the user out
        if (response.status === 401) {
          this.dispatch(logout())
          return
        }
        if (response.ok) {
          return response
        } else {
          console.error(`HTTP error! Status: ${response.status}`);
        }
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error(error);
        this.dispatch(logout())
      }
    }
    //   // Execute the request
    //   const response = await fetch(`${baseURL}${path}${buildQuery(query)}`, {
    //     headers: await this._getHeaders(),
    //     ...params,
    //     signal
    //   })

    //   // Handle 401 - log the user out
    //   if (response.status === 401) {
    //     this.dispatch(logout())
    //     return
    //   }

    //   return response

    // } catch (error) {
    //   this.dispatch(logout())
    // }
  }

  /**
   * Execute an arbitrary API call on the predefined base URL.
   *
   * @param {string} path The path to execute on the predefined base URL.
   * @param {{}} query The query to append as a GET params.
   * @param {{}} params The additional parameters for the native fetch() function().
   *
   * @return {Promise<{}>} The response as a JSON.
   * @private
   */
  async _executeJSON(path: string, query: {}, params = {}) {
    return await (await this._execute(path, query, params)).json()
  }

  /**
   * Execute the API call.
   *
   * @param {SystemAndCamera} cameraInfo The unique ids of the system and the camera.
   * @param {string} path The relative URL path to send the request to.
   * @param {{}} query The query to append as a GET params.
   *
   * @return {Promise<any>} The response from the server.
   */
  async _executeForCamera(cameraInfo: SystemAndCamera, path: string, query: {}) {
    if(!cameraInfo.cameraID || !cameraInfo.cameraID) return;
    return this._executeJSON(`cameras/${cameraInfo?.systemID}/${cameraInfo?.cameraID}/${path ?? ""}`, query)
  }

  /**
   * Test the token that is being sent.
   *
   * @return {Promise<*>}
   */
  async testAuthentication() {
    return this._executeJSON("secured/info")
  }

  /**
   * Get the list of all available cameras for the user.

   */
  async cameras(): CameraDTO[] {
    const cameras = await this._executeJSON("cameras")

    // API is returning random values for congestion, this forces the default icon to be used
    return cameras.map((el) => ({ ...el, status: { congestion: null } }))
  }

  /**
   * Get the list of  available cameras for a tenant.
   * @param {string} id The tenant id (system_id).
   */
  async getCameras(id): CameraDTO[] {
    return 
  }

  /**
   * Get the details about a single camera.
   *
   * @param cameraInfo The system and camera id of the camera to get info for.
   */
  async camera(cameraInfo: SystemAndCamera): CameraDTO {
    return this._executeForCamera(cameraInfo)
  }

  /**
   * Get the KPIs for a camera.
   *
   * @param cameraInfo The system and camera id of the camera to get info for.
   */
  async kpi(cameraInfo: SystemAndCamera): KPIArrayDTO[] {
    const data = await this._executeForCamera(cameraInfo, "data/kpi", prepareDateRange())
    return data?.kpis ?? []
  }

  /**
   * Get the information about all traffic points for a camera.
   *
   * @param cameraInfo The system and camera id of the camera to get info for.
   */
  async getTransitFlow(cameraInfo: SystemAndCamera): TransitFlowDTO {
    return this._executeForCamera(cameraInfo, "data/transitflow", prepareDateRange())
  }

  /**
   * Get the information about traffic summary.
   *
   * @param cameraInfo The system and camera id of the camera to get info for.
   */
  async getTransitSummary(cameraInfo: SystemAndCamera): TransitSummaryDTO {
    return this._executeForCamera(cameraInfo, "data/transit-summary", prepareDateRange())
  }

  /**
   * Contains per-hour data: count and average speed, without vehicle type. Also contains prepared data for KPI charts.
   *
   * @param cameraInfo The system and camera id of the camera to get info for.
   * @param isADT Check if the KPI is ADT
   */
  async getDataGranularSummary(cameraInfo: SystemAndCamera, isADT): GranularDataWithChartsDTO {
    const data = await this._executeForCamera(cameraInfo, isADT ? "data/daily-summary" : "data/granular-summary", prepareDateRange())

    // Prepare the data for the charts
    const charts = {
      count: [],
      avgSpeed: [],
      minSpeed: [],
      maxSpeed: [],
      p85Speed: [],
      percentiles: []
    }

    // Pack the data, so it is ready to show in a line chart
    data.forEach((item) => {

      // Parse the time
      const time = momentIgnoreTimezone(item.start).valueOf()

      // Populate the chart data
      charts.count.push([time, item.count])
      charts.avgSpeed.push([time, Math.round(parseFloat(item.avg_speed))])
      charts.minSpeed.push([time, Math.round(parseFloat(item.min_speed))])
      charts.maxSpeed.push([time, Math.round(parseFloat(item.max_speed))])
      charts.p85Speed.push([time, Math.round(parseFloat(item.p85_speed))])
      charts.percentiles.push([time, [
        Math.round(parseFloat(item.p01_speed)),
        Math.round(parseFloat(item.p25_speed)),
        Math.round(parseFloat(item.p50_speed)),
        Math.round(parseFloat(item.p75_speed)),
        Math.round(parseFloat(item.p99_speed))
      ]])
    })

    return { data, charts }
  }

  /**
   * Get the heatmap info for a camera.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   */
  async getHeatmap(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    const data = await this._executeForCamera(cameraInfo, "data/heatmap", query)
    return data?.rows ?? []
  }

  /**
   * Summarize the traffic by vehicle type.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   */
  async getVehicleTypeSummary(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/vehicletype-summary", query)
  }

  /**
   * Get the granular breakdown of the vehicles.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   * @param {int} granularity The granularity of the breakdown, in minutes.
   *
   * @return {VehicleTypeGranularJSON[]} The vehicle type broken down by granularity
   */
  async getVehicleTypeGranular(systemID, cameraID, range, vehicles, carriageways, lanes, granularity = 60) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/vehicletype-granular", { ...query, granularity })
  }

  /**
   * Get the summary for all lanes.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   *
   * @return {LanesSummaryReportDTO} The summary report for all lanes.
   */
  async getLanesSummary(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/lane-summary", query)
  }

  /**
   * Get the summary for all lanes.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   *
   * @return {HourlySummaryReportDTO[]} The summary report for all lanes.
   */
  async getDataHourlySummary(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/hourly-summary", query)
  }

  /**
   * Get the summary for all lanes.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   *
   * @return {HourlySummaryReportDTO[]} The summary report for all lanes.
   */
  async getDailySummary(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/daily-summary", query)
  }

  /**
   * Get the traffic flow details, per lane.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   *
   * @return {LanesSummaryReportDTO} The flow details between the lanes.
   */
  async getDataLaneFlow(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/laneflow", query)
  }

  /**
   * Get the trajectories of all vehicles and/or pedestrians.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   *
   * @return {VehicleTrajectoryDTO} The traffic trajectories.
   */
  async getVehicleTrajectory(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/vehicle-trajectory", query)
  }

  /**
   * 
   * 
   */
  async getVehicleTrajectoryHeatMaps(systemID, cameraID, range, carriageways, lanes, vehicles){
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/vehicle-trajectory-heatmaps", query)
  }

    /**
   * 
   * 
   */
    async getPedestrianTrajectoryHeatMaps(systemID, cameraID, range){
      const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
      return await this._executeForCamera(cameraInfo, "data/pedestrian-trajectory-heatmap", query)
    }

  /**
  * Get the trajectories of all vehicles and/or pedestrians.
  *
  * @param {string} systemID The system id for the camera to get the report for.
  * @param {string} cameraID The id of the camera to get the report for.
  * @param {DateRangeDTO} range The date range for the report.
  * @param {float} minDuration minimum duration for the report
  * @param {string|string[]} vehicles The list of vehicle types to filter by.
  * @param {string|string[]} zoneIds The list of zone uuids to filter by.
  * @param {string} url name to get the appropriate URL
  * @return {VehicleTrajectoryDTO} The traffic trajectories.
  */
  async getVehicleStoppedCount(systemID, cameraID, range, url, minDuration, granularity, vehicles, zoneIds) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, vehicles })
    return await this._executeForCamera(cameraInfo,
      `data/${url ? url : 'vehicles-stopped-count'}`,
      { ...query, min_duration: minDuration, granularity, zone_uuids: zoneIds })
  }

  /**
   * 
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {DateRangeDTO} range The date range for the report.
   * @param {string[]} vehicles The list of vehicle types to filter by.
   * @returns The summary report of vehicles captured by the camera
   */
  async getAllVehicleCount(systemID, range, vehicles) {
    const { query } = this._parseParams({ systemID, range })
    return await this._executeJSON(`cameras/${systemID ? systemID : '-'}/vehicles-stopped-summary`, query)
  }

  /**
  * Get the summary for all lanes.
  *
  * @param {string} systemID The system id for the camera to get the report for.
  * @param {string} cameraID The id of the camera to get the report for.
  * @param {DateRangeDTO} range The range for the report.
  * @return {HourlySummaryReportDTO[]} The summary report for all lanes.
  */
  async getPedestrianSummary(systemID, cameraID, range) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
    return await this._executeForCamera(cameraInfo, "data/pedestrian-summary", query)
  }
  /**
* Get the summary for all lanes.
*
* @param {string} systemID The system id for the camera to get the report for.
* @param {string} cameraID The id of the camera to get the report for.
* @param {DateRangeDTO} range The range for the report.
* @param {number} granularity Filter by seconds
* @return {HourlySummaryReportDTO[]} The summary report for all lanes.
*/
  async getPedestrianGranular(systemID, cameraID, range, granularity = 60) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, granularity })
    return await this._executeForCamera(cameraInfo, "data/pedestrian-granular", query)
  }
  /**
* Get the summary for all lanes.
*
* @param {string} systemID The system id for the camera to get the report for.
* @param {string} cameraID The id of the camera to get the report for.
* @param {DateRangeDTO} range The range for the report.
* @return {HourlySummaryReportDTO[]} The summary report for all lanes.
*/
  async getPedestrianDailySummary(systemID, cameraID, range) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
    return await this._executeForCamera(cameraInfo, "data/pedestrian-daily", query)
  }

  /**
   * 
   * @param {string} camera_id The camera id
   * @returns Returns the zones belonging to the camera
   */
  async getCameraZones(camera_id, system_id) {
    return await this._executeJSON(`cameras/${system_id}/${camera_id}/zones`)
  }

  /**
  * Get the origin-destination matrix of all vehicles and/or pedestrians.
  *
  * @param {string} systemID The system id for the camera to get the report for.
  * @param {string} cameraID The id of the camera to get the report for.
  * @param {DateRangeDTO} range The range for the report.
  * @param {float} minDuration minimum duration for the report
  * @param {string|string[]} vehicles The list of vehicle types to filter on.
  * @param {string|string[]} zone_id The list of vehicle types to filter on.
  * * @param {string} url name to get the appropriate URL
  * @return {VehicleTrajectoryDTO} The traffic trajectories.
  */
  async getOriginDestinationMatrix(systemID, cameraID, range, url, minDuration, vehicles, zone_id) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
    return await this._executeForCamera(cameraInfo, `data/${url ? url : 'transitflow'}`, { ...query, min_duration: minDuration })
  }

  /**
   * Get the traffic flow details, per lane.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   * @param {string|string[]} carriageways The list of carriageways to filter on.
   * @param {string|string[]} lanes The list of lanes to filter on.
   * @param {string|string[]} vehicles The list of vehicle types to filter on.
   *
   * @return {LanesSummaryReportDTO} The flow details between the lanes.
   */
  async getOriginDestinationReport(systemID, cameraID, range, carriageways, lanes, vehicles) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range, carriageways, lanes, vehicles })
    return await this._executeForCamera(cameraInfo, "data/transitflow", query)
  }

  /**
   * Get the trajectories of all vehicles and/or pedestrians.
   *
   * @param {string} systemID The system id for the camera to get the report for.
   * @param {string} cameraID The id of the camera to get the report for.
   * @param {DateRangeDTO} range The range for the report.
   *
   * @return {VehicleTrajectoryDTO} The traffic trajectories.
   */
  async getPedestrianTrajectory(systemID, cameraID, range) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
    return await this._executeForCamera(cameraInfo, "data/pedestrian-trajectory", query)
  }

  /**
  * Get the Flow-Density-Speed report of camera lanes
  *
  * @param {string} systemID The system id for the camera to get the report for.
  * @param {string} cameraID The id of the camera to get the report for.
  * @param {DateRangeDTO} range The range for the report.
  *@param {number} current The current index of the lane to generate chart for.
  * @return {FlowDensityReport} The flow density report.
  */
  async getFDSLaneMetrics(systemID, cameraID, range, current = 0) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
    const data = await this._executeForCamera(cameraInfo, "data/lane-metrics", query)

    const chartPlots = {}
    const timeplot = {}
    let maxOccupany = 0;
    // Pack the data, so it is ready to show in a line chart
    data.forEach((res, ind) => {
      const { lane_name } = res
      const plot = {
        rate_density: [],
        speed_density: [],
        speed_flow_rate: []
      }
      // Prepare the data for the charts
      const charts = {
        flow_rate: [],
        avgSpeed: [],
        density: [],
        occupancy: [],
        congestion: [],
      }

      res.data?.forEach(item => {
        // Parse the time
        const time = momentIgnoreTimezone(item.start).valueOf()

        // get the max occupancy
        if (item.occupancy_percentage > maxOccupany) {
          maxOccupany = item.occupancy_percentage
        }

        plot.rate_density.push([parseFloat(parseFloat(item.density).toFixed(3)), item.flow_rate, time])
        plot.speed_density.push([parseFloat(parseFloat(item.density).toFixed(3)), item.avg_speed, time])
        plot.speed_flow_rate.push([parseFloat(parseFloat(item.flow_rate).toFixed(3)), item.avg_speed, time])

        // Populate the chart data
        charts.flow_rate.push([time, item.flow_rate])
        charts.avgSpeed.push([time, Math.round(parseFloat(item.avg_speed))])
        charts.density.push([time, parseFloat(item.density).toFixed(3)])
        charts.occupancy.push([time, parseFloat(parseFloat(item.occupancy_percentage).toFixed(3))])
        charts.congestion.push([time, parseFloat(parseFloat(item.congestion_percentage).toFixed(3))])
      })

      chartPlots[lane_name] = plot
      timeplot[lane_name] = charts
    })
    return { data, chartPlots, charts: timeplot }
  }

  /**
  * Get the Flow-Density-Speed report of camera lanes
  *
  * @param {string} systemID The system id for the camera to get the report for.
  * @param {string} cameraID The id of the camera to get the report for.
  * @param {DateRangeDTO} range The range for the report.
  *
  * @return {FlowDensityReport} The flow density report.
  */
  async getFDSCarriageWayMetrics(systemID, cameraID, range, current = 0) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
    const data = await this._executeForCamera(cameraInfo, "data/carriageway-metrics", query)

    const chartPlots = {}
    const timeplot = {}
    let maxOccupany = 0;
    // Pack the data, so it is ready to show in a line chart
    data.forEach((res, ind) => {
      const { carriageway_name, carriageway_uuids } = res
      const plot = {
        rate_density: [],
        speed_density: [],
        speed_flow_rate: []
      }
      // Prepare the data for the charts
      const charts = {
        flow_rate: [],
        avgSpeed: [],
        density: [],
        occupancy: [],
        congestion: [],
      }

      res.data?.forEach(item => {
        // Parse the time
        const time = momentIgnoreTimezone(item.start).valueOf()

        // get the max occupancy
        if (item.occupancy_percentage > maxOccupany) {
          maxOccupany = item.occupancy_percentage
        }

        plot.rate_density.push([parseFloat(parseFloat(item.density).toFixed(3)), item.flow_rate, time])
        plot.speed_density.push([parseFloat(parseFloat(item.density).toFixed(3)), item.avg_speed, time])
        plot.speed_flow_rate.push([parseFloat(parseFloat(item.flow_rate).toFixed(3)), item.avg_speed, time])

        // Populate the chart data
        charts.flow_rate.push([time, item.flow_rate])
        charts.avgSpeed.push([time, Math.round(parseFloat(item.avg_speed))])
        charts.density.push([time, parseFloat(item.density).toFixed(3)])
        charts.occupancy.push([time, parseFloat(parseFloat(item.occupancy_percentage).toFixed(3))])
        charts.congestion.push([time, parseFloat(parseFloat(item.congestion_percentage).toFixed(3))])
      })

      chartPlots[carriageway_name] = plot
      timeplot[carriageway_name] = charts
    })
    let occupancy_percent = maxOccupany !== 0 ? Math.round(100 / maxOccupany) : 100

    return { data, chartPlots, charts: timeplot, occupancy_percent }
  }

  /**
   * 
   */
  async getLiveSessions(systemID, cameraID, range) {
    const { cameraInfo, query } = this._parseParams({ systemID, cameraID, range })
    return await this._executeForCamera(cameraInfo, "live-sessions", query)
  }

  /**
   * Get an image using the same protocol as for all other API calls.
   *
   * @param {string} src The URL to the image.
   *@param {any} signal Axios signal to pass to cancel the request
   * @return {?Promise<string>} The URL to the blob image.
   */
  async getImage(src, signal) {

    // Already data or blob URL
    if (src?.match(/^(data|blob):/) ? src : null) {
      return src
    }

    // Convert to blob URL
    const response = await this._execute(src, {}, { signal })
    if (response) {
      return URL.createObjectURL(await response.blob())
    }

    return null
  }
}

/**
 * Get the reference to the API service.
 */
const useAPI = (): ApiService => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { refresh, auth } = useSelector((state: ReduxStore) => state.auth)

  const [api] = useState(new ApiService(refresh, auth, history, dispatch))
  return api
}

export default useAPI

/**
 * Format array types for the GET query.
 *
 * @param {string|string[]} value A single value of an array of values to use.
 * @param {string} name The name of the GET parameter.
 *
 * @return {"&queryId=${string}"} The GET params for queryId.
 */
export const formatUrlArray = (value = null, name) => {

  // Guard: Skip empty values
  if (isEmpty(value)) {
    return ""
  }

  // Handle strings
  if (typeof value === "string") {
    value = [value]
  }

  // Build the query
  return value.reduce((query, val) => query + (val != null ? `&${name}=${val}` : ""), "")
}
