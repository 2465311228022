import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import LargeCard from "components/Structure/LargeCard";
import { createNewTenantSchema, formikDefault } from "utils/forms-schema";
import { SelectInput, StringInput } from "../../../../components/Reports/Fields";
import countries from "../../../../config/countries"

export const AddTenantForm = ({ tenant, updateTenant }) => {
  const history = useHistory();

  // Define the form
  const formik = useFormik({
    ...formikDefault,
    initialValues: tenant,
    validationSchema: createNewTenantSchema,
    onSubmit: tenant => updateTenant({ ...tenant, step: 2 })
  });

  // Set options for select inputs
  const selectCountries = countries.map(country => ({ value: country.name, label: country.name }));
  const selectPhonePrefix = countries.map(country => ({ value: country.dial_code, label: `${country.dial_code} (${country.code})` }));

  return (
    <div className="col-xl-6 offset-xl-3">
      <LargeCard title="Create a new tenant">
        <Form noValidate onSubmit={formik.handleSubmit}>

          <h4 className="form-header">Tenant details</h4>
          <StringInput placeholder="Tenant name" name="name" formik={formik} />
          <StringInput placeholder="Address" name="address" formik={formik} />
          <SelectInput placeholder="Country" name="country" options={selectCountries} formik={formik} />

          <h4 className="form-header">Contact person</h4>
          <div className="row">
            <div className="col-md-6"><StringInput placeholder="First Name" name="firstName" formik={formik} /></div>
            <div className="col-md-6"><StringInput placeholder="Last Name" name="lastName" formik={formik} /></div>
          </div>
          <StringInput placeholder="Email" name="email" formik={formik} />
          <div className="row">
            <div className="col-md-3"><SelectInput placeholder="Dial code" name="dial_code" options={selectPhonePrefix} formik={formik} /></div>
            <div className="col-md-9"><StringInput placeholder="Phone number" name="phone" formik={formik} /></div>
          </div>

          <div className="form-buttons">
            <button type="button" className="btn btn-secondary" onClick={() => history.go(-1)}>Cancel</button>
            <button type="submit" className="btn btn-primary">Next</button>
          </div>
        </Form>
      </LargeCard>
    </div>
  );
};
