import { ExportToCsv, LoadingWrapper } from "components";
import { allowEmpty } from "components/LoadingWrapper/LoadingWrapper";
import LargeCard from "components/Structure/LargeCard";
import { Table } from "react-bootstrap"
import React, { useContext, useState } from "react";
import { apiTime, formatDateRange } from "utils/time";
import { DateRange } from "@material-ui/icons";
import { DateRangePickerModal } from "components";
import { CiCircleMore } from 'react-icons/ci'
import { FiArrowUp, FiArrowDown } from 'react-icons/fi'
import { HiOutlineArrowNarrowUp, HiOutlineArrowNarrowDown } from 'react-icons/hi'
import EventsFilter from "./EventsFilter";
import moment from "moment";
import { eventListToCsv, getReadableTime, setMappedData } from "utils/functions";
import { EventsContext } from "./EventsLayout";
import { MoreFilter } from "components/Events/Fields";
import { useEffect } from "react";
import PaginationBar from "components/Pagination/PaginationBar";
import useAPI from "services/ApiService";
import TableComp from "./TableComp";


export const eventSorts = [
  { name: 'Number of Stopped Vehicles per zone', url: 'vehicles-stopped-count' },
  { name: 'Hourly Number of Stopped Vehicles per zone', url: 'vehicles-stopped-count-granular' },
  { name: 'List of Stopped Vehicles per zone', url: 'vehicles-stopped-list' },
  { name: 'Number of Stopped Vehicles per zone and vehicle type', url: 'vehicletypes-stopped-count' },
  { name: 'Hourly Number of Stopped Vehicles per zone and vehicle type', url: 'vehicletypes-stopped-count-granular' },
  { name: 'Transit flow', url: 'transitflow' },
  { name: 'Transit matrix hourly', url: 'transitmatrix-granular' },
  { name: 'Transit matrix hourly by vehicle types', url: 'transitmatrix-vehicletype-granular' },
]

const EventsPage = () => {
  const api = useAPI()
  const { setDateRangeModal, dateRange, dateRangeModal,
    vehicleData, selectedAPI, zoneIds,
    setSelectedAPI, isLoading, zones, setZones, setZoneIds, page, setPage } = useContext(EventsContext)
  const [filteredData, setFilteredData] = useState(null)
  const [mapData, setmapData] = useState([])
  // all zones belonging to a camera
  const [vehicleFilter, setVehicleFilter] = useState([])
  const [csv, setCsv] = useState([])
  const pagination = ['vehicles-stopped-list', 'vehicletypes-stopped-count-granular', 'vehicles-stopped-count-granular', 'vehicletypes-stopped-granular-15', 'vehicletypes-stopped-count']
  const vehicles = ['vehicletypes-stopped-count-granular', 'vehicletypes-stopped-count', 'vehicletypes-stopped-granular-15']
  // lower and upper boundary for pagination
  let lower = (page - 1) * 20
  let upper = page * 20

  // Date Range filter in reports pages
  const DateRangeFilterHeader =
    <>
      <div
        className="row date-range justify-content-between w-1/5 border rounded align-self-center mr-5 cursor-pointer px-5 py-2"
        onClick={() => setDateRangeModal(true)}
        title="Current date range"
      >
        <div className="mr-3 font-size-lg">
          <span>{formatDateRange(dateRange.start, dateRange.end)}</span>
        </div>
        <DateRange title="date range" />
      </div>
      <div className="csv">
        <DateRangePickerModal show={[dateRangeModal, setDateRangeModal]} />
        {selectedAPI === 'vehicles-stopped-list' && <ExportToCsv data={csv} filename="Events" />}
      </div>

    </>

  const getZoneEvents = (zone) => {
    setSelectedAPI('vehicles-stopped-list')
    if (!zoneIds.includes(zone.uuid)) {
      setZoneIds(prev => [...prev, zone.uuid])
      setZones(prev => [...prev, { value: zone.uuid, label: zone.name, id: zone.uuid }])
    }

  }

  console.log({ vehicleData, mapData, selectedAPI, vehicleFilter, zoneIds })
  useEffect(() => {
    if (vehicleData) {
      setFilteredData(vehicleData)
      let res = setMappedData(vehicleData, selectedAPI, vehicles)
      if (pagination.includes(selectedAPI)) {
        setmapData(res)
      }
      setPage(1)
      let zones = []
      vehicleData?.map(item => zones.push(item.zone))
      setVehicleFilter(zones)
    }

  }, [vehicleData, selectedAPI])

  // CSV table
  useEffect(() => {
    let arr = ['Date', 'Time', 'Zone', 'Type', 'Tracked Items', 'Avg Time in Zone'];
    if (filteredData) {
      setCsv(eventListToCsv(filteredData, arr))
    }
  }, [filteredData])

  useEffect(() => {
    setZones(zones.map(zone => {
      return { value: zone.uuid, label: zone.name, id: zone.uuid }
    }))
    return () => {
      setSelectedAPI('vehicles-stopped-count')
      setZoneIds([])
      setZones([])
    }
  }, [])

  return (
    <LargeCard title="Events" headerOptions={DateRangeFilterHeader}>
      <EventsFilter
        vehicleFilter={vehicleFilter}
      />
      <LoadingWrapper state={allowEmpty(isLoading)} onEmpty={"Data currently unavailable"}>
        {vehicleData && mapData ? (
          <>
            {selectedAPI === 'vehicles-stopped-count' ? <Table bordered hover={false} className="v-align">
              <thead>
                <tr>

                  <th width="*">Zone</th>
                  <th width="200">
                    Type
                  </th>
                  <th width="250">Avg Time in zone</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((camera) => (
                  <>
                    {camera?.data && <tr key={camera?.zone?.uuid}>
                      <td>{camera?.zone?.name}</td>
                      <td>{camera?.zone?.type}</td>
                      <td>{getReadableTime(camera?.data[0]?.avg_duration)}</td>
                      <td>
                        <span className="mr-2">{camera?.data[0]?.count}</span>
                        <span className="cursor-pointer" onClick={() => getZoneEvents(camera?.zone)}>
                          <CiCircleMore className="more" /></span>
                      </td>
                    </tr>}
                  </>

                ))}
              </tbody>
            </Table> :
              <TableComp
                mapData={mapData}
                setMappedData={setmapData}
                selectedApi={selectedAPI} />
            }
          </>

        ) : (
          <h1 className="text-center my-10">No events captured yet</h1>
        )}
        {pagination.includes(selectedAPI) && vehicleData && <PaginationBar arr={mapData} />}
      </LoadingWrapper>
    </LargeCard >
  );
};

export default EventsPage;
