import moment from 'moment-timezone';
import store from "../redux/store"
import { compareAsc, isToday, isYesterday, format, formatISO } from "date-fns"
import { buildFromPreset, DefaultPresetRanges } from "../components/DateRangePicker/Presets"
import { isEmpty, isNotEmpty } from "./functions"
import { leadingZeros } from "./strings"

/**
 * @typedef {{
 *    name: string,
 *    abbr: string,
 *    code: string,
 *    index: int
 * }} WeekDayDTO
 */

/** The list of supported timezone conversions for the frontend. */
export const TimeZoneConvert = {
  GMT: "GMT",
  ORIGINAL: "ORIGINAL",
  LOCAL: "LOCAL"
}

/** The time format to use for the API requests. */
const API_TIME_FORMAT = "YYYY-MM-DD[T]HH:mm"

/** The time format to use for the computer usage. */
const SYSTEM_TIME_FORMAT = "YYYYMMDDHHmm"

/** The time format to use for the humans to see. */
const HUMAN_TIME_FORMAT = "DD/MM/YYYY HH:mm"

/** The long time format to use for the humans to see. */
const HUMAN_LONG_TIME_FORMAT = "E, dd. MMM yyyy."

/** The current date time. */
export const NOW = format(new Date(), "yyyy-MM-dd HH:mm:ss")

/**
 * Get the current timestamp with an optional offset.
 *
 * @param {number} offset The number of seconds to append.
 *
 * @return {number} The number of seconds after the epoch.
 */
export const now = (offset: number = 0): number =>
  Math.round(new Date().valueOf() / 1000) + offset

/**
 * Wait for a certain number of milliseconds.
 *
 * @param {int} milliseconds The number of milliseconds to wait, defaults to 2000.
 *
 * @return {Promise} The promise that resolves after the requested period.
 */
export const sleep = async (milliseconds = 2000) =>
  new Promise((resolve) => {
    setTimeout(() => {
      console.log("Sleep end:", milliseconds)
      resolve(true)
    }, milliseconds)
  })

/**
 * Format the time for the API request.
 *
 * @param {Date} time The time to format.
 *
 * @return {string} The API date and time format used for API calls.
 */
export const apiTime = (time) =>
  parse(time, true).format(API_TIME_FORMAT)

/**
 * Format the time for the system.
 *
 * @param {Date} time The time to format.
 *
 * @return {string} The system friendly date and time format.
 */
export const systemTime = (time) =>
  parse(time, true).format(SYSTEM_TIME_FORMAT)

/**
 * Format the time for the humans.
 *
 * @param {Date} time The time to format.
 * @param {boolean} tz True to use the timezone, false to ignore the timezone.
 *
 * @return {string} The human friendly date and time format.
 */
export const humanTime = (time, tz = false) =>
  parse(time, tz).format(HUMAN_TIME_FORMAT)

/**
 * Format the date for the humans.
 *
 * @param {Date} time The time to format.
 * @param {boolean} tz True to use the timezone, false to ignore the timezone.
 *
 * @return {string} The human friendly date format.
 */
export const humanDate = (time, tz = false) =>
  humanTime(time, tz).substring(0, 10)

/**
 * Get only hour and minute.
 *
 * @param {Date} time The time to format.
 * @param {boolean} tz True to use the timezone, false to ignore the timezone.
 *
 * @return {string} Only HH:mm of the supplied time.
 */
export const hourMinute = (time, tz = false) =>
  humanTime(time, tz).substring(11, 16)

/**
 * Format the hours and minutes for the humans.
 *
 * @param {Date} time The time to format.
 * @param {boolean} replaceMidnight True to show 24:00 instead of 00:00.
 * @param {boolean} tz True to use the timezone, false to ignore the timezone.
 *
 * @return {string} The human friendly time format.
 */
export const humanDayTime = (time, replaceMidnight = false, tz = false) =>
  humanTime(time, tz)
    .substring(11, 16)
    .replace("00:00", replaceMidnight ? "24:00" : "00:00")

/**
 * Print the month as a human-friendly string.
 *
 * @param {int} index The numeric index of the month, where January is the number 1.
 * @param {boolean} long True to use the long format, false for the first three letters.
 *
 * @return {string} The human-friendly month name.
 */
export const monthName = (index, long = false) => {

  // Guard: proper index
  if (index < 1 || index > 12) {
    return ""
  }

  // Define the list of months
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  // Return the whole month name or just the first three letters
  return long ? months[index] : months[index].substring(0, 3)
}

/**
 * Print a date as a human friendly.
 *
 * @param {Date|string} time The time to represent as a string, either a Date object or a string in format 'YYYY-MM-DD hh:mm:ss'.
 * @param {boolean} long True to output the full name of the month, false to show only first three letters.
 *
 * @return {string} The human-friendly formatted date.
 */
export const asDate = (time, long = false) => {

  // Guard: Supplied time must exist
  if (!time) {
    return ""
  }

  // Handle time as a Date object
  if (typeof time === "object") {
    time = `${time.getFullYear()}-${(time.getMonth() + 1 + "").padStart(2, "0")}-${(time.getDate() + "").padStart(2, "0")}`
  }

  // Extract the time info
  const match = time.match(/(\d{4})-(\d{2})-(\d{2})( +(\d{2}):(\d{2}):(\d{2}))?/)
  if (!match) {
    return ""
  }

  const split = {
    year: match[1] * 1,
    month: match[2] * 1 - 1,
    day: match[3] * 1,
    hour: match[5] * 1,
    minute: match[6] * 1,
    second: match[7] * 1
  }

  // Build the date, but show year only if different from the current one
  return [monthName(split.month, long), split.day, split.year !== new Date().getFullYear() ? split.year + "." : ""].join(" ").trim()
}

/**
 * Add a number of days to an initial date.
 *
 * @param {int} days The number of days to add (can be negative as well).
 * @param {?Date} initialDate The date to add the days to, or null to use today.
 *
 * @return {Date} The date object with the added days.
 */
export const addDays = (days, initialDate = null) => {
  let current_date = new Date()
  // Default date to now and make sure the initialDate is cloned and never changed
  const date = initialDate ? new Date(initialDate.getTime()) : current_date

  // Append the number of days
  date.setDate(date.getDate() + days)
  date.setHours(current_date.getHours())
  date.setMinutes(current_date.getMinutes())
  date.setSeconds(current_date.getSeconds())
  return date
}

/**
 * Convert a date range into a URL safe string.
 *
 * @param {DateRangeDTO} dateRange The date range to serialize.
 *
 * @return {string} The string representation of the date range.
 */
export const serializeDateRange = (dateRange) =>
  dateRange != null ? systemTime(dateRange.start) + "-" + systemTime(dateRange.end) : ""

/**
 * Extract a date range into from a URL safe string.
 *
 * @param {string} value The string representation of the date range.
 *
 * @return {DateRangeDTO} The resulting date range.
 */
export const deserializeDateRange = (value) => {

  // Try the predefined values first
  // for (const preset of DefaultPresetRanges) {
  //   if (preset[0].replace(/ /g, "-") === value.replace(/ /g, "-")) {
  //     return buildFromPreset(preset)
  //   }
  // }
  for (const preset of DefaultPresetRanges()) {
    if (preset[0].replace(/ /g, "-") === value.replace(/ /g, "-")) {
      return buildFromPreset(preset)
    }
  }

  const split = value.split("-")
  return {
    start: moment(split[0], SYSTEM_TIME_FORMAT).toDate(),
    end: moment(split[1], SYSTEM_TIME_FORMAT).toDate()
  }
}

/**
 * Parse the input range as either a predefined preset or a custom defined range.
 *
 * @param {string} range The name of the range, or a custom defined range created with serializeDateRange().
 *
 * @return {DateRangeDTO} The resulting date range.
 */
export const parseDateRange = (range) => {

  // Try to match in presets
  // for (const preset of DefaultPresetRanges) {
  //   if (preset[0] === range) {
  //     return buildFromPreset(preset)
  //   }
  // }
  for (const preset of DefaultPresetRanges()) {
    if (preset[0] === range) {
      return buildFromPreset(preset)
    }
  }

  // Parse the custom defined range
  return deserializeDateRange(range)
}

/**
 * Show the currently selected date range as a human friendly string.
 *
 * @param {?DateRangeDTO} dateRange The active date range.
 * @param {?string} preset The preset to use instead of the range.
 *
 *  @return {string} The string to be shown to the user.
 */
export const showSelectedDateRange = (dateRange, preset = null) => {

  // Preset is in the date range
  if (dateRange?.name != null) {
    preset = dateRange.name
  }

  // Just return the preset
  if (preset != null) {
    return preset
  }

  // Guard: Both dates must exist
  if (dateRange == null || dateRange.start == null || dateRange.end == null) {
    return ""
  }

  // Check if the two dates are the same
  if (compareAsc(dateRange.start, dateRange.end) === 0) {
    let prefix = ""

    // Today
    if (isToday(dateRange.start)) {
      prefix = "Today, "
    }

    // Yesterday
    if (isYesterday(dateRange.start)) {
      prefix = "Yesterday, "
    }

    // Some other date
    return prefix + asDate(dateRange.start, true)
  }

  // Range
  const from = asDate(dateRange.start)
  const to = asDate(dateRange.end)
  return from + (from !== to ? " - " + to : "")
}

/**
 * Clear the timezone value from the datetime string.
 *
 * @param {string|int} time The datetime string ending with +hh:mm.
 *
 * @return {string|int} The datetime string with the timezone modifier set to 00:00.
 */
export const clearTimeZone = (time) =>
  typeof time === "string"
    ? time.replace("T", " ").replace(/\+[0-9][0-9]:[0-9][0-9]$/, " ")
    : time

/**
 * Parse a time into a moment time, but ignore the timezone.
 *
 * @param {string} time The datetime string ending with +hh:mm.
 *
 * @return {moment.Moment} The instance of moment time.
 */
export const momentIgnoreTimezone = (time) =>
  moment(clearTimeZone(time), `${time}`.match(/^\d+$/) ? "X" : "YYYY-MM-DD hh:mm:ss")

/**
 * Parse a time into a moment time.
 *
 * @param {string} time The datetime string to parse.
 * @param {boolean} tz True to use the timezone, false to ignore the timezone.
 *
 * @return {moment.Moment|moment.Moment}
 */
export const parse = (time, tz = false) => tz
  ? moment(time)
  : momentIgnoreTimezone(time)

/**
 * convert utc time to the time in the given timezone
 * @param time The start time of the camera
 * @param timezone The timezone of the camera's location
 * @param updateFile Check if its a updateFile req
 * @return The time in the user's timezone
 */
export const applyTimeZone = (time, timezone, updateFile = false) => {
  const isUtc = time?.includes('Z')

  // Convert to camera's zone time
  const localTime = moment.tz(time, timezone);

  // Format the time in the desired format
  const formattedTime = updateFile ? time : isUtc ? localTime.format("DD/MM/YYYY HH:mm (Z)") : localTime.format("YYYY-MM-DD HH:mm");
  return formattedTime
}

/**
 * Get a week day info on a week day index, where Monday is 1.
 *
 * @param {int} index The index of the weekday, starting from 1 for Monday to 7 for Sunday.
 *
 * @return {WeekDayDTO} The requested week day.
 */
export const getWeekDay = (index) => {

  // Map the week day index into name
  const map = [null, "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

  return {
    index,
    name: map[index],
    abbr: map[index].substring(0, 3),
    code: map[index].substring(0, 3).toLowerCase()
  }
}

/**
 * Get all the possible week days.
 *
 * @return {WeekDayDTO[]} The list of all available week days, starting from 1 for Monday to 7 for Sunday.
 */
export const x = () => [1, 2, 3, 4, 5, 6, 7].map((index) =>
  getWeekDay(index))

/**
 * Extract info about the week day from a data.
 *
 * @param {timeString} time The datetime to parse.
 * @param {boolean} tz True to use the timezone, false to ignore the timezone.
 *
 * @return {WeekDayDTO} The parsed week day.
 */
export const getWeekDayFromTime = (time, tz = false) =>
  getWeekDay(parse(time, tz).isoWeekday())

/**
 * Format date range value in date range filters.
 *
 * @param {timeString} start The start date.
 * @param {timeString} end The end date.
 *
 * @return {string} The format to be shown to the user.
 */
export const formatDateRange = (start, end) => {

  // Empty
  if (isEmpty(start) || isEmpty(end)) {
    return ""
  }

  // Format for humans
  let from = start ? format(start, HUMAN_LONG_TIME_FORMAT) : ""
  let to = end ? format(end, HUMAN_LONG_TIME_FORMAT) : ""

  // Do not show the current year
  const year = format(new Date(), "yyyy") + "."
  if (from.substring(from.length - 5) === year) {
    from = from.replace(/ \d+\.$/, "")
  }
  if (to.substring(to.length - 5) === year) {
    to = to.replace(/ \d+\.$/, "")
  }

  // Start hours
  const startHours = hourMinute(start)
  from += " " + startHours


  // End hours
  const endHours = hourMinute(end)
  to += " " + endHours


  // // Start hours
  // const startHours = hourMinute(start)
  // if (startHours !== "00:00") {
  //   from += " " + startHours
  // }

  // // End hours
  // const endHours = hourMinute(end)
  // if (endHours !== "23:59") {
  //   to += " " + endHours
  // }

  // Do not show same values
  return from + (isNotEmpty(to) && to !== from
    ? ` - ${to}`
    : "")
}

/** @return {int} The current UNIX timestamp. */
export const timestamp = () =>
  Math.floor(new Date().getTime() / 1000)

/**
 * Prepare date range value for ApiService.
 *
 * @return {object} The date range formatted to ISO.
 */
export const prepareDateRangeApiService = () => {
  const dateRange: DateRangeDTO = store.getState().dateRange

  return {
    from: formatISO(dateRange.start),
    to: formatISO(dateRange.end),
    granularity: 60
  }
}

/**
 * Prepare date range value.
 *
 * @return {object} The date range formatted to ISO.
 */
export const prepareDateRange = () => {
  const range = prepareDateRangeApiService()
  range.from = encodeURIComponent(range.from)
  range.to = encodeURIComponent(range.to)

  return range
}

// noinspection JSUnresolvedFunction
/**
 * Get the client's timezone name.
 *
 * @return {string} The timezone name.
 */
export const getTimezoneName = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone || ""

/**
 * Get the client's timezone offset.
 *
 * @return {number} The timezone offset, in minutes.
 */
export const getTimezoneOffset = (): number => {
  const now = new Date()

  // Do we have access to the direct timezone
  if (now.getTimezoneOffset) {
    return -now.getTimezoneOffset()
  }

  // Load the timezone and make sure there is something
  const timezone = new Date().toString().match(/([-+])([0-9][0-9])([0-9][0-9])\s/)
  if (!timezone) {
    return 0
  }

  // Convert to minutes
  return (timezone[1] === "-" ? -1 : 1) * (60 * timezone[2] + 1 * timezone[3])
}

/**
 * Apply a timezone offset to a time.
 *
 * @param {string|moment|Date} time The original time.
 * @param {int} offset The offset to add, in minutes.
 *
 * @return {moment} The updated time.
 */
export const applyTimezoneOffset = (time, offset) =>
  parse(time).add(offset, "minutes")

/**
 * Convert timezone offset to timezone suffix.
 *
 * @param {int} offset The timezone offset in minutes.
 *
 * @return {string} The offset in format "+HH:MM".
 */
export const formatTimezoneOffset = (offset) =>
  (offset > 0 ? "+" : "-") + leadingZeros(Math.floor(offset / 60)) + ":" + leadingZeros(offset % 60)

/**
 * Show the time in a user defined timezone.
 *
 * @param {"GMT"|"ORIGINAL"|"LOCAL"} timezone The timezone user has selected to show time in.
 * @param {string|moment|Date}time The original time,
 * @param {int} offset The original timezone.
 *
 * @return {string} The human-friendly time to show.
 */
export const applyCustomTimezoneOffset = (timezone, time, offset) => {

  // Get the proper increment
  let increment
  switch (timezone) {
    case TimeZoneConvert.LOCAL:
      increment = getTimezoneOffset()
      break
    case TimeZoneConvert.ORIGINAL:
      increment = offset
      break
    default:
    case TimeZoneConvert.GMT:
      increment = 0
  }

  return applyTimezoneOffset(time, increment)
}

export function validateTime(value) {
  // Get the selected time value
  let selectedTime = value;


  // Convert the selected time to minutes
  let parts = selectedTime.split(':');
  let hours = parseInt(parts[0]);
  let minutes = parseInt(parts[1]);
  let totalMinutes = hours * 60 + minutes;

  // Round the selected time to the nearest 15-minute interval
  let roundedMinutes = Math.round(totalMinutes / 15) * 15;
  hours = Math.floor(roundedMinutes / 60);
  minutes = roundedMinutes % 60;

  // Format hours and minutes to ensure leading zeros
  let formattedHours = hours.toString().padStart(2, '0');
  let formattedMinutes = minutes.toString().padStart(2, '0');

  // Set the input value to the rounded time
  let new_val = formattedHours + ':' + formattedMinutes;
  return new_val
}

export function validateDateTime(dateStr) {
  // Parse the input date string
  const date = new Date(dateStr);

  // Get the current minutes
  const minutes = date.getMinutes();

  // Round minutes to the nearest multiple of 15
  let roundedMinutes = Math.floor(minutes / 15) * 15;
  if (minutes % 15 >= 8) {  // if the remainder is 8 or more, round up
    roundedMinutes += 15;
  }

  // Adjust the date based on the rounded minutes
  if (roundedMinutes === 60) {
    date.setHours(date.getHours() + 1);
    roundedMinutes = 0;
  }
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);
  date.setMilliseconds(0);



  // Manually format the output date string
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = daysOfWeek[date.getDay()];
  const month = monthsOfYear[date.getMonth()];
  const dayOfMonth = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutesStr = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // Extract the timezone offset in the required format
  const timezoneOffset = -date.getTimezoneOffset();
  const sign = timezoneOffset >= 0 ? '+' : '-';
  const absOffset = Math.abs(timezoneOffset);
  const hoursOffset = String(Math.floor(absOffset / 60)).padStart(2, '0');
  const minutesOffset = String(absOffset % 60).padStart(2, '0');
  const timezone = `GMT${sign}${hoursOffset}${minutesOffset}`;

  // Manually extract and format the time zone name from the input date string
  const timeZoneName = dateStr?.toString().match(/\(([^)]+)\)/)[1];

  // Construct the formatted date string
  const formattedDateStr = `${day} ${month} ${dayOfMonth} ${year} ${hours}:${minutesStr}:${seconds} ${timezone} (${timeZoneName})`;

  return new Date(formattedDateStr);
}


export function validateDate(input) {
  if (!isDateValid(input)) {
    return new Date()
  }
  // Get the selected date value
  let selectedDate = new Date(input);

  // Round the time of the selected date to the nearest 15-minute interval
  let totalMinutes = selectedDate.getHours() * 60 + selectedDate.getMinutes();
  let roundedMinutes = Math.round(totalMinutes / 15) * 15;
  selectedDate.setHours(Math.floor(roundedMinutes / 60));
  selectedDate.setMinutes(roundedMinutes % 60);
  selectedDate.setSeconds(0);
  selectedDate.setMilliseconds(0);

  // Return the date with the formatted time
  return selectedDate;
}

export function convertDateToTime(date) {

  if (!isDateValid(date)) {
    return
  }
  // Get the current hour and minute
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Ensure both hour and minute are two digits long
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.toString().padStart(2, '0');

  // Combine the hour and minute with a colon
  let timeString = `${hours}:${minutes}`;

  // Outputs the current time in h:mm format
  return timeString
}

export function convertTimeToValidDate(timeStr, defaultDateString) {
  let time;
  if (!isDateValid(timeStr)) {
    time = '00:00'
  }
  else {
    time = timeStr
  }
  // Parse the default date
  let defaultDate = new Date(defaultDateString);

  // Extract hours and minutes from the time string
  let [hours, minutes] = time.split(':').map(Number);

  // Set the extracted time on the default date
  defaultDate.setHours(hours, minutes);

  // Return the new date with the set time
  return defaultDate;
}


function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}


/**
 * Validates two time strings to ensure they are in a valid 24-hour format.
 * Converts "24:00" to "23:59" before validation.
 * 
 * @param {string} timeStr1 - The first time string to validate.
 * @param {string} timeStr2 - The second time string to validate.
 * @returns {boolean} Returns true if both time strings are valid, false otherwise.
 * 
 * @example
 * isValidTime("12:30", "14:45") // returns true
 * isValidTime("24:00", "09:30") // returns true (24:00 is converted to 23:59)
 * isValidTime("25:00", "08:30") // returns false
 */
export function isValidTime(timeStr1, timeStr2) {
  const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  
  // Function to convert 24:00 to 23:59
  const convertTime = (timeStr) => {
    return timeStr === '24:00' ? '23:59' : timeStr;
  };

  // Convert and validate both time strings
  const convertedTime1 = convertTime(timeStr1);
  const convertedTime2 = convertTime(timeStr2);
  
  const validTime = regex.test(convertedTime1) && regex.test(convertedTime2);
  
  return validTime;
}



