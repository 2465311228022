import * as Yup from "yup"

// Default formik setup
export const formikDefault = {
  validateOnChange: false,
  validateOnBlur: false
}

// Common regexp checks
const locationRegExp = /^[+-]?[0-9]{1,3}(\.[0-9]+)?$/
const validPhone = [/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Please supply a valid phone number"]

/** Strong password check */
Yup.addMethod(Yup.string, "strongPassword", function () {
  const minLength = 6
  return this
    .min(minLength, `Password must contain at least ${minLength} characters`)
    .matches(/[a-z]/, "Please include at least one letter")
    .matches(/[0-9]/, "Please include at least one digit")
    .matches(/[\W]/, "Please include at least one special character")
})

// Create New user schema
export const createNewUserSchema = Yup.object().shape({
  firstName: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* First name is required"),
  lastName: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Last name is required"),
  email: Yup
    .string()
    .email("Please supply a valid email address")
    .required("* Email is required"),
  tenants: Yup
    .array()
    .min(1, "* User must belongs to one tenant at least")
    .required("* User must belongs to one tenant at least"),
  password: Yup
    .string()
    .required("* You must set a password")
    ["strongPassword"](),
  passwordConfirmation: Yup
    .string()
    .required("* Passwords must match")
    .oneOf([Yup.ref("password"), null], "* Passwords must match")
})

// Create New tenant schema
export const createNewTenantSchema = Yup.object().shape({
  name: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Tenant name is required"),
  address: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(100, "Maximum 100 characters")
    .required("* Tenant address is required"),
  firstName: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* First name is required"),
  lastName: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Last name is required"),
  email: Yup
    .string()
    .email("Please supply a valid email address")
    .required("* Contact email is required"),
  dial_code: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 4 characters")
    .required("* Dial code is required"),
  phone: Yup
    .string()
    .matches(validPhone[0], validPhone[1])
    .required("* Contact phone is required")
})

// Create a first manager for a new the tenant
export const createInitialManagerForTenantSchema = Yup.object().shape({
  loginFirstName: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* First name is required"),
  loginLastName: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Last name is required"),
  loginEmail: Yup
    .string()
    .email("Please supply a valid email address")
    .required("* Email is required"),
  loginPassword: Yup
    .string()
    .required("* You must set a password")
    ["strongPassword"](),
  loginPasswordRepeat: Yup
    .string()
    .required("* Passwords must match")
    .oneOf([Yup.ref("loginPassword"), null], "* Passwords must match")
})

// Edit tenant schema
export const editTenantSchema = Yup.object().shape({
  name: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Tenant name is required"),
  address: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(100, "Maximum 100 characters")
    .required("* Tenant address is required"),
  personName: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Person to contact name is required"),
  email: Yup
    .string()
    .email("Please supply a valid email address")
    .required("* Tenant email is required"),
  dial_code: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 4 characters")
    .required("* Dial code is required"),
  phone: Yup
    .string()
    .matches(validPhone[0], validPhone[1])
    .required("* Contact phone is required")
})

// Edit existing user schema
export const editUserSchema = Yup.object().shape({
  first_name: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* First name is required"),
  last_name: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Last name is required"),
  tenants: Yup
    .array()
    .required("* User must belongs to one tenant at least")
})

// Change user password schema
export const changePasswordSchema = Yup.object().shape({
  password: Yup
    .string()
    .required("* You must set a password")
    ["strongPassword"](),
  passwordConfirmation: Yup
    .string()
    .oneOf([Yup.ref("password"), null], "* Passwords must match")
})

// Edit profile
export const editProfileSchema = Yup.object().shape({
  firstname: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* First name is required"),
  lastname: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Last name is required")
})

// Change account password
export const changeAccountPassword = Yup.object().shape({
  oldPassword: Yup
    .string()
    .required("* You must set the old password"),
  newPassword: Yup
    .string()
    .required("* You must set a password")
    ["strongPassword"](),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref("newPassword"), null], "* Passwords must match")
})

// Camera management (step 1)
export const cameraManagementStep1 = Yup.object().shape({
  longitude: Yup
    .string()
    .matches(locationRegExp, { message: "Supplied longitude is not in a proper format" })
    .required("Please select the camera location by clicking on the map"),
  latitude: Yup
    .string()
    .matches(locationRegExp, { message: "Supplied latitude is not in a proper format" })
    .required("Please select the camera location by clicking on the map")
})

// Camera management (step 2)
export const cameraManagementStep2 = Yup.object().shape({
  name: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Camera name is required"),
  url: Yup
    .string()
    .matches(/^((rtsp|https?):\/\/)/, "Please supply a valid URL, with protocol (rtsp, http or https) included"),
  country: Yup
    .string()
    .required("* The The country is required"),
  locality: Yup
    .string()
    .required("* The locality is required"),
  street: Yup
    .string()
    .required("* The street is required")
})

// Camera management (step 3)
export const cameraManagementStep3 = Yup.object().shape({
  url: Yup
    .string()
    .matches(/^((rtsp|https?):\/\/)/, "Please supply a valid URL, with protocol (rtsp, http or https) included")
})

// Camera management (step 5)
export const cameraManagementStep5 = Yup.object().shape({
  lines: Yup.array().of(Yup.object().shape({
    carriageway: Yup
      .string()
      .required("* The carriageway name is required"),
    lane: Yup
      .string()
      .required("* The lane name is required")
  }))
})

// Camera management (step 6)
export const cameraManagementStep6 = Yup.object().shape({
  pairs: Yup.array().of(Yup.object().shape({
    distance: Yup.number().when("active", {
      is: active => active,
      then: Yup
        .number()
        .typeError("The distance must be a positive number")
        .required("* The distance is required")
        .min(1, "Distances less than 1 meters cannot be processed")
    })
  }))
})

// Event Management schema
// Camera management (step 2)
export const EventManagement = Yup.object().shape({
  name: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* Zone name is required"),
  type: Yup
    .string()
    .required("* Zone type is required")
})

// Forgot password schema
export const forgotPasswordSchema = Yup.object().shape({
  email: Yup
    .string()
    .email("Please supply a valid email address")
    .required("* Please supply your email address")
})

// Create New user schema
export const uploadFilesStep1 = Yup.object().shape({
  name: Yup
    .string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("* File batch name is required"),
  camera_uuid: Yup
    .string()
    .required("* You must select a camera"),
  start: Yup
    .date()
    .transform((value, originalValue) => new Date(originalValue))
    .max(new Date(), "* Future date is not allowed")
    .required("* You must set a date")
})
