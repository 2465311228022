/* eslint-disable default-case */
import { useState } from "react"
// import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { Delete, Cancel, VideoLibrary, RemoveRedEye, Settings, Edit, Help } from "@material-ui/icons"
import TableRowAction from "components/Table/TableRowAction"
import TitleHover from "../../../../components/TitleHover/TitleHover"
import { applyTimeZone } from "../../../../utils/time"
import CannotProcessBatch from "../forms/CannotProcessBatch"
import DeleteBatch from "../forms/DeleteBatch"
import CancelProcessing from "../forms/CancelProcessing"
import ViewBatch from "../forms/ViewBatch"
// import moment from 'moment-timezone';
import { getReadableTime } from "utils/functions"

const batchSize = (files) => {
  const filesSize = files.reduce((previousValue, currentValue) => previousValue + currentValue.size, 0)
  return Math.round(filesSize * 0.000001)
}

/**
 * @param {CMBatch} batch
 * @return {JSX.Element}
 * @constructor
 */
export default function TableRow({ batch }) {
  const history = useHistory()
// 
  const [showCannotProcessBatch, setShowCannotProcessBatch] = useState(false)
  const [showDeleteBatch, setShowDeleteBatch] = useState(false)
  const [showCancelBatch, setShowCancelBatch] = useState(false)
  const [showBatchFiles, setShowBatchFiles] = useState(false)

  // Trigger processing if there is at least one file
  const process = () => {
    if (batch.files.length > 0) {
      history.push(`/camera-files/process/${batch.uuid}`)
    } else {
      setShowCannotProcessBatch(true)
    }
  }

  // Valid actions
  const processAction = { icon: <Settings />, title: "Process batch", setState: process }
  const editBatchAction = { icon: <Edit />, title: "Edit batch information", setState: () => history.push(`/camera-files/edit-batch/${batch.uuid}`) }
  const editFilesAction = { icon: <VideoLibrary />, title: "Edit files", setState: () => history.push(`/camera-files/edit-files/${batch.uuid}`) }
  const deleteAction = { icon: <Delete />, title: "Delete batch", setState: () => setShowDeleteBatch(true) }
  const cancelAction = { icon: <Cancel />, title: "Cancel processing", setState: () => setShowCancelBatch(true) }

  // Set actions based on the batch status
  let actions = []
  switch (batch.status) {
    case "DRAFT":
      actions = [processAction, editBatchAction, editFilesAction, deleteAction]
      break
    case "PROCESSING_PENDING":
      actions = [cancelAction]
      break
    default:
  }
  const tableRowsActions = actions
  const extendedHelp = status => {
    switch (status) {
      case "DRAFT":
        return "Video batches are saved, but not scheduled for processing"
      case "PROCESSING_PENDING":
        return "The system will start processing files shortly"
      case "PROCESSING":
        return "The files are currently being processed"
    }
  }
  const help = extendedHelp(batch.status)
  const duration = batch.files.reduce((items, current) => items + current.duration, 0)
  // console.log(batch)
  return (
    <>
      <tr>
        <td>{batch.name}</td>
        <td className="text-center">
          {batch.files?.length}{" "}
          <span className="ml-2 cursor-pointer" onClick={() => setShowBatchFiles(true)}>
            <RemoveRedEye />
          </span>
        </td>
        <td>{batchSize(batch.files)} MB</td>
        <td>{applyTimeZone(batch.start, batch.camera.time_zone)}</td>
        <td><Link to={`/cameras/dashboard`}>{batch.camera.name}</Link> </td>
        <td className="text-left">
          {batch.status}
          {help && (
            <TitleHover text={extendedHelp(batch.status)} style={{ fontSize: ".5em", float: "left", cursor: "help", display: "inline", paddingRight: ".5em", color: '#bbb' }}>
              <Help />
            </TitleHover>
          )}
        </td>
        <td>{getReadableTime(duration)}</td>
        <td className="text-center"><TableRowAction actions={tableRowsActions} /></td>
      </tr>

      <CannotProcessBatch batchUUID={batch.uuid} state={[showCannotProcessBatch, setShowCannotProcessBatch]} />
      <DeleteBatch name={batch.name} batchUUID={batch.uuid} state={[showDeleteBatch, setShowDeleteBatch]} />
      <CancelProcessing name={batch.name} batchUUID={batch.uuid} state={[showCancelBatch, setShowCancelBatch]} />
      <ViewBatch name={batch.name} files={batch.files} state={[showBatchFiles, setShowBatchFiles]} />
    </>
  )
}
