import { MultiSelect, Select, SelectDropdown } from "components/Events/Fields";
import { State, useLoading } from "hooks/useLoading";
import { getVehicleTypes } from "../../../config/VehicleTypes"
import React, { useContext, useEffect, useState } from "react";
import useAPI from "services/ApiService";
import CustomSlider from "components/Slider/CustomSlider";
import { EventsContext } from "./EventsLayout";
import { useParams } from "react-router-dom";


const EventsFilter = ({ vehicleFilter }) => {
  const api = useAPI()
  const { cameraId, sys_id } = useParams()
  const [isCameraDropdownClicked, setIsCameraDropdownClicked] = useState(false)
  const { selectedCameras, setSelectedVehicles,
    setSelectedCameras, selectedAPI, setSelectedAPI, zones, setZones, zoneIds, setZoneIds } = useContext(EventsContext)
  const [vehicles, setVehicles] = useState([])

  let [cameras: CameraDTO[], camerasLoadingState] = useLoading(() => api.cameras())
  cameras = cameras != null ? cameras : []
  const [zoneCams, loadingCamZones] = useLoading(() => api.getCameraZones(cameraId, sys_id))

  const eventSorts = [
    { name: 'Number of Stopped Vehicles per zone', url: 'vehicles-stopped-count' },
    { name: 'Hourly Number of Stopped Vehicles per zone', url: 'vehicles-stopped-count-granular' },
    { name: 'List of Stopped Vehicles per zone', url: 'vehicles-stopped-list' },
    { name: 'Number of Stopped Vehicles per zone and vehicle type', url: 'vehicletypes-stopped-count' },
    { name: 'Hourly Number of Stopped Vehicles per zone and vehicle type', url: 'vehicletypes-stopped-count-granular' },
    { name: 'Number of Stopped Vehicles per zone and vehicle type every 15 minutes', url: 'vehicletypes-stopped-granular-15', other: 15 },
  ]

  /** Available formatting options for the multi-select. */
  const MultiSelectOptionFormat = {
    vehicle: (vehicle) => ({
      value: vehicle.type,
      label: vehicle.plural,
      id: vehicle.type
    }),
    cameras: (camera) => ({
      value: camera.uuid,
      label: camera.name,
      id: camera.uuid
    }),
    apis: (api) => ({
      value: api.url,
      label: api.name,
      id: api.url
    }),
    zones: (zone) => ({
      value: zone.uuid,
      label: zone.name,
      id: zone.uuid
    })
  }

  // Update report parameters on any change
  const getValues = (selections) => selections?.map((selection) => selection.value ?? selection.uuid) ?? []
  useEffect(() => {
    setSelectedVehicles(getValues(vehicles))
  }, [vehicles])

  useEffect(() => {
    setZoneIds(getValues(zones))
  }, [zones])

  console.log(zones)
  useEffect(() => {
    if (cameras) {
      const def_cam = cameras?.find(item => item.camera_id === cameraId)
      setSelectedCameras(JSON.stringify(def_cam))
    }
  }, [cameras, cameraId])

  return <div className="row">
    {/* Select: cameras */}

    <div className={`${"col-xl-4 col-md-6"}`} onClick={() => setIsCameraDropdownClicked(true)}>
      <SelectDropdown
        state={[selectedCameras, setSelectedCameras]}
        name="Camera"
        options={cameras?.map(camera => [camera.uuid, camera.name, camera])}
      />
    </div>
    {/* Event type */}
    {

      <div className={`${"col-xl-4 col-md-6"}`}>
        <Select
          name="Report"
          empty="Sort by"
          state={[selectedAPI, setSelectedAPI]}
          options={eventSorts.map(event => [event.url, event.name])}
        />
      </div>
    }

    {/* Select: Vehicles */}
    <div className={`${"col-xl-4 col-md-6"}`}>
      <MultiSelect
        name="Vehicle types"
        empty="Include all"
        state={[vehicles,  setVehicles]}
        options={getVehicleTypes().map(MultiSelectOptionFormat.vehicle)}
      />
    </div>
    {/* min duration */}
    <div className={`${"col-xl-4 col-md-6"}`}>
      <CustomSlider label='Stopped for at least (mins)' context={EventsContext} />
    </div>
    {/* Select: zones */}
    {zoneCams && <div className={`${"col-xl-4 col-md-6"}`}>
      <MultiSelect
        name="Zones"
        empty="Include all"
        state={[zones, setZones]}
        options={zoneCams?.map(MultiSelectOptionFormat.zones)}
      />
    </div>}
  </div>;
};

export default EventsFilter;

