import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLoading } from "hooks/useLoading";
import { buildReportLink } from "../Reports";
import LargeCard from "components/Structure/LargeCard";
import { readReportParamsFromQuery } from "../Reports";
import ReportFilters from "components/Reports/ReportFilters";
import { ArrowBack } from "@material-ui/icons";
import useAPI from "services/ApiService";
import useStoreDateRange from "hooks/useStoreDateRange";
import { LoadingWrapper } from "components";
import Presentation from "components/Trajectories/Presentation";
import { loadLast24Hrs, sameDate } from "utils/functions";
import toast from "react-hot-toast";

export const TrajectoriesReportPage = () => {
  const dateRange = useStoreDateRange();
  const api = useAPI();

  // Read Report type
  const reportType = window.location.pathname.split("/")[2];

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery());

  const [isLast24, setisLast24] = useState(true)
  const [localRange, setLocalRange] = useState(loadLast24Hrs(dateRange, isLast24))
  // Load the data
  const [camera: CameraDTO, cameraLoadingState] = useLoading(() => api.camera(params));
  const [vehicles: VehicleTrajectoryDTO, vehiclesLoadingState] = useLoading(
    () => api.getVehicleTrajectory(params.systemID, params.cameraID, localRange, params.carriageways, params.lanes, params.vehicles),
    [params, localRange],
    ["entries"]
  );
  const [pedestrians: PedestrianTrajectoryDTO, pedestriansLoadingState] = useLoading(
    () => api.getPedestrianTrajectory(params.systemID, params.cameraID, localRange),
    [params, localRange],
    ["entries"]
  );

  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink className="mr-5" to={buildReportLink(reportType, params, true)}>
        <ArrowBack /> Back to reports generator
      </NavLink>
    </div>
  );

  useEffect(() => {
    const check = sameDate(localRange.globalRange, dateRange)
  
    if (check) {
      setLocalRange(loadLast24Hrs(dateRange, isLast24))
    }
    else {
      setisLast24(true)
      setLocalRange(loadLast24Hrs(dateRange, true))
    }
    return ()=> {
      toast.dismiss()
    }
  }, [dateRange, isLast24])

  return (
    <LargeCard title="Trajectories report" headerOptions={headerOptions}>

      {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

      <Presentation
        camera={camera}
        vehicles={vehicles}
        pedestrians={pedestrians}
        isLast24={isLast24}
        setisLast24={setisLast24}
        localRange={localRange}
        dateRange={dateRange}
      />

      <LoadingWrapper state={[cameraLoadingState, vehiclesLoadingState, pedestriansLoadingState]} onEmpty={"There is no data to show for the supplied filter"} />
    </LargeCard>
  );
};
