import React from "react"
import { Button } from "react-bootstrap"
import { useState } from "react"
import { AppModal } from "./AppModal"

/**
 * A simple message modal.
 *
 * @param {{title: string, message: string, button: string, buttonStyle: string, isVisible: boolean, show: function, hide: function}} hook The return value from the useMessageModal() hook.
 */
export const MessageModal = ({ hook }) => {

  return !hook.isVisible ? <></> : (
    <AppModal title={hook.title}>
      <div style={{ whiteSpace: "pre-wrap", fontSize: "1.2em" }}>
        {hook.message}
      </div>

      <div className="mt-5 float-right">
        <Button variant={hook.buttonStyle ?? "primary"} className="ml-3" onClick={hook.hide}>
          {hook.button ?? "OK"}
        </Button>
      </div>
    </AppModal>
  )

}

/**
 * Convenient hook to use for the destructive confirmation modal.
 *
 * @return {{title: string, message: string, button: string, buttonStyle: string, isVisible: boolean, show: function, hide: function}} The hook to use in the MessageModal component.
 */
export const useMessageModal = () => {
  const [state, setState] = useState(false)
  return {
    ...state,

    /**
     * @param {string} title The title of the modal.
     * @param {string} message The question to ask, without punctuation.
     * @param {string} button The label of the confirm button.
     * @param {string} buttonStyle The style to use for the button ("primary" or "danger").
     */
    show: (title, message, button = "Close", buttonStyle = "primary") => setState({ title, message, button, buttonStyle, isVisible: true }),
    hide: () => setState({ isVisible: false })
  }
}
