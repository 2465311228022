import React, { useEffect, useRef } from "react"
import ApexCharts from "apexcharts"
import "./LineChart.scss"
import { extend } from "../../utils/data"
import { humanDate, humanDayTime } from "utils/time"
import { humanSpeed } from "utils/functions"

export const ScatterChartSeries = ({
    data,
    title,
    chartXLabel,
    chartYSuffix = "",
    height = 500,
    options = {},
    type = 'scatter',
    label,
    hasTime = false,
    fileName
}) => {

    // Create a reference to the DOM element that shows the chart
    const chartRef = useRef()


    useEffect(() => {

        // Make sure the DOM element has been initialized
        if (!chartRef.current) {
            return
        }

        // Define the options for the chart
        const useOptions = extend(options, {
            chart: {
                type,
                height: height,
                animations: {
                    enabled: false
                },
                toolbar: {
                  export: {
                    csv: {
                      filename: fileName,
                    },
                    svg: {
                      filename: fileName,
                    },
                    png: {
                      filename: fileName,
                    }
                  }
                }
            },
            xaxis: {
                type: 'numeric',
                title: {
                    text: chartXLabel,
                },
            },
            toolbar: {
                show: true
            },
            yaxis: {
                show: true,
                title: {
                    text: chartYSuffix,
                    style: {
                        fontSize: "13px"
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '10px',
                    padding: '10px'
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    return `<div className='text-xs'>${label.y}: ${label.type === 'speed' ? Math.round(data[1]) : data[1]}${'    '} <strong>${label.yUnit}</strong>
                     <br /> ${label.x}: ${data[0]}${' '}  <strong>${label.xUnit}</strong>
                     <br />Time: ${humanDate(data[2] / 1000)} ${humanDayTime(data[2] / 1000)}
                     </div>`;
                },
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {

                    return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
                }
            },
            colors: ["#84c341", '#0052cc', '#00E396', '#FEB019'],
            stroke: {
                curve: "smooth"
            },
            grid: {
                position: "front"
            },
            series: data
        })

        // Draw the chart
        const chart = new ApexCharts(chartRef.current, useOptions)
        chart.render()

        // Make sure chart is properly disposed of
        return () => chart.destroy()
    }, [data, title, height, chartXLabel, chartYSuffix, options])

    return (
        <div className="chart line-chart">
            {title && <h5 style={{ textAlign: "center", textTransform: 'capitalize' }}>{title}</h5>}
            <div ref={chartRef} />
        </div>
    )
}

export default ScatterChartSeries
