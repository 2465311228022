import { OverlayTrigger, Tooltip } from "react-bootstrap"

/**
 * Show a text on hover.
 *
 * @param {string} text The text to show.
 * @param {JSX.Element} children THe children to show.
 * @return {JSX.Element}
 */
const TitleHover = ({ text, style, children }) =>
  <OverlayTrigger placement="top" delay={{ show: 100, hide: 200 }} overlay={<Tooltip id="button-tooltip">{text}</Tooltip>}>
    <div style={style}>{children}</div>
  </OverlayTrigger>

export default TitleHover
