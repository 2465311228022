import React, { createContext, useEffect, useState } from 'react'
import { allowEmpty, extractTrueState } from "../../../components/LoadingWrapper/LoadingWrapper"
import useAPI from "../../../services/ApiService"
import { State, useLoading, useQueryLoading } from "../../../hooks/useLoading"
import { LoadingWrapper } from "../../../components"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import CameraSearch from './CameraSearch'
import { useSelector } from 'react-redux'

export const CMContext = createContext()

const CameraLayout = ({ children }) => {
    const api = useAPI()
    const configuration = useCameraManagementAPI()

    // Load the cameras from the Configuration API
    const [configurationCameras, configurationCamerasLoadingState] = useQueryLoading("CameraManagement:List", () => configuration.cameras())

    // Load the cameras from the Data API (for additional details)
    const [dataCameras, dataCamerasLoadingState] = useLoading(() => api.cameras())

    // console.log(dataCameras)

    const [combinedCameras, setCombinedCameras] = useState([])
    const [filteredCameras, setFilteredCameras] = useState([])
    const [searchFilter, setSearchFilter] = useState('')

    // console.log(configurationCameras)

    useEffect(() => {
        if (State.READY === extractTrueState([allowEmpty(dataCamerasLoadingState), configurationCamerasLoadingState]) && configurationCameras.length > 0) {
            const combined = configurationCameras.map(confCamera => {
                const dataCamera = dataCameras?.find(dc => dc.uuid === confCamera.uuid)
                const isInDataApi = !!dataCamera
                return {
                    uuid: confCamera.uuid,
                    name: confCamera.name,
                    camera_id: confCamera.camera_id,
                    sys_id: confCamera.system_id,
                    has_stream: confCamera.has_stream,
                    group: confCamera.camera_group,
                    location: confCamera.locality,
                    lat: confCamera.map?.latitude,
                    lon: confCamera.map?.longitude,
                    image: confCamera.image?.url,
                    status: isInDataApi ? dataCamera.status : { "congestion": null },
                    // Additional properties from data API
                    live: isInDataApi ? dataCamera.live : false,
                    was_live: isInDataApi ? dataCamera.was_live : false,
                    timezone_id: isInDataApi ? dataCamera.timezone_id : null,
                    current_timezone_offset: isInDataApi ? dataCamera.current_timezone_offset : null,
                    current_timezone_offset_seconds: isInDataApi ? dataCamera.current_timezone_offset_seconds : null,
                    isInDataApi,
                    // You can add more properties here as needed
                }
            })
            setCombinedCameras(combined)
            setFilteredCameras(combined)
        }
    }, [configurationCamerasLoadingState, dataCamerasLoadingState, configurationCameras, dataCameras])
    // console.log(combinedCameras, filteredCameras)

    return (
        <CMContext.Provider value={{
            filteredCameras,
            setFilteredCameras,
            searchFilter,
            setSearchFilter,
            cameras: combinedCameras,
        }}>
            {children}
            {/* <LoadingWrapper state={[configurationCamerasLoadingState]} onEmpty="No cameras have been added yet">
                <CameraSearch label='Search Cameras' value={searchFilter} />
                {children}
            </LoadingWrapper> */}
        </CMContext.Provider>
    )
}

export default CameraLayout
