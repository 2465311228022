// Date Range Filter in Report Form page
import { ReadOnly } from 'components/Reports/Fields'
import React, { useState } from 'react'
import { formatDateRange } from 'utils/time'
import { DateRangePickerModal } from './DateRangePickerModal'

const DateRangeFilter = ({ dateRange }) => {
    // The states related to the date range picker
    const [dateRangeModal, setDateRangeModal] = useState(false)

    return (
        <ReadOnly
            label="Date range"
            className="cursor-pointer w-full"
            value={formatDateRange(dateRange.start, dateRange.end)}
            onClick={() => setDateRangeModal(!dateRangeModal)}
        >

            <DateRangePickerModal show={[dateRangeModal, setDateRangeModal]} />

        </ReadOnly>
    )
}

export default DateRangeFilter
