import { useContext, useEffect, useRef, useState } from "react"
import { Image, Layer, Stage } from "react-konva"
import { config } from "./ZoneWrapper"
import { v4 as UUIDv4 } from "uuid"
import ZoneBox from "./ZoneBox"
import { useOnResize } from "hooks/useOnResize"
import { append, deepClone, updateAt } from "utils/data"
import { EventsContext } from "app/pages/Events/EventsLayout"
import { checkAllObjs } from "utils/functions"
import { Box, Modal } from "@mui/material"
import { GrClose } from 'react-icons/gr'

const ZoneCanvas = ({ lines, updateLines, image, prevStep }) => {
  const stageParentRef = useRef(null)
  const stageRef = useRef(null)
  const onResize = useOnResize()
  const { setZones, setSteps, zones, setValidZone, validZone } = useContext(EventsContext)

  // We need to have parallel lines in order to avoid update loops
  const [initialLines, setInitialLines] = useState(deepClone(lines))
  const [modifiedLines, setModifiedLines] = useState(deepClone(lines))
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Use a new variable as it is easier to understand
  const isEditable = updateLines != null

  /**
   * Add a new line.
   *
   * @param {string} type The type of the line: "entry" or "exit".
   */
  const addPolygon = () => {
    const line = {
      number: zones.length + 1,
      vertices: null,
      coords: null,
      id: UUIDv4(),
      uuid: UUIDv4(),
      name: '',
      type: '',
      angle: 0
    }

    // We must update both lines
    setInitialLines(append(line))
    setModifiedLines(append(line))
    setZones(append(line))
  }

  // Handle selected line
  const [selectedId, selectShape] = useState(null)
  const checkDeselect = (e) => {
    if (e.target.attrs.id === "ImageCanvas") {
      selectShape(null)
    }
  }

  // Responsive canvas
  const [width, setWidth] = useState(0)
  const height = width / config.aspectRatio
  useEffect(() => {
    if (stageParentRef.current?.offsetWidth) {
      setWidth(stageParentRef.current?.offsetWidth)
    }
  }, [onResize])

  useEffect(() => {
    setModifiedLines([...zones])
  }, [zones])

  useEffect(() => {
    setInitialLines(modifiedLines)
  }, [modifiedLines])

  console.log({ lines, modifiedLines, zones })
  //  Check if all zones have name and type
  const isValid = checkAllObjs(zones)

  return (
    <>
      {isEditable && (
        <div className="my-5" style={{ display: "flex", justifyContent: "center", gap: ".6em" }}>
          <button className="btn btn-primary" onClick={() => addPolygon()}>Add zone</button>
        </div>
      )}
      <div className="mb-5" ref={stageParentRef}>
        {/* Add new lines */}
        <Stage onMouseDown={checkDeselect} onTouchStart={checkDeselect} width={width} height={height} ref={stageRef}>
          <Layer>

            {/* Camera image as a background */}
            <Image image={image} width={width} height={height} id="ImageCanvas" />

            {/* Lines drawn on the image */}
            {initialLines.map((shape, index) => (
              <ZoneBox
                key={shape?.uuid}
                shape={{ ...shape, id: index + 1 }}
                width={width}
                height={height}
                isEditable={isEditable}
                updateShape={shape => {
                  setModifiedLines(updateAt(index, shape))
                  setZones(updateAt(index, shape))
                }}
                handleZone={setOpen}
                isSelected={selectedId === shape?.uuid}
                onSelect={() => selectShape(shape?.uuid)} />
            ))}
          </Layer>
        </Stage>
      </div>
      {/* Do not if this is a readonly canvas */}
      {isEditable && (
        <div className="form-buttons">
          {prevStep && (<button className="btn btn-default" type="button" onClick={() => setSteps(prev => prev - 1)}>Back</button>)}
          <button disabled={!isValid && !open} className="btn btn-primary" type="submit" onClick={() => {
            // setZones(modifiedLines)
            setSteps(prev => prev + 1)
          }
          }>Next</button>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      ><Box style={{
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        background: 'white',
        boxShadow: 24,
        p: 6,
      }}>
          <div className="p-4">
            <div className="flex justify-end">
              <div onClick={handleClose}>
                <GrClose />
              </div>

            </div>
            <div className="py-2">
              The zone is too close to the edge of the camera. Adjust it to make sure the zone is well captured.
            </div>

          </div>
        </Box>
      </Modal>
    </>
  )
}

export default ZoneCanvas
