import { Button, Modal } from "react-bootstrap"
import { useHistory } from "react-router-dom"

const DeleteBatch = ({ batchUUID, state }) => {
  const history = useHistory()

  return (
    <Modal centered show={state[0]} size="lg" onHide={() => state[1](false)}>
      <Modal.Header closeButton>
        <Modal.Title>Process File Batch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-weight-light">
          You need to add some files in order to process this batch.
        </h5>
        <div className="mt-5 float-right">
          <Button variant="secondary" onClick={() => state[1](false)}>
            Cancel
          </Button>
          <Button variant="primary" className="ml-3" onClick={() => history.push(`/camera-files/edit-files/${batchUUID}`)}>
            Add files
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteBatch
