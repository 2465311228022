import React, { useState } from 'react'
import Steps from './Form/Steps'
import MCLayout from './MCLayout'

const MultiCameraIndex = () => {
    return (
        <MCLayout>
            <Steps />
        </MCLayout>
    )
}

export default MultiCameraIndex