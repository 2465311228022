import { useFormik } from "formik"
import { Form } from "react-bootstrap"
import LargeCard from "../../../../components/Structure/LargeCard"
import { FormText, StringInput } from "../../../../components/Reports/Fields"
import { cameraManagementStep2, formikDefault } from "../../../../utils/forms-schema"
import { LoadingWrapper } from "../../../../components"
import { stepTitle } from "../form"

/**
 * Set the basic information about the camera.
 *
 * @param camera
 * @param title
 * @param setValues
 * @param prevStep
 * @return {JSX.Element}
 * @constructor
 */
const Step2 = ({ camera, title, setValues, prevStep }) => {

  const formik = useFormik({
    ...formikDefault,
    initialValues: camera,
    onSubmit: setValues
  })

  return (
    <LargeCard title={stepTitle(title, camera, "Basic info")}>
      <LoadingWrapper state={[]}>
        <FormText>
          <p>
            Before you move to Step 3, please make sure that if you would like the system to automatically
            take the camera image the camera must be showing the new scene that you are adding.
          </p>
        </FormText>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <StringInput label="New scene name" name="name" formik={formik} />
            </div>
          </div>

          <div className="form-buttons">
            <button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>
            <button disabled={!formik.values.name} className="btn btn-primary" type="submit">Next</button>
          </div>
        </Form>
      </LoadingWrapper>
    </LargeCard>
  )
}

export default Step2
