import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { EventsContext } from 'app/pages/Events/EventsLayout';

const marks = [
    {
        value: 0,
        label: '0min',
    },
    {
        value: 5,
        label: '5mins',
    },
    {
        value: 10,
        label: '10mins',
    },
    {
        value: 30,
        label: '30mins',
    },
];

function valuetext(value: number) {
    return `${value}mins`;
}

export default function CustomSlider({ label, context }) {
    const { setMinDuration } = React.useContext(context)
    return (
        <div className='p-2 py-4'>
            <div className='d-block'>{label}</div>
            <Box sx={{ width: 300, px: 2 }} className='d-block'>
                <Slider
                    aria-label="Custom marks"
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    step={1}
                    max={60}
                    onChange={(e) => setMinDuration(parseInt(e.target.value) * 60)}
                    valueLabelDisplay="auto"
                // marks={marks}
                />
            </Box>
        </div>
    );
}