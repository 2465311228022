import { useFormik } from "formik"
import { Form } from "react-bootstrap"
import LargeCard from "../../../../components/Structure/LargeCard"
import { FormText, StringInput } from "../../../../components/Reports/Fields"
import { cameraManagementStep1, formikDefault } from "../../../../utils/forms-schema"
import { stepTitle } from "../form"
import { useEffect, useState } from "react"
import Map from "./Map/Map"
import { useSelector } from "react-redux"

/**
 * Get the GPS position of the camera.
 *
 * @param camera
 * @param title
 * @param setValues
 * @return {JSX.Element}
 * @constructor
 */
const Step1 = ({ camera, title, setValues }) => {
  const { locationArr } = useSelector(state => state.camera)
  console.log({ locationArr, camera })
  // Initial location
  const extractLocation = (camera) => ({
    latitude: camera?.latitude ?? locationArr[0] ?? "35.88727689026155",
    longitude: camera?.longitude ?? locationArr[1] ?? "14.447391252898122"
  })

  // Define form
  const formik = useFormik({
    ...formikDefault,
    initialValues: extractLocation(camera),
    validationSchema: cameraManagementStep1,
    onSubmit: setValues
  })

  // Update values on camera update
  useEffect(() => {
    formik.setFieldValue("latitude", extractLocation(camera).latitude)
    formik.setFieldValue("longitude", extractLocation(camera).longitude)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  // Update the GPS location of the camera
  const setLocation = (location) => {
    formik.setFieldValue("latitude", location.lat)
    formik.setFieldValue("longitude", location.lng)
  }

  // Show satellite map view
  const [satellite, setSatellite] = useState(false)

  return (
    <LargeCard title={stepTitle(title, camera, "Location")}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <FormText>
          The location of the new Camera can be input through the latitude and longitude fields.
          <br />
          <br />
          Otherwise, the map can be used to find the location of the Camera. Drag the map to find the position and click on it to place a marker
        </FormText>
        <div className="row">
          <div className="col-md-6">
            <Map satellite={satellite} scrollWheelZoom={true} locations={formik.values.latitude ? [[formik.values.latitude, formik.values.longitude]] : []} onChange={setLocation} />

            {/* Switch between roadmap and satellite view */}
            {/*<a className="block as-link mt-2" onClick={() => setSatellite(satellite => !satellite)}>*/}
            {/*  {satellite ? "Change to roadmap" : "Change to satellite view"}*/}
            {/*</a>*/}
          </div>
          <div className="col-md-6">
            <StringInput label="Latitude" name="latitude" formik={formik} />
            <StringInput label="Longitude" name="longitude" formik={formik} />
          </div>
        </div>

        <div className="form-buttons">
          <button className="btn btn-primary" type="submit">Next</button>
        </div>
      </Form>
    </LargeCard>
  )
}

export default Step1
