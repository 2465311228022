import { MultiSelect, Select, SelectDropdown } from "components/Events/Fields";
import { useLoading } from "hooks/useLoading";
import { getVehicleTypes } from "../../../config/VehicleTypes"
import React, { useContext, useEffect, useState } from "react";
import useAPI from "services/ApiService";
import CustomSlider from "components/Slider/CustomSlider";
import { MatrixContext } from "./MatrixLayout";


const MatrixFilter = () => {
    const api = useAPI()
    const [isCameraDropdownClicked, setIsCameraDropdownClicked] = useState(false)
    const { selectedCameras, selectedVehicles, setSelectedVehicles,
        setSelectedCameras, selectedAPI, setSelectedAPI } = useContext(MatrixContext)

    let [cameras: CameraDTO[], camerasLoadingState] = useLoading(() => api.cameras())
    cameras = cameras != null ? cameras : []

    const matrixSorts = [
        { name: 'Transit flow', url: 'transitflow' },
        // { name: 'Transit matrix hourly', url: 'transitmatrix-granular' },
        // { name: 'Transit matrix hourly by vehicle types', url: 'transitmatrix-vehicletype-granular' },
    ]

    /** Available formatting options for the multi-select. */
    const MultiSelectOptionFormat = {
        vehicle: (vehicle) => ({
            value: vehicle.type,
            label: vehicle.plural,
            id: vehicle.type
        }),
        cameras: (camera) => ({
            value: camera.uuid,
            label: camera.name,
            id: camera.uuid
        }),
        apis: (api) => ({
            value: api.url,
            label: api.name,
            id: api.url
        })
    }

    useEffect(() => {
        cameras && setSelectedCameras(JSON.stringify(cameras[2]))
    }, [cameras])



    return <div className="row">
        {/* Select: cameras */}

        <div className={`${"col-xl-4 col-md-6"}`} onClick={() => setIsCameraDropdownClicked(true)}>
            <SelectDropdown
                state={[selectedCameras, setSelectedCameras]}
                name="Camera"
                options={cameras?.map(camera => [camera.uuid, camera.name, camera])}
            />
        </div>
        {/* Event type */}
        {

            <div className={`${"col-xl-4 col-md-6"}`}>
                <Select
                    name="Report"
                    empty="Sort by"
                    state={[selectedAPI, setSelectedAPI]}
                    options={matrixSorts.map(event => [event.url, event.name])}
                />
            </div>
        }

        {/* Select: Vehicles */}
        {

            <div className={`${"col-xl-4 col-md-6"}`}>
                <MultiSelect
                    name="Vehicle types"
                    empty="Include all"
                    state={[selectedVehicles, setSelectedVehicles]}
                    options={getVehicleTypes().map(MultiSelectOptionFormat.vehicle)}
                />
            </div>
        }
        {/* min duration */}
        <CustomSlider label='Minimum time spent (mins)' context={MatrixContext} />
    </div>;
};

export default MatrixFilter;

