import React from "react";
import { Circle, Group, Text } from "react-konva";
import { PointType } from "../../utils/enums";
import config from "./CameraConfig";

/**
 *
 * @param {TransitFlowPointDTO} point The point to draw.
 * @param {boolean} active True if this point is an active one.
 * @param {float} ratio The ratio of the image.
 * @param {int} width The total width of the image.
 * @param {int} height The total height of the image.
 * @param {boolean} dimmed Set to true to show the point as dimmed.
 * @param {LaneFlowDTO[]} relatedPoints The list of transit points that share the traffic with this one.
 * @param {function(TransitFlowPointDTO)} onClick Called when the user clicks on the point.
 * @param {function(TransitFlowPointDTO)} onHover Called when the mouse hovers over the point.
 */
export const Point = ({ point, active, ratio, width, height, dimmed, relatedPoints, onClick, onHover }) => {

  /**
   * Get the text to show inside the circle of a traffic point.
   *
   * @param {TransitFlowPointDTO} point The point to get the text for.
   *
   * @return {string} The text show show in the circle.
   */
  const getText = (point: TransitFlowPointDTO) => {

    // For the active point show the type
    if (active) {
      return point.transit_point.type.toLowerCase();
    }

    // Show the traffic percentage if we have an active point
    for (const relatedPoint of relatedPoints) {
      if (relatedPoint.transit_point.uuid === point.transit_point.uuid) {
        return Math.round(relatedPoint.percent) + "%";
      }
    }

    // By default do not show anything
    return "";
  };

  /**
   * Get the colors for a point.
   *
   * @param {TransitFlowPointDTO} point The point po draw.
   *
   * @return {FillAndBorder} The fill and border to use.
   */
  const getColor = (point: TransitFlowPointDTO) => {

    // Get the source from where to read the colors
    const source = dimmed ? "dimmed" : (active ? "active" : "inactive");

    // Handle both types but also show a default one if type is unknown
    switch (point.transit_point.type) {
      default:
      case PointType.ENTRY:
        return config.point.type.entry[source];

      case PointType.EXIT:
        return config.point.type.exit[source];
    }
  };

  const onMouseEnter = (event) => {

    // Set the cursor to a pointer
    const container = event.target.getStage().container();
    container.style.cursor = "pointer";

    // Notify the parent on hover
    onHover(point);
  };

  const onMouseLeave = (event) => {

    // Set the cursor to a default one
    const container = event.target.getStage().container();
    container.style.cursor = "default";

    // Notify the parent of hover end
    onHover(null);
  };

  return (
    <Group
      key={point.transit_point.uuid}
      onClick={() => onClick(point)}
      onTap={() => onClick(point)}
      onMouseEnter={event => onMouseEnter(event)}
      onMouseLeave={event => onMouseLeave(event)}
    >
      <Circle
        radius={config.point.radius}
        fill={getColor(point).fill}
        scale={{ x: ratio, y: ratio }}
        x={point.transit_point.xLane * width * ratio}
        y={point.transit_point.yLane * height * ratio}
        stroke={getColor(point).border}
        strokeWidth={2}
      />
      <Text
        text={getText(point, relatedPoints)}
        align="center"
        verticalAlign="middle"
        width={config.point.radius}
        x={point.transit_point.xLane * width * ratio - config.point.radius / 2}
        y={point.transit_point.yLane * height * ratio - 6}
        fontSize={14}
        fill="rgba(250, 250, 250, 1)"
      />
    </Group>
  );
};
