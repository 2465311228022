import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import Select from "react-select";
import { useMe } from "hooks/useMe";
import { createNewUserSchema } from "utils/forms-schema";
import { ErrorMessage } from "components/UserManagement/ErrorMessage";
import LargeCard from "components/Structure/LargeCard";
import { LoadingWrapper } from "components";
import { useUsersQuery } from "../../queries";

export const AddUserDetails = ({ details: { userDetails, setUserDetails } }) => {
  const history = useHistory();
  const { userId, userManagingTenantsIds } = useMe();
  const { users } = useUsersQuery(userManagingTenantsIds);

  // Tenants managed by logged-in user
  const managerTenants = users?.find((user) => user.id === userId)?.admin_groups;

  // Get initial Values from state
  const { firstName, lastName, email } = userDetails;

  const onSubmit = (values) => {
    const { firstName, lastName, email, password, tenants } = values;
    setUserDetails({ ...userDetails, firstName, lastName, email, password, tenants });
  };

  return (
    <div className="col-xl-6 offset-xl-3">
      <LargeCard title="Create a new user">
        <LoadingWrapper state={"success"} onEmpty={"Can not create a user"}>
          <Formik
            initialValues={{
              firstName,
              lastName,
              email,
              password: "",
              passwordConfirmation: "",
              tenants: []
            }}
            validationSchema={createNewUserSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Label className="h6 mb-2">Name</Form.Label>
                <div className="d-flex mb-1">
                  <Form.Group className="mb-2" style={{ width: "48%" }}>
                    <Form.Control type="text" name="firstName" placeholder="First Name" value={values.firstName} onChange={handleChange} />
                    <ErrorMessage>{errors.firstName}</ErrorMessage>
                  </Form.Group>
                  <Form.Group className="mb-2 ml-auto" style={{ width: "48%" }}>
                    <Form.Control type="text" name="lastName" placeholder="Last Name" value={values.lastName} onChange={handleChange} />
                    <ErrorMessage>{errors.lastName}</ErrorMessage>
                  </Form.Group>
                </div>
                <Form.Label className="h6 mb-2">Email</Form.Label>
                <Form.Group className="mb-2">
                  <Form.Control type="email" name="email" placeholder="Email" value={values.email} onChange={handleChange} />
                  <ErrorMessage>{errors.email}</ErrorMessage>
                </Form.Group>
                <Form.Label className="h6 mt-5 mb-2">Password</Form.Label>
                <Form.Group className="mb-2">
                  <Form.Control type="password" name="password" placeholder="Password" value={values.password} onChange={handleChange} />
                  <ErrorMessage>{errors.password}</ErrorMessage>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Control
                    type="password"
                    name="passwordConfirmation"
                    placeholder="Confirm password"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                  />
                  <ErrorMessage>{errors.passwordConfirmation}</ErrorMessage>
                </Form.Group>

                <Form.Label className="h6 mt-5 mb-2">Tenants</Form.Label>
                <Select
                  isMulti
                  name="tenants"
                  onChange={(value) => handleChange({ target: { name: "tenants", value } })}
                  options={managerTenants?.map((tenant) => ({ id: tenant.system_id, value: tenant.name, label: tenant.name }))}
                />
                <ErrorMessage>{errors.tenants}</ErrorMessage>
                <div className="float-right mt-7">
                  <Button variant="secondary" size="lg" className="mt-9 mr-3" onClick={() => history.push("/user-management")}>
                    Cancel
                  </Button>
                  <Button variant="primary" size="lg" type="submit" className="mt-9">
                    <span className="mx-2">Next</span>
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </LoadingWrapper>
      </LargeCard>
    </div>
  );
};
