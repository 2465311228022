import { useState } from "react"
import { Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { humanDate } from "utils/time"
import { humanNumber, humanSpeed, humanTotal } from "utils/functions"
import { useLoading } from "hooks/useLoading"
import { buildReportLink } from "../Reports"
import { ExportToCsv, LoadingWrapper } from "components"
import LargeCard from "components/Structure/LargeCard"
import { ArrowBack } from "@material-ui/icons"
import ReportFilters from "components/Reports/ReportFilters"
import { csvAvgSpeed } from "utils/csv"
import { initPath, toArray } from "utils/data"
import { readReportParamsFromQuery } from "../Reports"
import useStoreDateRange from "hooks/useStoreDateRange"
import useAPI from "services/ApiService"
import format from "date-fns/format"

export const DailySummaryReportPage = () => {
  const dateRange = useStoreDateRange()
  const api = useAPI()

  // Read Report type
  const reportType = window.location.pathname.split("/")[2]

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery())

  // Load the data
  const [camera: CameraDTO] = useLoading(() => api.camera(params))
  const [report, reportLoading] = useLoading(() =>
    api.getDailySummary(params.systemID, params.cameraID, dateRange, params.carriageways, params.lanes, params.vehicles),
    [params]
  )

  // CSV table
  const csv = report?.map(row => [
    humanDate(row.start),
    row.count,
    csvAvgSpeed(row.avg_speed),
    csvAvgSpeed(row.p85_speed)
  ])
  csv?.unshift(["Date", "Count", "Avg Speed [km/h]" /*, "Min speed [km/h]" */, "85th percentile [km/h]"])

  const startDate = format(dateRange?.start, "dd-MM-yyyy")
  const endDate = format(dateRange?.end, "dd-MM-yyyy")


  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink
        className="mr-5"
        to={buildReportLink(reportType, {
          systemID: params.systemID,
          cameraID: params.cameraID,
          carriageways: params.carriageways,
          lanes: params.lanes,
          vehicles: params.vehicles,
          range: params.range
        }, true)}
      >
        <ArrowBack /> Back to reports generator
      </NavLink>

      <ExportToCsv data={csv} filename={`${camera?.name} - Daily summary - ${startDate} - ${endDate}`} />
    </div>
  )

  return (
    <LargeCard title="Daily summary report" headerOptions={headerOptions}>
      {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

      <LoadingWrapper state={reportLoading} onEmpty={"There is no data to show for the supplied filter"}>
        <Table bordered hover>
          <thead>
            <tr>
              <th width="20%">Date</th>
              <th width="20%" className="text-right">Count</th>
              <th width="30%" className="text-right">Avg speed</th>
              {/*<th width="12%" className="text-right">Min speed</th>*/}
              <th width="30%" className="text-right">85th percentile</th>
            </tr>
          </thead>
          <tbody>
            {
              report?.map((row) => (
                <tr key={row.key}>
                  <td>{humanDate(row.start)}</td>
                  <td className="text-right">{humanNumber(row.count)}</td>
                  <td className="text-right">{humanSpeed(row.avg_speed)}</td>
                  {/*<td className="text-right">{humanSpeed(row.min_speed)}</td>*/}
                  <td className="text-right">{humanSpeed(row.p85_speed)}</td>
                </tr>
              ))
            }

            {/* Total */}
            <tr>
              <td className="text-right" colSpan="2">
                {humanTotal(humanNumber(report?.reduce((acc, row) => acc + row.count, 0)), 'vehicles')}
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
      </LoadingWrapper>
    </LargeCard>
  )
}
