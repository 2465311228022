import toast from "react-hot-toast"
import { useQueryClient, useMutation } from "react-query"

/**
 * Mutate user dashboard data.
 *
 * @param {function} fn function to execute ,it depends on operation create/edit/delete user.
 * @param {string} successMessage Success message to render in a toast.
 *
 * @return {function} react-query mutation function.
 */

export const useMutationUser = (fn, successMessage = "Success") => {
  const queryClient = useQueryClient()
  return useMutation(fn, {
    onSuccess: () => {
      toast.success(successMessage)
      queryClient.invalidateQueries()
    },

    onError: error => toast.error(error?.message ?? "An unknown error has occurred")
  })
}

/* List of admin roles */
export const adminRoles = [
  {
    id: "ef536665-7d68-4f84-9233-5a3b8fb56948",
    name: "query-users"
  },
  {
    id: "8e3b2703-c6ba-47b1-a0a7-14a8feaab9bc",
    name: "manage-clients"
  },
  {
    id: "cbf88e44-5f20-46c2-888e-2cff92dc59dd",
    name: "query-groups"
  },
  {
    id: "f9998106-d646-42b1-a1d8-bd0292259a2a",
    name: "manage-realm"
  },
  {
    id: "c08f3d91-368f-4699-8e7f-1728685aeef0",
    name: "realm-admin"
  },
  {
    id: "57040af9-dc4f-4c90-aaf0-fa85483aff9a",
    name: "query-clients"
  },
  {
    id: "d0a140bb-6adc-4fbc-a72c-e1100f0e6f9c",
    name: "create-client"
  },
  {
    id: "acbb82bb-f490-4fb8-a8d5-b61a73128cbc",
    name: "query-realms"
  },
  {
    id: "ddc0e66b-c1ca-4a08-b659-79727664a1ed",
    name: "impersonation"
  },
  {
    id: "6ebb9d55-2ff1-4d83-bff8-20150a4f8726",
    name: "manage-authorization"
  },
  {
    id: "cedeb2e8-009b-411b-afb4-6335ccd45a0c",
    name: "manage-events"
  },
  {
    id: "779baa64-488c-44a2-98eb-34230c7aa14a",
    name: "manage-identity-providers"
  },
  {
    id: "a85e93ae-8b47-4d5d-8fba-0cb7087d9a80",
    name: "manage-users"
  }
]
