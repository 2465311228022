/**
 * @typedef TrajectoriesJobResult
 *    @property {int} elapsed The time it took to finish the job.
 *    @property {int} lineCount The number of lines that were drawn.
 */

/**
 * Build a worker from a script.
 *
 * @param {function()} fun The function to act as a worker.
 *
 * @return {Worker} The created service worker.
 */
export const buildWorker = fun =>
  new Worker(URL.createObjectURL(
    new Blob([`(${fun.toString()})()`])
  ));
