import { useState } from "react"
import { Table } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { hourMinute, humanDate, humanDayTime } from "utils/time"
import { humanNumber, humanSpeed, humanTotal } from "utils/functions"
import { buildReportLink } from "../Reports"
import { useLoading } from "hooks/useLoading"
import { ExportToCsv, LoadingWrapper } from "components"
import LargeCard from "components/Structure/LargeCard"
import ReportFilters from "components/Reports/ReportFilters"
import { csvAvgSpeed } from "utils/csv"
import { readReportParamsFromQuery } from "../Reports"
import { ArrowBack } from "@material-ui/icons"
import useAPI from "services/ApiService"
import useStoreDateRange from "hooks/useStoreDateRange"
import format from "date-fns/format"

export const HourlyPedestrianReport = () => {
    const dateRange = useStoreDateRange()
    const api = useAPI()

    // Read Report type
    const reportType = window.location.pathname.split("/")[2]

    // Read report parameters from the URL
    const [params, updateParams] = useState(readReportParamsFromQuery())

    const [report: HourlySummaryReportDTO[], reportLoadingState] = useLoading(() =>
        api.getPedestrianGranular(params.systemID, params.cameraID, dateRange, params.carriageways, params.lanes, params.vehicles),
        [params]
    )
    const [camera] = useLoading(() => api.camera(params))
    // Last show day used to emphasize when a new day starts
    let lastDay = null

    // CSV table
    const csv = report?.map((row) => [
        humanDate(row.start),
        hourMinute(row.start) + " - " + humanDayTime(row.end, true),
        row.count,
    ])
    csv?.unshift(["Date", "Time", "Count"])

    const startDate = format(dateRange?.start, "dd-MM-yyyy")
    const endDate = format(dateRange?.end, "dd-MM-yyyy")

    // The header options
    const headerOptions = (
        <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
            <NavLink
                className="mr-5"
                to={buildReportLink(reportType, {
                    systemID: params.systemID,
                    cameraID: params.cameraID,
                    range: params.range
                }, true)}
            >
                <ArrowBack /> Back to reports generator
            </NavLink>

            <ExportToCsv data={csv} filename={`${camera?.name} - Hourly pedestrian - ${startDate} - ${endDate}`} />
        </div>
    )

    return (
        <LargeCard title="Hourly pedestrian report" headerOptions={headerOptions}>
            <LoadingWrapper state={reportLoadingState} onEmpty={"There is no data to show for the supplied filter"}>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th width="200px">Date</th>
                            <th width="200px">Time</th>
                            <th width="100px">Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {report?.map((row, index) => {
                            const day = humanDate(row.start)

                            // Update the last shown date
                            const showAsHeader = lastDay != null && lastDay !== day
                            lastDay = day

                            return (
                                <tr key={index} className={showAsHeader ? "table-header?" : null}>
                                    <td>{day}</td>
                                    <td>{humanDayTime(row.start)} - {humanDayTime(row.end, true)}</td>
                                    <td>{humanNumber(row.count)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </LoadingWrapper>
        </LargeCard>
    )
}
