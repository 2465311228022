import { CustomInput, FormText, Select } from 'components/Events/Fields'
import { useFormik } from 'formik'
import { useLoading } from 'hooks/useLoading'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useCameraManagementAPI from 'services/CameraManagementService'
import { formikDefault } from 'utils/forms-schema'
import { EventsContext } from '../Events/EventsLayout'
import ZoneTable from './ZoneCanvas/ZoneTable'
import ZoneWrapper from './ZoneCanvas/ZoneWrapper'

const AddZone = () => {
    const { zones, setZones, camera: image, types, setZoneTypes } = useContext(EventsContext)
    const cameraAPI = useCameraManagementAPI()
    const { cameraId } = useParams()

    const [zone, zoneLoadingState] = useLoading(() =>
        cameraAPI.getParkingZones(`cameras/${cameraId}/zones`),
        [cameraId]
    )
    useEffect(() => {
        if (zones?.length === 0 && zone) {
            setZones(zone)
        }
    }, [zone, zones?.length])
    


    return (
        <div>
            <ZoneTable zones={zones} types={types} setZones={setZones} setZoneType={setZoneTypes} />
            {/* <ZoneWrapper image={image} isEditable={true} /> */}
            <ZoneWrapper image={image} lines={zones ?? []} updateLines={lines => setZones(lines)} />
        </div >
    )
}

export default AddZone