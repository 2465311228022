import { toAbsoluteUrl } from "../_metronic/_helpers";

/**
 * Get the path to to the public folder.
 *
 * @param {string} path The path in the 'public/' folder.
 *
 * @return {string} The relative path.
 */
export const getPath = (path) =>
  toAbsoluteUrl(window.location.origin + "/" + path);

/**
 * Get the path to the file im 'public/media' folder.
 *
 * @param {string} path The path in the 'public/media/' folder.
 *
 * @return {string} The relative path to the file.
 */
export const media = (path) =>
  getPath("media/" + path);

/**
 * Get the path to the image im 'public/img' folder.
 *
 * @param {string} img The path in the 'public/img/' folder.
 *
 * @return {string} The relative path to the image.
 */
export const image = (img) =>
  getPath("img/" + img);

/** The path to the image that shows that we do not have an image. */
export const noImage =
  image("no-image.png");
