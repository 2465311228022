import { Table, Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";
import { useMe } from "hooks/useMe";
import { useUpdateUser, useUsersQuery } from "../queries";

const UserRole = ({ user, show, exitModal }) => {
  // Logged in user id
  const { userId, userManagingTenantsIds } = useMe();
  const { users } = useUsersQuery(userManagingTenantsIds);
  const { id, first_name, last_name, groups: tenants, admin_groups: managingTenants } = user;

  // Tenants managed by logged in user
  const managerTenants = users?.find((user) => user.id === userId)?.admin_groups;
  const updateUser = useUpdateUser(id);

  const onSubmit = (values) => {
    Object.keys(values).forEach((tenant) => {
      updateUser.mutate({
        tenantID: values[tenant].id,
        tenant_manager: values[tenant].value === "Admin",
        details: { first_name, last_name }
      });
    });
    exitModal();
  };

  const initialValues = {};
  tenants?.forEach((tenant) => {
    // Check if user has admin groups attributes
    initialValues[tenant.name] = managingTenants?.some((tn) => tn.id === tenant.id)
      ? { id: tenant.system_id, value: "Admin", label: "Admin" }
      : { id: tenant.system_id, value: "Viewer", label: "Viewer" };
  });

  const selectOptions = {};
  managerTenants?.forEach((tenant) => {
    selectOptions[tenant.name] = [
      { id: tenant.system_id, value: "Viewer", label: "Viewer" },
      { id: tenant.system_id, value: "Admin", label: "Admin" }
    ];
  });

  return (
    <Modal centered show={show} size="md" onHide={exitModal}>
      <Modal.Header closeButton>
        <Modal.Title>Change user role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Table borderless hover={false}>
                <thead>
                  <tr className="bg-white">
                    <th className="bg-white" width="35%">
                      Tenant
                    </th>
                    <th className="bg-white">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {tenants?.map((tenant, id) => (
                    <tr key={id}>
                      <td style={{ verticalAlign: "middle" }}>{tenant?.name}</td>
                      <td>
                        <Select
                          name={tenant.name}
                          defaultValue={initialValues[tenant.name]}
                          onChange={(value) => handleChange({ target: { name: tenant.name, value } })}
                          options={selectOptions[tenant.name]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="float-right">
                <Button variant="secondary" className="mt-9  mr-3" onClick={exitModal}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="mt-9">
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UserRole;
