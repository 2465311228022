import { LoadingWrapper } from 'components'
import LargeCard from 'components/Structure/LargeCard'
import { State, useLoading, useQueryLoading } from 'hooks/useLoading'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { Tooltip } from "@mui/material"
import { Delete, Edit, FileCopy } from "@material-ui/icons"
import { Summarize } from '@mui/icons-material';
import useCameraManagementAPI from 'services/CameraManagementService'
import TableRow from '../TableRow'
import TableRowAction from 'components/Table/TableRowAction'
import { toaster } from 'utils/toaster'
import { useQueryClient } from 'react-query'
import { useMe } from 'hooks/useMe'

export const dummyCameras = [
    { name: 'Camera one', joined_cameras: ['zetur, mgarr, lagos'] },
    { name: 'Camera two', joined_cameras: ['zetur, mgarr, lagos'] },
    { name: 'Camera three', joined_cameras: ['zetur, mgarr, lagos'] }
]

const MultiCameras = () => {
    const { isAdmin } = useMe()
    const confAPI = useCameraManagementAPI()
    const { push } = useHistory()
    const queryClient = useQueryClient()
    const [res, loading] = useQueryLoading("Multicamera:list", () => confAPI.getSavedCameras())
    const [filteredCameras, setfilteredCameras] = useState([])

    const AddPair = <>
        <Link to={'/new/multi_camera_report'}>
            <button className="btn btn-primary float-right" type="submit">New camera pair</button>
        </Link>

    </>

    // Delete a camera
    const deleteCamera = async (camera) => {
        if (window.confirm(`Are you sure you want to delete camera pair "${camera.name}" "?`)) {

            // Create camera and invalidate the list on success
            const promise = confAPI.getSavedCamera(camera.uuid, null, 'DELETE')
                .then(() => queryClient.invalidateQueries("Multicamera:list"))

            // Show a friendly toaster
            await toaster.promise(promise, "Camera deleted successfully")
        }
    }

    useEffect(() => {
        setfilteredCameras(res?.multiCameraContexts)
    }, [res])

    return (
        <LargeCard title="Multi-Camera" headerOptions={isAdmin ? AddPair : <></>}>
            <LoadingWrapper state={loading} onEmpty={"Please use the 'Add new pair' button to add your first setup"}>
                {filteredCameras?.length > 0 ? (
                    <Table bordered hover={false} className="v-align">
                        <thead>
                            <tr>
                                <th width="*">Camera pair name</th>
                                {/* <th width="*">Combined cameras</th> */}
                                <th width="300" />
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCameras?.map((camera, i) => (
                                <tr key={i}>
                                    <td>{camera.name}</td>
                                    {/* <TableRow item={camera} /> */}
                                    {/* <td>{camera.joined_cameras.map(item => `${item} `)}</td> */}
                                    <td className="">
                                        <div className='text-center w-80 space-evenly'>
                                            <TableRowAction
                                                actions={[
                                                    { icon: <Edit />, show: isAdmin ? true : false, title: "Edit camera pair", setState: () => push(`/multi_camera/edit/${camera.uuid}`) },
                                                    { icon: <Delete />, show: isAdmin ? true : false, title: "Delete camera pair", setState: () => deleteCamera(camera) },
                                                    { icon: <Summarize />, title: `Generate report`, setState: () => push(`/multi_camera/report/${camera.uuid}`) }
                                                ]}
                                            />
                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <h1 className="text-center my-10">Please use the 'Add new pair button to add your first setup</h1>
                )}
            </LoadingWrapper>
        </LargeCard >
    )
}

export default MultiCameras