import { Card, CardBody } from "../../_metronic/_partials/controls";
import "./Kpi.scss";

/**
 * Show the KPI box.
 *
 * @param {?string} title The title of the box.
 * @param {?string} value The value to show.
 * @param {function} onClick The action to preform on click.
 * @param {boolean} active True if this box is currently selected by the user.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const Kpi = ({ title, value, onClick, active }) => (
  <Card onClick={onClick} className={`mt-4 kpi ${active && `active-kpi`}`}>
    <CardBody className="text-center">
      {title && <p>{title}</p>}
      {value && <h2>{value}</h2>}
    </CardBody>
  </Card>
);
