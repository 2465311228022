import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import React from "react";

/**
 * Go to a new line and indent with an arrow.
 *
 * @param {{br: boolean}}
 *
 * @return {JSX.Element}
 * @constructor
 */
export const IndentArrow = ({ br = true }) => <>
  {br && <br />}
  <KeyboardReturnIcon style={{ transform: "scaleX(-1)", marginLeft: 5, marginRight: 5 }} />
</>;
