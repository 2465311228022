import { useState } from "react";
import { formatDateRange, validateDate } from "utils/time";
import { DateRange } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { DateRangePickerModal } from "components";
import './DateTopbar.scss'

/**
 * Date range filter in Nav bar
 */
function DateRangeTopBar() {
  const dateRange = useSelector((state) => state.dateRange);
  const [dateRangeModal: boolean, setDateRangeModal] = useState(false);

  return (
    <>
      <div
        className="row justify-content-between border rounded align-self-center mr-5 cursor-pointer px-5 py-2"
        onClick={() => setDateRangeModal(true)}
        title="Current date range"
      >
        <div className="mr-3 font-sisze-lg">
          <span>{formatDateRange(validateDate(dateRange.start), validateDate(dateRange.end))}</span>
        </div>
        <DateRange title="date range" />
      </div>
      <DateRangePickerModal show={[dateRangeModal, setDateRangeModal]} />
    </>
  );
}

export { DateRangeTopBar };
