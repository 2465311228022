import { Camera, DateRange, Folder } from "@material-ui/icons"
import { format } from "date-fns"

const BatchHeader = ({ batch }) => (
  <div className="row">
    <p className="col-md-6">
      <Folder />{" "}
      <span className="ml-1">
        Batch file name: <strong>{batch?.name}</strong>
      </span>
    </p>
    <p className="col-md-6">
      <Camera />{" "}
      <span className="ml-1">
        Linked camera: <strong>{batch?.camera?.name}</strong>
      </span>
    </p>
    <p className="col-md-12">
      <DateRange />{" "}
      <span className="ml-1">
        Start time of the batch: <strong>{format(new Date(batch?.start), "dd/MM/yyyy, HH:mm:ss")}</strong>
      </span>
    </p>
  </div>
)

export default BatchHeader
