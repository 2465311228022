/**
 * Show an alert message.
 *
 * @param {string} title The main title of the alert.
 * @param {string} text The additional text in the alert.
 * @param {"primary"|"success"|"info"|"warning"|"error"} type The type of the alert to show.
 * @return {JSX.Element}
 * @constructor
 */
const Alert = ({ title, text, type = "success" }) => {
  return (
    <div className={`alert d-flex justify-content-center align-items-center  ${title.length > 0 && 'p-5 mb-10'}`}>
      <div className="d-flex flex-column">
        {title && <h4 className={`mb-1 text-${type}`}>{title}</h4>}
        {text && <span>{text}</span>}
      </div>
    </div>
  );
};

export default Alert;
