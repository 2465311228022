import { useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { useCancelProcessing } from "../queries"

const DeleteBatch = ({ name, batchUUID, state }) => {
  const cancelProcessing = useCancelProcessing(batchUUID)
  const audit = useCameraManagementAPI()

  useEffect(() => {
    if (cancelProcessing.isSuccess) {
      state[1](false)

      // Audit log
      audit.log("# File batch cancel processing", { batchUUID })
    }

  }, [cancelProcessing.isSuccess, state, audit, batchUUID])
  return (
    <Modal centered show={state[0]} size="lg" onHide={() => state[1](false)}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel processing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-weight-light">
          Are you sure you want to cancel the processing of the video file batch: <span className="font-weight-bold">{name}</span> ?
        </h5>
        <div className="mt-5 float-right">
          <Button variant="secondary" onClick={() => state[1](false)}>
            Back
          </Button>
          <Button variant="danger" className="ml-3" onClick={cancelProcessing.mutate}>
            Cancel Processing
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteBatch
