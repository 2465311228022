import { toArray } from "../../../utils/data"

/**
 * Convert a camera settings file to form data.
 *
 * @param {CMCameraSettings} settings The settings JSON file for processing.
 *
 * @return {CMCamera} The camera form data.
 */
export const covertCameraSettingsToFormData = (settings) => {
  const camera: CMCamera = {
    step: 1,
    system: settings.system.id,
    name: settings.camera_name,
    uuid: settings.camera_uuid,
    url: settings.stream_path ?? "",
    description: settings.description,
    newImage: null,
    image: settings.image,
    country: settings.location.country,
    locality: settings.location.locality,
    street: settings.location.street,
    location: settings.location.name,
    latitude: settings.location.lat,
    longitude: settings.location.lon,
    lines: [],
    pairs: [],
    camera_type: settings.camera_type
  }

  // References to entry and exit lines
  const lineReferences = { entry: [], exit: [] }

  // Extract all the lines
  let lineNumber = 1
  for (const lineType of ["entry", "exit"]) {
    for (const lane of toArray(settings[`${lineType}_lines`])) {
      for (const line of toArray(lane.value)) {
        // Parse the new line
        const newLine = {
          number: lineNumber++,
          type: lineType,

          // Position
          points: line.value.line_tuple,

          // Names and ids
          id: line.value.uuid,
          street: "",
          carriageway: line.value.carriageway_name,
          carriagewayUuid: line.value.carriageway_uuid,
          laneUuid: line.value.lane_uuid,
          lane: line.value.lane_name
        }

        // Append
        camera.lines.push(newLine)
        lineReferences[lineType].push(newLine)
      }
    }
  }

  // Create all pairs
  for (const entry of lineReferences["entry"]) {
    for (const exit of lineReferences["exit"]) {

      // Get the pair from the settings file
      const pair = settings.entry_exit_distance.find((pair) => pair[0] === entry.id && pair[1] === exit.id)

      // Append to the list
      camera.pairs.push({
        id: `${entry.id}:${exit.id}`,
        entry: entry.number,
        exit: exit.number,
        distance: pair ? pair[2] : "",
        active: !!pair
      })
    }
  }

  return camera
}

/**
  * 
  * @param {array} lines The lines array to check for duplicates
  * @return Return a boolean indicating if true or false
*/
export const checkValidLines = (lines) => {
  let isValid = true
  const arr = lines
  arr.forEach((item, i) => {
    arr.forEach((res, j) => {
      if (item?.lane?.toLowerCase() === res?.lane?.toLowerCase() && i !== j && item.type === res.type) {
        isValid = false
        return
      }
    })
    return
  })
  return isValid
}
