import React, { useState } from 'react'
import { TbReportSearch } from 'react-icons/tb'

const ReportCard = ({ report, selectedReportType, selectReportType, setReportSelected, setCameraInfo }) => {
    const [isSelected, setisSelected] = useState(false)
    const handleSelectReport = (item) => {
        selectReportType(!isSelected ? item : '')
        setisSelected(!isSelected)
        setReportSelected(true)
        setCameraInfo()
    }
    return (
        <div onClick={() => handleSelectReport(report)}
            className={`mb-5 reportcard ${selectedReportType?.path === report?.path ? 'active' : ''}`}>
            <div className='my-2'>
                <TbReportSearch className='h3' />
                <h4 className='mx-1'>
                    {report.name}
                </h4>
            </div>

            <div className='report-desc'>
                {report.desc}
            </div>
        </div>
    )
}

export default ReportCard