import SpeedIcon from "@material-ui/icons/Speed"
import DriveEtaIcon from "@material-ui/icons/DriveEta"
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder"
import { humanDate, humanDayTime } from "utils/time"
import { humanNumber, humanSpeed } from "utils/functions"
import { Section } from "./Section"

/**
 * Get the peak data for the supplied values.
 *
 * @param {?GranularDataWithChartsDTO} granularData The data to retrieve the peak time from.
 * @param {boolean} morning True to get the peak hour in the morning, false for afternoon.
 *
 * @return {?PeakDTO} The found peak time.
 */
const getPeakTime = (granularData, morning): PeakDTO =>
  granularData?.data.reduce(
    (peak: ?PeakDTO, cell) => {
      const isMorning = +cell.end.substring(11, 13) <= 12
      return (!peak || (cell.count > peak.total_vehicles && ((morning && isMorning) || (!morning && !isMorning))))
        ? { total_vehicles: cell.count, avg_speed: cell.avg_speed, peak_end: cell.end, peak_start: cell.start }
        : peak

    }
    , null)

/**
 * Show the peak of traffic.
 *
 * @param {?TransitSummaryDTO} transitSummary The complete transit summary of the camera.
 * @param {?TransitFlowPointDTO} selectedPoint Currently selected point, or null if nothing is selected.
 * @param {?GranularDataWithChartsDTO} granularData The combined granular data.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Peak = ({ selectedPoint, transitSummary, granularData }) => {

  // Guard: Make sure the both are loaded
  if (!transitSummary || !granularData) {
    return <></>
  }

  // Define the box
  const box = (icon, text) => (
    <div className="col-md-4 mb-2">
      <div className="peak">
        {icon}{` `}{text}
      </div>
    </div>
  )

  const build = (peak: PeakDTO, suffix: string = '') => (
    <Section title={`Peak hour${suffix}`}>
      <div className="row">
        {box(<QueryBuilderIcon />, `${humanDate(peak.peak_start).substring(0, 5)} ${humanDayTime(peak.peak_start)} - ${humanDayTime(peak.peak_end, true)}`)}
        {box(<DriveEtaIcon />, `Total vehicles: ${humanNumber(peak.total_vehicles)}`)}
        {box(<SpeedIcon />, `Avg speed: ${humanSpeed(peak.avg_speed)}`)}
      </div>
    </Section>
  )

  // Show a single peak
  if (selectedPoint) {
    const peak = transitSummary?.transit_points.find((point) => point.transit_point.uuid === selectedPoint?.transit_point.uuid)?.peak
    return peak ? build(peak) : null
  }

  // Load the data
  const morningPeak: PeakDTO = getPeakTime(granularData, true)
  const afternoonPeak: PeakDTO = getPeakTime(granularData, false)

  // Show the peaks
  return (
    <>
      {morningPeak && build(morningPeak, ", morning")}
      {afternoonPeak && build(afternoonPeak, ", afternoon")}
    </>
  )
}

export default Peak
