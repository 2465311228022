import React from "react"
import { Route } from "react-router-dom"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { pairIfNotEmpty } from "../../../../utils/data"
import { Content } from "./Content"

export function ContentRoute({ children, component, render, auditName = null, skipAudit = false, ...props }) {
  const api = useCameraManagementAPI()

  // Humanize the component name for the audit log
  const getComponentName = (component, auditName = null) => {
    let name = auditName || component.displayName || component.name

    // Guard: unknown name
    if (!name || name.length < 1) {
      return "<unknown>"
    }

    // Lower-case space separated words
    name = name.replaceAll(/(.)([A-Z])/g, "$1 $2").toLowerCase()
    name = name.substring(0, 1).toUpperCase() + name.substring(1)

    // Remove common endings
    for (const suffix of ["page", "dashboard"]) {
      const start = name.length - suffix.length - 1
      if (name.substring(start) === " " + suffix) {
        name = name.substring(0, start)
      }
    }

    return name
  }

  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>
        }

        if (!routeProps.match) {
          return null
        }

        if (children) {
          return <Content>{children}</Content>
        }

        if (component) {

          // Automatically log the access
          !skipAudit && api.log(`> ${getComponentName(component, auditName)}`, {
            url: routeProps.location.pathname,
            ...pairIfNotEmpty("params", routeProps.location.match),
            ...pairIfNotEmpty("query", routeProps.location.search, val => Object.fromEntries(new URLSearchParams(val)))
          })

          return <Content>{React.createElement(component, routeProps)}</Content>
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>
        }

        return null
      }}
    </Route>
  )
}
