import { Section } from "./Section";
import { CloseRounded } from "@material-ui/icons";
import { humanNumber, humanSpeed } from "../../../../../../utils/functions";
import React from "react";

/**
 * Render the header that shows the currently selected point.
 *
 * @param {?TransitFlowPointDTO} selectedPoint The currently selected node.
 * @param {?function()} clearSelection The method to call to clear the selection.
 *
 * @return {JSX.Element}
 * @constructor
 */
const SelectedPointHeader = ({ selectedPoint, clearSelection }) => {

  // Guard: make sure there is a selected point
  if (!selectedPoint) {
    return <></>;
  }

  // Define the title and the list of options
  const title = `${selectedPoint.transit_point.type}: ${selectedPoint.transit_point.lane?.name}`;

  return (
    <div className="content-container gr-bg-light">
      <Section title={title} options={[[<CloseRounded />, () => clearSelection()]]}>

        {/* Additional info for the selected point */}
        <div className="row" style={{ fontSize: "1.2rem" }}>

          {/* Show: Total vehicle count */}
          <div className="col-md-6">
            {`Total vehicles: ${humanNumber(selectedPoint.count)}`}
          </div>

          {/* Show: Average speed */}
          <div className="col-md-6">
            {`Avg speed: ${humanSpeed(selectedPoint.avg_speed)}`}
          </div>

        </div>

      </Section>
    </div>
  );

};

export default SelectedPointHeader;
