import { useSelector } from "react-redux";

/**
 * Get the currently selected date range.
 *
 * @return {DateRangeDTO} The active date range.
 */
export default function useStoreDateRange() {
  return useSelector((state: ReduxStore) => state.dateRange);
}
