import { useState, useEffect } from "react"
import { LoadingWrapper } from "components"
import ZoneCanvas from "./ZoneCanvas"
import useAPI from "services/ApiService"
import { State } from "hooks/useLoading"
import { useParams } from "react-router-dom"

/** The configuration for the canvas. */
export const config = {
  aspectRatio: 16 / 9
}

const ZoneWrapper = ({ lines = [], image, updateLines, prevStep }) => {
  const api = useAPI()
  const { cameraId } = useParams()

  // Make sure nothing is rendered until the image is completely downloaded
  const [ready, setReady] = useState(false)

  // Use the API service to load the image in order to use proper authentication headers
  const [imageBlobURL, setImageBlobURL] = useState()

  useEffect(() => {
    api.getImage(image).then(response => setImageBlobURL(response))
  }, [api, image, cameraId])

  // Build the image element
  const imageElement = document.createElement("img")
  imageElement.onload = () => setReady(true)
  if (imageBlobURL) {
    imageElement.src = imageBlobURL
  }

  // Handle loading
  const state = ready
    ? State.READY
    : State.LOADING

  // To prevent unnecessary re-renders we need to do deep copy of lines, so we do not update original array because each line is an object
  return (
    <LoadingWrapper state={[state]} aspectRatio={config.aspectRatio}>
      <ZoneCanvas lines={lines} updateLines={updateLines} prevStep={prevStep} image={imageElement} />
    </LoadingWrapper>
  )
}

export default ZoneWrapper
