import React from "react";
import config from "../CameraImage/CameraConfig";
import "./Tooltip.scss";

/**
 * Show a floating tooltip.
 *
 * @param {TooltipDTO} tooltip The tooltip to show.
 */
export const Tooltip = ({ tooltip }) => {
  return (
    <div className="tooltip fade show bs-tooltip-bottom" role="tooltip" style={{
      top: tooltip.position.y + 30,                         // Move below the pointer
      left: tooltip.position.x - config.tooltip.width / 2,  // Center horizontally
      transform: "translate(12.5px, 0)"                     // This offsets the padding that grid CSS provides
    }}>
      <div className="arrow" style={{ left: tooltip.width / 2 - 5 }} />
      <div className="tooltip-inner flexible" style={{ width: tooltip.width }}>
        {tooltip.text}
      </div>
    </div>
  );
};
