import ErrorTemplate from "./ErrorTemplate";

/**
 * Inform the user that the requested page does not exis.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Error404 = () => <ErrorTemplate
  title="Page not found"
  message="The page you requested does not exist" />;

export default Error404;
