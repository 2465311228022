import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/authSlice";

/**
 * Log the user out of the system.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Logout = () => {
  const dispatch = useDispatch();
  dispatch(logout());
  return <Redirect to="/auth/login" />;
};

export default Logout;
