import { useEffect } from "react"
import { Button, Modal } from "react-bootstrap"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { useUpdateFiles } from "../queries"

const DeleteFile = ({ cameraUUID, name, fileUUID, batchName, batchUUID, start, state }) => {
  const updateFile = useUpdateFiles(batchUUID)
  const audit = useCameraManagementAPI()

  const handleDeleteFile = () => {
    const data = {
      camera_uuid: cameraUUID,
      start,
      name: batchName,
      ordering: [],
      files: [],
      remove: [fileUUID]
    }

    updateFile.mutate(data)

    // Audit log
    audit.log("# File batch delete file", { batchUUID, fileUUID, data })
  }
  useEffect(() => {
    if (updateFile.isSuccess) {
      state[1](false)
    }
  }, [updateFile.isSuccess, state])
  return (
    <Modal centered show={state[0]} size="lg" onHide={() => state[1](false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-weight-light">
          Are you sure you want to permanently delete the file: <span className="font-weight-bold">{name}</span>?
        </h5>
        <div className="mt-5 float-right">
          <Button variant="secondary" onClick={() => state[1](false)}>
            Cancel
          </Button>
          <Button variant="danger" className="ml-3" onClick={handleDeleteFile}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteFile
