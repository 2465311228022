/**
 * Draw trajectories on the canvas.
 */
export const TrajectoriesWorker = () => {
  // eslint-disable-next-line no-restricted-globals
  const me = self;
  me.onmessage = (message) => {
    try {

      /**
       * Draw a transit point on a canvas.
       *
       * @param {CanvasRenderingContext2D} context The context in which to draw.
       * @param {int} x The x coordinate of the center of the point.
       * @param {int} y The y coordinate of the center of the point.
       * @param {string} color The color to use for the circle fill.
       */
      const drawPoint = (context: CanvasRenderingContext2D, x: number, y: number, color: string) => {
        context.beginPath();
        context.arc(x, y, 5, 0, 2 * Math.PI, false);
        context.fillStyle = color;
        context.fill();
        context.lineWidth = 0;
        context.strokeStyle = "rgba(0,0,0,0)";
        context.stroke();
      };

      // Handle the incoming message
      postMessage({ msg: "ECHO", payload: message.data.msg });
      switch (message.data.msg) {

        // Initialize once
        case "INIT":

          // Check if everything is loaded
          if (message.data.payload.canvas) {
            me.offscreenCanvas = message.data.payload.canvas;
            postMessage({ msg: "READY", payload: null });
          }
          return;

        // Draw the lines on the canvas
        case "DRAW":
          const start = new Date().getTime();

          // Make sure the canvas is ready
          if (me.offscreenCanvas) {
            const lines = message.data.payload.lines;

            // Initialize the 2d context
            const canvasContext = me.offscreenCanvas.getContext("2d");
            canvasContext.clearRect(0, 0, me.offscreenCanvas.width, me.offscreenCanvas.height);

            // Darken the canvas
            canvasContext.fillStyle = "rgba(0, 0, 0, .4)";
            canvasContext.fillRect(0, 0, me.offscreenCanvas.width, me.offscreenCanvas.height);

            // Draw each line
            for (const color in lines) {
              if (lines.hasOwnProperty(color)) {

                // Draw all lines
                for (const i in lines[color]) {
                  if (lines[color].hasOwnProperty(i)) {
                    const line = lines[color][i];

                    // Set the color
                    canvasContext.strokeStyle = color;
                    canvasContext.beginPath();

                    // Go to the first line
                    const start = line.shift();
                    canvasContext.moveTo(start[0] * me.offscreenCanvas.width, start[1] * me.offscreenCanvas.height);

                    // Connect the dots to all other lines
                    const len = line.length;
                    for (let j = 0; j < len; j++) {
                      canvasContext.lineTo(line[j][0] * me.offscreenCanvas.width, line[j][1] * me.offscreenCanvas.height);
                    }
                    canvasContext.stroke();

                    // Entry point
                    if (message.data.payload.entryPoints) {
                      drawPoint(canvasContext, start[0] * me.offscreenCanvas.width, start[1] * me.offscreenCanvas.height, "rgba(132, 195, 65, 0.9)");
                    }

                    // Exit point
                    if (message.data.payload.exitPoints) {
                      const end = line[line.length - 1];
                      drawPoint(canvasContext, end[0] * me.offscreenCanvas.width, end[1] * me.offscreenCanvas.height, "rgba(237, 41, 57, 0.9)");
                    }
                  }
                }
              }
            }

            // Notify that the job has been completed
            postMessage({
              msg: "DONE", payload: {
                elapsed: new Date().getTime() - start,
                lineCount: lines.length
              }
            });
          }
          break;

        default:
          postMessage({ msg: "ERROR", payload: { error: "Unknown message", message: message.data.msg } });
      }

    } catch (error) {
      postMessage({ msg: "ERROR", payload: error });
    }
  };
};


/**
 * Draw Vehicles Heatmap on the canvas
 */
export const HeatmapWorker = () => {
  // eslint-disable-next-line no-restricted-globals
  const me = self;
  me.onmessage = (message) => {
    try {
      switch (message.data.msg) {
        // Initialize once
        case "INIT":

          // Check if everything is loaded
          if (message.data.payload.canvas) {
            me.offscreenCanvas = message.data.payload.canvas;
            postMessage({ msg: "READY", payload: null });
          }
          return;
        case "PROCESS":
          const start = new Date().getTime();
          const { trajectories, filterVehicleType, width, height } = message.data.payload;

          const heatmapData = [];

          for (const vehicleType in trajectories) {
            if (trajectories.hasOwnProperty(vehicleType) && filterVehicleType[vehicleType]) {
              const data = trajectories[vehicleType];
              for (let i = 0; i < data.lines.length; i++) {
                const trajectory = data.lines[i];
                for (let j = 0; j < trajectory.length; j++) {
                  const [x, y] = trajectory[j];
                  heatmapData.push({
                    x: x * width,
                    y: y * height,
                    value: 1
                  });
                }
              }
            }
          }

          postMessage({
            msg: "DONE",
            payload: {
              heatmapData,
              elapsed: new Date().getTime() - start
            }
          });
          break;

        default:
          postMessage({ msg: "ERROR", payload: { error: "Unknown message", message: message.data.msg } });
      }
    } catch (error) {
      postMessage({ msg: "ERROR", payload: error.toString() });
    }
  };
};