import { useEffect, useRef } from "react"
import { Table, Button, Form } from "react-bootstrap"
import { Formik } from "formik"
import Select from "react-select"
import { useHistory } from "react-router-dom"
import { useMe } from "hooks/useMe"
import useCameraManagementAPI from "../../../../../services/CameraManagementService"
import { useCreateUser, useAddUserToTenant } from "../../queries"
import LargeCard from "components/Structure/LargeCard"

export const AddUserRole = ({ details: { userDetails, setUserDetails } }) => {
  const history = useHistory()
  const audit = useCameraManagementAPI()
  const userAssignedTenants = useRef([])
  const { userManagingTenantsIds } = useMe()

  // Get details from state
  const { firstName, lastName, email, password, tenants } = userDetails

  const createUser = useCreateUser()
  const addUserToTenant = useAddUserToTenant()

  const onSubmit = (values) => {
    const arrKeys = Object.keys(values)
    const data = {
      email_address: email,
      password,
      first_name: firstName,
      last_name: lastName,
      tenant_manager: values[arrKeys[0]].value === "Admin"
    }
    createUser.mutate([data, values[arrKeys[0]].id])
    userAssignedTenants.current = { ...values }

    // Audit log
    audit.log("# User create", { system_id: values[arrKeys[0]].id, ...data })
  }

  const handleBack = () => {
    setUserDetails({ ...userDetails, password: "" })
  }

  // Define Select input initial values
  const initialValues = {}
  tenants?.forEach((tenant) => {
    initialValues[tenant.value] = { id: tenant.id, value: "Viewer", label: "Viewer" }
  })
  // Define Select input options for each tenant
  const selectOptions = {}
  tenants?.forEach((tenant) => {
    selectOptions[tenant.value] = [{ id: tenant.id, value: "Viewer", label: "Viewer" }]
    selectOptions[tenant.value].push({ id: tenant.id, value: "Admin", label: "Admin" })
  })

  useEffect(() => {
    if (createUser?.data?.id) {
      Object.keys(userAssignedTenants.current).forEach((tenant) => {
        addUserToTenant.mutate({
          email_address: email,
          tenant_manager: userAssignedTenants.current[tenant].value === "Admin",
          system_id: userAssignedTenants.current[tenant].id
        })
      })
      history.push("/user-management")
    }
  }, [createUser.data, addUserToTenant, userManagingTenantsIds, history, email])

  return (
    <div className="col-xl-6 offset-xl-3">
      <LargeCard title="Change user role">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Table borderless hover={false}>
                <thead>
                  <tr className="bg-white">
                    <th className="bg-white" width="35%">
                      Tenant
                    </th>
                    <th className="bg-white">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {tenants?.map((tenant, id) => (
                    <tr key={tenant.id}>
                      <td style={{ verticalAlign: "middle" }}>{tenant.value}</td>
                      <td>
                        <Select
                          name={tenant.value}
                          defaultValue={{ value: "Viewer", label: "Viewer" }}
                          onChange={(value) => handleChange({ target: { name: tenant.value, value } })}
                          options={selectOptions[tenant.value]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="float-right">
                <Button variant="secondary" size="lg" className="mt-9 mr-3" onClick={handleBack}>
                  Back
                </Button>
                <Button variant="primary" size="lg" type="submit" className="mt-9">
                  <span className="mx-2">Confirm</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </LargeCard>
    </div>
  )
}
