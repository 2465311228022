import { useState } from "react";
import { Edit, Delete, Person, Email, LocalPhone } from "@material-ui/icons";
import EditTenant from "../forms/EditTenant";
import DeleteTenant from "../forms/DeleteTenant";
import TableRowAction from "components/Table/TableRowAction";

const Td = (props) => <td className="text-left py-8" style={{ verticalAlign: "middle" }} {...props} />;

const TenantTableRow = ({ tenant }) => {
  const {
    name,
    contact: { country, address, first_name, last_name, email_address, phone }
  } = tenant;

  // State related to Forms
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteTenant, setShowDeleteTenant] = useState(false);

  return (
    <>
      <tr>
        <Td>{name}</Td>
        <Td>{address}</Td>
        <Td>{country}</Td>
        <Td>
          <p>
            <Person />
            <span className="ml-2">
              {first_name} {last_name}
            </span>
          </p>
          <p>
            <Email />
            <span className="ml-2">{email_address}</span>
          </p>
          <p>
            <LocalPhone />
            <span className="ml-2">
              ({phone?.country_code}) {phone?.number}
            </span>
          </p>
        </Td>
        <Td>
          {name !== "Greenroads" && (
            <TableRowAction
              actions={[
                { icon: <Edit />, title: "Edit tenant", setState: () => setShowEditForm(true) },
                { icon: <Delete />, title: "Delete tenant", setState: () => setShowDeleteTenant(true) }
              ]}
            />
          )}
        </Td>
      </tr>
      <EditTenant tenant={tenant} show={showEditForm} exitModal={() => setShowEditForm(false)} />
      <DeleteTenant tenant={tenant} show={showDeleteTenant} exitModal={() => setShowDeleteTenant(false)} />
    </>
  );
};

export default TenantTableRow;
