import { useDispatch } from "react-redux";
import { logout } from "redux/authSlice";
import { Button, Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import { useMutationUser } from "utils/user-management";
import { editProfileSchema } from "utils/forms-schema";
import AuthService from "services/AuthService";
import { ErrorMessage } from "components/UserManagement/ErrorMessage";

const ProfileSettings = ({ user, state, dispatch }) => {
  const reduxDispatch = useDispatch();
  const { firstname, lastname } = user;
  const editProfileSuccessMessage = "Your name has been changed successfully !";
  const mutation = useMutationUser((data) => AuthService.editMyProfile(data), editProfileSuccessMessage);
  const onSubmit = (values) => {
    mutation.mutate({ first_name: values.firstname, last_name: values.lastname });
    dispatch({ type: "close" });
  };

  const openPasswordModal = () => {
    dispatch({ type: "password" });
  };

  //If user changes his name => logout
  if (mutation.isSuccess) {
    reduxDispatch(logout());
  }

  return (
    <Modal centered show={state.settings} size="md" onHide={() => dispatch({ type: "close" })}>
      <Modal.Header closeButton>
        <Modal.Title className="h4">Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            firstname,
            lastname
          }}
          validationSchema={editProfileSchema}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-7">
                <Form.Label className="h6">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First name"
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  className="mb-3"
                />
                <Form.Control type="text" placeholder="Last name" name="lastname" value={values.lastname} onChange={handleChange} />
                <ErrorMessage>{errors.firstName}</ErrorMessage>
                <ErrorMessage>{errors.lastName}</ErrorMessage>
              </Form.Group>

              <Form.Group className="mb-2 d-flex flex-column align-items-start">
                <Form.Label className="h6">Password</Form.Label>
                <p className="cursor-pointer d-inline-block" style={{ color: "#009dff" }} onClick={openPasswordModal}>
                  Change password
                </p>
              </Form.Group>

              <div className="mt-9 float-right">
                <Button variant="secondary" onClick={() => dispatch({ type: "close" })}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" className="ml-3">
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
export default ProfileSettings;
