import { Select, SelectWithFilter } from 'components/Reports/Fields'
import React, { useContext, useEffect } from 'react'
import { MCLayoutContext } from './MCLayout'

const SelectLines = ({ lines, item, index, id, combinedCameras }) => {
    const { selectedEntryLines, selectedExitLines,
        setSelectedEntryLines, setSelectedExitLines, setLines } = useContext(MCLayoutContext)

    const updateLinePair = (e, i, item, type) => {
        const cam = e,
            parsedCam = { ...e, id: i }

        if (type === 'entry') {
            setSelectedEntryLines(parsedCam)
        }
        else {
            setSelectedExitLines(parsedCam)
        }

        const res = lines.slice();
        res[i] = { ...item, [type]: cam };
        setLines(res)
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <SelectWithFilter
                    handleChange={(e) => updateLinePair(e, index, item, 'entry')}
                    state={[item?.entry, setSelectedEntryLines]}
                    name="Entry line"
                    empty='Select line'
                    options={combinedCameras?.entry_res.map(camera => {
                        return {
                            id: camera.uuid, label: `${camera.lane_name} (${camera?.camera})`, value: { ...combinedCameras?.system, ...camera }
                        }
                    })}
                />

            </div>
            <div className="col-md-6">
                <SelectWithFilter
                    handleChange={(e) => updateLinePair(e, index, item, 'exit')}
                    state={[item?.exit, setSelectedExitLines]}
                    name="Exit line"
                    empty='Select line'
                    options={combinedCameras?.exit_res.filter(res => {
                        if (item.id === selectedEntryLines.id) {
                            return res.camera !== selectedEntryLines.camera
                        }
                        else {
                            return res
                        }
                    }).map(camera => {
                        return {
                            id: camera.uuid, label: `${camera.lane_name} (${camera?.camera})`, value:
                                { ...combinedCameras?.system, ...camera }
                        }
                    })}
                />
            </div>
        </div>
    )
}

export default SelectLines