import { useFormik } from "formik"
import { Form, Table } from "react-bootstrap"
import cameraConfig from "../../../../components/CameraImage/CameraConfig"
import { FormText, SelectInput, StringInput } from "../../../../components/Reports/Fields"
import { cameraManagementStep5, formikDefault } from "../../../../utils/forms-schema"
import LargeCard from "../../../../components/Structure/LargeCard"
import CameraCanvas from "../Form/CameraCanvas/CameraCanvas"
import { useCameraImage } from "../../../../hooks/useCameraImage"
import { stepTitle } from "../form"
import { useEffect, useState } from "react"
import { checkValidLines } from "../utils"
import { CiCircleMore } from 'react-icons/ci'
import { RiDeleteBinLine } from 'react-icons/ri'
import useCameraManagementAPI from "services/CameraManagementService"
import { useLoading } from "hooks/useLoading"
/**
 * Define the parameters of the lines.
 *
 * @return {JSX.Element}
 * @constructor
 */
const Step5 = ({ title, camera, setValues, setLines, prevStep }) => {
  const image = useCameraImage(camera)
  const lines = [...camera.lines]
  const [tempLines, setTempLines] = useState(lines)
  const api = useCameraManagementAPI()


  // Define form
  const forSelect = (data, key = "name") => (data ?? []).map(item => ({ value: item[key], label: item.name }))
  const formik = useFormik({
    ...formikDefault,
    initialValues: { lines },
    validationSchema: cameraManagementStep5,
    onSubmit: setValues
  })
  const disabled = !checkValidLines(formik.values.lines)
  const handleDeleteLine = (item, ind) => {
    const res = tempLines.filter(line => item.id !== line.id).map((obj, i) => {
      return {
        ...obj,
        number: i + 1
      }
    })
    setTempLines(res)
    setLines({ lines: res })
  }

  const[positions: CMPositions, positionsLoading] = useLoading(() => api.getLinePositions())
  // console.log(positions)

  console.log(lines)
  return (
    <LargeCard title={stepTitle(title, camera, "Names of carriageways & lanes")}>
      <Form noValidate onSubmit={formik.handleSubmit}>

        <FormText>
          <p>  Please insert the name for the Carriageways / Lanes across the lines you created.</p>
          <strong>Instructions</strong>
          <ul>
            <li>It is recommended that <strong>carriageways</strong> adopt the naming convention:
              <br />“[locality name] - [carriageway name]”
              (e.g. New York - 7th Avenue)
            </li>
            <br />
            <li> Similarly <strong>lanes</strong> make up a carriageway. Thus, it is proposed to adopt the convention:
              <br /> “[locality name] - [carriageway name] - [lane direction or position]”
              (e.g. New York - 7th Avenue - Southwards). <br />
              This naming convention helps when navigating the dashboard and creating reports.
            </li>
            <br />
            <li>
              Different entries cannot share the same lane name. Similarly for exits.
              Please use the recommended convention to get unique names. (An entry and an exit can share the same name)
            </li>
          </ul>
        </FormText>

        <div className="row">

          {/* Set carriageway and lane names for all lines */}
          <div className="col-md-5 step">

            <Table bordered hover={false} className="v-align">
              <thead>
                <tr>
                  <th width="50" className="text-center">Line</th>
                  <th width="*">Carriageway</th>
                  <th width="*">Lane</th>
                  <th width="120">Direction</th>
                  <th><CiCircleMore className="more" /></th>
                </tr>
              </thead>

              <tbody>
                {(tempLines ?? []).map((line, index) => (
                  <tr key={index}>

                    {/* Line number */}
                    <td className="text-center">
                      <h3 style={{ color: cameraConfig.point.type[line.type].active.fill }}>{line.number}</h3>
                    </td>

                    {/* Carriageway */}
                    <td>
                      <StringInput
                        name={`lines.${index}.carriageway`}
                        formik={formik} />
                    </td>

                    {/* Line */}
                    <td>
                      <StringInput
                        name={`lines.${index}.lane`}
                        formik={formik} />
                    </td>
                    <td>
                      <SelectInput name={`lines.${index}.direction`} options={forSelect(positions, 'label')} formik={formik}  />
                    </td>
                    <td>
                      {line.new &&
                        <div className='table-cont'
                          onClick={() => handleDeleteLine(line, index)}>
                          <RiDeleteBinLine className='table-icon' />
                        </div>}
                    </td>
                  </tr>
                ))}
              </tbody>

            </Table>
            {disabled && <div className="error">Lane names should be unique</div>}
          </div>

          {/* The image that shows the locations of lines */}
          <div className="col-md-7">
            <Table bordered hover={false} className="v-align">
              <thead>
                <tr>
                  <th>Entry and exit lines preview</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ position: "sticky", top: 0 }}>
                    <CameraCanvas image={image} lines={tempLines} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        <div className="form-buttons">
          <button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>
          <button disabled={disabled} className="btn btn-primary" type="submit">Next</button>
        </div>
      </Form>
    </LargeCard>
  )
}

export default Step5
