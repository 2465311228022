import { Button, Modal, Form } from "react-bootstrap"
import { Formik } from "formik"
import Select from "react-select"
import { editUserSchema } from "utils/forms-schema"
import { useMe } from "hooks/useMe"
import { ErrorMessage } from "components/UserManagement/ErrorMessage"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { useAddUserToTenant, useRemoveUserFromTenant, useUpdateUser, useUsersQuery } from "../queries"

const EditUser = ({ user, show, exitModal }) => {
  const audit = useCameraManagementAPI()
  const { userId, userManagingTenantsIds } = useMe()
  const { id, first_name, last_name, email_address, groups: tenants } = user
  const { users } = useUsersQuery(userManagingTenantsIds)

  // Tenants managed by logged-in user
  const managerTenants = users?.find((user) => user.id === userId)?.admin_groups

  const updateUser = useUpdateUser(id)
  const addUserToTenant = useAddUserToTenant()
  const removeUserFromTenant = useRemoveUserFromTenant(id)

  const onSubmit = (values) => {
    if (values.first_name !== first_name || values.last_name !== last_name) {
      const userData = {
        tenantID: tenants[0].system_id,
        tenant_manager: false,
        details: { first_name: values.first_name, last_name: values.last_name }
      }

      updateUser.mutate(userData)
      audit.log("# User edit", { user, newData: userData })
    }

    // Define tenants ids that have been added or removed from
    const addedTenantsIds = values.tenants?.filter((tenant) => !tenants?.some((tn) => tn.system_id === tenant.id))?.map((tenant) => tenant.id)
    const removedTenantsIds = tenants
      ?.filter((tenant) => !values.tenants.some((tn) => tn.id === tenant.system_id))
      ?.map((tenant) => tenant.system_id)

    if (addedTenantsIds.length > 0) {
      addedTenantsIds.forEach((system_id) => {
        addUserToTenant.mutate({ email_address, tenant_manager: false, system_id })
      })
    }
    if (removedTenantsIds.length > 0) {
      removedTenantsIds.forEach((system_id) => {
        removeUserFromTenant.mutate(system_id)
      })
    }
    exitModal()
  }

  return (
    <Modal centered show={show} size="md" onHide={exitModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit user : {email_address}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {users ? (
          <Formik
            initialValues={{
              first_name,
              last_name,
              tenants: tenants.map((group) => ({ id: group.system_id, value: group.name, label: group.name }))
            }}
            validationSchema={editUserSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>First name </Form.Label>
                  <Form.Control type="text" name="first_name" value={values.first_name} onChange={handleChange} />
                  <ErrorMessage>{errors.first_name}</ErrorMessage>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Last name </Form.Label>
                  <Form.Control type="text" name="last_name" value={values.last_name} onChange={handleChange} />
                  <ErrorMessage>{errors.last_name}</ErrorMessage>
                </Form.Group>
                <Form.Label>Tenants</Form.Label>
                <Select
                  name="tenants"
                  isMulti
                  defaultValue={tenants?.map((tenant) => ({ id: tenant.system_id, value: tenant.name, label: tenant.name }))}
                  onChange={(value) => handleChange({ target: { name: "tenants", value } })}
                  options={[...managerTenants]?.map((tenant) => ({
                    id: tenant.system_id,
                    value: tenant.name,
                    label: tenant.name
                  }))}
                />
                <ErrorMessage>{errors.tenants}</ErrorMessage>
                <div className="mt-9 float-right">
                  <Button variant="secondary" onClick={exitModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit" className="ml-3">
                    Confirm
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <p>Loading...</p>
        )}
      </Modal.Body>
    </Modal>
  )
}
export default EditUser
