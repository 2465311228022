import { useFormik } from "formik"
import { Form } from "react-bootstrap"
import LargeCard from "../../../../components/Structure/LargeCard"
import { FileInput, FormHeader, FormText, StringInput } from "../../../../components/Reports/Fields"
import { cameraManagementStep3, formikDefault } from "../../../../utils/forms-schema"
import { LoadingImage } from "../../../../components"
import { stepTitle } from "../form"
import { config } from "./CameraCanvas/CameraCanvas"
import { validateStreamUrl } from "utils/functions"

/**
 * Set the basic information about the camera.
 *
 * @param camera
 * @param title
 * @param setValues
 * @param prevStep
 * @return {JSX.Element}
 * @constructor
 */
const Step3 = ({ camera, title, setValues, prevStep }) => {

  // Define form
  const formik = useFormik({
    ...formikDefault,
    initialValues: camera,
    validationSchema: cameraManagementStep3,
    onSubmit: setValues
  })

  // Show the proper image
  const image = formik.values.newImage || camera.newImage || camera.image?.url
  const preview = image ? (
    <LoadingImage className="img-fluid mb-2" src={image} alt="Camera image" aspectRatio={config.aspectRatio} />
  ) : (
    <div className="well info mt-0 mb-3" style={{ display: "none" }}>
      <h5>Auto-generated camera screenshot image</h5>
      If a video stream / video file was provided the system will generate a camera screenshot image in the next few minutes
    </div>
  )
  const isValidRtspUrl = validateStreamUrl(formik.values.url)

  return (
    <LargeCard title={stepTitle(title, camera, "Source and image")}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="row">

          {/* URL for the live feed */}
          <div className="col-md-6">

            <FormHeader>Source</FormHeader>
            <FormText>
              <p>Video can be processed either from a live video feed URL, or from manually uploaded files:</p>
              <ul>
                <li><b>Live video stream:</b> enter the URL in the field below. The url should be a valid rtsp url e.g rtsp://localhost:8554/mystream or a valid .m3u8 url
                  e.g: https://example.com/stream/index.m3u8</li>
                <li><b>Uploaded video files:</b> leave the following field empty, create the camera and then go to "Camera Files" in the main menu</li>
              </ul>
            </FormText>

            {/* URL input */}
            <StringInput
              label="The URL to the camera feed (leave empty if camera will only process manually uploaded files)"
              name="url"
              placeholder=""
              formik={formik} />
            {formik.values.url && formik.values.url.length > 0 && !isValidRtspUrl && <div className="fv-plugins-message-container">
              <div className="fv-help-block">Enter a valid rtsp url</div>
            </div>}
          </div>

          {/* Camera image */}
          <div className="col-md-6">

            <FormHeader>Camera image</FormHeader>
            <FormText>
              <p>
                A still image of the camera that you are setting up is required. Results of camera analysis are plotted on this image.<br />
                You can either manually upload an image yourself or else the system can automatically generate an image.
              </p>
              <ul>
                <li><b>Manual upload:</b> upload the image that will be used by clicking on the link below</li>
                <li><b>Auto-generated:</b> do not upload image, the system will generate a camera screenshot image from the video feed or file in the next few minutes</li>
              </ul>
            </FormText>

            {/* Upload image */}
            <FileInput
              name="newImage"
              button={image ? "Click here to change the image" : "Click here to manually upload an image"}
              preview={preview}
              accept="image/jpeg, image/png"
              formik={formik} />
          </div>
        </div>

        <div className="form-buttons">
          <button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>
          <button disabled={!isValidRtspUrl} className="btn btn-primary" type="submit">Next</button>
        </div>

      </Form>
    </LargeCard>
  )
}

export default Step3
