import React from "react"
import { Button } from "react-bootstrap"
import { useState } from "react"
import { AppModal } from "./AppModal"

/**
 * @param {?*} params The parameters to pass into the execute function.
 * @param {{title: string, question: string, button: string, buttonStyle: string, isVisible: boolean, show: function, hide: function, execute: function}} hook The return value from the useDestructiveConfirmModal() hook.
 */
export const ConfirmModal = ({ params, hook }) => {

  return !hook.isVisible ? <></> : (
    <AppModal title={hook.title}>
      <h5 className="font-weight-light">
        {hook.question}
      </h5>
      <div className="mt-5 float-right">
        <Button variant="secondary" onClick={hook.hide}>
          Cancel
        </Button>
        <Button variant={hook.buttonStyle} className="ml-3" onClick={() => hook.execute(params)}>
          {hook.button}
        </Button>
      </div>
    </AppModal>
  )

}

/**
 * Convenient hook to use for the destructive confirmation modal.
 *
 * @param {string} title The title of the modal.
 * @param {string} question The question to ask, without punctuation.
 * @param {function} execute What to do when confirmed.
 * @param {string} button The label of the confirm button.
 * @param {string} buttonStyle The style to use for the button ("primary" or "danger").
 *
 * @typedef {{title: string, question: string, button: string, buttonStyle: string, isVisible: boolean, show: function, hide: function, execute: function}} ConfirmModalHook
 *
 * @return ConfirmModalHook
 */
export const useConfirmModal = (title, question, execute, button = "Confirm", buttonStyle = "primary") => {
  const [isVisible, setIsVisible] = useState(false)

  // Show and hide
  const show = () => setIsVisible(true)
  const hide = () => setIsVisible(false)

  return { title, question, button, buttonStyle, isVisible, show, hide, execute }
}
