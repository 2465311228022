import React from 'react'
import { CameraDetails } from './CameraDetails'
import CameraLayout from './CameraLayout'

const DetailsWrapper = () => {
    return (
        <CameraDetails />
        // <CameraLayout>
        //     <CameraDetails />
        // </CameraLayout>
    )
}

export default DetailsWrapper