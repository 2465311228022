import React from 'react'

const ProgressBar = ({ file, currentChunkIndex, chunkSize, waiting, width = '70%' }) => {
    return (
        <div className="bar-content" style={{ marginTop: '2em', width }}>
            <div className="bar-flex" style={{ width: '100%', marginLeft: 0 }}>
                <p>
                    {file.name}
                </p>
                <>
                    <div className="progress" style={{ height: 4 }}>
                        <div className="progress-bar" style={{ padding: 0, width: waiting ? '0' : file.finalFilename ? '100%' : `${Math.round(currentChunkIndex / Math.ceil(file.size / chunkSize) * 100)}%` }} role="progressbar" />
                    </div>

                    <p className="text-dark-50 text-sm">{waiting ? '0%' : file.finalFilename ? '100%' : `${Math.round(currentChunkIndex / Math.ceil(file.size / chunkSize) * 100)}%`}</p>
                </>
            </div>
        </div>
    )
}

export default ProgressBar