import { useEffect, useState } from "react"
import { State } from "../../hooks/useLoading"
import { noImage } from "../../utils/static"
import useAPI from "../../services/ApiService"
import { defaultCameraImage } from "../../utils/camera"
import { LoadingWrapper } from "../LoadingWrapper/LoadingWrapper"

/**
 * Show a loader while the image is being downloaded.
 *
 * @param {string} src The source from where to load the image.
 * @param {int} height The minimum height of the loading screen.
 * @param {float} aspectRatio The aspect ratio to keep.
 * @param {{}} props All other passed properties.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const LoadingImage = ({ src, height, aspectRatio, ...props }) => {
  const [image, setImage] = useState()
  const api = useAPI()

  // Make sure the image is loaded and only then apply it
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    // Image is in a data form
    if (src && typeof src === "string" && src.substring(0, 11) === "data:image/") {
      return setImage(src)
    }

    // Load the image
    api.getImage(src ?? defaultCameraImage.url, signal)
      .then(response => {
          setImage(response)
      })
      .catch(err => {
          setImage(noImage)
        console.warn(`Unable to load the image: ${src} (${err})`)
      })

    return () => {
      controller.abort()
    }
  }, [api, src])
  return (
    <LoadingWrapper state={image ? State.READY : State.LOADING} height={height} aspectRatio={aspectRatio}>
      <img alt="" src={image} {...props} />
    </LoadingWrapper>
  )
}
