/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import AuthPage from "./modules/Auth/pages/AuthPage";
import Logout from "./modules/Auth/pages/Logout";
import AuthService from "../services/AuthService";
import CameraLayout from "./pages/Cameras/CameraLayout";

export function Routes() {
  const isAuthorized = useSelector((state: StoreDTO) => AuthService.isValid(state?.auth?.auth));

  return (
    <Switch>
      {
        // If not authorized show the login page, otherwise disallow "/auth" path
        !isAuthorized
          ? <Route><AuthPage /></Route>
          : <Redirect from="/auth/login" to="/" />
      }

      <Route path="/logout" component={Logout} />
      <CameraLayout>
        <Layout>
          <BasePage />
        </Layout>
      </CameraLayout>
    </Switch>
  );
}
