import { Table } from "react-bootstrap"
import cameraConfig from "../../../../components/CameraImage/CameraConfig"
import { FormText } from "../../../../components/Reports/Fields"
import LargeCard from "../../../../components/Structure/LargeCard"
import { LoadingWrapper } from "../../../../components"
import { useLoading } from "../../../../hooks/useLoading"
import { isEmpty, isNotEmpty } from "../../../../utils/functions"
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import CameraCanvas from "../Form/CameraCanvas/CameraCanvas"
import { stepTitle } from "../form"
import Map from "./Map/Map"
import submit from "./submit"
import { useQueryClient } from "react-query"
import toast from "react-hot-toast"

/**
 * Overview and execute.
 *
 * @param camera
 * @param title
 * @param onComplete
 * @param prevStep
 * @param step
 * @return {JSX.Element}
 * @constructor
 */
const Step7 = ({ camera, title, onComplete, prevStep }) => {
  const queryClient = useQueryClient()
  const api = useCameraManagementAPI()

  // Get the list of systems for this user
  const [systems: CMSystemResponse[], systemsLoadingState] = useLoading(() => api.systems())

  // Ignore unpaired lines
  camera.pairs = camera.pairs.filter(pair => pair.active)
  // Create the camera
  const execute = async () => {
    submit(api, { ...camera, uuid: null }).then(async (data) => {
      console.log(data)
      queryClient.invalidateQueries("CameraManagement:List")
      try {
        await api.createMultiScene({ existingCameraUuid: camera?.uuid, newCameraUuid: data?.data?.camera?.uuid })
        onComplete()
        toast.success('Multi-Scene created')
      } catch (error) {
        toast.error('An error occurred:', JSON.stringify(error))
      }

    }).catch(err => {
      toast.error(JSON.stringify(err))
    })
  }

  // Show the proper image
  const image = camera.newImage || camera.image?.url

  return (
    <LargeCard title={stepTitle(title, camera, "Overview and confirmation")}>
      <LoadingWrapper state={systemsLoadingState}>

        {/* If image is not set we cannot show the lines */}
        {isEmpty(camera.image ?? camera.newImage) && (
          <FormText>
            <p>Steps 4, 5 and 6 cannot be completed without an image.</p>
            <p>
              As no image has been provided, our system will automatically generate one from the {camera.url ? "video stream" : "first uploaded file"}. <br />
              Afterwards you can proceed to finalize the parameters of this camera.
            </p>
            <p>To manually upload an image now, press the "Back" button bellow.</p>
          </FormText>
        )}

        {/* If lines are not drawn but (but image is there) */}
        {!isEmpty(camera.image ?? camera.newImage) && isEmpty(camera.lines) && (
          <FormText>
            <p>Steps 5 and 6 are skipped as no lines have been defined in step 4.</p>
            <p>To define the lines, press the "Back" button bellow.</p>
          </FormText>
        )}

        <div className="row">

          {/* Camera parameters */}
          <div className="col-md-7">
            <Table bordered hover={false}>
              <thead>
                <tr>
                  <th colSpan="2">Camera parameters</th>
                </tr>
              </thead>
              <tbody>

                {/* If the user has more than one system */}
                {(systems ?? []).length > 1 && (
                  <tr>
                    <td width="170">System</td>
                    <td width="*">{(systems ?? []).find(system => system.id === camera.system)?.name}</td>
                  </tr>
                )}

                <tr>
                  <td width="170">Camera name</td>
                  <td width="*">{camera.name}</td>
                </tr>

                <tr>
                  <td>Description</td>
                  <td style={{ whiteSpace: "pre" }}>{camera.description}</td>
                </tr>

                <tr>
                  <td>Location</td>
                  <td>{camera.locality}, {camera.country}<br />{camera.street}</td>
                </tr>

                <tr>
                  <td>Source</td>
                  <td>
                    {camera.url ? camera.url : "Manual video file upload"}
                    {!camera.url && (<p className="well info">After you confirm this camera, please go to "<a href="/camera-files" target="_blank">Camera Files</a>" in the main menu and upload the video files for processing.</p>)}
                  </td>
                </tr>

              </tbody>
            </Table>
          </div>

          {/* Map preview */}
          <div className="col-md-5">
            <Table bordered hover={false} className="v-align">
              <thead>
                <tr>
                  <th>Location on map</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Map locations={[[camera.latitude, camera.longitude]]} height={250} />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

        </div>

        {/* If image is not set we cannot show the lines */}
        {isNotEmpty(camera.image ?? camera.newImage) && (
          <div className="row mt-5">

            {/* The image that shows the locations of lines */}
            <div className="col-md-7">
              <Table bordered hover={false} className="v-align">
                <thead>
                  <tr>
                    <th>Camera image used for processing</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <CameraCanvas image={image} lines={camera.lines} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            {/* List of entry-exit pairs and its travel distances */}
            <div className="col-md-5">
              <Table bordered hover={false} className="v-align">
                <thead>
                  <tr>
                    <th colSpan="3">Entry-exit pairs with travel distances</th>
                  </tr>
                </thead>

                <tbody>

                  {/* Show the defined pairs */}
                  {isNotEmpty(camera.pairs) && camera.pairs.map(pair => (
                    <tr key={pair.id}>

                      {/* Which entry and which exit, connected or not */}
                      <td width="50" className="text-center"><h3 style={{ color: cameraConfig.point.type.entry.active.fill }}>{pair.entry}</h3></td>
                      <td width="50" className="text-center"><h3 style={{ color: cameraConfig.point.type.exit.active.fill }}>{pair.exit}</h3></td>

                      {/* What is the distance, if entry and exit are connected */}
                      <td width="*">{pair.distance} m</td>
                    </tr>
                  ))}

                  {/* No lines have been defined */}
                  {isEmpty(camera.pairs) && (
                    <tr>
                      <td colSpan="3">
                        <FormText className="text-center m-0">No lines have been defined</FormText>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        <div className="form-buttons">
          <button className="btn btn-default" type="button" onClick={() => prevStep()}>Back</button>
          <button className="btn btn-primary" type="submit" onClick={() => execute()}>Confirm</button>
        </div>

      </LoadingWrapper>
    </LargeCard>
  )
}

export default Step7
