import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { DateRange } from "@material-ui/icons"
import { DateRangePickerModal } from ".."
import { MultiSelect, ReadOnly } from "./Fields"
import { formatDateRange, validateDateTime } from "utils/time"
import { VideocamTwoTone } from "@material-ui/icons"
import { parseReportPath, readReportParamsFromQuery } from "../../app/pages/Reports/Reports"
import { getVehicleTypes } from "../../config/VehicleTypes"
import { getCarriageWayForLane } from "../../utils/carriageways"

/** Available formatting options for the multi-select. */
const MultiSelectOptionFormat = {
  vehicle: (vehicle) => ({
    value: vehicle.type,
    label: vehicle.plural,
    id: vehicle.type
  }),
  carriageway: (carriageway) => ({
    value: carriageway.uuid,
    label: carriageway.name,
    id: carriageway.uuid
  }),
  lane: (camera: CameraDTO, lane) => ({
    value: lane.uuid,
    label: lane.name,
    id: lane.uuid,
    carriageway: getCarriageWayForLane(camera, lane.uuid)?.name
  })
}

/**
 * Get the list of initially selected lanes.
 *
 * @param params The parameters for the report.
 * @param {CameraDTO} camera The camera to get the lanes in.
 *
 * @return {{}} The list of lanes.
 */
const getInitialValues = (params, camera: CameraDTO) => {
  const carriageways = []
  const lanes = []

  // Iterate over the carriageways
  for (const carriageway of camera?.carriageways ?? []) {
    if (params.carriageways?.includes(carriageway.uuid)) {
      carriageways.push(MultiSelectOptionFormat.carriageway(carriageway))
      for (const lane of carriageway.lanes) {
        if (params.lanes.includes(lane.uuid)) {
          lanes.push(MultiSelectOptionFormat.lane(camera, lane))
        }
      }
    }
  }

  // Extract vehicles
  const vehicles = getVehicleTypes()
    .filter((vehicle) => params.vehicles?.includes(vehicle.type))
    .map((vehicle) => MultiSelectOptionFormat.vehicle(vehicle)) ?? []

  return { carriageways, lanes, vehicles }
}

/**
 * @param {string} report The name of the report.
 * @param {CameraDTO} camera The camera for which this report is for.
 * @param {function} updateParams param state updater
 * @param {boolean} isReportForm Check if filters will be rendered at report form page
 * @param {string} selectedCamera param camera current state
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function ReportFilters({
  report,
  camera,
  updateParams,
  isReportForm = false,
  noVehicleFilter = false,
  selectedCamera = "",
  noCarriage = true,
  filterComp
}) {
  const dateRange: DateRangeDTO = useSelector((state) => state.dateRange)
  // Read report parameters from the URL
  const params = readReportParamsFromQuery()

  // Optional selected values
  const initial = getInitialValues(params, camera)
  const [selectedCarriageways: [], selectCarriageways] = useState(() => initial.carriageways)
  const [selectedLanes: [], selectLanes] = useState(() => initial.lanes)
  const [selectedVehicles: [], selectVehicles] = useState(() => initial.vehicles)

  // The states related to the date range picker
  const [dateRangeModal: boolean, setDateRangeModal] = useState(false)

  // State to be sure that wee don't override initial values only when  carriageway dropdown clicked
  const [isCarriageDropdownClicked, setIsCarriageDropdownClicked] = useState(false)
  const [preserveSelectedLanes, setPreserveSelectedLanes] = useState(false)

  // List of available carriageways and lanes
  const availableCarriageways = camera?.carriageways
  const availableLanes = availableCarriageways?.reduce((lanes, carriageway) => {
    if (selectedCarriageways?.some((selected) => selected?.value === carriageway?.uuid)) {
      lanes = [...lanes, ...carriageway?.lanes]
    }
    return lanes
  }, [])

  // Get the report type
  const reportType = parseReportPath(report)

  // Change the list of available lanes on every change of the selected carriageways
  useEffect(() => {

    // Assure that wee fill selectedLanes with initial values
    if (params.lanes && !isCarriageDropdownClicked) {
      return
    }

    // Get all the lanes from all the selected
    const lanes =
      availableCarriageways
        .filter((carriageway) => selectedCarriageways?.find((selected) => carriageway.name === selected.label))
        .map((carriageway) => carriageway.lanes)
        .flat() ?? []

    // Select lanes automatically when carriageway changes
    selectLanes((previousSelectedLanes) => {
      const newSelectedLanes = lanes.map((lane) =>
        MultiSelectOptionFormat.lane(camera, lane)
      )

      // Do not select all carriageway lanes if one or more of its lanes are already selected
      return preserveSelectedLanes ? previousSelectedLanes : newSelectedLanes
    })
    setPreserveSelectedLanes(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, selectedCarriageways, availableCarriageways])

  // Deselect a carriageway if all of it lanes are deselected
  useEffect(() => {
    const newSelectedCarriageways = selectedCarriageways?.filter((carriageway) =>
      selectedLanes?.find((lane) => lane.carriageway === carriageway.label)
    )
    if (newSelectedCarriageways?.length !== selectedCarriageways?.length) {
      selectCarriageways(newSelectedCarriageways)
      setPreserveSelectedLanes(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanes])

  // Update report parameters on any change
  const getValues = (selections) => selections?.map((selection) => selection.value) ?? []
  useEffect(() => {
    updateParams((state) => ({
      ...state,
      carriageways: getValues(selectedCarriageways),
      lanes: getValues(selectedLanes),
      vehicles: getValues(selectedVehicles)
    }))
  }, [
    dateRange,
    selectedCarriageways,
    selectedLanes,
    selectedVehicles,
    updateParams
  ])

  // Initialize carriageways & lanes values when camera changes
  useEffect(() => {
    const selectedCameraID = selectedCamera?.split(":")[1]
    if (selectedCamera && selectedCameraID !== params.cameraID) {
      selectCarriageways([])
      selectLanes([])
    }
  }, [selectedCamera, params.cameraID])

  // Date Range Filter in Report Form page
  const DateRangeFilter = () => {
    return (
      <ReadOnly
        label="Date range"
        className="cursor-pointer w-full"
        value={formatDateRange(validateDateTime(dateRange.start), validateDateTime(dateRange.end))}
        onClick={() => setDateRangeModal(!dateRangeModal)}
      >

        <DateRangePickerModal show={[dateRangeModal, setDateRangeModal]} />

      </ReadOnly>
    )
  }

  // Date Range filter in reports pages
  const DateRangeFilterHeader = () => {
    return (
      <>
        <div
          className="row justify-content-between border rounded align-self-center mr-5 cursor-pointer px-5 py-2"
          onClick={() => setDateRangeModal(true)}
          title="Current date range"
        >
          <div className="mr-3 font-size-lg">
            <span>{formatDateRange(validateDateTime(dateRange.start), validateDateTime(dateRange.end))}</span>
          </div>
          <DateRange title="date range" />
        </div>

        <DateRangePickerModal show={[dateRangeModal, setDateRangeModal]} />
      </>
    )
  }

  return (
    <>
      {!isReportForm ? (
        <div className="row justify-content-between align-items-center mb-10">
          <div className="header-hover">
            <NavLink
              to={`/cameras/dashboard/${camera.sys_id}/${camera.camera_id}`}
              className="text-dark-75 text-hover-dark-50 font-size-h5 font-weight-bold"
              title="Go to camera dashboard"
            >
              <VideocamTwoTone /> {camera?.name}
            </NavLink>
          </div>
          <DateRangeFilterHeader />
        </div>
      ) : null}

      <div className={`${isReportForm ? "" : "flex-row-space"} mb-5`}>

        {/* Select: Date range */}

        {isReportForm ? (
          <div className="">
            <DateRangeFilter />
          </div>
        ) : null}

        {report?.path && !(report?.path.includes('pedestrian') || report?.path.includes('fdo-by-lane')) && <>
          {/* Select: Carriageways */}

          <div className={`${isReportForm ? "" : "col-xl-4 col-md-6"}`} onClick={() => setIsCarriageDropdownClicked(true)}>
            <MultiSelect
              state={[selectedCarriageways, selectCarriageways]}
              name="Carriageways"
              empty="Include all"
              options={availableCarriageways?.map(
                MultiSelectOptionFormat.carriageway
              )}
            />
          </div>

          {/* Select: Lanes */}

          <div className={`${isReportForm ? "" : "col-xl-4 col-md-6"}`}>
            <MultiSelect
              state={[selectedLanes, selectLanes]}
              name="Lanes"
              empty="Include all"
              options={availableLanes?.map((lane) =>
                MultiSelectOptionFormat.lane(camera, lane)
              )}
              groupBy={true}
            />
          </div>

          {/* Select: Vehicles */}
          {
            !noVehicleFilter && !reportType?.filter?.vehicle_types?.skip && (
              <div className={`${isReportForm ? "" : "col-xl-4 col-md-6"}`}>
                <MultiSelect
                  name="Vehicle types"
                  empty="Include all"
                  state={[selectedVehicles, selectVehicles]}
                  options={getVehicleTypes(reportType?.filter?.vehicle_types?.withPedestrians).map(MultiSelectOptionFormat.vehicle)}
                />
              </div>
            )
          }
        </>}

        {filterComp && filterComp()}
      </div>
    </>
  )
}
