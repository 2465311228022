import { createSlice } from "@reduxjs/toolkit";

/** @type CameraSlice */
const initialCameraState = {
  uuid: "",
  systemID: "",
  name: "",
  group: "",
  location: "",
  locationArr: [],
  image: "",
  width: 0,
  height: 0,
  prevCamera: null,
  new_cam: false
};

export const cameraSlice = createSlice({
  name: "camera",
  initialState: initialCameraState,
  reducers: {
    updateCamera: (state, action) => Object.assign({}, state, action.payload),
    setPrevCamera: (state, action) => {
      state.prevCamera = action.payload
    },
    addNewCam: (state, action) => {
      state.new_cam = action.payload
    },
    setDefaultLocation: (state, action) => {
      state.locationArr = action.payload
    }
  }
});

export const { updateCamera, setPrevCamera, addNewCam, setDefaultLocation } = cameraSlice.actions;

export const updateCameraAction = (camera) => (dispatch) =>
  dispatch(updateCamera(camera));
