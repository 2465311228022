import "./HeaderButtons.scss";
import { NavLink } from "react-router-dom";

/**
 * Create a single button for the header.
 * @param {JSX.Element} icon The icon to use for the button.
 * @param {string} text The text show on the button.
 * @param {string} path The link of the button.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const HeaderButton = ({ icon, text, path }) => (
  <NavLink className="btn btn-sm btn-outline-primary btn-header" to={path}>
    {icon} {text}
  </NavLink>
);

/**
 * Build a list of buttons for the header.
 *
 * @param {{icon:JSX.Element, text:string, path:string}[]} buttons The definition of buttons to use (@see HeaderButton).
 * @constructor
 */
const HeaderButtons = ({ buttons }) =>
  buttons.map((button, i) => <HeaderButton key={i} {...button} />);

export default HeaderButtons;
