import { MultiCameraImage } from 'components/MultiCamera/MultiCameraImage'
import { FormText } from 'components/Reports/Fields'
import LargeCard from 'components/Structure/LargeCard'
import { useFormik } from 'formik'
import { useCameraImage } from 'hooks/useCameraImage'
import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useCameraManagementAPI from 'services/CameraManagementService'
import { append } from 'utils/data'
import { formikDefault } from 'utils/forms-schema'
import { filterCombinedCameras, getCombinedLines } from 'utils/functions'
import { MCLayoutContext } from '../MCLayout'
import SelectLines from '../SelectLines'

const Step2 = () => {
    const { step, setStep, combinedCameras, setCombinedCameras,
        lines, setLines, cameras, savedMultiCamera } = useContext(MCLayoutContext)
    const api = useCameraManagementAPI()
    const { uuid } = useParams()
    const [disabled, setDisabled] = useState(false)
    /** The currently selected point and all the points related to it. */
    const [selectedPoint: TransitFlowPointDTO, setSelectedPoint] = useState()
    const [relatedPoints: LaneFlowDTO[], setRelatedPoints] = useState([])
    const [points, setPoints] = useState([])

    const highlightedPoints = []

    const activatePoint = (point) => {

        // Clear
        if (point == null) {
            setSelectedPoint(null)
            setRelatedPoints([])
            return
        }

        // Update all states
        setSelectedPoint(point)

        // Get related points
        const result = [].entries.find((item) => item.transit_point.uuid === point.transit_point.uuid)
        setRelatedPoints(result.flows)
    }
    const addLinePair = () => {
        const new_pair = {
            id: lines.length,
        }
        setLines(append(new_pair))
    }
    const onSubmit = () => {
        setStep(prev => prev + 1)
    }
    // Define form
    const formik = useFormik({
        ...formikDefault,
        initialValues: {},
        validationSchema: {},
        onSubmit
    })

    useEffect(() => {
        // handleCombinedLines(cameras, api)
        getCombinedLines(cameras, api).then(res => {
            setCombinedCameras(res)

        }).catch(err => console.log(err))
    }, [cameras, api])

    useEffect(() => {
        if (combinedCameras) {
            const res = filterCombinedCameras(savedMultiCamera, combinedCameras)
            console.log(res)
            setLines(res)
        }
    }, [combinedCameras, savedMultiCamera])
    console.log({ combinedCameras, lines, cameras })

    useEffect(() => {
        const check = lines.some(line => !line.entry || !line.exit)
        check || lines.length === 0 ? setDisabled(true) : setDisabled(false)
    }, [lines])

    return (
        <LargeCard title={`Multi-Camera ${step}/3: Combine Cameras`}>
            <Form noValidate onSubmit={formik.handleSubmit}>
                <FormText>
                    <p>Select pairs of lines from different cameras that will be joined.</p>
                    <p>Please ensure the selected lines are adjacent when considering the physical lane.</p>
                    <p>Picture it that a vehicle exits from one camera and enters in the next.</p>
                </FormText>
               
                <div className='mt-4 mcCanva'>
                    {combinedCameras?.combinedData?.map((item, i) => <div key={i} className='col-xl-6 mt-4'>

                        <MultiCameraImage width={item.image.width} selectedPoint={selectedPoint}
                            setSelectedPoint={(point) => activatePoint(point)}
                            highlightedPoints={highlightedPoints}
                            points={combinedCameras?.cameraGroup[i]?.data}
                            relatedPoints={relatedPoints} height={item.image.height} image={item.image.url} />
                    </div>)}
                </div>
                <div className='mcHeader'>
                    {combinedCameras?.combinedData.map((item, i) => <div key={i} className='mcTitle'>
                        <strong>{item?.camera_name}
                        </strong>
                    </div>)}
                </div>
                <div className='mt-6'>
                    {lines.length > 0 && lines.map((item, i) =>
                        <SelectLines key={i}
                            item={item}
                            index={i}
                            id={uuid}
                            combinedCameras={combinedCameras}
                            lines={lines} />)}
                </div>


                <div className="full-width row justify-content-between">
                    <div>
                        <button onClick={() => setStep(prev => prev - 1)} className="btn btn-primary" type="submit">Back</button>
                    </div>
                    <div className='form-buttons '>
                        <button onClick={addLinePair} className="btn btn-primary">Add line pair</button>
                        <button disabled={disabled} onClick={onSubmit} className="btn btn-primary">Next</button>
                    </div>

                </div>
            </Form>
        </LargeCard>
    )
}

export default Step2