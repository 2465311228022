import {
  MediaController,
  MediaControlBar,
  MediaTimeRange,
  MediaTimeDisplay,
  MediaLoadingIndicator,
  MediaVolumeRange,
  MediaPlaybackRateButton,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaMuteButton,
  MediaCaptionsButton,
  MediaAirplayButton,
  MediaPipButton,
  MediaFullscreenButton,
  MediaPosterImage
} from "media-chrome/react";
import { useState } from "react";
import 'hls-video-element';

const chromeStyles = {
  "--media-primary-color": "white"
};

const LiveMediaStream = ({ src }) => {
  const [noDefaultStore, setNoDefaultStore] = useState(false);
  console.log(src);
  return (
    <div>
      <MediaController style={chromeStyles} defaultSubtitles noDefaultStore={noDefaultStore}>
        <hls-video src={src} slot="media" crossorigin muted></hls-video>
        <MediaPosterImage
          slot="poster"
          src=""
          placeholderSrc=""
        ></MediaPosterImage>
        <MediaLoadingIndicator
          suppressHydrationWarning
          noautohide
          slot="centered-chrome"
          style={{ "--media-loading-indicator-icon-height": "200px" }}
        ></MediaLoadingIndicator>
        <MediaControlBar>
          <MediaPlayButton></MediaPlayButton>
          <MediaSeekBackwardButton seekOffset={10}></MediaSeekBackwardButton>
          <MediaSeekForwardButton seekOffset={10}></MediaSeekForwardButton>
          <MediaTimeRange></MediaTimeRange>
          <MediaTimeDisplay showDuration></MediaTimeDisplay>
          <MediaMuteButton></MediaMuteButton>
          <MediaVolumeRange></MediaVolumeRange>
          <MediaPlaybackRateButton></MediaPlaybackRateButton>
          <MediaCaptionsButton></MediaCaptionsButton>
          <MediaAirplayButton></MediaAirplayButton>
          <MediaPipButton></MediaPipButton>
          <MediaFullscreenButton></MediaFullscreenButton>
        </MediaControlBar>
      </MediaController>
    </div>
  );
};

export default LiveMediaStream;
