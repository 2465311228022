import React from 'react'

const HeaderText = ({ message, extraComp }) => {
    return (
        <div className='header-text'>
            <div className='header-text-main'>{message}</div>
            <div className='header-extra-comp'>{extraComp}</div>
        </div>
    )
}

export default HeaderText