import ClipLoader from "react-spinners/ClipLoader";

const Clip = ({ size = 80 }) => <ClipLoader
  className="type-clip"
  size={size}
  css={{
    borderWidth: size / 15
  }}
/>;

export default Clip;
