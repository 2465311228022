import { useStateWithDebounce } from "./useStateWithDebounce";
import { useCallback, useEffect } from "react";

/**
 * Track the changes in the width of a window or a change on the size of an element.
 * Commonly used as a parameter for the useEffect() hook to trigger re-render.
 *
 * @param element The optional reference to the DOM element created by the useRef() hook.
 *
 * @return {width} The width of the element or the width of the window if the element is not defined.
 */
export const useOnResize = (element) => {

  // The size that we will be using for a reference
  const [width, setWidth] = useStateWithDebounce(window.innerWidth, 500);
  const iterate = useCallback(() => {

    // Try to get the width from the actual element, but fall back to window width
    const newWidth = element && element.current
      ? element.current.offsetWidth
      : window.innerWidth;

    setWidth(newWidth);

  }, [element, setWidth]);

  // Capture the resize of the window
  window.addEventListener("resize", iterate);

  // Capture the element as well
  useEffect(() => {

    // Make sure the element is referenced
    if (ResizeObserver && element && element.current) {
      const observer = new ResizeObserver(iterate);
      observer.observe(element.current);

      // Clean up the observer
      return () => observer && observer.disconnect();
    }
  }, [element, iterate]);

  return width;
};
