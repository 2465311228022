/* eslint-disable no-unused-vars */
import { LoadingWrapper } from 'components'
// import Alert from 'components/Alert/Alert'
import Button from 'components/Alert/Button'
// import { CustomInput } from 'components/Events/Fields'
import LargeCard from 'components/Structure/LargeCard'
import { useLoading, useQueryLoading } from 'hooks/useLoading'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useQueryClient } from 'react-query'
// import { useSelector } from 'react-redux'
// import useAPI from 'services/ApiService'
import useCameraManagementAPI from 'services/CameraManagementService'
// import { slugOf } from 'utils/strings'
import { toaster } from 'utils/toaster'
import SettingsInput from './SettingsInput'
import SettingsLayout from './SettingsLayout'

const PcuSettings = () => {
    const api = useCameraManagementAPI()
    // const { user } = useSelector((state) => state.auth)
    const [data, loading] = useLoading(() => api.getPcu())
    const [submitting, setSubmitting] = useState(false)
    const queryClient = useQueryClient()
    // Get the list of all systems used, as a unique array
    const [systems, systemsLoadingState] = useQueryLoading("Systems", () => api.systems())
    const [error, setError] = useState('')
    const [disabled, setDisabled] = useState(false)

    const [pcuValues, setpcuValues] = useState([])

    const handlePcuValueChange = ({ index, value, item }) => {
        // console.log({ value, index })
        let res = pcuValues.slice()
        res[index] = { ...item, pcu: value }
        // console.log(res)
        setpcuValues(res)
    }

    const onSubmit = async () => {
        setSubmitting(true)
        try {
            const data = {
                PCU: pcuValues
            }
            const res = api.updatePcu(pcuValues[0]?.system_code, data)
                .then(() => queryClient.invalidateQueries("SystemsId"))

            // Show a friendly toaster
            await toaster.promise(res, "PCU value updated successfully")
            // console.log(res)
        } catch (error) {
            console.log(error)
        }
        setSubmitting(false)
    }

    useEffect(() => {
        // const filteredPcu = (data ?? []).filter(item => user.userManagingTenantsIds.includes(slugOf(item.system_code)))
        // if (filteredPcu) {
        //     setpcuValues(filteredPcu)
        // }
        setpcuValues(data)
    }, [data])

    useEffect(() => {
        if (pcuValues) {
            if (pcuValues?.some(item => isNaN(item.pcu))) {
                setDisabled(true)
            }
            else {
                setError('')
                setDisabled(false)
            }
        }
    }, [pcuValues])


    // console.log(pcuValues)
    return (
        <SettingsLayout>
            <div className="row">
                <div className="col-xl-8 offset-xl-2">
                    <LargeCard title='PCU Settings'>
                        <LoadingWrapper state={[loading, systemsLoadingState]}>
                            {pcuValues && pcuValues?.length > 0 ? (
                                <>
                                    <Table bordered hover={false} className="v-align">
                                        <thead>
                                            <tr>
                                                <th>Vehicle Type</th>
                                                <th width="*">PCU Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pcuValues.map((item, i) => (
                                                <tr key={i}>
                                                    <td>{item?.vehicle_type_name}</td>
                                                    <SettingsInput
                                                        handlePcuValueChange={handlePcuValueChange}
                                                        item={item} index={i} value={item?.pcu} />
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button disabled={disabled} text='Save' onClick={onSubmit} loading={submitting} />
                                </>
                            ) : (
                                <h1 className="text-center my-10">No vehicle type added</h1>
                            )}
                        </LoadingWrapper>
                    </LargeCard>
                </div>
            </div>
        </SettingsLayout>
    )
}

export default PcuSettings
