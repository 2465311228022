import { useState } from "react"

const JSONPainterCollapsable = ({ collapsed = false, before = null, after = null, children }) => {
  const [isCollapsed, setCollapsed] = useState(collapsed)

  // Collapsed
  if (isCollapsed) {
    return <>
      {before}
      <u onClick={() => setCollapsed(false)} className="jsonpainter-key-collapsable">...</u>
      {after}
    </>
  }

  // Expanded
  return <>
    <i onClick={() => setCollapsed(true)} className="jsonpainter-key-collapsable">-&nbsp;</i>
    {children}
  </>
}

export default JSONPainterCollapsable
