import { LoadingWrapper } from 'components'
import { CustomInput } from 'components/Events/Fields'
import { FormText, MultiSelect } from 'components/Reports/Fields'
import LargeCard from 'components/Structure/LargeCard'
import { useFormik } from 'formik'
import { useLoading } from 'hooks/useLoading'
import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateCameraAction } from 'redux/cameraSlice'
import useAPI from 'services/ApiService'
import useCameraManagementAPI from 'services/CameraManagementService'
import { defaultCameraImage } from 'utils/camera'
import { get } from 'utils/data'
import { formikDefault } from 'utils/forms-schema'
import { filterObjectsByItem } from 'utils/functions'
import { MCLayoutContext } from '../MCLayout'

const Step1 = () => {
    const { cameras: selectedCameras,
        cameraName, setCameraName, setCameras, setStep, step,
        setSavedMultiCamera } = useContext(MCLayoutContext)
    const [disabled, setDisabled] = useState(false)
    const dispatch = useDispatch()
    const { uuid } = useParams()
    // Load the cameras from the Data API
    const api = useCameraManagementAPI()
    let [cameras: CameraDTO[], camerasLoadingState] = useLoading(() => api.cameras())
    cameras = cameras != null ? cameras : []
    const [camera, cameraLoading] = useLoading(() => api.getSavedCamera(uuid))


    const onSubmit = () => {
        setStep(prev => prev + 1)
    }
    const handleSetCamera = (e) => {
        setCameraName(e.target.value)
    }

    // Define form
    const formik = useFormik({
        ...formikDefault,
        initialValues: {},
        validationSchema: {},
        onSubmit
    })

    useEffect(() => {
        if (camera) {
            const { multiCameraContext, camerasToCombine, linesToCombine } = camera
            setCameraName(multiCameraContext?.name)
            setSavedMultiCamera(linesToCombine)
            const res = filterObjectsByItem(camerasToCombine, cameras)
            setCameras(res)
            // setCameras(camerasToCombine)
        }
    }, [camera, cameras])

    useEffect(() => {
        if (cameraName.length <= 2 || selectedCameras?.length < 2) {
            setDisabled(true)
        }
        else {
            if (selectedCameras?.every(item => item?.tenant_id === selectedCameras[0]?.tenant_id)) {
                setDisabled(false)
            }
            else {
                setDisabled(true)
            }
        }

    }, [cameraName, selectedCameras])

    return (
        <LargeCard title={`Multi-Camera ${step}/3: Select Cameras`}>
            <Form noValidate onSubmit={formik.handleSubmit}>
                <FormText>
                    <p>Select all adjacent cameras to create a network.</p>
                    <p>Make sure you know which lines to connect for each camera as that is needed in the next step.</p>
                    <p>Select at least two cameras.</p>
                </FormText>
                <div className='col-md-6 pl-0'>
                    <CustomInput label='Camera pair name' value={cameraName} handleChange={handleSetCamera} />
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <MultiSelect
                            name="Select Cameras"
                            empty="Include all"
                            state={[selectedCameras, setCameras]}
                            options={cameras?.map(camera => ({ id: camera.uuid, value: camera.camera_id, label: camera.name, tenant: camera?.system_id }))}
                        />
                    </div>
                </div>

                <div className="form-buttons">
                    <button disabled={disabled} onClick={onSubmit} className="btn btn-primary" type="submit">Next</button>
                </div>
            </Form>
        </LargeCard>
    )
}

export default Step1