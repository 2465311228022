import { LoadingWrapper } from 'components'
import LargeCard from 'components/Structure/LargeCard'
import { useLoading } from 'hooks/useLoading'
import useStoreDateRange from 'hooks/useStoreDateRange'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAPI from 'services/ApiService'
import useCameraManagementAPI from 'services/CameraManagementService'
import { getCameraImageURL } from 'utils/camera'
import { EventsContext } from '../Events/EventsLayout'
import AddZone from './AddZone'
import ConfirmZone from './ConfirmZone'

const Index = () => {
    const { Steps, eventTitle, setCamera } = useContext(EventsContext)
    const { cameraId } = useParams()

    useEffect(() => {
        const image = getCameraImageURL(cameraId)
        setCamera(image)

        return () => {
            setCamera('')
        }
    }, [cameraId])


    const handleStep = () => {
        switch (Steps) {
            case 1:
                return (
                    <AddZone />
                )
            case 2:
                return <ConfirmZone />
            default:
                break;
        }
    }

    return <LargeCard title={`${eventTitle} - events`}>
        {handleStep()}

    </LargeCard>

}

export default Index