import { useFormik } from "formik"
import { Form } from "react-bootstrap"
import LargeCard from "../../../../components/Structure/LargeCard"
import { cameraManagementStep1, formikDefault } from "../../../../utils/forms-schema"
import { stepTitle } from "../form"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import useAPI from "services/ApiService"
import useCameraManagementAPI from "services/CameraManagementService"
import { useLoading, useQueryLoading } from "hooks/useLoading"
import { slugOf } from "utils/strings"
import { LoadingWrapper } from "components"
import { allowEmpty } from "components/LoadingWrapper/LoadingWrapper"
import { covertCameraSettingsToFormData } from "../utils"
import { FormText } from "components/Reports/Fields"
import { Link } from "react-router-dom"

/**
 * Get the GPS position of the camera.
 *
 * @param camera
 * @param title
 * @param setValues
 * @return {JSX.Element}
 * @constructor
 */
const Step1 = ({ camera, title, setValues, setCamera }) => {
  const [searchFilter, setSearchFilter] = useState('')
  const [newScene, setNewScene] = useState(null)
  const { user } = useSelector((state) => state.auth)

  // Load the existing cameras
  const dataAPI = useAPI()
  const confAPI = useCameraManagementAPI()
  const [cameras: CMCameraResponse[], camerasLoadingState] = useQueryLoading("CameraManagement:List", () => confAPI.cameras())
  const [cameradata, camerasDataLoadingState] = useLoading(() => dataAPI.cameras())

  // Get the list of all systems used, as a unique array
  const [systemdata, systemsLoadingState] = useQueryLoading("Systems", () => confAPI.systems())

  // Load the camera location && rtsp url
  const [cameraSettings: CameraDTO] = useLoading(() => confAPI.cameraLocation(newScene?.uuid), [newScene])
  const [cameraUrl, setCameraUrl] = useState()

  const filteredCameras = (cameras ?? []).filter(camera => user.userManagingTenantsIds.includes(slugOf(camera.system)))

  const handleSearchCamera = (e) => {
    if (e) {
      setSearchFilter(e.target.value)
    }

  }
  const handleSelect = (e) => {
    if (e) {
      let cam = e.target.textContent
      setSearchFilter(cam)
      let new_cam = filteredCameras.find(res => res.name === cam)
      setNewScene(new_cam)
      if (new_cam) {
        confAPI.getCameraUrl(new_cam?.uuid).then((res) => setCameraUrl(res)).catch((err) => {
          console.log(err)
        })

      }
    }
  }
  // Initial location
  const extractLocation = (camera) => ({
    latitude: camera?.latitude ?? "35.88727689026155",
    longitude: camera?.longitude ?? "14.447391252898122"
  })

  // Define form
  const formik = useFormik({
    ...formikDefault,
    initialValues: extractLocation(camera),
    validationSchema: cameraManagementStep1,
    onSubmit: setValues
  })

  useEffect(() => {
    cameraSettings &&
      setValues({ ...camera, ...cameraSettings, uuid: formik.values.existingCameraUuid })
  }, [cameraSettings])

  useEffect(() => {
    formik.setValues({ ...camera, ...newScene })
    formik.setFieldValue('existingCameraUuid', newScene?.uuid)
    cameraUrl && formik.setFieldValue('url', cameraUrl?.rtsp_url)
  }, [newScene, cameraUrl])

  console.log({ formikVals: formik.values, filteredCameras, newScene, cameraUrl })
  return (
    <LargeCard title={stepTitle(title, camera, "Select camera")}>
      <LoadingWrapper state={[camerasLoadingState, allowEmpty(camerasDataLoadingState), systemsLoadingState]} onEmpty={"There is no multi-scene to show"}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className="row">
            <FormText>
              Please select the existing camera to which the new scene will be added.
              The camera that has the existing scene(s).
              If you want to add a new camera or camera scene you can do this from the{' '}
              <Link to='/admin/cm'> Camera Management section</Link>
            </FormText>
            <div className="col-md-6">
              <Autocomplete
                disablePortal
                id="search_cam"
                placeholder='Search Cameras'
                inputValue={searchFilter}
                onInputChange={handleSearchCamera}
                options={filteredCameras}
                getOptionLabel={option => option.name}
                onChange={handleSelect}
                sx={{ width: 300, height: 40, marginBottom: 5, outlineColor: '#f0f0f0' }}
                renderInput={(params) => <TextField value={searchFilter} onChange={handleSearchCamera} {...params} label={'Search Cameras'} />}
              />
            </div>
          </div>

          <div className="form-buttons">
            <button disabled={!camera?.uuid} className="btn btn-primary" type="submit">Next</button>
          </div>
        </Form>
      </LoadingWrapper>

    </LargeCard>
  )
}

export default Step1
