import { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import LargeCard from "components/Structure/LargeCard"
import { LoadingWrapper } from "../../../../../components"
import { FormText } from "../../../../../components/Reports/Fields"
import useCameraManagementAPI from "../../../../../services/CameraManagementService"
import DropZone from "../../components/DropZone"
import FileUploadProgress from "../../components/FileUploadProgress"
import { useCreateBatchFiles } from "../../queries"
import { MessageModal, useMessageModal } from "../../../../../components/Modal/MessageModal"
import { v4 as UUIDv4 } from "uuid"

const Step2 = ({ batch, updateValues }) => {
  const messageModal = useMessageModal()
  const countFilesRef = useRef(0)
  const history = useHistory()
  const audit = useCameraManagementAPI()
  const [files, setFiles] = useState(batch.files ?? [])
  const [newlyUploadedFiles, setNewlyUploadedNewlyUploadedFiles] = useState([])
  const [minBatchesToProcess, setMinBatchesToProcess] = useState(0)
  const [isBatchFilesCreated, setIsBatchFilesCreated] = useState(false)
  const [uploading, setUploading] = useState(minBatchesToProcess !== countFilesRef.current)
  const createBatchFiles = useCreateBatchFiles()
  const [tempFileName, setTempFileName] = useState(UUIDv4())
  const batchUUID = createBatchFiles?.data?.batch?.uuid
  const { uuid, name, camera_uuid, start } = batch

  // Are any files have been uploaded
  const [hasFiles, setHasFiles] = useState(false)
  const anyFiles = () => newlyUploadedFiles.length > 0 && setHasFiles(true)

  useEffect(() => {
    if (!batch.edit && isBatchFilesCreated) {
      return
    }
    anyFiles()
    if (batch.edit || (newlyUploadedFiles.length > 0 && !isBatchFilesCreated)) {
      const data = { uuid, name, camera_uuid, start: new Date(start).toISOString(), files: [], ordering: [], remove: [] }
      createBatchFiles.mutate(
        data,
        {
          onSuccess: () => setIsBatchFilesCreated(true),
          onError: () => setIsBatchFilesCreated(true)
        }
      )

      // Audit log
      audit.log(`# File batch ${batch.edit ? "edit" : "create"}`, data)

      // No need for step 2
      if (batch.edit) {
        history.push("/camera-files")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyUploadedFiles, isBatchFilesCreated])

  useEffect(() => {
    if (batchUUID && newlyUploadedFiles.length > 0) {
      anyFiles()

      // Update files without payload
      let newFiles = [...files, ...newlyUploadedFiles]
      // for (const newFile of newlyUploadedFiles) {
      //   newFiles.push({ ...newFile /*, payload: null */ })
      // }

      const uniqueArray = newFiles.filter((obj, index, self) => {
        const firstIndex = self.findIndex(el =>
          Object.keys(el).every(key => el[key] === obj[key])
        );
        return firstIndex === index;
      });
      setFiles(uniqueArray)

      // The total number of files
      countFilesRef.current = countFilesRef.current + newlyUploadedFiles.length

      // Clear the list of new files
      setNewlyUploadedNewlyUploadedFiles([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyUploadedFiles, batchUUID])


  console.log(tempFileName)
  // No need for step 2
  if (batch.edit) {
    return <LoadingWrapper />
  }

  /**
   * Save and redirect.
   *
   * @param {int} step The next step to take.
   */
  const saveAndGo = (step: number = 3) => {

    // No files have been uploaded
    if (files.length === 0 && step > 1) {
      return messageModal.show("Info", "You must select at least one file to save this batch.")
    }

    // Still uploading
    if (uploading) {
      return messageModal.show("Info", "Please wait until all files have been uploaded to save this batch.")
    }

    updateValues({ step, files, uuid: batchUUID })
  }

  return (
    <LargeCard title="Video Files Batch 2/3: Upload files">

      <FormText>
        <p>The supported files format are : <q>.mp4</q>, <q>.avi</q>, <q>.mkv</q>, <q>.mov</q>, <q>.wmv</q>, <q>.m2ts</q> and <q>.mpeg</q></p>
        <p>The exact ordering of the files can be defined in the next step.</p>
      </FormText>

      <div className="row align-items-start">
        <div className="col-md-7">
          <DropZone
            files={files}
            setFiles={setNewlyUploadedNewlyUploadedFiles}
            batch={batch}
            tempFilename={tempFileName}
            setTempFileName={setTempFileName}
            setUploading={setUploading}
            uploading={uploading} />
        </div>

        {files.length > 0 && <div className="col-md-5">
          <h3 style={{ marginTop: '10px' }}>Files Uploaded</h3>
          {(files ?? []).map(file => (
            <FileUploadProgress key={file.id} state={[minBatchesToProcess, setMinBatchesToProcess]} file={file} batchUUID={batchUUID} />
          ))}
        </div>}
      </div>

      <div className="form-buttons">
        <button className="btn btn-default" type="button" onClick={() => saveAndGo(1)}>Back</button>
        <button disabled={uploading} className="btn btn-primary" type="submit" onClick={() => saveAndGo(3)}>Next</button>
      </div>

      <MessageModal hook={messageModal} />

    </LargeCard>
  )
}

export default Step2
