import React from "react"
import { Modal } from "react-bootstrap"

export const AppModal = ({ title, children, onHide = () => null }) => {

  return (
    <Modal centered show={true} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>

    </Modal>
  )

}
