import { useState, useEffect, useContext } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Form } from "react-bootstrap"
import LargeCard from "components/Structure/LargeCard"
import { LoadingWrapper } from "components"
import { useLoading } from "hooks/useLoading"
import { Select } from "components/Reports/Fields"
import DateRangeFilter from "components/DateRangePicker/DateRangeFilter"
import useCameraManagementAPI from "services/CameraManagementService"
import { ReadOnly } from "components/Events/Fields"
import Alert from "components/Alert/Alert"
import { McResContext } from "./MCResLayout"
import Button from "components/Alert/Button"

const formatCameraState = (id, name) => `${id}:${name}`

export const reportType = [{
    name: "Vehicle count estimate",
    path: "estimate"
},
{
    name: "Vehicle count breakdown",
    path: "vehicletype_summary_estimate",
},
{
    name: "Vehicle type granular breakdown",
    path: "vehicletype_granular_estimate",
},
]

export const MCReportsForm = () => {
    const {
        report, selectedReportType,
        selectedEntries, setSelectedEntries,
        selectedExits, setSelectedExits, selectReportType,
        setStep, setReport, setReportFilters, reportFilters,
        setEntryName, setExitName, setCamera } = useContext(McResContext)
    const dateRange = useSelector((state) => state.dateRange)
    const { uuid } = useParams()
    const api = useCameraManagementAPI()

    // Form validation state
    const [validated, setValidated] = useState(false)

    // Load available options
    const [camera, cameraState] = useLoading(() => api.getSavedCamera(uuid))

    // Load available options
    const [filter, filterState] = useLoading(() => api.getSavedCameraFilter(uuid))

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [disabled, setDisabled] = useState(true)

    /** Build the URL based on the user's selection. */
    const handleSubmit = async (e) => {
        e.preventDefault()
        e.stopPropagation()

        setCamera(camera)
        setValidated(true)
        setLoading(true)
        try {
            const { camerasToCombine, linesToCombine, multiCameraContext } = camera
            const data = {
                cameras_to_combine: camerasToCombine,
                lines_to_combine: linesToCombine,
                name: multiCameraContext?.name,
                uuid: multiCameraContext?.uuid
            }
            const res = await api.getMultiCameraReport(data, selectedReportType, dateRange, camera?.systemCode,
                selectedEntries, selectedExits, selectedReportType?.includes('vehicletype_granular_estimate') && 'mc')
            setReport(res)
            setStep(2)
        } catch (error) {
            console.log(error)
            setError(error?.error ?? 'An unknown error occurred')
            setTimeout(() => {
                setError('')
            }, 2000);
        }

        setLoading(false)
    }

    useEffect(() => {
        if (filter) {
            setReportFilters(filter?.multiCameraEntriesExits)
        }
    }, [filter])

    useEffect(() => {
        if (!selectedReportType?.includes('vehicletype_granular_estimate') ||
            (selectReportType?.length > 0 &&
                selectedEntries?.length > 0 &&
                selectedExits?.length > 0)) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }, [selectReportType, selectedEntries, selectedExits, selectedReportType])

    console.log(reportFilters)

    return (
        <div className="row">
            <div className="col-xl-6 offset-xl-3">
                <LargeCard title="Generate a report">
                    <Alert title={error} type='danger' />
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <LoadingWrapper state={[cameraState, filterState]}>

                            {/* Select: Camera */}
                            <ReadOnly label='Camera pair' value={camera?.multiCameraContext?.name} />

                            {/* Select: Report type */}
                            <Select
                                name="Report type"
                                state={[selectedReportType, selectReportType]}
                                empty="select report type"
                                options={reportType.map((type) => [type.path, type.name])}
                            />

                            <DateRangeFilter dateRange={dateRange} />
                            {/* Entry and Exit Line Filter */}
                            {selectedReportType?.includes('vehicletype_granular_estimate') && <>
                                <Select
                                    state={[selectedEntries, setSelectedEntries]}
                                    empty="Select entry"
                                    name="Entry"
                                    options={reportFilters?.entries?.map(camera => [camera?.uuid, `${camera?.lane?.name} -(${camera?.camera?.camera_id})`])}
                                />

                                <Select
                                    state={[selectedExits, setSelectedExits]}
                                    empty="Select exit"
                                    name="Exit"
                                    options={reportFilters?.exits?.map(camera => [camera?.uuid, `${camera?.lane?.name} -(${camera?.camera?.camera_id})`])}
                                />
                            </>}

                            <Button disabled={disabled} className='float-right mt-2'
                                text='Generate Report' onClick={handleSubmit} loading={loading} />

                        </LoadingWrapper>
                    </Form>
                </LargeCard>
            </div>
        </div>
    )
}
