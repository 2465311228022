import { useState, useEffect } from "react"
import { useMutation } from "react-query"
import toast from "react-hot-toast"
import { Button, Modal, Form } from "react-bootstrap"
import { Formik } from "formik"
import { Email } from "@material-ui/icons"
import { forgotPasswordSchema } from "utils/forms-schema"
import AuthService from "services/AuthService"
import { ErrorMessage } from "components/UserManagement/ErrorMessage"

const ForgotPassword = ({ isOpenModal, setIsOpenModal }) => {
  const [email, setEmail] = useState(null)
  const closeForm = () => {
    setIsOpenModal(false)
  }
  //Get all groups
  const mutation = useMutation("ForgotPassword", data => AuthService.forgotMyPassword(data))
  const onSubmit = ({ email }) => {
    setEmail(email)
    mutation.mutate({ email_address: email })
  }
  useEffect(() => {
    if (mutation.isSuccess && email) {
      toast.success("Instructions to rest your password has been sent !")
      setEmail(null)
      setIsOpenModal(false)
    }
  }, [mutation, setIsOpenModal, email])

  return (
    <Modal centered show={isOpenModal} size="md" onHide={closeForm}>
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="font-weight-light">Enter your email address and we'll email you instructions on how to reset your password</p>
        {mutation.isError && email ? <div className="mb-2 text-danger font-weight-bold">{`* No account matches ${email}`}</div> : null}
        <Formik
          initialValues={{
            email: ""
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={forgotPasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control type="email" name="email" placeholder="Email" value={values.email} onChange={handleChange} />
                <ErrorMessage>{errors.email}</ErrorMessage>
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-9 mr-3 w-100">
                <Email />
                <span className="ml-2">Send</span>
                {mutation.isLoading && <span className="ml-5 spinner spinner-white" />}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ForgotPassword
