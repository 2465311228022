import "./CameraOperationState.scss"
import { CloudUpload, Lens, Settings, Warning } from "@material-ui/icons"
import { isEmpty } from "../../utils/functions"
import TitleHover from "../TitleHover/TitleHover"

/** The list of supported states. */
export const CameraOperationStates = {

  // Camera is processing the video feed live
  LIVE: "LIVE",

  // Camera is fully set up, but only processes the uploaded video batches
  UPLOAD: "UPLOAD",

  // Camera is not yet fully configured
  PENDING: "PENDING",

  // There is an issue with a camera that should process live feed
  ERROR: "ERROR",

  // We do not know...
  UNKNOWN: "UNKNOWN"
}

/**
 * Show the status to the user.
 *
 * @param {string} operationState The current operation state of the camera.
 *
 * @return {JSX.Element} The element to show to the user.
 */
const getElement = (operationState) => {
  switch (operationState) {

    case CameraOperationStates.LIVE:
      return <TitleHover text="Processing live feed">
        <Lens className="camera-operation-state-live" />
      </TitleHover>

    case CameraOperationStates.PENDING:
      return <TitleHover text="Camera is not yet fully configured">
        <Settings className="camera-operation-state-pending" />
      </TitleHover>

    case CameraOperationStates.UPLOAD:
      return <TitleHover text="Camera can accept video files for processing">
        <CloudUpload className="camera-operation-state-upload" />
      </TitleHover>

    default:
    case CameraOperationStates.ERROR:
      return <TitleHover text="Camera might have operational issues">
        <Warning className="camera-operation-state-error" />
      </TitleHover>
  }
}

/**
 * Get the operation state of a camera.
 *
 * @param {CameraDTO|CMCameraResponse} camera The camera to get the state for.
 *
 * @return string The operation state of the camera, {@see CameraOperationStates} above.
 */
const getOperationStateOf = (camera) => {

  // Unknown
  if (!camera) {
    return CameraOperationStates.UNKNOWN
  }

  // Camera is live (LIVE), or was live at some point (ERROR)
  if (camera.live) {
    return CameraOperationStates.LIVE
  } else if (camera.was_live) {
    return CameraOperationStates.ERROR
  }

  // Camera is not yet fully configured
  if (!camera.camera_id) {
    return CameraOperationStates.PENDING
  }

  // Accepts only video files
  if (isEmpty(camera.has_stream)) {
    return CameraOperationStates.UPLOAD
  }

  // Unknown state
  return CameraOperationStates.UNKNOWN
}

/**
 * Get the camera operational status.
 *
 * @param {CameraDTO} camera The camera to get the state for.
 * @param {?string} position The position of the state, {@see CameraOperationState.scss}.
 *
 * @return {JSX.Element} The element to show to the user.
 */
export const CameraOperationState = ({ camera, position }) => {

  // Get the state
  const state = getOperationStateOf(camera)
  if (state === CameraOperationStates.UNKNOWN) {
    return <></>
  }

  return <span className={`camera-operation-state${position ? "-" + position : ""}`}>
    {getElement(state)}
  </span>
}
