import "./Loader.scss"
import Scale from "./Types/Scale"
import Beat from "./Types/Beat"
import Clip from "./Types/Clip"
import Pulse from "./Types/Pulse"

/**
 * The list of all supported
 * @typedef {"clip"|"beat"|"pulse"|"scale"|"simple"} LoaderType
 */
export const Type = { clip: "clip", beat: "beat", pulse: "pulse", scale: "scale", simple: "simple" }

/**
 * Show the infinite loading animation.
 *
 * @param {int} size The size of the animation, in pixels.
 * @param {LoaderType} type The loader type to use, see Loader.Type for reference.
 * @param {int} height The minimum height of the loading screen.
 * @param {float} aspectRatio The aspect ratio to keep.
 * @param {bool} visible Use false to hide the loader, but keep in the DOM.
 */
export const Loader = ({ type = "clip", size = 80, height = 0, aspectRatio = null, visible = true }) => (
  <div className={`loader-container ${type ?? "clip"}`} style={{ minHeight: height, aspectRatio: (aspectRatio ?? "inherit") + "", display: visible ? "flex" : "none" }}>
    {(() => {
      switch (type) {
        default:
        case "clip":
          return <Clip size={size} />
        case "beat":
          return <Beat size={size} />
        case "scale":
          return <Scale size={size} />
        case "pulse":
          return <Pulse size={size} />
      }
    })()}
  </div>
)

export default Loader
