import { useState } from "react";
import { RemoveRedEye, Edit, Delete, Person } from "@material-ui/icons";
import TableRowAction from "components/Table/TableRowAction";
import EditUser from "../forms/EditUser";
import DeleteUser from "../forms/DeleteUser";
import UserRole from "../forms/UserRole";
import ViewTenants from "../forms/ViewTenants";
import "../UserManagement.scss";

const Td = (props) => <td className="text-left" style={{ verticalAlign: "middle" }} {...props} />;

const UserTableRow = ({ user }) => {
  const { first_name, last_name, email_address, groups: tenants } = user;

  // State related to Forms
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [showUserRole, setShowUserRole] = useState(false);
  const [showTenants, setShowTenants] = useState(false);

  const actions = [
    { icon: <RemoveRedEye />, title: "View tenants", setState: setShowTenants },
    { icon: <Edit />, title: "Edit user", setState: setShowEditForm },
    { icon: <Delete />, title: "Delete user", setState: setShowDeleteUser }
  ];

  const userRoleAction = { icon: <Person />, title: "Change user role", setState: setShowUserRole };

  if (tenants?.length > 0) {
    actions.splice(2, 0, userRoleAction);
  }
  return (
    <>
      <tr>
        <Td>
          {first_name} {last_name}
        </Td>
        <Td>{email_address}</Td>
        {/*   <Td>
          <UserStatus user={user} />
        </Td> */}

        <Td className="text-center">
          <TableRowAction actions={actions} />
        </Td>
      </tr>
      {/* Edit user Modal Form */}
      <EditUser user={user} show={showEditForm} exitModal={() => setShowEditForm(false)} />
      {/* Delete user Modal */}
      <DeleteUser user={user} show={showDeleteUser} exitModal={() => setShowDeleteUser(false)} />
      {/* Change user Role Modal Form*/}
      <UserRole user={user} show={showUserRole} exitModal={() => setShowUserRole(false)} />
      {/* View Tenants*/}
      <ViewTenants user={user} show={showTenants} exitModal={() => setShowTenants(false)} />
    </>
  );
};

export default UserTableRow;
