import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import "./colChart.scss";
import { extend } from "../../utils/data";
import { humanNumber, humanPercent } from "../../utils/functions";
import { humanTime } from "utils/time";

/**
 * Draw a horizontal bar chart.
 *
 * @param {string} title The title to show above the chart.
 * @param {KeyValue[]} data The key-value pair of data.
 * @param {label} label Average daily traffic Indicator
 *
 * @return {JSX.Element}
 * @constructor
 */
export const ColumnnChart = ({ title, data, label, adt, chartXLabel, height = 500, chartYSuffix = '', options = {} }) => {
    // const adt = label?.map(item => humanNumber(Math.round(parseFloat(item.value))))
    const nadt = parseInt(adt[0].replace(',', ''))
    // Create a reference to the DOM element that shows the chart
    const chartRef = useRef();

    useEffect(() => {

        // Make sure the DOM element has been initialized
        if (!chartRef.current) {
            return
        }

        // Define the options for the chart
        const useOptions = extend(options, {
            series: [
                {
                    name: chartXLabel,
                    data
                },
            ],
            chart: {
                type: "bar",
                height,
                animations: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                type: "datetime",
                labels: {
                    datetimeUTC: false
                },
            },
            toolbar: {
                show: true
            },
            yaxis: {
                show: true,
                title: {
                    text: chartYSuffix,
                    style: {
                        fontSize: "13px"
                    }
                },
            },
            annotations: {
                yaxis: [
                    {
                        y: nadt,
                        borderColor: '#775DD0',
                        strokeDashArray: 0,
                        label: {
                            borderColor: '#775DD0',
                            style: {
                                color: '#fff',
                                background: '#775DD0'
                            },
                            text: `Average daily traffic: ${adt}`
                        }
                    }
                ]
            },
            tooltip: {
                x: {
                    formatter: val => humanTime(val / 1000)
                },
                y: {
                    formatter: val => humanNumber(val) + chartYSuffix
                }
            },
            colors: ["#84c341"],
            stroke: {
                curve: "smooth"
            },
            legend: {
                showForSingleSeries: true,
                position: 'top'
            },
            grid: {
                position: "front"
            },

        })

        // Draw the chart
        const chart = new ApexCharts(chartRef.current, useOptions)
        chart.render()

        // Make sure chart is properly disposed of
        return () => chart.destroy()
    }, [data, title, height, chartXLabel, chartYSuffix, options])

    return (
        <div className="chart col-chart">
            {/* {title && <h5 style={{ textAlign: "center" }}>{title}</h5>} */}
            <div ref={chartRef} />
            {label && <h5 style={{ textAlign: "center" }}>Average daily traffic in the given period:  {adt}</h5>}
        </div>
    );
};

export default ColumnnChart;
