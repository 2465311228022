import { transpose } from "../../utils/data";

const VehicleFilter = (filterState, setFilterState) => ({

  /**
   * Set the vehicle type.
   *
   * @param {string} type The type of the vehicle to set.
   * @param {boolean} active True to view the vehicle type, false to hide it.
   */
  set: (type: string, active: boolean) => {
    const filter = { ...filterState };
    filter[type] = active;
    setFilterState(filter);
  },

  /**
   * Select or deselect all vehicle types.
   *
   * @type {boolean} selected True to select all, false to deselect all.
   */
  all: (selected: boolean = true) =>
    setFilterState(transpose(filterState, key => [key, selected])),

  /**
   * Select ony one type.
   *
   * @param {string} type The type to select.
   */
  one: (type: string) =>
    setFilterState(transpose(filterState, key => [key, key === type]))
});

export default VehicleFilter;
