import React, { createContext } from 'react'

export const settingsContext = createContext()

const SettingsLayout = ({ children }) => {
    return (
        <settingsContext.Provider>{children}</settingsContext.Provider>
    )
}

export default SettingsLayout