import { useSelector } from "react-redux";

export const useMe = () => {
  const userId = useSelector((state) => state?.auth?.user?.id);
  const userViewingTenantsIds = useSelector((state) => state?.auth?.user?.userViewingTenantsIds);
  const userManagingTenantsIds = useSelector((state) => state?.auth?.user?.userManagingTenantsIds);
  const isAdmin = userManagingTenantsIds?.length > 0;
  const isGreenRoadsAdmin = userManagingTenantsIds?.length > 0 && userManagingTenantsIds?.includes('greenroads')
  return {
    isAdmin,
    userId,
    userManagingTenantsIds,
    userViewingTenantsIds,
    userMembershipTenants: [...userManagingTenantsIds, ...userViewingTenantsIds],
    isGreenRoadsAdmin
  };
};
