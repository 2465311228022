import moment from "moment";
import { subDays } from "date-fns";

/**
 * Read the stored date range.
 *
 * @return DateRangeSlice
 */
export const loadDateRangeState = (): DateRangeSlice => {

  // Default value is for the last 2 hours
  const range = {
    name: null,
    hash: null,
    start: subDays(new Date(), 30),
    end: new Date(),
    prevRange: null
  };

  // Guard: Item exists in local storage
  const serializedState = localStorage.getItem("dateRange");
  if (serializedState !== null) {

    // Convert to JSON
    const state = JSON.parse(serializedState);

    // Populate the range
    for (const property in state) {
      if (state.hasOwnProperty(property)) {
        range[property] = property === "start" || property === "end"
          ? moment(state[property]).toDate()
          : state[property];
      }
    }
  }

  return range;
};

export const saveDateRangeState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("dateRange", serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

export const loadSystemId = () => {
  try {
    const serializedSystemId = localStorage.getItem("persist:user");
    if (serializedSystemId === null) {
      return undefined;
    }

    return JSON.parse(JSON.parse(serializedSystemId).systemId);
  } catch (err) {
    return undefined;
  }
};
