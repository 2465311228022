import BarChart from "components/Charts/BarChart"
import ColumnnChart from "components/Charts/ColumnChart"
import LineChart from "../../../../../../components/Charts/LineChart"
import { exponentialIncrease, gaussian, humanNumber, quadraticCurve } from "../../../../../../utils/functions"

/**
 * Show the selected indicator as a chart.
 *
 * @param {?string} selected The key of the currently selected indicator.
 * @param {GranularDataWithChartsDTO} data The data to show.
 *
 * @return {JSX.Element}
 * @constructor
 */
const BigCharts = ({ selected, data, label }) => {
  // console.log({ label, selected })
  const adt = label?.map(item => humanNumber(Math.round(parseFloat(item.value))))


  // Define parameters
  const peakValue = 1000;  // Peak value of the curve
  const width = 20;  // Width of the curve

  // Generate the list
  let numbers = [];
  let invNumbers = []
  for (let x = 1; x <= 100; x++) {
    numbers.push({ y: Math.round(gaussian(x, peakValue, width)), x });
    invNumbers.push({ y: Math.round(quadraticCurve(x)), x });
  }
  // console.log({ numbers, invNumbers });
  // The map of all available charts
  const map = {

    // The total vehicle count
    count: (
      <LineChart
        title="Total vehicles"
        chartXLabel="Total vehicles"
        chartYSuffix=" vehicles"
        data={data?.charts?.count}
        options={{
          yaxis: { labels: { formatter: (val) => humanNumber(val) } }
        }}
      />
    ),

    // Average speed
    avg_speed: (
      <LineChart
        title="Average vehicle speed"
        chartXLabel="Average speed"
        chartYSuffix=" km/h"
        data={data?.charts?.avgSpeed}
      />
    ),

    // average daily traffic
    adt: (
      <ColumnnChart
        chartYSuffix=" vehicles"
        title='Average daily traffic'
        chartXLabel="daily traffic"
        label={label}
        adt={adt}
        data={data?.charts?.count} />
    ),

    // 85th percentile speed
    p85_speed: (
      <LineChart
        title="The 85th percentile speed"
        chartXLabel="The 85th percentile speed"
        chartYSuffix=" km/h"
        data={data?.charts?.p85Speed}
      />
    ),
  }

  return map.hasOwnProperty(selected) ? map[selected] : <></>
}

export default BigCharts
