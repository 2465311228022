import { useState } from "react";

/**
 * Easily update a state to a new value so we can force a change in useEffect().
 *
 * @return {[number, (function(): void)]} The current value and the function to update it.
 */
export const useRefresh = () => {
  const [state, setState] = useState(1);
  return [state, () => setState(state + 1)];
};
