import "./CameraPreview.scss"
import { NavLink } from "react-router-dom"
import { CameraOperationState } from "../CameraOperationState/CameraOperationState"
import { LoadingImage } from "../LoadingImage/LoadingImage"
import { getCameraImageURL } from "../../utils/camera"
import { MessageModal, useMessageModal } from "../Modal/MessageModal"
import CameraCard from "./CameraCard"

/**
 * @param {CameraDTO} camera
 */
export const CameraPreview = ({ camera }) => {
  const messageModal = useMessageModal()
 
  // The cell content
  const cell = (
    <div className="camera-wrapper">
      <div className="camera-wrapper__image ratio-16-9">
        <LoadingImage className="img-fluid cropped ratio-16-9" src={getCameraImageURL(camera.uuid)} alt={camera.location} />
        <CameraOperationState camera={camera} position="top-right" />
      </div>
      <div className="text-secondary wordBreak">
        <h1>{camera.name}</h1>
        <h2>{camera.group}</h2>
        <h3>{camera.location}</h3>
      </div>
    </div>
  )

  // Do not allow details of a camera without data
  return (
    <div className="col-md-3 mb-5">
      {
        camera.isInDataApi
          ? <NavLink to={`/cameras/dashboard/${camera.sys_id}/${camera.camera_id ?? camera.name}`}>
            <CameraCard camera={camera} />
          </NavLink>
          : <div className="clickable" onClick={() => messageModal.show(`Camera ${camera.name}`, `Camera "${camera.name}" currently does not have any data.`)}>{cell}</div>
      }

      <MessageModal hook={messageModal} />
    </div>
  )
}
