import { useState, useEffect, useRef } from "react";

export const useDropDown = () => {
  const dropDownRef = useRef();
  const [toggleDropdownn, setToggleDropdown] = useState(false);

  useEffect(() => {
    const handleDropDownToggle = (e) => {
      if (!dropDownRef?.current?.contains(e.target)) {
        setToggleDropdown(false);
      }
    };
    window.addEventListener("mousedown", handleDropDownToggle);
    return () => {
      window.removeEventListener("mousedown", handleDropDownToggle);
    };
  });

  return { dropDownRef, toggleDropdownn, setToggleDropdown };
};
