import React, { useContext } from 'react'
import { MCLayoutContext } from '../MCLayout'
import MCReport from '../Report/MCReport'
import Step1 from './Step1'
import Step2 from './Step2'
import Step2b from './Step2b'
import Step3 from './Step3'

const Steps = () => {
    const { step } = useContext(MCLayoutContext)

    return (
        <div>{step === 1 ? <Step1 />
            : step === 2 ? <Step2 />
                : step === 3 ? <Step3 />
                    : step === 4 ? <MCReport />
                        : <></>}
        </div>
    )
}

export default Steps