import { useFormik } from "formik"
import { useState } from "react"
import { Redirect, useParams } from "react-router-dom"
import { useMe } from "hooks/useMe"
import Filter, { updateOrdering } from "../../../components/Filter/Filter"
import JSONPainter from "../../../components/JSON/JSONPainter"
import { emptyWrapper } from "../../../components/LoadingWrapper/LoadingWrapper"
import { SelectInput } from "../../../components/Reports/Fields"
import TableColumn from "../../../components/Table/TableColumn"
import TableHeader from "../../../components/Table/TableHeader"
import useCSV, { DownloadCSV } from "../../../hooks/useCSV"
import useCameraManagementAPI from "../../../services/CameraManagementService"
import { useQueryLoading } from "../../../hooks/useLoading"
import LargeCard from "../../../components/Structure/LargeCard"
import { LoadingWrapper } from "../../../components"
import { Table } from "react-bootstrap"
import { get, getQuery } from "../../../utils/data"
import { formikDefault } from "../../../utils/forms-schema"
import { applyCustomTimezoneOffset, formatTimezoneOffset, humanTime, TimeZoneConvert } from "../../../utils/time"
import PaginationToolbar from "../../../components/Pagination/PaginationToolbar"

export const EventLogDashboard = () => {
  const api = useCameraManagementAPI()
  const { isAdmin } = useMe()
  const { page: startingPage } = useParams()

  // Load the values for the filter
  const [filterParams: FilterParam, filterParamsLoadingState] =
    useQueryLoading(`EventLogFilterParams`, () => api.eventFilterParams())

  // Load the audit trail
  const [filter, setFilter] = useState({ page: startingPage || 1, order: "-created" })
  const [eventLog: CMPagination<EventLogEntry>, eventLogLoadingState] =
    useQueryLoading(`EventLog:${getQuery(filter)}`, () => api.eventLog(filter), [filter])

  // Allow download
  const [csv, triggerCSV] = useCSV(async () => (await api.eventLog({...filter, page: 0 })).items.map((row: EventLogEntry) => ({
      "User": row.actor,
    "Action": row.action,
    "Time": row.created.replace(/[A-Z]+$/, "") + formatTimezoneOffset(row.created_tz),
    "Details": JSON.stringify(row.payload).replace(/"/g, "\"\"")
  })))

    // console.log({eventLog, filterParams})

    // Present user an option to choose the timezone for the first column
    const formik = useFormik({...formikDefault, initialValues: {tz: TimeZoneConvert.LOCAL } })
    const headerOptions = (
    <div className="float-right">
      <SelectInput
        formik={formik}
        name="tz"
        className="float-left"
        options={[
          { value: TimeZoneConvert.GMT, label: "Show time in GMT" },
          { value: TimeZoneConvert.ORIGINAL, label: "Show time in original timezone" },
          { value: TimeZoneConvert.LOCAL, label: "Show time in my current timezone" }
        ]} />

      <button className="btn btn-light float-left ml-3" onClick={triggerCSV}>Export to CSV</button>
    </div>
    )

    // Only for admins
    if (!isAdmin) {
    return <Redirect to="/locations" />
  }

    return (
    <LargeCard title="Audit Trail" headerOptions={headerOptions}>
      <DownloadCSV csv={csv} name="audit-log" />
      <Filter params={filterParams?.params ?? []} onChange={filter => setFilter(filter)} />
      <LoadingWrapper state={[eventLogLoadingState, filterParamsLoadingState]} onEmpty={"There are no events to show"} isEmpty={state => emptyWrapper(state, eventLog?.items)}>
        <Table bordered hover={false}>

          <TableHeader order={filter.order} onClick={updateOrdering([filter, setFilter])}>
            <TableColumn title="Time" name="created" direction="-" width="140" />
            <TableColumn title="User" name="actor" width="200" />
            <TableColumn title="Action" name="action" width="340" />
            <TableColumn title="Details" />
          </TableHeader>

          <tbody>
            {(eventLog?.items ?? []).map((entry: EventLogEntry) =>
              <tr key={entry.id}>
                <td>{humanTime(applyCustomTimezoneOffset(get(formik, "values.tz"), entry.created, entry.created_tz))}</td>
                <td>{entry.actor}</td>
                <td>{entry.action}</td>
                <td><JSONPainter json={entry.payload} collapsed={true} /></td>
              </tr>
            )}
          </tbody>
        </Table>

        <PaginationToolbar pagination={eventLog?.pagination} setPage={page => setFilter({ ...filter, page })} />
      </LoadingWrapper>
    </LargeCard>
    )

}

    export default EventLogDashboard
