import JSONPainterCollapsable from "./JSONPainterCollapsable"
import JSONPainterNode from "./JSONPainterNode"

const JSONPainterNodeArray = ({ node, collapsed = false }) => {
  const len = node.length

  return (
    <JSONPainterCollapsable collapsed={collapsed} before="[ " after=" ]">
      {"["}
      <ol>
        {node.map((item, index) => (
          <li key={index}>
            <JSONPainterNode node={item} />
            {index < len - 1 ? <>,<br /></> : ""}
          </li>
        ))}
      </ol>
      {"]"}
    </JSONPainterCollapsable>
  )
}

export default JSONPainterNodeArray
