import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthService from "services/AuthService";
import { ucWords } from "utils/functions";
import { adminRoles } from "utils/user-management";

/** @type AuthSlice */
const anonymous = {
  user: null,
  token: null,
  refresh: null,
  systems: []
};

export const reducer = persistReducer({ storage, key: "auth", whitelist: ["systems", "user", "auth", "refresh"] }, (state = anonymous, action) => {
  switch (action.type) {
    case "login":
    case "refresh": {
      const response: KeyCloakResponse = action?.payload;

      // The token must readable
      const jwt = AuthService.decodeJWT(response?.access_token);
      if (!jwt) {
        return anonymous;
      }

      // Get the current timestamp
      const timestamp = Math.floor(new Date().getTime() / 1000);
      const tenant_manager_system_ids = jwt?.tenant_manager_system_ids ?? [];
      return {
        systems: jwt.system_ids,
        user: {
          name: jwt.name,
          firstname: jwt.given_name,
          lastname: jwt.family_name,
          username: jwt.preferred_username,
          email: jwt.email,
          groups: (jwt.system_ids ?? []).map((system) => ucWords(system.replace(/-/g, " "))),
          id: jwt.sub,
          userManagingTenantsIds: tenant_manager_system_ids,
          userViewingTenantsIds: jwt.system_ids?.filter((sys_id) => !tenant_manager_system_ids?.includes(sys_id))
        },
        auth: {
          token: response.access_token,
          expires: timestamp + response.expires_in
        },
        refresh: {
          token: response.refresh_token,
          expires: timestamp + response.refresh_expires_in
        },
        // Check if current user has enough roles to be an admin, maybe we should check each role separately
        access: jwt.resource_access["realm-management"]?.roles?.length >= adminRoles.length
      };
    }

    case "logout": {
      AuthService.writeToStorage(null);
      return anonymous;
    }

    default:
      return state;
  }
});

/**
 * Log the user in with the response from the KeyCloak server.
 *
 * @param {KeyCloakResponse} response The complete response from the KeyCloak authentication server.
 */
export const login = (response: KeyCloakResponse) => (dispatch) => dispatch({ type: "login", payload: response });

/**
 * Refresh the authentication token.
 *
 * @param {KeyCloakResponse} response The complete response from the KeyCloak authentication server.
 */
export const refresh = (response: KeyCloakResponse) => (dispatch) => dispatch({ type: "refresh", payload: response });

/**
 * Log the user out of the system.
 */
export const logout = () => (dispatch) => dispatch({ type: "logout" });
