/**
 * Create a section on the camera details page.
 *
 * @param {string} title The title of the section.
 * @param {JSX.Element} children The contents of the section.
 * @param {[JSX.Element, action][]} options The options for the header.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const Section = ({ title, children, options = [] }) => (
  <div className="camera-section">
    <h4 className={!children ? "mb-0" : null}>

      {
        // Show the header options
        options && (
          <div className="header-options float-right">
            {
              options?.map((option, index) => (
                <span key={index} className="cursor-pointer" onClick={option[1]}>
                  {option[0]}
                </span>
              ))
            }
          </div>
        )
      }

      {title}

    </h4>
    {children}
  </div>
);

/**
 * Build an toggle button for the section header.
 *
 * @param {JSX.Element} icon The icon to use from the mui-icons {@see https://mui.com/components/material-icons/}.
 * @param {boolean} active True to show this as an active option.
 * @param {function} onClick The callback to invoke when the user click son the option.
 *
 * @return {(JSX.Element|*)[]} A single header option.
 */
export const headerToggle = (icon: JSX.Element, active: boolean = false, onClick: function) =>
  [<span className={`right btn-icon${active ? " active" : ""}`}>{icon}</span>, onClick];

/**
 * Build an option for the section header.
 *
 * @param {JSX.Element} icon The icon to use from the mui-icons {@see https://mui.com/components/material-icons/}.
 * @param {?string} label The optional label to place beside the icon.
 * @param {boolean} active True to show this as an active option.
 * @param {function} onClick The callback to invoke when the user click son the option.
 *
 * @return {(JSX.Element|*)[]} A single header option.
 */
export const headerButton = (icon: JSX.Element, label: ?string = null, active: boolean = false, onClick: function) =>
  [<button className="btn btn-sm align-self-center float-right btn btn-secondary">{icon}<span>{label}</span></button>, onClick];
