import { ArrowBack } from '@material-ui/icons'
import { ExportToCsv, LoadingWrapper } from 'components'
import LargeCard from 'components/Structure/LargeCard'
import { getVehicleTypes } from 'config/VehicleTypes'
import { State, useLoading } from 'hooks/useLoading'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import useCameraManagementAPI from 'services/CameraManagementService'
import { getMCReportCount, getMCReportPcu, getMCReportTotal, getReportColumn, reportVehicleSummary } from 'utils/functions'
import GranularTable from '../GranularTable'
import MCReportFilter from './MCReportFilter'
import { McResContext } from './MCResLayout'


const MCReport = () => {
    // const report = require('../../../../utils/zejtun_estimate.json')
    const history = useHistory()
    const api = useCameraManagementAPI()
    const dateRange = useSelector(state => state.dateRange)
    const [pcu, pcuLoadingState] = useLoading(() =>
        api.getPcuReport(dateRange),
        []
    )
    const {
        report, selectedReportType, setStep,
        selectedEntries, setSelectedEntries,
        selectedExits, setSelectedExits, entryName, exitName,
        camera } = useContext(McResContext)
    const [csv, setCsv] = useState([])
    const [filteredData, setFilteredData] = useState(null)
    const [mappedData, setMappedData] = useState(null)
    const vehicles = getVehicleTypes();

    console.log({ filteredData, mappedData })
    useEffect(() => {
        if (report) {
            if (!selectedReportType?.includes('vehicletype_granular_estimate')) {
                setFilteredData(report?.entries?.filter(entry => entry?.transit_point?.type?.toLowerCase()?.includes('entry')))
                const { col_header } = getReportColumn(report?.entries)
                setMappedData(col_header)
            }
            else {
                setFilteredData(report?.data)
            }
        }

    }, [report, selectedReportType])

    // CSV table
    useEffect(() => {
        if (report) {
            let arr = [], res = [];
            vehicles?.map(item => arr.push(item?.name))
            if (!selectedReportType?.includes('vehicletype_granular_estimate')) {
                arr.unshift('Routes')
                res.push(arr)
                report?.entries?.map(entry => entry?.flows?.map((item, i) => {
                    let new_arr = [`${entry?.transit_point?.lane?.name} --> ${item?.transit_point?.lane?.name}`]
                    vehicles.map(ite => new_arr.push(reportVehicleSummary(ite?.name, item?.vehicles)?.count))
                    res.push(new_arr)
                }))

            }
            else {
                arr.unshift('Date', 'Time')
                arr.push('Total', 'Pcu')
                res.push(arr)
                if (pcu) {
                    report?.data?.map((item) => {
                        let new_arr = [`${moment(item?.start).format('DD/MM/YYYY')}`,
                        `${moment(item?.start).format('H:mm:ss')} - ${moment(item?.end).format('H:mm:ss')}`
                        ]
                        vehicles?.map((vehicle) => new_arr.push(getMCReportCount(item?.entries, vehicle)))
                        new_arr.push(getMCReportTotal(item?.entries))
                        new_arr.push(getMCReportPcu(item?.entries, pcu))
                        res.push(new_arr)
                    })
                }
            }
            setCsv(res)
        }
    }, [report, pcu, selectedReportType])

    // The header options
    const headerOptions = (
        <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
            <Link onClick={() => setStep(1)} className='mr-2'>
                <ArrowBack /> Back
            </Link>

            <ExportToCsv data={csv}
                filename={`${selectedReportType?.includes('vehicletype_granular_estimate') ? `${entryName} - ${exitName}`
                    : 'Multi-Camera'}`} />
        </div>
    )

    return (
        <LargeCard title={`Multi-Camera Report`} headerOptions={headerOptions}>
            <h4 className='mb-4'>
                <strong>{camera?.multiCameraContext?.name}</strong>
            </h4>

            <LoadingWrapper
                state={pcuLoadingState}
                onEmpty={"There is no data to show for the supplied filter"}
            >

                {/* Entry and Exit Camera Filter */}
                {selectedReportType?.includes('vehicletype_granular_estimate') &&
                    <div className='mb-3'>
                        <MCReportFilter report={report}
                            selectedEntries={selectedEntries}
                            setSelectedEntries={setSelectedEntries}
                            selectedExits={selectedExits}
                            setSelectedExits={setSelectedExits} />
                    </div>
                }
                {filteredData && filteredData?.length > 0 ? (
                    <>
                        {selectedReportType === 'estimate' ? <Table bordered hover={false} className="v-align">
                            <thead>
                                <tr>
                                    <th>Entry</th>
                                    {mappedData?.map((item, i) => <th key={i}>{item}</th>)}
                                </tr>
                            </thead>
                            <tbody>

                                {filteredData?.map((item, ind) => <tr key={ind}>
                                    <td>{item?.transit_point?.lane?.name}</td>
                                    {item?.flows.map(data => <td>{data?.count}</td>)}
                                    {/* {mappedData?.map((data, i) => <td key={i}>{gridMatrixFn(item?.flows, data, ind)}</td>)} */}
                                </tr>)}
                            </tbody>
                        </Table> : selectedReportType.includes('vehicletype_summary_estimate') ?
                            <Table bordered hover={false} className="v-align">
                                <thead>
                                    <tr>
                                        <th width="30%">Routes</th>
                                        {vehicles?.map((item, i) => <th key={i}>{item?.name}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {report?.entries?.map(entry => entry?.flows?.map((item, i) => {
                                        return <tr key={i}>
                                            <td>{`${entry?.transit_point?.lane?.name} --> ${item?.transit_point?.lane?.name}`}</td>
                                            {vehicles.map((ite, i) => <td key={i}>{reportVehicleSummary(ite?.name, item?.vehicles)?.count}</td>)}
                                        </tr>
                                    }))
                                    }
                                    {/* {mappedData?.map((item, ind) => <tr key={ind}>
                                        <td>{item}</td>
                                        {vehicles.map((item, i) => <td key={i}>{reportVehicleSummary(item?.name, report?.entries)?.count}</td>)}
                                    </tr>)} */}
                                </tbody>
                            </Table> :
                            selectedReportType?.includes('vehicletype_granular_estimate') ? <GranularTable pcu={pcu} /> :
                                <></>}
                    </>

                ) : (
                    <h1 className="text-center my-10">No data found for this camera pair</h1>
                )}
            </LoadingWrapper>
        </LargeCard>
    )
}

export default MCReport