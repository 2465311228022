import React from "react"
import { useParams, useHistory } from "react-router-dom"
import { Table, Button } from "react-bootstrap"
import { CloudUploadOutlined } from "@material-ui/icons"
import LargeCard from "components/Structure/LargeCard"
import { LoadingWrapper } from "components"
import { FormText } from "../../../../components/Reports/Fields"
import TableRow from "./TableRow"
import { useGetFiles } from "../queries"

const EditFiles = () => {
  const history = useHistory()
  const { batchUUID } = useParams()
  const { data: batchFiles, status } = useGetFiles(batchUUID)
  const headerOptions = (
    <div className="float-right">
      <Button variant="primary" className="btn btn-primary" onClick={() => history.push(`/camera-files/add-files/${batchUUID}`)}>
        <CloudUploadOutlined /> <span className="ml-2">Add more files</span>
      </Button>
    </div>
  )

  return (
    <LargeCard title="Edit Files" headerOptions={headerOptions}>
      <LoadingWrapper state={status} onEmpty={"There are currently no files in this batch"}>
        <FormText>You can order your files once your proceed to "Process Batch"</FormText>
        <Table bordered hover={false} className="v-align">
          <thead>
            <tr>
              <th className="text-left">File name</th>
              <th width="20%" className="text-left">File Size</th>
              <th width="20%" className="text-left">Status</th>
              <th width="10%" className="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              batchFiles?.files?.map((file) => (
                <TableRow
                  key={file.uuid}
                  start={batchFiles.start}
                  cameraUUID={batchFiles.camera.uuid}
                  batchUUID={batchFiles.uuid}
                  fileUUID={file.uuid}
                  batchName={batchFiles.name}
                  name={file.name}
                  size={file.size}
                  status={file.status}
                />
              ))
            }
          </tbody>
        </Table>
        <div className="float-right">
          <Button variant="secondary" className="mt-9  mr-3" onClick={() => history.push("/camera-files")}>
            Back
          </Button>
        </div>
      </LoadingWrapper>
    </LargeCard>
  )
}

export default EditFiles
