import { Button, Modal } from "react-bootstrap";
import useCameraManagementAPI from "../../../../services/CameraManagementService"
import { useRemoveUserFromTenant } from "../queries";

const DeleteUser = ({ user, show, exitModal }) => {
  const audit = useCameraManagementAPI()
  const { id, email_address, groups: tenants } = user;
  const removeUserFromTenant = useRemoveUserFromTenant(id);

  const handleRemoveUser = () => {
    tenants.forEach((tenant) => {
      removeUserFromTenant.mutate(tenant.system_id);
    });
    exitModal();

    // Audit log
    audit.log("# User delete", user)
  };
  return (
    <Modal centered show={show} size="lg" onHide={exitModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-weight-light">
          Are you sure you want to permanently delete the user: <span className="font-weight-bold">{email_address}</span>?
        </h5>
        <div className="mt-5 float-right">
          <Button variant="secondary" onClick={exitModal}>
            Cancel
          </Button>
          <Button variant="danger" className="ml-3" onClick={handleRemoveUser}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteUser;
