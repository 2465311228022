import { useState } from "react";
import { Redirect } from "react-router-dom";
import { AddUserDetails } from "./components/AddUserDetails";
import { AddUserRole } from "./components/AddUserRole";
import { useMe } from "hooks/useMe";
export const AddUser = () => {
  const { isAdmin } = useMe();
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    tenants: []
  });
  if (!isAdmin) {
    return <Redirect to="/locations" />;
  }

  return userDetails.password === "" ? (
    <AddUserDetails details={{ userDetails, setUserDetails }} />
  ) : (
    <AddUserRole details={{ userDetails, setUserDetails }} />
  );
};
