/**
 * A standardized large card for the application.
 *
 * @param {string} title The title for the header, or null to skip the header.
 * @param {string} subtitle The subtitle for the header, or null to skip it.
 * @param {JSX.Element} headerOptions The additional options for the header.
 * @param {JSX.Element} children The children to show.
 * @param {{}} props All other properties to be passed on to the top element.
 *
 * @return {JSX.Element}
 */
const LargeCard = ({ title, subtitle, headerOptions, children, ...props }) => (
  <div className="card" {...props}>
    {title != null && (
      <div className="card-header">
        {headerOptions}
        <h3>{title}</h3>
        {subtitle && <h4>{subtitle}</h4>}
      </div>
    )}

    <div className="card-body">{children}</div>
  </div>
);

export default LargeCard;
