import { useValidLine } from "hooks/useValidLine"
import { useEffect, useRef } from "react"
import { Rect, Line, Arrow, Group, Transformer, Text } from "react-konva"
import { deepClone } from "utils/data"
import { checkValidLines, getEdgeCoords, getRectVertices, getVertices, getVerticesCoords } from "utils/functions"
import Rectangle from "utils/rectangle"

/**
 * @return {JSX.Element}
 * @constructor
 *
 * @typedef {{current: { getAbsoluteTransform: function }}} ShapeRef
 * @typedef {{current: { nodes: function, getLayer: function }}} TransformerRef
 */
const ZoneBox = ({ shape, isSelected, onSelect, updateShape, isEditable, width, height, handleZone }) => {
  // Make sure
  let shapeClone = deepClone(shape)
 
  // Reference to the share
  const shapeRef: ShapeRef = useRef()

  // Attach the Transformer
  const transformerRef: TransformerRef = useRef()
  useEffect(() => {
    if (isEditable && isSelected && transformerRef.current) {
      transformerRef.current.nodes([shapeRef.current])
      transformerRef.current.getLayer().batchDraw()
    }
  }, [isEditable, isSelected])

  // Handle new coords so that the line is at the center and on 45 degrees
  if (shapeClone?.coords === null) {
    const length = 65
    shapeClone.coords = [
      .5 - length / width,
      .5 - length / height,
      .5 + length / width,
      .5 + length / height
    ]
  }
  // Handle new vertices so that the line is at the center and on 45 degrees
  if (shapeClone?.vertices === null || shapeClone?.vertices?.length === 0) {
    const length = 65
    shapeClone.vertices = [
      .5 - length / width,
      .5 - length / height,
      .5 + length / width,
      .5 + length / height
    ]
  }

  let origin;

  if (shapeClone?.vertices[0][0]) {
    origin = [shapeClone.vertices[0][0], shapeClone.vertices[2][1], shapeClone.vertices[1][0], shapeClone.vertices[1][1]]
  }
  else {
    // Make sure the origin vertices will not be changed
    origin = [...shapeClone?.vertices]
  }

  // Convert percentages to absolute values in pixes
  const relative = new Rectangle(...origin, true)
  const absolute = relative.scale(width, height)

  if (relative) {
    shapeClone.vertices = getVerticesCoords(relative)
  }
  const handlePositionChange = (e) => {
    const { isValid } = checkValidLines(absolute, width, height, handleZone)
    handleZone(!isValid)
    return updateShape({
      ...shapeClone,
      vertices: getEdgeCoords(e.target)
    });
  }

  const handleTransform = (e) => {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    const new_vertices = getRectVertices(node, scaleX, scaleY, width, height)
    return updateShape({
      ...shapeClone,
      vertices: new_vertices,
      angle: node.rotation(),
    });
  }
  // const handleShapePositionChange = (e) => {
  //   // Guard: Editable
  //   if (!isEditable) {
  //     return
  //   }

  //   // Track shapes coordinates
  //   const node = shapeRef.current

  //   return updateShape({ ...shapeClone, vertices: getEdgeCoords(e.target), angle: node.rotation() })
  // }

  return (
    <>
      {/* <Group
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        draggable={isEditable}
        onDragEnd={handlePositionChange}
        onTransformEnd={handleTransform}
      >
        <Rect
          x={absolute.left}
          y={absolute.top}
          width={absolute.width}
          height={absolute.height}
          rotation={shapeClone?.angle}
          fill="transparent"
          stroke='red'

        />
        <Text
          x={absolute.center.x}
          y={absolute.center.y}
          rotation={shapeClone?.angle}
          text={shapeClone.id}
          fill={'green'}
          fontSize={30} />
      </Group> */}
      <Rect
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        x={absolute.left}
        y={absolute.top}
        width={absolute.width}
        height={absolute.height}
        rotation={shapeClone?.angle}
        fill="transparent"
        stroke='red'
        draggable
        onDragEnd={handlePositionChange}
        onTransformEnd={handleTransform}
      />

      {/* Add a Text element for labeling */}
      {shapeClone?.id && (
        <Text
          x={absolute.left}
          y={absolute.top}
          text={shapeClone.id}
          fontSize={absolute.text}
          rotation={shapeClone?.angle}
          fill="yellow"
          align="center"
          verticalAlign="middle"
        // offsetX={shapeClone.id * 4} // Adjust the offset as needed
        />
      )}
      {isEditable && isSelected && (
        <Transformer
          ref={transformerRef}
          enabledAnchors={["top-left", "top-center", "top-right", "middle-right", "bottom-right", "bottom-center", "bottom-left", "middle-left"]}

          // Make sure the box cannot get too small
          boundBoxFunc={(oldBox, newBox) => {
            return newBox.width > 30 && newBox.height > 30
              ? newBox
              : oldBox
          }}
        />
      )}
    </>
  )
}

export default ZoneBox
