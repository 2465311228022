import { useState } from "react"
import CSVDownload from "react-csv/src/components/Download"

/**
 * Connect a CSV to a promise that will fetch the data.
 *
 * @param {Promise<[][]>} promise The promise that will return the data for the CSV.
 *
 * @return {[unknown,((function(): Promise<void>)|*)]} The hook.
 */
const useCSV = (promise: Promise<[][]>) => {
  const [csv, setCSV] = useState(null)

  // Download when triggered
  const trigger = async () => {
    setCSV(true)
    setCSV(await promise())
  }

  return [csv, trigger]
}

/**
 * Download the CSV when ready.
 *
 * @param {unknown} csv The first value from the useCSV() hook.
 * @param {string} name The name of the file, without ".csv" extension.
 *
 * @return {JSX.Element}
 * @constructor
 */
export const DownloadCSV = ({ csv, name }) =>
  csv && typeof csv === "object"
    ? <CSVExport data={csv} name={name} />
    : <></>

/**
 * NOT WORKING!!
 * Download a CSV file with a custom name.
 *
 * @param {[]} data The CSV data.
 * @param {string} name The name of the downloaded file, without the extension.
 *
 * @return {JSX.Element}
 * @constructor
 */
const CSVExport = ({ data, name }) => {
  return <CSVDownload data={data} name={`${name}.csv`} />
  // const ref = useRef(null)
  // useEffect(() => ref.current?.click(), [ref])
  // return (
  //   <CSVLink data={csv} name={`${name}.csv`}><span ref={ref} /></CSVLink>
  // )
}

export default useCSV
