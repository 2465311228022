import { DateRange } from '@material-ui/icons'
import { DateRangePickerModal, LoadingWrapper } from 'components'
import { allowEmpty } from 'components/LoadingWrapper/LoadingWrapper'
import LargeCard from 'components/Structure/LargeCard'
import { useLoading } from 'hooks/useLoading'
import React, { useContext } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useAPI from 'services/ApiService'
import { getReadableTime } from 'utils/functions'
import { formatDateRange } from 'utils/time'
import { EventsContext } from './EventsLayout'

const AllEvents = () => {
    const api = useAPI()
    const { dateRange, systemID, setDateRangeModal, dateRangeModal } = useContext(EventsContext)
    const [data, dataLoading] = useLoading(() => api.getAllVehicleCount(null, dateRange))

    const DateRangeFilterHeader =
        <>
            <div
                className="row date-range justify-content-between w-1/5 border rounded align-self-center mr-5 cursor-pointer px-5 py-2"
                onClick={() => setDateRangeModal(true)}
                title="Current date range"
            >
                <div className="mr-3 font-size-lg">
                    <span>{formatDateRange(dateRange.start, dateRange.end)}</span>
                </div>
                <DateRange title="date range" />
            </div>
            <div className="csv">
                <DateRangePickerModal show={[dateRangeModal, setDateRangeModal]} />
            </div>

        </>

    return (
        <LargeCard title="Events" headerOptions={DateRangeFilterHeader}>
            <LoadingWrapper state={allowEmpty(dataLoading)} onEmpty={"Data currently unavailable"}>
                <Table bordered hover={false} className="v-align">
                    <thead>
                        <tr>
                            <th width="*">Camera name</th>
                            <th width="*">System</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, i) =>
                            <tr key={i}>
                                <td>    <Link to={`/events/${item?.camera?.sys_id}/${item?.camera?.camera_id}`}>
                                    {item?.camera?.name}
                                </Link>
                                </td>
                                <td>{item?.camera?.sys_id}</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </LoadingWrapper>
        </LargeCard >

    )
}

export default AllEvents