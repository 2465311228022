import { useState } from "react";

/**
 * Make sure that multiple calls to a setter are ignored
 * and that only the last one is executed.
 *
 * @param {any} initialValue The initial value to set.
 * @param {int} timeout The amount of time we wait until the action is dispatched, in milliseconds.
 *
 * @return {[value, function({any})]} Used the same way as the useState() hook.
 */
export const useStateWithDebounce = (initialValue, timeout = 300) => {

  // The actual value that is persisted over renders
  const [value, setValue] = useState(initialValue);

  // The last update and the reference to the timeout
  let previousCallback = null;

  // The setter that will handle the actual debouncing
  const setState = (newState) => {

    // Cancel the previous callback
    clearTimeout(previousCallback);

    // // Set the action in the future
    previousCallback = setTimeout(() => {
      setValue(newState);
      previousCallback = null;
    }, timeout);
  };

  return [value, setState];
};
