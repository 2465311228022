import { NavLink } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { Table } from "react-bootstrap";
import { LoadingWrapper } from "components";
import LargeCard from "components/Structure/LargeCard";
import UserTableRow from "./UserTableRow";
import { useMe } from "hooks/useMe";
import { useUsersQuery } from "../queries";
import { useState } from "react";
import Filter from "components/Filter/Filter";
import { useMemo } from "react";

export const UsersTable = () => {
  const { userManagingTenantsIds } = useMe();

  // Get the users for each tenant which that the logged in user is a manager
  const { users, status } = useUsersQuery(userManagingTenantsIds);
  const [filter, setFilter] = useState({ term: "" })

  const params = [{
    name: 'term',
    label: 'Search name or email',
    type: 'text'
  }]

  // Memoize the filtered users to avoid unnecessary re-renders
  const filteredUsers = useMemo(() => {
    if (!users) return [];
    return users.filter((user) => {
      if (!filter.term) return true;
      const searchTerm = filter.term.toLowerCase();
      return (
        user?.first_name?.toLowerCase().includes(searchTerm) ||
        user?.last_name?.toLowerCase().includes(searchTerm) ||
        user?.username?.toLowerCase().includes(searchTerm)
      );
    });
  }, [users, filter.term]);

  // The header options
  const headerOptions = (
    <div className="float-right">
      <NavLink className="btn btn-primary camera-views" to="/user-management/add-user">
        <Add /> Add user
      </NavLink>
    </div>
  );

  return (
    <>
      <LargeCard title="User Management" headerOptions={headerOptions}>
        <LoadingWrapper state={status} onEmpty={"There is no user to show."}>
          <Filter params={params} onChange={filter => setFilter(filter)} />
          <Table bordered hover={false}>
            <thead>
              <tr>
                <th width="35%" className="text-left">
                  Name
                </th>
                <th className="text-left">Email</th>
                {/* <th width="13%" className="text-left">
                  Status
                </th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user) => <UserTableRow user={user} key={user?.id} />)}
            </tbody>
          </Table>
        </LoadingWrapper>
      </LargeCard>
    </>
  );
};
