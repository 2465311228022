/**
 * Print an average speed for CSV.
 *
 * @param {number} avgSpeed The original average speed.
 *
 * @return {string} The formatted average speed.
 */
export const csvAvgSpeed = (avgSpeed) => {
  if (avgSpeed == null || isNaN(avgSpeed) || avgSpeed === 0) {
    return "";
  }

  return avgSpeed.toFixed(0);
};

/**
 * Print a percentage for CSV.
 *
 * @param {number} percent The original average speed.
 *
 * @return {string} The formatted average speed.
 */
export const csvPercent = (percent) => {
  if (percent == null) {
    return "";
  }

  return percent.toFixed(2);
};
