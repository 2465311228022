import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { HiOutlineArrowNarrowUp, HiOutlineArrowNarrowDown } from 'react-icons/hi'
import { getReadableTime } from 'utils/functions'
import { EventsContext } from './EventsLayout'

const TableComp = ({ mapData, setMappedData, vehicleFilter, selectedApi }) => {
    const [columnOrder, setColumnOrder] = useState(false)
    const [col_filter, setCol_filter] = useState('')
    const { page } = useContext(EventsContext)

    // lower and upper boundary for pagination
    let lower = (page - 1) * 20
    let upper = page * 20

    const handleFilterData = (filter) => {
        let sortedData;
        setColumnOrder(!columnOrder);
        setCol_filter(filter)

        if (filter === 'duration') {
            if (!columnOrder) {
                sortedData = selectedApi !== 'vehicles-stopped-list' ?
                    mapData?.sort((a, b) => a?.avg_duration < b?.avg_duration ? 1 : a?.avg_duration > b?.avg_duration ? -1 : 0) :
                    mapData?.sort((a, b) => a?.duration_seconds < b?.duration_seconds ? 1 : a?.duration_seconds > b?.duration_seconds ? -1 : 0)
            }
            else {
                sortedData = selectedApi !== 'vehicles-stopped-list' ?
                    mapData?.sort((a, b) => a?.avg_duration > b?.avg_duration ? 1 : a?.avg_duration < b?.avg_duration ? -1 : 0) :
                    mapData?.sort((a, b) => a?.duration_seconds > b?.duration_seconds ? 1 : a?.duration_seconds < b?.duration_seconds ? -1 : 0)
            }
            setMappedData(sortedData)
        }
        else if (filter === 'date') {
            sortedData = mapData.sort((x, y) => {
                x = new Date(x.start_ts);
                y = new Date(y.start_ts);
                if (!columnOrder) {
                    return x - y;
                }
                else {
                    return y - x;
                }
            });
            setMappedData(sortedData)
        }
        else if (filter === 'count') {
            if (!columnOrder) {
                sortedData =
                    mapData?.sort((a, b) => a?.count < b?.count ? 1 : a?.count > b?.count ? -1 : 0)
            }
            else {
                sortedData =
                    mapData?.sort((a, b) => a?.count > b?.count ? 1 : a?.count < b?.count ? -1 : 0)
            }
            setMappedData(sortedData)
        }
    }

    useEffect(() => {
        handleFilterData('date')
    }, [])

    return (
        <Table bordered hover={false} className="v-align">
            <thead>
                <tr>
                    <th>
                        <span className="align-center" onClick={() => {
                            handleFilterData('date')
                        }}> Date
                            {columnOrder && col_filter === 'date' ? <HiOutlineArrowNarrowUp /> : <HiOutlineArrowNarrowDown />}
                        </span>
                    </th>
                    <th>Time</th>
                    {selectedApi !== 'vehicletypes-stopped-count-granular' && <th width="250">Zone</th>}
                    {selectedApi !== 'vehicletypes-stopped-count-granular' && <th width="200">Type</th>}
                    {selectedApi !== 'vehicles-stopped-count-granular' && <th width="200">Tracked items</th>}
                    <th width="200">
                        <span className="align-center" onClick={() => {
                            handleFilterData('duration')
                        }}>Avg Time in zone
                            {columnOrder && col_filter === 'duration' ? <HiOutlineArrowNarrowUp /> : <HiOutlineArrowNarrowDown />}
                        </span>
                    </th>
                    {selectedApi !== 'vehicles-stopped-list' && <th>
                        <span className="align-center" onClick={() => {
                            handleFilterData('count')
                        }}>Count
                            {columnOrder && col_filter === 'count' ? <HiOutlineArrowNarrowUp /> : <HiOutlineArrowNarrowDown />}
                        </span>
                    </th>}

                    {/* <th /> */}
                </tr>
            </thead>
            <tbody>
                {mapData?.slice(lower, upper)?.map(vehicle => <tr key={vehicle?.uuid}>
                    <td>{moment(vehicle?.start_ts).format('DD/MM/YYYY')}</td>
                    <td>{moment(vehicle?.start_ts).format('H:mm:ss')} - {moment(vehicle?.end_ts).format('H:mm:ss')}</td>
                    {selectedApi !== 'vehicletypes-stopped-count-granular' && <td>{vehicle?.name}</td>}
                    {selectedApi !== 'vehicletypes-stopped-count-granular' && <td>{vehicle?.type}</td>}
                    {selectedApi !== 'vehicles-stopped-count-granular' && <td>{vehicle?.vehicle_type?.type ?? vehicle?.vehicle}</td>}
                    <td>{getReadableTime(selectedApi !== 'vehicles-stopped-list' ? vehicle?.avg_duration : vehicle?.duration_seconds)}</td>
                    {selectedApi !== 'vehicles-stopped-list' && <td>{vehicle?.count}</td>}

                    {/* <td>
                      <MoreFilter filter={filter} items={[{ type: 'zone', name: vehicleFilter?.name },
                      { type: 'Infringement type', name: vehicleFilter?.type }]}
                        setFilter={handleFilterData} />
                    </td> */}

                </tr>)
                }
            </tbody>
        </Table>
    )
}

export default TableComp