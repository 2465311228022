import { FormText } from "../../../../components/Reports/Fields"
import LargeCard from "../../../../components/Structure/LargeCard"
import CameraCanvas from "../Form/CameraCanvas/CameraCanvas"
import { stepTitle } from "../form"
import { useCameraImage } from "../../../../hooks/useCameraImage"

/**
 * Define the position, the size and the orientation of the lines.
 *
 * @param camera
 * @param title
 * @param updateLines
 * @param prevStep
 * @return {JSX.Element}
 * @constructor
 */
const Step4 = ({ camera, title, setValues, prevStep }) => {
  const image = useCameraImage(camera)

  return (
    <LargeCard title={stepTitle(title, camera, "Entry & exit lines")}>

      <FormText>
        <p>Here you'll mark all the lane entry & exit lines you want the system to count vehicles for.</p>
        <strong>Instructions</strong>
        <ul>
          <li>Add a line from the buttons below then manipulate it by clicking the line and resizing or rotating as need be</li>
          <li>The yellow arrow signifies the direction of traffic</li>
        </ul>
        <strong>Tips</strong>
        <ul>
          <li>Make sure the line is perpendicular to the lane it was drawn on</li>
          <li>It’s better if the line is a bit longer as to make sure it catches objects passing</li>
          <li>Be careful when drawing lines around occluded areas</li>
          <li>
            Do not draw lines too close to the edges of the camera in order to allow enough time for the vehicle to fully appear
          </li>
        </ul>
      </FormText>

      <CameraCanvas image={image} lines={camera?.lines ?? []} updateLines={lines => setValues({ lines })} prevStep={prevStep} />
    </LargeCard>
  )
}

export default Step4
