import { useState, Fragment } from "react";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { buildReportLink } from "../Reports";
import { useLoading } from "hooks/useLoading";
import { ExportToCsv, LoadingWrapper } from "components";
import { humanNumber, humanPercent, humanSpeed } from "utils/functions";
import LargeCard from "components/Structure/LargeCard";
import { IndentArrow } from "components/Structure/Elements";
import { set, toArray } from "utils/data";
import { csvAvgSpeed, csvPercent } from "utils/csv";
import { readReportParamsFromQuery } from "../Reports";
import ReportFilters from "components/Reports/ReportFilters";
import { ArrowBack } from "@material-ui/icons";
import useAPI from "services/ApiService";
import useStoreDateRange from "hooks/useStoreDateRange";
import format from "date-fns/format";

export const LaneFlowStructuredReportPageByExit = () => {
  const dateRange = useStoreDateRange();
  const api = useAPI();

  // Read Report type
  const reportType = window.location.pathname.split("/")[2];

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery());

  // Load the data
  const [camera: CameraDTO] = useLoading(() => api.camera(params));
  const [report: LaneFlowReportDTO, reportLoadingState] = useLoading(() =>
      api.getDataLaneFlow(params.systemID, params.cameraID, dateRange, params.carriageways, params.lanes, params.vehicles),
    [params],
    ["rows"]
  );

  // Repack the data into a structured pack
  const structured = {};
  for (const item of report?.rows ?? []) {
    set(
      structured,
      [
        item.exit_carriageway,
        item.exit_lane,
        item.entry_carriageway,
        item.entry_lane
      ],
      item
    );
  }

  // CSV table
  const csv = [
    [
      "Exit carriageway",
      "Exit lane",
      "Entry carriageway",
      "Entry lane",
      "Count",
      "Avg speed [km/h]",
      "Entry carriageway [%]",
      "Entry lane [%]",
      "Exit carriageway [%]",
      "Exit carriageway [%]"
    ]
  ];
  for (const row of report?.rows ?? []) {
    csv.push([
      row.exit_carriageway,
      row.exit_lane,
      row.entry_carriageway,
      row.entry_lane,
      row.count,
      csvAvgSpeed(row.avg_speed),
      csvPercent(row.percent_of_entry_carriageway),
      csvPercent(row.percent_of_entry_lane),
      csvPercent(row.percent_of_exit_carriageway),
      csvPercent(row.percent_of_exit_lane)
    ]);
  }

  const startDate = format(dateRange?.start, "dd-MM-yyyy")
  const endDate = format(dateRange?.end, "dd-MM-yyyy")


  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink
        className="mr-5"
        to={buildReportLink(reportType, {
          systemID: params.systemID,
          cameraID: params.cameraID,
          carriageways: params.carriageways,
          lanes: params.lanes,
          vehicles: params.vehicles,
          range: params.range
        }, true)}
      >
        <ArrowBack /> Back to reports generator
      </NavLink>

      <ExportToCsv data={csv} filename={`${camera?.name} - Lane analysis, by exit - ${startDate} - ${endDate}`} />
    </div>
  );
  return (
    <LargeCard title="lane analysis report, by exit" headerOptions={headerOptions}>
      {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

      <LoadingWrapper
        state={reportLoadingState}
        onEmpty={"There is no data to show for the supplied filter"}
      >
        <Table bordered hover>
          <thead>
            <tr>
              <th>Exit</th>
              <th>Entry</th>
              <th className="text-right">Total count</th>
              <th className="text-right">Avg speed</th>
              <th className="text-center">Carriageway %</th>
              <th className="text-center">Lane %</th>
              {/*<th className="text-center">Entry carriageway</th>*/}
              {/*<th className="text-center">Entry lane</th>*/}
            </tr>
          </thead>
          <tbody>
            {// The entry carriageways
              toArray(structured, 4).map((entryCarriageway: KeyValue) => (
                <Fragment key={entryCarriageway.key}>
                  <tr className="table-header">
                    <td colSpan="8">
                      <span className="prefix-carriageway">Carriageway:</span>
                      {entryCarriageway.key}
                    </td>
                  </tr>

                  {// The entry lanes
                    entryCarriageway.value.map((lane: KeyValue) => (
                      <Fragment key={lane.key}>
                        <tr>
                          <td colSpan="8">
                            <IndentArrow br={false} />
                            <span className="prefix-lane">Lane:</span>
                            {lane.key}
                          </td>
                        </tr>

                        {// The exit carriageways
                          lane.value.map((exitCarriageway: KeyValue) => (
                            <Fragment key={exitCarriageway.key}>
                              <tr className="extend-down">
                                <td width="30%" />
                                <td>
                                  <span className="prefix-carriageway">Carriageway:</span>
                                  {exitCarriageway.key}
                                </td>
                                <td colSpan="6" className="border-bottom" />
                              </tr>

                              {// The exit lanes
                                exitCarriageway.value.map(
                                  (exitLane: KeyValue, index) => {
                                    const info: LaneFlowReportRowDTO = exitLane.value;

                                    // Get the class for the row in order to appear as joined
                                    let rowClass = "extend-up";
                                    if (index < exitCarriageway.value.length - 1) {
                                      rowClass += " extend-down";
                                    }

                                    return (
                                      <tr key={exitLane.key} className={rowClass}>
                                        <td width="30%" />
                                        <td>
                                          <IndentArrow br={false} />
                                          <span className="prefix-lane">Lane:</span>
                                          {exitLane.key}
                                        </td>
                                        <td className="text-right">
                                          {humanNumber(info.count)}
                                        </td>
                                        <td className="text-right">
                                          {humanSpeed(info.avg_speed)}
                                        </td>
                                        {/*<td className="text-center">*/}
                                        {/*  {humanPercent(info.percent_of_entry_carriageway)}*/}
                                        {/*</td>*/}
                                        {/*<td className="text-center">*/}
                                        {/*  {humanPercent(info.percent_of_entry_lane)}*/}
                                        {/*</td>*/}
                                        <td className="text-center">
                                          {humanPercent(
                                            info.percent_of_exit_carriageway
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {humanPercent(info.percent_of_exit_lane)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </Fragment>
                          ))}
                      </Fragment>
                    ))}
                </Fragment>
              ))}
          </tbody>
        </Table>
      </LoadingWrapper>
    </LargeCard>
  );
};
