import { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { buildReportLink } from "../Reports";
import { useLoading } from "hooks/useLoading";
import { ExportToCsv, LoadingWrapper } from "components";
import { humanNumber, humanSpeed, isNotEmpty } from "utils/functions";
import LargeCard from "components/Structure/LargeCard";
import ReportFilters from "components/Reports/ReportFilters";
import { csvAvgSpeed } from "utils/csv";
import { getVehicleTypes, sortByVehicleType } from "config/VehicleTypes";
import { readReportParamsFromQuery } from "../Reports";
import { groupLanesByCarriageway } from "utils/carriageways";
import { ArrowBack } from "@material-ui/icons";
import useAPI from "services/ApiService";
import useStoreDateRange from "hooks/useStoreDateRange";
import format from "date-fns/format";

export const LanesSummaryWithVehicleTypeBreakdownReportPage = () => {
  const dateRange = useStoreDateRange();
  const api = useAPI();

  // Read Report type
  const reportType = window.location.pathname.split("/")[2];

  // Read report parameters from the URL
  const [params, updateParams] = useState(readReportParamsFromQuery());

  // Load the data
  const [camera: CameraDTO] = useLoading(() => api.camera(params));
  const [report: LanesSummaryReportDTO, reportLoadingState] = useLoading(() =>
      api.getLanesSummary(params.systemID, params.cameraID, dateRange, params.carriageways, params.lanes, params.vehicles),
    [params],
    ["data"]
  );

  // Vehicle types
  const vehicles = isNotEmpty(params.vehicles)
    ? params.vehicles.map((type) => getVehicleTypes().find((vehicle) => vehicle.type === type))
    : getVehicleTypes();

  // Populate the CSV
  const columns = (prefix: string) =>
    vehicles.reduce(
      (acc, vehicle) => [
        ...acc,
        `${prefix}: ${vehicle.plural} count`,
        `${prefix}: ${vehicle.plural} avg speed [km/h]`
      ],
      []
    );
  const csv = [
    [
      "Carriageway",
      "Lane",
      "Entries",
      "Exits",
      "Avg speed [km/h]",
      ...columns("Entry"),
      ...columns("Exit")
    ]
  ];
  for (const row of report?.data ?? []) {

    // The main columns
    const line = [
      row.carriageway,
      row.lane,
      row.entry_count,
      row.exit_count,
      csvAvgSpeed(row.avg_speed)
    ];

    // Entries and exits for each vehicle type
    for (const pointType of ["entry", "exit"]) {

      // Add for all vehicle types
      const vehicleData = row[`${pointType}_vehicles`];
      for (const vehicle of vehicles) {

        // Find the proper data for the current vehicle type
        const data = vehicleData.find(
          (item) => item.vehicle_type === vehicle.type
        );

        // Add the count and the average speed
        line.push(data?.count ?? 0);
        line.push(csvAvgSpeed(data?.avg_speed));
      }
    }

    csv.push(line);
  }

  // Group lanes by carriageway
  const dataByCarriageway = report ? groupLanesByCarriageway(report.data) : [];

  const startDate = format(dateRange?.start, "dd-MM-yyyy")
  const endDate = format(dateRange?.end, "dd-MM-yyyy")

  // The header options
  const headerOptions = (
    <div className="float-right" style={{ margin: "-8px 0 -8px 10px" }}>
      <NavLink
        className="mr-5"
        to={buildReportLink(reportType, {
          systemID: params.systemID,
          cameraID: params.cameraID,
          carriageways: params.carriageways,
          lanes: params.lanes,
          vehicles: params.vehicles,
          range: params.range
        }, true)}
      >
        <ArrowBack /> Back to reports generator
      </NavLink>
      <ExportToCsv data={csv} filename={`${camera?.name} - Lanes summary with vehicle type breakdown - ${startDate} - ${endDate}`} />
    </div>
  );

  return (
    <LargeCard title="Lane summary with vehicle type break report" headerOptions={headerOptions}>

      {camera && <ReportFilters report={reportType} camera={camera} updateParams={updateParams} />}

      <LoadingWrapper state={reportLoadingState} onEmpty={"There is no data to show for the supplied filter"}>
        <Table bordered hover>
          <thead>
            <tr>
              <th width="*">Lane</th>
              <th width="25%" className="text-right">Entries</th>
              <th width="25%" className="text-right">Exits</th>
              <th width="15%" className="text-right">Avg speed</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(dataByCarriageway).map((carriageway) => (
              <Fragment key={carriageway}>
                <tr className="table-header">
                  <td colSpan="8">{carriageway}</td>
                </tr>
                {dataByCarriageway[carriageway].map((row) => (
                  <tr key={`${row.entry_uuid}-${row.exit_uuid}`}>
                    <td>{row.lane}</td>
                    {["entry", "exit"].map((type) => (
                      <td key={type}>
                        {
                          row[`${type}_count`] === 0 ? ("n/a") : (
                            <>
                              <div className="text-right">
                                {humanNumber(row[`${type}_count`])}
                              </div>

                              <hr />

                              <table className="inner-table">
                                <tbody>
                                  {
                                    sortByVehicleType(row[`${type}_vehicles`], { count: 0 }, vehicles).map((vehicle) => (
                                      <tr key={vehicle.vehicle_type}>
                                        <td width="*">{vehicle.vehicle_name}</td>
                                        <td width="25%" className="text-right">{humanSpeed(vehicle.avg_speed, "-")}</td>
                                        <td width="25%" className="text-right">{humanNumber(vehicle.count)}</td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </>
                          )
                        }
                      </td>
                    ))}
                    <td className="text-right">{humanSpeed(row.avg_speed)}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </LoadingWrapper>
    </LargeCard>
  );
};
