/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useMe } from "hooks/useMe";
import { menuItems, userManagementRoute, tenantsRoute } from "./menu-items";
export function HeaderMenu({ layoutProps }) {
  const headerMenu = [...menuItems];

  const { isAdmin, isFromGreenroads } = useMe();
  if ((isAdmin || isFromGreenroads) && !headerMenu.some((item) => item.name === userManagementRoute.name)) {
    headerMenu.push(userManagementRoute);
  }
  if (isFromGreenroads && !headerMenu.some((item) => item.name === tenantsRoute.name)) {
    headerMenu.push(tenantsRoute);
  }
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <div id="kt_header_menu" className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`} {...layoutProps.headerMenuAttributes}>
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {headerMenu.map((item, index) => (
          <li className={`menu-item menu-item-rel ${getMenuItemActive(item.to)}`} key={index}>
            <NavLink className="menu-link" to={item.to}>
              <span className="menu-text">{item.name}</span>
              {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
            </NavLink>
          </li>
        ))}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
